import { Icon, Image, NewModalLayout } from "@components/common";
import React, { useEffect, useState } from "react";
import { AmoForm } from "../AmoForm";
import "./UpdateModal.scss";

export default function UpdateModal() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const wasModalClosed = localStorage.getItem("updateModalClosed");
    if (wasModalClosed) {
      setIsVisible(false);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setIsVisible(true);
      }, 2000);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("updateModalClosed", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <NewModalLayout>
      <div className="update-modal">
        <Image className="update-modal__image" name="zhuma.png" />
        <div className="update-modal__content">
          <h2 className="update-modal__title">
            Только в течение Kaspi Жұма — полное сопровождение в рассрочку на 24
            месяца, успей забронировать место!
          </h2>
          <Icon
            name="close-rounded"
            className="update-modal__close"
            handleClick={handleClose}
          />
          <AmoForm onClose={handleClose} buttonClass="btn--kaspizhuma" />
        </div>
      </div>
    </NewModalLayout>
  );
}

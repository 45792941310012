// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-loader {
  width: 100vw;
  height: 100vh;
  background: #6997db;
}
.app-loader__content {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
.app-loader__box {
  width: 50px;
  height: 50px;
  background: #fff;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
.app-loader__shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Loader/AppLoader/AppLoader.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,qBAAA;EACA,sBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;AAAJ;AAGE;EACE,WAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,kBAAA;EACA,sCAAA;AADJ;;AAKA;EACE;IACE,YAAA;EAFF;EAIA;IACE,UAAA;EAFF;AACF;AAKA;EACE;IACE,+BAAA;EAHF;EAKA;IACE,0CAAA;EAHF;EAKA;IACE,uDAAA;IACA,gCAAA;EAHF;EAKA;IACE,0CAAA;EAHF;EAKA;IACE,sCAAA;EAHF;AACF;AAMA;EACE;IACE,wBAAA;EAJF;AACF","sourcesContent":[".app-loader {\n  width: 100vw;\n  height: 100vh;\n  background: #6997db;\n\n  &__content {\n    position: absolute;\n    top: calc(50% - 20px);\n    left: calc(50% - 20px);\n  }\n\n  &__box {\n    width: 50px;\n    height: 50px;\n    background: #fff;\n    animation: animate 0.5s linear infinite;\n    position: absolute;\n    top: 0;\n    left: 0;\n    border-radius: 3px;\n  }\n\n  &__shadow {\n    width: 50px;\n    height: 5px;\n    background: #000;\n    opacity: 0.1;\n    position: absolute;\n    top: 59px;\n    left: 0;\n    border-radius: 50%;\n    animation: shadow 0.5s linear infinite;\n  }\n}\n\n@keyframes loader {\n  0% {\n    left: -100px;\n  }\n  100% {\n    left: 110%;\n  }\n}\n\n@keyframes animate {\n  17% {\n    border-bottom-right-radius: 3px;\n  }\n  25% {\n    transform: translateY(9px) rotate(22.5deg);\n  }\n  50% {\n    transform: translateY(18px) scale(1, 0.9) rotate(45deg);\n    border-bottom-right-radius: 40px;\n  }\n  75% {\n    transform: translateY(9px) rotate(67.5deg);\n  }\n  100% {\n    transform: translateY(0) rotate(90deg);\n  }\n}\n\n@keyframes shadow {\n  50% {\n    transform: scale(1.2, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import './QuoteNotification.scss';

const QuoteNotification = ({ quote, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 300); // Wait for fade-out animation before closing
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`quote-notification ${isVisible ? 'visible' : ''}`}>
      <p>{quote}</p>
    </div>
  );
};

export default QuoteNotification;
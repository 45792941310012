import Skeleton from "react-loading-skeleton";
import {List} from "@components/common";
import "./ProgramSkeleton.scss";

export default function ProgramSkeleton() {
    return (
        <div className={"program-skeleton"}>
            <div className={"program-skeleton__img"}>
                <Skeleton/>
            </div>
            <div className={"program-skeleton__content"}>
                <div className={"program-skeleton__title"}>
                    <Skeleton height={19}/>
                </div>
                <div className={"program-skeleton__university"}>
                    <Skeleton height={24}/>
                </div>
                <div className={"program-skeleton__location"}>
                    <Skeleton height={24}/>
                </div>
                <List
                    className={"program-skeleton__characters"}
                    items={Array(7).fill(Infinity).map((item, itemId) => itemId)}
                    renderItem={(university, universityId) => (
                        <li key={universityId} className={"program-skeleton__item"}>
                            <Skeleton width={75} height={24}/>
                            <Skeleton width={100} height={24}/>
                        </li>
                    )}
                />
                <div className={"program-skeleton__btn"}>
                    <Skeleton height={44}/>
                    <Skeleton height={44}/>
                </div>
            </div>
        </div>
    );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blockedSection {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.blockedSection-icon {
  height: 100%;
  width: 100%;
}
.blockedSection-text {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Section/SectionBlocked/SectionBlocked.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;AACR;AAEI;EACI,kBAAA;AAAR","sourcesContent":[".blockedSection{\n    height: 60vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap:10px;\n\n    &-icon{\n        height: 100%;\n        width: 100%;\n    }\n\n    &-text{\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {WithRemoveScroll} from "@helpers/hoc";
import {Icon} from "@components/common";
import classNames from "classnames";
import "./Modal.scss";

export default function Modal(props) {
    const {
        className, children, open, setOpen,
        isStart, width, title, isClose=false
    } = props;
    return (
        <WithRemoveScroll flag={open}>
            <div
                className={classNames("modal", className, {active: open, start: isStart})}
                onClick={() => setOpen(false)}
            >
                <div
                    className={classNames("modal__content", {active: open})}
                    style={{width}}
                    onClick={(e) => e.stopPropagation()}
                >
                    {
                        isClose && <div className={"modal__close"}>
                            <Icon name={"modal-close"} handleClick={() => setOpen(false)}/>
                        </div>
                    }
                    {
                        title && <h6 className={"modal__title"}>
                            {
                                title
                            }
                        </h6>
                    }
                    {
                        children
                    }
                </div>
            </div>
        </WithRemoveScroll>
    );
};
import { useTranslate } from "@helpers/hooks";
import { Label, List } from "@components/common";
import { SINGLE_SELECT_CHIP_FIELDS } from "@utils/consts";
import { Chip } from "./Chip";
import "./SelectChips.scss";

export default function SelectChips({ item, handleChange }) {
  const { t } = useTranslate();
  const isActive = (option) => {
    if (SINGLE_SELECT_CHIP_FIELDS.includes(item.name)) {
      return item.value === option.value;
    }
    return item.value.find((val) => val === option.value);
  };

  return (
    <div className="select_chips">
      <Label className={"label_v2"} label={t(item.label)} />
      {item?.placeholder && <p className="select_chips__helper">{t(item.placeholder)}</p>}
      <List
        className="select_chips__body"
        items={item.options}
        renderItem={(option) => (
          <Chip
            key={option.id}
            text={t(option.text)}
            onClick={(e) =>
              handleChange(e, item.field, item.name, option.value)
            }
            isActive={isActive(option)}
          />
        )}
      />
    </div>
  );
}

import React from "react";
import { Button, ButtonLink } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { COURSE_LESSON_ROUTE } from "@utils/consts";
import { useParams, useHistory } from "react-router-dom";
import { useIsMobile } from "@helpers/hooks";
import "./LessonQuizHeader.scss";

export default function LessonQuizHeader({ lesson, handleSubmit, quizContent, isSubmitDisabled }) {
  const { t } = useTranslate();
  const { courseId, sectionId, lessonId } = useParams();
  const history = useHistory();
  const isMobile = useIsMobile();
  const link = COURSE_LESSON_ROUTE.replace(":courseId", courseId).replace(":sectionId", sectionId).replace(":lessonId", lessonId);

  const handleButtonClick = async () => {
    await handleSubmit();
    history.push(link);
  };

  return (
    <header className={isMobile ? `lesson__header-mobile` : `lesson__header`}>
      <nav className={isMobile ? `lesson__header-nav-mobile` : `lesson__header-nav`}>
        <div>
          <div className={isMobile ? "lesson__buttons-mobile" : ''}>
            <ButtonLink
              iconLeft={"arrow-back"}
              text={t("LMS.BUTTON.BACK")}
              link={link}
              className={`${isMobile ? `lesson-nav-mobile` : ''} button--transparent lesson__header-btn`}
            />
            {isMobile && (
              <Button
                className={`${isMobile ? `lesson-nav-mobile` : ''} btn--primary btn--pill`}
                type="submit"
                onClick={handleButtonClick}
                text={t("LMS.BUTTON.SUBMIT")}
                disabled={isSubmitDisabled}
              />
            )}
          </div>
          <div className="lesson__text__group">
            <p className={isMobile ? "lesson__text-main-mobile" : "lesson__text-main"}>
              {lesson.title}, <span className="lesson__text-secondary">{lesson.content[0].content_quiz.title}</span>
            </p>

            <div className="lesson__text__group">
              {!isMobile && <div className="lesson__dot"></div>}
              <p className={isMobile ? "lesson__text-secondary-mobile" :"lesson__text-secondary"}>
                {lesson.duration} {t("LMS.MINUTES")}
              </p>
              <div className="lesson__dot"></div>
              <p className={isMobile ? "lesson__text-secondary-mobile" :"lesson__text-secondary"}>
                {quizContent.total_questions} {t("LMS.QUIZ.TOTAL_POINTS_AVAILABLE")}
              </p>
              <div className="lesson__dot"></div>
              <p className={isMobile ? "lesson__text-secondary-mobile" :"lesson__text-secondary"}>
                {quizContent.correct_answers} {t("LMS.QUIZ.TOTAL_POINTS")}
              </p>
            </div>
          </div>
        </div>
        {!isMobile && (
          <Button className="btn--primary btn--pill" type="submit" onClick={handleButtonClick} text={t("LMS.BUTTON.SUBMIT")} disabled={isSubmitDisabled} />
        )}
      </nav>
    </header>
  );
}
import {createSlice} from "@reduxjs/toolkit";
import {
    fetchAvailableTypes,
    createGeneralEssay,
    deleteGeneralEssay,
    fetchGeneralEssays,
    fetchGeneralEssay,
    updateGeneralEssay,
    fetchUniversitySpecificEssays,
    fetchUniversitySpecificEssay,
    createUniversitySpecificEssay,
    updateUniversitySpecificEssay,
    deleteUniversitySpecificEssay
} from "@store/actions/essaysAction";
import { sortByUpdatedAt } from "@utils/functions";

const initialState = {
    results: null,
    generalEssay: {},
    generalEssays: {},
    availableTypes: null,
    universitySpecificEssay: {},
    universitySpecificEssays: {},
    isUpdating: false,
    isLoading: false,
    isEssayLoading: false,
    error: null
};

export const essaysSlice = createSlice({
    name: "essays",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchGeneralEssays.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchGeneralEssays.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.generalEssays.totalCount = action.payload.count;
            state.generalEssays.results = sortByUpdatedAt(action.payload.results);
        },
        [fetchGeneralEssays.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchAvailableTypes.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [fetchAvailableTypes.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.availableTypes = action.payload;
        },
        [fetchAvailableTypes.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        [fetchGeneralEssay.pending.type]: (state) => {
            state.isEssayLoading = true;
        },
        [fetchGeneralEssay.fulfilled.type]: (state, action) => {
            state.isEssayLoading = false;
            state.error = null;
            state.generalEssay = action.payload;
        },
        [fetchGeneralEssay.rejected.type]: (state, action) => {
            state.isEssayLoading = false;
            state.error = action.payload;
        },
        [createGeneralEssay.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [createGeneralEssay.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.generalEssay = action.payload;
        },
        [createGeneralEssay.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        [updateGeneralEssay.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [updateGeneralEssay.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.generalEssay = action.payload;
        },
        [updateGeneralEssay.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        [deleteGeneralEssay.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [deleteGeneralEssay.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.generalEssay = action.payload;
        },
        [deleteGeneralEssay.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        [fetchUniversitySpecificEssays.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchUniversitySpecificEssays.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.universitySpecificEssays = action.payload;
        },
        [fetchUniversitySpecificEssays.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchUniversitySpecificEssay.pending.type]: (state) => {
            state.isEssayLoading = true;
        },
        [fetchUniversitySpecificEssay.fulfilled.type]: (state, action) => {
            state.isEssayLoading = false;
            state.error = null;
            state.universitySpecificEssay = action.payload;
        },
        [fetchUniversitySpecificEssay.rejected.type]: (state, action) => {
            state.isEssayLoading = false;
            state.error = action.payload;
        },
        [createUniversitySpecificEssay.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [createUniversitySpecificEssay.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.universitySpecificEssay = action.payload;
        },
        [createUniversitySpecificEssay.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        [updateUniversitySpecificEssay.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [updateUniversitySpecificEssay.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.universitySpecificEssay = action.payload;
        },
        [updateUniversitySpecificEssay.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        [deleteUniversitySpecificEssay.pending.type]: (state) => {
            state.isUpdating = true;
        },
        [deleteUniversitySpecificEssay.fulfilled.type]: (state, action) => {
            state.isUpdating = false;
            state.error = null;
            state.universitySpecificEssay = action.payload;
        },
        [deleteUniversitySpecificEssay.rejected.type]: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
    }
});

export default essaysSlice.reducer;
import { Image, PageTitle } from '@components/common';
import { useTranslate } from '@helpers/hooks';
import { useHistory } from 'react-router-dom';
import { MobileHeader } from '../MobileHeader';
import './MobilePlaceholder.scss';

const MobilePlaceholder = ({title, goBackRoute}) => {
    const {t} = useTranslate(),
    history = useHistory();

    const handleClick = () => {
        if (!goBackRoute) {
            history.push('/more');
            return;
        }
        history.push(goBackRoute);
    }

    return (
        <div className="mobile-placeholder">
            <PageTitle title={title} iconLeft={"arrow_title"} isGoBack/>
            <div className="mobile-placeholder__content">
                <Image
                    className={"mobile-placeholder__img"}
                    name={"mobilePlaceholder.png"}
                />
                <h3>{t("MOBILE.PLACEHOLDER")}</h3>
                <p>{t("MOBILE.AVAILABLE_SOON")}</p>
            </div>
        </div>
    );
}

export default MobilePlaceholder;

export default function UniversityDetailsCheck(category, value, t) {
    switch(category) {
        case "scholarships":
            return (typeof value === "boolean") && 
            (value ? t("BUTTON.YES") : t("BUTTON.NO"));

        case "acceptance_rate":
        case "graduation_rate":
        case "percent_of_need_met":
        case "students_receiving_financial_aid":
            return (value) && `${value}%`;

        case "application_fee":
            return (value) && `$${(+value).toFixed(0)}`;

        case "average_first_year_fin_aid_package":
            case "net_price":
            return (value) &&
            `$${(+value).toLocaleString('en-US')}/${t("RECOMMENDATIONS.COSTS.LABEL_8")}`;

        case "net_price_costs":
            return (value) && `$${(+value/1000).toFixed(0)}k /${t("RECOMMENDATIONS.COSTS.LABEL_8")}`;

        case "tuition_fee":
            return (value) && `$${(+value/1000).toFixed(0)}`;

        case "ielts_toefl":
            return (value.ielts && value.toefl) && `${value.ielts}+ IELTS\n${value.toefl}+ TOEFL`;

        default:
            break;
    }    
}
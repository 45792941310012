import React from 'react';
import './UpSale.scss';
import { Image, Icon, NewModalLayout, Button } from '@components/common';
import { useTranslate } from '@helpers/hooks';

export default function UpSale({ setIsSaleOpen }) {
    const { t } = useTranslate();

    const handleClose = () => {
        setIsSaleOpen(false);
    };

    return (
        <NewModalLayout className="sale_modal">
            <Icon className="sale-content__close" name="close" handleClick={handleClose} />
            <div className="sale">
            
                <div className="sale-image-container">
                    <Image className={"sale-image"} name="sale.png" alt="Sale" />
                </div>
                <div className="sale-content">
                    <h1>{t('UPSALE.TITLE')}</h1>
                    <p>{t('UPSALE.TEXT_1')} <strong>{t('UPSALE.TEXT_2')}</strong></p>
                    <Button className="btn btn--rounded btn--primary" text={t('UPSALE.BUTTON')} />
                </div>
                <Image className={"sale-bg"} name="upsale.png" alt="Sale" />
                <Image className={"sale-mobile"} name="Content.png" alt="Sale" />
            </div>
        </NewModalLayout>
    );
}

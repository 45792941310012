import { NavLink, useLocation, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate, } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import { HeaderLanguageToggle, Logout } from "@components/feature";
import classNames from "classnames";
import "./BurgerCourseMenu.scss";

import {
  LEARNING_PLATFORM_ROUTE,
  COURSE_GRADES_ROUTE,
  COURSE_NOTES_ROUTE,
  COURSE_INFO_ROUTE,
  WORKSHOP_ROUTE,
  COURSE_SECTION_ROUTE,
  COURSE_RANKING_ROUTE
} from "@utils/consts";

export default function BurgerCourseMenu({ isMenuOpen, setIsMenuOpen }) {
  const { t } = useTranslate(),
    { pathname } = useLocation(),
    { courseId, sectionId } = useParams(),
    { course } = useSelector((state) => state.courses),
    [isAccordionOpen, setIsAccordionOpen] = useState(false),
    links = useMemo(
        () => [
          {
            id: 0,
            route: LEARNING_PLATFORM_ROUTE,
            icon: "pagination-arrow-left",
            name: t("LMS.BACK_TO_COURSES"),
            isDivider: true,
          },
          {
            id: 1,
            route: COURSE_SECTION_ROUTE,
            icon: "course-material",
            name: t("LMS.COURSE_MATERIAL"),
            isAccordionOpen: isAccordionOpen,
            confidential: true
          },
          {
            id: 2,
            route: WORKSHOP_ROUTE.replace(":workshopId", findFirstWorkshopId(course)),
            icon: "workshop",
            name: t("LMS.WORKSHOP"),
            isDivider: true,
            confidential: true
          },
          {
            id: 3,
            route: COURSE_GRADES_ROUTE,
            icon: "grades",
            name: t("LMS.GRADES.TITLE"),
            confidential: false
          },
          {
            id: 4,
            route: COURSE_NOTES_ROUTE,
            icon: "notes",
            name: t("LMS.NOTES.TITLE"),
            confidential: false
          },
          {
            id: 5,
            route: COURSE_RANKING_ROUTE,
            icon: "leaderboard",
            name: t("LMS.RANKING.TITLE"),
          },
          {
            id: 6,
            route: COURSE_INFO_ROUTE,
            icon: "info",
            name: t("LMS.INFO"),
            confidential: false
          },
        ],
        [t, course, findFirstIncompleteSection, findFirstWorkshopId, isAccordionOpen]
      );


      
    function findFirstIncompleteSection(course) {
      const foundCourse = course?.sections?.find((section) => !section.completed);
      return foundCourse?.id ?? "0/onboarding";
    };


    function findFirstWorkshopId(course) {
      const foundWorkshop = course && course.workshops?.find((workshop) => workshop.id);
      return foundWorkshop?.id ?? "0";
    }
    
    const isSectionRoute = (route) => pathname === route.replace(":courseId", courseId || course?.id).replace(":sectionId", sectionId)
    const isNavLinkSelected = (route) => pathname === route.replace(":courseId", courseId || course?.id)

  return (
    <div className={classNames("burger-menu", {"burger-menu--active": isMenuOpen})}>
      <List
        className={"burger-menu__list"}
        items={links}
        renderItem={({ id, route, icon, name, isExternal, isAccordionOpen, confidential }) => (
          (course?.enrolled || !confidential) &&
          <li key={id} className={classNames("burger-menu__item", {"burger-menu__link--selected__skyblue": pathname === route.replace(":courseId", courseId || course?.id)})}>
            {isExternal ? (
              <a className={classNames("burger-menu__link", {"burger-menu__link--selected": pathname === route})}
                href={route}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                <Icon name={icon} className={classNames({ "icon--white": pathname === route })}/>
                <p className={classNames("burger-menu__text", {"burger-menu__text--selected": pathname === route})}>
                  {name}
                </p>
              </a>
            ) : (
              <>
             
                {
                  isAccordionOpen !== null && isAccordionOpen !== undefined ? 
                  <div 
                    className={classNames("burger-menu__link", {"burger-menu__link--selected__skyblue": isSectionRoute(route)})}
                    onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                  >
                    <Icon name={icon} className={classNames({ "icon--white": isSectionRoute(route) })} />
                    <p className={classNames("burger-menu__text", {"burger-menu__text--selected": isSectionRoute(route)})}>
                      {name}
                    </p>
                    <Icon className={classNames("arrow-down", {"icon--white": isSectionRoute(route)})} name={"arrow-down"} />
                  </div>
                  : 
                  <NavLink
                    to={ isAccordionOpen === undefined && isAccordionOpen === null ? { pathname: route.replace(":courseId", courseId || course?.id) } : route.replace(":courseId", courseId || course?.id).replace(":sectionId", findFirstIncompleteSection(course))}
                    className={"burger-menu__link"}
                    onClick={() => setIsMenuOpen(prev => !prev)}
                  >
                    <Icon name={icon} className={classNames({ "icon--white": isNavLinkSelected(route) })} />
                    <p className={classNames("burger-menu__text", {"burger-menu__text--selected": isNavLinkSelected(route)})}>
                      {name}
                    </p>
                    {isAccordionOpen !== undefined && isAccordionOpen !== null && 
                      <Icon className="arrow-down" name={"arrow-up"} />
                    }
                  </NavLink>
                }
          {
                  isAccordionOpen &&
                  <div className="sidebar__dropdown-list">
                  {course?.sections.map(section =>
                  <NavLink
                    key={section.id}
                    to={route.replace(":courseId", courseId || course?.id).replace(":sectionId", section.id) }
                    className={classNames("burger-menu__link", "sidebar__dropdown-item")}
                    activeClassName={classNames({
                      "burger-menu__link--selected": pathname === route,
                    })}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {section.completed ? 
                      <div className="sidebar__dropdown-item_circle-completed">
                          <Icon name={"completed-section"} className="icon--completed-smaller" />
                      </div>
                      :
                      <Icon className={pathname === route.replace(":courseId", courseId || course?.id).replace(":sectionId", section.id) ? "sidebar__dropdown-item_circle-active" : "sidebar__dropdown-item_circle"} />
                    }
                    <p>{section.title}</p>
                  </NavLink>
                  )}
                  </div>
                }
              </>
            )}
          </li>
        )}
      />
      <br />
      <HeaderLanguageToggle className={"burger-menu__language"} />
      <Logout className={"burger-menu__logout"} />
    </div>
  );
}

import {useMemo} from "react";
import {useSelector} from "react-redux";
import {useTranslate} from "@helpers/hooks";
import {Button, Image, List, Modal, Divider, CustomLink} from "@components/common";
import {UniversityAboutSkeleton, UniversitySubjects} from "@components/feature";
import {getAcceptanceRate} from "@utils/functions";
import "./UniversityModal.scss";

export default function UniversityModal(props) {
    const {
        open, setOpen, selectedProgram, setSelectedProgram,
        setSelectedTab, tabs, setSearch, setIsResetFilter,
        setOrder
    } = props;
    const {t} = useTranslate(),
        {university, isUniversityLoading} = useSelector(state => state.university),
        UNIVERSITY_MODAL_INFOS = useMemo(() => [
            {id: 1, title: "Scholarships", value: university.scholarships ? "Yes" : "No"},
            {id: 2, title: "Acceptance Rate", value: getAcceptanceRate(university.degree?.acceptance_rate)},
            {id: 3, title: "Application Deadline", value: university.degree?.application_deadline},
            {id: 4, title: "NET Price", value: university.cost?.net_price ? `$${university.cost?.net_price}/year` : ""},
        ], [university]),
        UNIVERSITY_MODAL_CHARACTERS = useMemo(() => [
            {
                id: 1,
                title: "About university",
                isShow: true,
                fields: [
                    {
                        name: "Scholarships",
                        value: university.scholarships ? "Yes" : "No"
                    },
                    {
                        name: "Graduation Rate",
                        value: university.general?.graduation_rate ? `${university.general?.graduation_rate}%` : ""
                    },
                    {
                        name: "Full-Time Enrollment",
                        value: university.student?.full_time_enrollment
                    },
                    {
                        name: "Language",
                        value: university.language
                    }
                ]
            },
            {
                id: 2,
                title: "Admission statistics",
                isShow: true,
                fields: [
                    {
                        name: "Acceptance Rate",
                        value: getAcceptanceRate(university.degree?.acceptance_rate)
                    },
                    {
                        name: "SAT Range",
                        value: university.degree?.SAT_range
                    },
                    {
                        name: "ACT Range",
                        value: university.degree?.ACT_range
                    },
                    {
                        name: "IELTS",
                        value: university.more_info?.ielts
                    },
                    {
                        name: "TOEFL",
                        value: university.more_info?.toefl
                    },
                    {
                        name: "Application Fee",
                        value: university.more_info?.application_fee ? `$${university.more_info?.application_fee}` : ""
                    },
                    {
                        name: "SAT/ACT",
                        value: university.degree?.SAT_ACT_requirements
                    },
                    {
                        name: "High School GPA",
                        value: university.more_info?.high_school_GPA
                    },
                    {
                        name: "Early Decision/Early Action",
                        value: university.degree?.early_decision ? "Yes" : "No"
                    },
                    {
                        name: "Application Deadline",
                        value: university.degree?.application_deadline
                    }
                ]
            },
            {
                id: 3,
                title: "Finance",
                isShow: university.cost,
                fields: [
                    {
                        name: "NET Price",
                        value: university.cost?.net_price ? `$${university.cost?.net_price}/year` : ""
                    },
                    {
                        name: "Students Receiving Financial Aid",
                        value: university.cost?.students_receiving_financial_aid ? `${university.cost?.students_receiving_financial_aid}%` : ""
                    }
                ]
            },
            {
                id: 4,
                title: "Entry requirements",
                isShow: university.more_info,
                fields: [
                    {
                        name: "High School GPA",
                        value: university.more_info?.high_school_GPA
                    },
                    {
                        name: "High School Rank",
                        value: university.more_info?.high_School_Rank
                    },
                    {
                        name: "High School Transcript",
                        value: university.more_info?.high_School_Transcript
                    },
                    {
                        name: "Recommendations",
                        value: university.more_info?.recommendations
                    },
                    {
                        name: "Personal Statement",
                        value: university.more_info?.personal_statement
                    },
                    {
                        name: "College Prep Courses",
                        value: university.more_info?.college_Prep_Courses
                    },
                    {
                        name: "SAT/ACT",
                        value: university.more_info?.SAT_ACT
                    },
                    {
                        name: "IELTS/TOEFL",
                        value: university?.ielts_req
                    }
                ]
            },
            {
                id: 5,
                title: "Campus",
                isShow: university.campus_life,
                fields: [
                    {
                        name: "Average Housing Cost",
                        value: university.campus_life?.average_housing_cost ? `$${university.campus_life?.average_housing_cost}/year` : ""
                    },
                    {
                        name: "Average Meal Plan Cost",
                        value: university.campus_life?.average_mea_plan_cost ? `$${university.campus_life?.average_mea_plan_cost}/year` : ""
                    }
                ]
            },
            {
                id: 6,
                title: "After graduation",
                isShow: university.after_college,
                fields: [
                    {
                        name: "Graduation Rate",
                        value: university.after_college?.graduation_rate ? `${university.after_college?.graduation_rate}%` : ""
                    },
                    {
                        name: "Median Earnings 2 Years After Graduation",
                        value: university.after_college?.median_earnings_2_years_after_graduation ? `$${university.after_college?.median_earnings_2_years_after_graduation}/year` : ""
                    }
                ]
            },
            {
                id: 7,
                title: "Rating",
                isShow: university.rating,
                fields: [
                    {
                        name: "US News",
                        value: university.rating?.us_news
                    },
                    {
                        name: "QS",
                        value: university.rating?.QS
                    },
                    {
                        name: "Shanghai",
                        value: university.rating?.shanghai
                    }
                ]
            }
        ], [university]);
    return (
        <Modal
            className={"university-modal"}
            width={1112}
            open={open}
            setOpen={setOpen}
            isClose={true}
            isStart={true}
        >
            {
                !isUniversityLoading
                    ? <div className={"modal__wrapper"}>
                        <h6 className={"modal__title"}>
                            {university.title}
                        </h6>
                        <div className={"modal__about"}>
                            <Image
                                className={"about__img"}
                                src={university.logo}
                                alt={university.title}
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "/assets/img/university.jpg";
                                }}
                            />
                            <div className={"about__info"}>
                                <p className={"about__desc"}>
                                    {university.general?.about_university}
                                </p>
                                <div className={"about__link"}>
                                    {
                                        university?.uni_website?.length > 0 && <CustomLink
                                            isExternal={true}
                                            to={university.uni_website}
                                            target={"_blank"}
                                            rel={"noreferrer noopener"}
                                        >
                                            <Button
                                                className={"btn--primary btn--pill"}
                                                text={t("LINK.GO_WEBSITE")}
                                            />
                                        </CustomLink>
                                    }
                                </div>
                            </div>
                        </div>
                        <Divider
                            className={"modal__divider"}
                            height={1}
                            color={"#F3F2F2"}
                        />
                        <List
                            className={"modal__info"}
                            items={UNIVERSITY_MODAL_INFOS}
                            renderItem={(info) => (
                                info.value && <li key={info.id} className={"info__item"}>
                                    <h3 className={"info__value"}>
                                        {info.value ?? "No data"}
                                    </h3>
                                    <p className={"info__title"}>
                                        {(info.title)}
                                    </p>
                                </li>
                            )}
                        />
                        <List
                            className={"modal__character"}
                            items={UNIVERSITY_MODAL_CHARACTERS}
                            renderItem={(character) => (
                                character.isShow && <li key={character.id} className={"character__item"}>
                                    <h5 className={"character__title"}>
                                        {(character.title)}
                                    </h5>
                                    <List
                                        className={"character__list"}
                                        items={character.fields}
                                        renderItem={(field, fieldId) => (
                                            field.value && <li key={fieldId} className={"list__item"}>
                                                <p className={"item__name"}>
                                                    {(field.name)}
                                                </p>
                                                <h5 className={"item__value"}>
                                                    {field.value}
                                                </h5>
                                            </li>
                                        )}
                                    />
                                </li>
                            )}
                        />
                        <UniversitySubjects
                            setOpen={setOpen}
                            selectedProgram={selectedProgram}
                            setSelectedProgram={setSelectedProgram}
                            tabs={tabs}
                            setSelectedTab={setSelectedTab}
                            setSearch={setSearch}
                            setIsResetFilter={setIsResetFilter}
                            setOrder={setOrder}
                        />
                    </div>
                    : <UniversityAboutSkeleton />
            }
        </Modal>
    );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.player__buttons {
  display: flex;
  justify-items: flex-start;
}
.player__video video {
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/LessonVideoPlayer/LessonVideoPlayer.scss"],"names":[],"mappings":"AAGI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAFR;AAKI;EACI,aAAA;EACA,yBAAA;AAHR;AAMI;EACI,mBAAA;AAJR","sourcesContent":["@import \"@styles/variables\";\n\n.player {\n    &__container {\n        display: flex;\n        flex-direction: column;\n        gap: 30px;\n    }\n\n    &__buttons {\n        display: flex;\n        justify-items: flex-start;\n    }\n\n    &__video video{\n        border-radius: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {
  LEARNING_PLATFORM_ROUTE,
  COURSE_INFO_ROUTE,
  COURSE_GRADES_ROUTE,
  COURSE_NOTES_ROUTE,
  COURSE_SECTION_ROUTE,
  COURSE_LESSON_ROUTE,
  WORKSHOP_ROUTE,
  COURSE_ONBOARDING_ROUTE,
  COURSE_RANKING_ROUTE
} from "@utils/consts";

const getAdditionalRouteName = (route) => {
  if (route === "info") return "Course Info";
  if (route === "grades") return "Grades";
  if (route === "notes") return "Notes";
  if (route === "ranking") return "Ranking";
}

const getAdditionalRouteLink = (route, courseId) => {
  if (route === "info") return COURSE_INFO_ROUTE.replace(":courseId", courseId);
  if (route === "grades") return COURSE_GRADES_ROUTE.replace(":courseId", courseId);
  if (route === "notes") return COURSE_NOTES_ROUTE.replace(":courseId", courseId);
  if (route === "ranking") return COURSE_RANKING_ROUTE.replace(":courseId", courseId);
}

const ADDITIONAL_ROUTES = ["info", "grades", "notes", "ranking"]

export default function getBreadcrumbItems(course, location, params) {
  if (!course) return [];
  const { courseId, sectionId, workshopId, lessonId } = params;
  const items = []
  items.push({
    text: "Home",
    link: LEARNING_PLATFORM_ROUTE,
    isActive: false
  })
  items.push({isDivider: true})
  for (const route of ADDITIONAL_ROUTES) {
    if (location.pathname.includes(route)) {
      items.push({
        text: getAdditionalRouteName(route, course),
        link: getAdditionalRouteLink(route, courseId),
        isActive: true
      });
      return items
    }
  }

  const foundSection = course?.sections?.find((section) => section.id === +sectionId);
  const foundWorkshop = course?.workshops?.find((workshop) => workshop.id === +workshopId);
  const sectionTitle = foundSection || foundWorkshop ? foundSection?.title || foundWorkshop?.title : "???";

  if (location.pathname.includes("workshops")) {
    items.push({
      text: `Workshop (${sectionTitle})`,
      link: WORKSHOP_ROUTE.replace(":courseId", courseId).replace(":workshopId", workshopId ? workshopId : ""),
      isActive: !lessonId,
    });
  }

  if (location.pathname.includes("sections")) {
    if (location.pathname.includes("onboarding")) {
      items.push({
        text: `Course Materials (Guide)`,
        link: COURSE_ONBOARDING_ROUTE.replace(":courseId", courseId),
        isActive: !lessonId,
      });
    } else {
      items.push({
        text: `Course Materials (${sectionTitle})`,
        link: COURSE_SECTION_ROUTE.replace(":courseId", courseId).replace(":sectionId", sectionId ? sectionId : ""),
        isActive: !lessonId,
      })
    }
  }
  if (!lessonId) return items
  items.push({ isDivider: true })
  items.push({
    text: course.sections.find((section) => section.id === +sectionId).lessons.find((lesson) => lesson.id === +lessonId)
      .title,
    link: COURSE_LESSON_ROUTE.replace(":courseId", courseId)
      .replace(":sectionId", sectionId)
      .replace(":lessonId", lessonId),
    isActive: true,
  });
  return items;
}

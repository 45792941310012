import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction, useTranslate } from '@helpers/hooks';
import { countWords, truncateWords } from '@utils/functions';
import { ESSAY_TYPES } from '@utils/consts';
import { Button, FormItem, List } from '@components/common';
import './EssayModal.scss';

const EssayModal = ({isModalOpen, setIsModalOpen, title, essay, isEdit, isUniversitySpecific}) => {
    const { t } = useTranslate(),
    { availableTypes, isLoading } = useSelector(state => state.essays),
    { resultsDirectory } = useSelector(state => state.university),
    { fetchAvailableTypes, createGeneralEssay, updateGeneralEssay, createUniversitySpecificEssay,
    updateUniversitySpecificEssay, fetchUniversities } = useAction(),
    [values, setValues] = useState({
        essayUniversity: {name: essay?.university_name, value: essay?.university},
        essayType: essay?.type,
        essayTopic: essay?.topic,
        essayTopicSpecific: essay?.topic,
        essayTopicOptions: [],
        essayText: essay?.text,
        essayWordLimit: essay?.word_limit,
    }),
    initialInfos = useMemo(() => [
        {
            id: 1,
            label: t("ESSAY.LABELS.LABEL_7"),
            placeholder: "FIELD.NOT_SELECTED",
            field: "dropdown",
            name: "university",
            value: values.essayUniversity.value,
            defaultValue: {
                name: values.essayUniversity.name,
                value: values.essayUniversity.value,
            },
            isSearch: true,
            options: resultsDirectory,
            method: fetchUniversities,
            hide: isUniversitySpecific ? false : true,
        },
        {
            id: 2,
            label: t("ESSAY.LABELS.LABEL_3"),
            placeholder: "FIELD.NOT_SELECTED",
            field: "dropdown",
            name: "type",
            defaultValue: {
                name: t(`ESSAY.LABELS.${values.essayType && values.essayType.toUpperCase()}`),
                value: values.essayType,
            },
            value: values.essayType,
            options: ESSAY_TYPES(t),
            hide: isUniversitySpecific ? true : false,
        },
        {
            id: 3,
            label: t("ESSAY.LABELS.LABEL_4"),
            placeholder: "FIELD.CHOOSE_TOPIC",
            field: "dropdown",
            name: "topic",
            defaultValue: {
                name: values.essayTopic,
                value: values.essayTopic,
            },
            value: values.essayTopic,
            options: values.essayTopicOptions,
            isTextArea: false,
            hide: availableTypes?.available_types?.[values.essayType]?.topics.length === 0 || 
            isUniversitySpecific ? true : false,
        },
        {
            id: 4,
            label: t("ESSAY.LABELS.LABEL_4"),
            placeholder: "FIELD.CHOOSE_TOPIC",
            field: "input",
            name: "topic",
            type: "text",
            value: values.essayTopicSpecific,
            isTextArea: false,
            hide: isUniversitySpecific ? false : true,
        },
        {
            id: 5,
            label: t("ESSAY.LABELS.LABEL_6"),
            placeholder: "FIELD.ENTER_WORD_LIMIT",
            field: "input",
            name: "word_limit",
            type: "number",
            min: 0,
            max: 9999999999,
            maxLength: 4,
            value: values.essayWordLimit,
            isTextArea: false,
            hide: isUniversitySpecific ? false : true,
        },
        {
            id: 6,
            label: isUniversitySpecific ? t("ESSAY.LABELS.LABEL_11") :
            t("ESSAY.LABELS.LABEL_8", {word_limit: availableTypes?.available_types?.[values.essayType]?.word_limit}),
            placeholder: "Enter your essay",
            field: "input",
            name: "essay",
            type: "text",
            value: values.essayText,
            isTextArea: true,
        }
    ], [values, resultsDirectory, t, isUniversitySpecific]);
    
    const setValue = (key, value) => {
        setValues(prevValues => ({ ...prevValues, [key]: value }));
    };

    useEffect(() => {
        fetchAvailableTypes();
    }, []);

    useEffect(() => {
        if (values.essayType && !isLoading) {
            const options = availableTypes?.available_types?.[values.essayType]?.topics[0]?.map(topic => ({
                id: topic[1],
                name: topic[0],
                value: topic[0],
            }));
            setValue('essayTopicOptions', options);
        }
    }, [values.essayType, isLoading])


    function handleChange(e, field, name) {
        if (field === "dropdown") {
            if (name === "type") setValue('essayType', e.value);
            if (name === "topic") setValue('essayTopic', e.value);
            if (name === "university") setValue('essayUniversity', {name: e.name, value: e.value});
            return;
        } 
        if (field === "input") {
            if (name === "topic") setValue('essayTopicSpecific', e.target.value);
            if (name === "word_limit") {
                const inputValue = e.target.value;
                if ((inputValue === "" || !isNaN(inputValue)) && !/^0[0-9]+/.test(inputValue)) {
                  setValue('essayWordLimit', inputValue);
                }
              }             
            if (name === "essay") {
                let inputValue = e.target.value;
                const wordCount = countWords(inputValue);
                const maxWords = availableTypes?.available_types?.[values.essayType]?.word_limit;
        
                if (wordCount > maxWords) {
                    inputValue = truncateWords(inputValue, maxWords);
                }
                setValue('essayText', inputValue);
                return;
            }
        }
    }

    const handleSaveEdit = () => {
        if (isUniversitySpecific) {
            if (!values.essayTopicSpecific || !values.essayText || !values.essayUniversity || !values.essayWordLimit) {
                alert("Please fill all fields");
                return;
            }
            updateUniversitySpecificEssay({
                user: essay?.user,
                id: essay?.id,
                university: values.essayUniversity.value,
                topic: values.essayTopicSpecific,
                text: values.essayText,
                word_limit: values.essayWordLimit,
            });
            if (isModalOpen) setIsModalOpen(false);
            return;
        }
    
        if (!values.essayType || !values.essayText) {
            alert("Please fill all fields");
            return;
        }
    
        if (availableTypes?.available_types?.[values.essayType].topics.length !== 0 && !values.essayTopic) {
            alert("Please fill all fields");
            return;
        }
    
        updateGeneralEssay({
            id: essay?.id,
            user: essay?.user,
            type: values.essayType,
            topic: values.essayTopic || null,
            text: values.essayText,
            word_limit: availableTypes?.available_types?.[values.essayType]?.word_limit,
        });
        if (isModalOpen) setIsModalOpen(false);
    };
    
    const handleSaveNew = () => {
        if (isUniversitySpecific) {
            if (!values.essayTopicSpecific || !values.essayText || !values.essayUniversity || !values.essayWordLimit) {
                alert("Please fill all fields");
                return;
            }
            createUniversitySpecificEssay({
                university: values.essayUniversity.value,
                topic: values.essayTopicSpecific,
                text: values.essayText,
                word_limit: values.essayWordLimit,
            });
            if (isModalOpen) setIsModalOpen(false);
            return;
        }
    
        if (!values.essayType || !values.essayText) {
            alert("Please fill all fields");
            return;
        }
    
        if (availableTypes?.available_types?.[values.essayType].topics.length !== 0 && !values.essayTopic) {
            alert("Please fill all fields");
            return;
        }
    
        createGeneralEssay({
            type: values.essayType,
            topic: values.essayTopic || null,
            text: values.essayText,
            word_limit: availableTypes?.available_types?.[values.essayType]?.word_limit,
        });
        if (isModalOpen) setIsModalOpen(false);
    };
    
    const handleGoBack = () => {
        setIsModalOpen(false);
    }

    return (
        <div className='essay_modal'>
            <h1 className='essay_modal__title'>{title}</h1>
            <List 
                className="essay_modal__list"
                items={initialInfos}
                renderItem={(item) => !item.hide ? (
                    <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                    ) : null
                }
            />
            <div className='essay_modal__buttons'>
                <Button text="Cancel" className="btn--primary btn--pill" onClick={handleGoBack}/>
                <Button text="Save" className="btn--secondary btn--pill" onClick={isEdit ? handleSaveEdit : handleSaveNew}/>
            </div>
        </div>
    )
}

export default EssayModal;
import { Divider } from "@components/common";
import { SectionDescription, SectionModulesList } from "@components/feature";
import "./SectionContent.scss"


export default function SectionContent({ section, courseId, sectionId }) {
  return (
    <div>
      {section && (
        <div>
          <SectionDescription section={section} courseId={courseId} sectionId={sectionId}/>
          <Divider className={"section__divider"} />
          {section.lessons && <SectionModulesList  section={section} courseId={courseId} sectionId={sectionId}/>}
        </div>
      )}
    </div>
  );
}

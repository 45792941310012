import {useLayoutEffect, useState} from "react";

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useLayoutEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.screen.width,
                height: window.screen.height,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};
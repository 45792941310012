const OLYMPIADS = [
    {id: 28, name: "OLYMPIADS.NAME_28", value: "DIFFERENT"},
    {id: 1, name: "OLYMPIADS.NAME_1", value: "APHO"},
    {id: 2, name: "OLYMPIADS.NAME_2", value: "APMO"},
    {id: 3, name: "OLYMPIADS.NAME_3", value: "BGO"},
    {id: 4, name: "OLYMPIADS.NAME_4", value: "BPHO"},
    {id: 5, name: "OLYMPIADS.NAME_5", value: "WRO"},
    {id: 6, name: "OLYMPIADS.NAME_6", value: "EGO"},
    {id: 7, name: "OLYMPIADS.NAME_7", value: "EGMO"},
    {id: 8, name: "OLYMPIADS.NAME_8", value: "EGOCS"},
    {id: 9, name: "OLYMPIADS.NAME_9", value: "EJOCS"},
    {id: 10, name: "OLYMPIADS.NAME_10", value: "EPHO"},
    {id: 11, name: "OLYMPIADS.NAME_11", value: "IAPAO"},
    {id: 12, name: "OLYMPIADS.NAME_12", value: "IAO"},
    {id: 13, name: "OLYMPIADS.NAME_13", value: "IBO"},
    {id: 14, name: "OLYMPIADS.NAME_14", value: "IGO"},
    {id: 15, name: "OLYMPIADS.NAME_15", value: "INSO"},
    {id: 16, name: "OLYMPIADS.NAME_16", value: "IZOS"},
    {id: 17, name: "OLYMPIADS.NAME_17", value: "IMO"},
    {id: 18, name: "OLYMPIADS.NAME_18", value: "IMOSC"},
    {id: 19, name: "OLYMPIADS.NAME_19", value: "IOAA"},
    {id: 20, name: "OLYMPIADS.NAME_20", value: "IOCS"},
    {id: 21, name: "OLYMPIADS.NAME_21", value: "IOL"},
    {id: 22, name: "OLYMPIADS.NAME_22", value: "IOES"},
    {id: 23, name: "OLYMPIADS.NAME_23", value: "IOSE"},
    {id: 24, name: "OLYMPIADS.NAME_24", value: "IPO"},
    {id: 25, name: "OLYMPIADS.NAME_25", value: "IPHIO"},
    {id: 26, name: "OLYMPIADS.NAME_26", value: "ICO"},
    {id: 27, name: "OLYMPIADS.NAME_27", value: "ITYP"},
];

export {
    OLYMPIADS
};
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Button, Icon, Image, List } from "@components/common";
import { AudioPlayer } from "@components/feature";
import { QuizQuestionItem } from "@components/feature/Lesson";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import {
  blobToBase64,
  capitalizeFirstLetter,
  countWords,
} from "@utils/functions";

import rehypeRaw from "rehype-raw";

import { useTranslate } from "@helpers/hooks";

import "./MockIeltsSection.scss";

export default function MockIeltsSection({
  sectionData,
  userAnswers,
  setUserAnswers,
  questionsData,
  currentStageIndex,
  setCurrentStageIndex,
  currentReviewSection,
  setCurrentReviewSection,
  sectionMappings,
}) {
  const { stage } = useSelector((state) => state.mocks);
  const [wordcount, setWordcount] = useState(0);

  const [highlights, setHighlights] = useState({});
  const [showProTip, setShowProTip] = useState(false);
  const { t } = useTranslate();


  useEffect(() => {
    const isProTipClosed = localStorage.getItem("proTipClosed");
    if (!isProTipClosed) {
      setShowProTip(true);
    }
  }, []);

  // Reference to the text container element
  const textContainerRef = useRef(null);

  // Load highlights from local storage when the component mounts
  useEffect(() => {
    const savedHighlights =
      JSON.parse(localStorage.getItem("highlights")) || {};
    setHighlights(savedHighlights);
  }, []);

  // Save highlights to local storage whenever they are updated
  useEffect(() => {
    localStorage.setItem("highlights", JSON.stringify(highlights));
  }, [highlights]);

  // Automatically highlight text after selection
  const handleHighlight = useCallback(() => {
    if (stage !== "reading") return; // Only allow highlighting during the reading stage

    const selection = window.getSelection();
    if (!selection || selection.toString().length === 0) return;

    const range = selection.getRangeAt(0);
    const root = textContainerRef.current;

    if (!root.contains(range.commonAncestorContainer)) {
      return;
    }

    const tempDiv = document.createElement("div");
    tempDiv.appendChild(range.cloneContents());
    const selectedHtml = tempDiv.innerHTML;

    setHighlights((prevHighlights) => {
      const updatedHighlights = { ...prevHighlights };
      const stageHighlights = updatedHighlights[currentStageIndex] || [];
      updatedHighlights[currentStageIndex] = [
        ...stageHighlights,
        {
          selectedHtml,
        },
      ];
      return updatedHighlights;
    });

    selection.removeAllRanges();
  }, [currentStageIndex, stage]);

  // Listen for mouseup event to trigger highlighting
  useEffect(() => {
    const handleMouseUp = () => {
      if (stage === "reading") {
        const selection = window.getSelection();
        if (selection && selection.toString().length > 0) {
          handleHighlight();
        }
      }
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleHighlight, stage]);

  const handleTextChange = (event) => {
    const text = event.target.value;
    const wordCount = countWords(text);
    setWordcount(wordCount);
  };

  const handleCloseProTip = () => {
    setShowProTip(false);
    localStorage.setItem("proTipClosed", "true");
  };

  const handleAnswerChange = useCallback(
    async (questionId, answer) => {
      // Prevent unnecessary updates if the answer hasn't changed
      if (userAnswers[questionId] === answer) return;

      const processAnswer = async (answer) => {
        if (stage === "speaking" && answer instanceof Blob) {
          return await blobToBase64(answer);
        }
        return answer;
      };

      if (stage === "speaking") {
        const newAnswer = await processAnswer(answer);
        setUserAnswers(prevAnswers => ({
          ...prevAnswers,
          [questionId]: newAnswer
        }));
      } else {
        setUserAnswers(prevAnswers => ({
          ...prevAnswers,
          [questionId]: answer
        }));
        
        if (stage === "writing" && typeof answer === "string") {
          setWordcount(countWords(answer));
        }

        const newAnswers = {
          ...userAnswers,
          [questionId]: answer
        };
        localStorage.setItem("userAnswers", JSON.stringify(newAnswers));
      }
    },
    [stage, userAnswers] 
  );

  const handleNextStage = () => {
    if (currentStageIndex < sectionData.length - 1) {
      setCurrentStageIndex(currentStageIndex + 1);
    }
  };

  const handlePrevStage = () => {
    if (currentStageIndex > 0) {
      setCurrentStageIndex(currentStageIndex - 1);
    }
  };

  const renderTitle = () => {
    return capitalizeFirstLetter(
      stage === "finished" ? currentReviewSection : stage
    );
  };

  const TABS = [
    {
      key: "listening",
      label: sectionMappings["listening"]
        ? `Listening (${sectionMappings["listening"][0].score})`
        : "Listening",
    },
    {
      key: "reading",
      label: sectionMappings["reading"]
        ? `Reading (${sectionMappings["reading"][0].score})`
        : "Reading",
    },
    {
      key: "writing",
      label: sectionMappings["writing"]?.[0]?.score
        ? `Writing (${sectionMappings["writing"][0]?.score})`
        : "Writing",
    },
    {
      key: "speaking",
      label: sectionMappings["speaking"]?.[0].score
        ? `Speaking (${sectionMappings["speaking"][0].score})`
        : "Speaking",
    },
  ];

  const handleReviewSection = (tab) => {
    setCurrentStageIndex(0);
    setCurrentReviewSection(tab);
  };

  const renderAudioPlayer = () => (
    <div className="section__audio">
      <AudioPlayer
        src={sectionData[currentStageIndex]?.audio}
        isMock={true}
        isSettings={false}
      />
    </div>
  );

  const renderHighlightedText = (htmlContent, highlights) => {
    const currentHighlights = highlights[currentStageIndex] || [];
    if (!currentHighlights.length) {
      return (
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{htmlContent}</ReactMarkdown>
      ); // If no highlights, return the text as-is
    }

    let adjustedHtml = htmlContent;

    currentHighlights.forEach(({ selectedHtml }) => {
      // Escape special regex characters in selectedHtml
      const escapedSelectedHtml = selectedHtml.replace(
        /[-/\\^$*+?.()|[\]{}]/g,
        "\\$&"
      );

      // Create a regex to match the selectedHtml
      const regex = new RegExp(escapedSelectedHtml, "g");

      // Replace the selectedHtml with the highlighted version
      adjustedHtml = adjustedHtml.replace(
        regex,
        `<mark>${selectedHtml}</mark>`
      );
    });

    return (
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{adjustedHtml}</ReactMarkdown>
    );
  };

  const renderTextContent = (text) => (
    <div className="section__text" ref={textContainerRef}>
      <div className="section__questions-container">
        {renderHighlightedText(
          sectionData[currentStageIndex]?.reading_text,
          highlights
        )}
        {highlights[currentStageIndex]?.length > 0 && (
          <div className="section__questions-container-buttons">
            <Button
              iconLeft={<Icon name="delete-trash-filled" />}
              className="btn--danger-text btn--pill btn--delete"
              text="Delete all highlights"
              onClick={handleDeleteHighlights}
            />
          </div>
        )}
      </div>
    </div>
  );

  const renderQuestionList = (items) => (
    <List
      className={"section__questions-list"}
      items={items}
      renderItem={(item) => (
        <QuizQuestionItem
          key={item.id}
          question={item}
          userAnswers={userAnswers}
          handleAnswerChange={handleAnswerChange}
          // index={item.position}
        />
      )}
    />
  );

  const renderWritingQuestion = (text, images) => (
    <div className="writing__question">
      <ReactMarkdown className="section__text-text">{text}</ReactMarkdown>
      {images && <Image className={"quiz__image"} src={images} />}
    </div>
  );

  const handleDeleteHighlights = () => {
    setHighlights((prevHighlights) => {
      const updatedHighlights = { ...prevHighlights };
      delete updatedHighlights[currentStageIndex];
      return updatedHighlights;
    });
    localStorage.setItem("highlights", JSON.stringify(highlights)); // Persist changes
  };

  return (
    <div className="section">
      <nav className="section__nav">
        <div className="section__nav-stages">
          <h3>
            {renderTitle()}. Part {currentStageIndex + 1}
          </h3>
        </div>

        <div className="section__nav-controls">
          {stage === "finished" && (
            <ul className={"section__tabs"}>
              {TABS.map((tab) => {
                const isSelected =
                  tab.key.toLowerCase() === currentReviewSection.toLowerCase();
                return (
                  <li
                    key={tab.key}
                    className={classNames("section__tab", {
                      active: isSelected,
                    })}
                    onClick={() => handleReviewSection(tab.key)}
                  >
                    <p className={"section__tab-text"}>{tab.label}</p>
                  </li>
                );
              })}
            </ul>
          )}

          <div className="section__nav-arrow" onClick={handlePrevStage}>
            <Icon name={"arrow-left-nav"} />
          </div>
          <div className="section__nav-arrow" onClick={handleNextStage}>
            <Icon name={"arrow-right-nav"} />
          </div>
        </div>
      </nav>

      <div className="section__content">
        {(stage === "listening" ||
          (stage === "finished" && currentReviewSection === "listening")) && (
          <div className="section__content-narrow">
            {renderAudioPlayer()}
            {renderQuestionList(questionsData)}
          </div>
        )}

        {(stage === "reading" ||
          (stage === "finished" && currentReviewSection === "reading")) &&
          renderTextContent(sectionData[currentStageIndex]?.reading_text)}

        {(stage === "reading" ||
          (stage === "finished" && currentReviewSection === "reading")) && (
          <div className="section__questions">
            {showProTip && (
              <div className="pro-tip">
                <p>
                  <strong>Pro Tip:</strong> You can highlight text in the
                  passage for easier review. For best results, try to highlight
                  text within a single paragraph.
                </p>
                <button className="pro-tip__close" onClick={handleCloseProTip}>
                  <Icon name="close" />
                </button>
              </div>
            )}
            <div className="section__questions-container">
              {renderQuestionList(questionsData)}
            </div>
          </div>
        )}

        {(stage === "writing" ||
          (stage === "finished" && currentReviewSection === "writing")) && (
          <div className="section__questions">
            <div className="section__questions-container">
              {renderWritingQuestion(
                questionsData[0].question_text,
                questionsData[0]?.question_practice.images_store[0]?.image
              )}
            </div>
          </div>
        )}

        {(stage === "writing" ||
          (stage === "finished" && currentReviewSection === "writing")) && (
          <div className="writing__text">
            {questionsData && renderQuestionList([questionsData[0]])}
            {stage === "finished" && (
              <div className="question__feedback">
                <h4 className="question__text">{t("ADMIN.FEEDBACK")}</h4>
                <p>{sectionMappings["writing"][0].feedback}</p>
              </div>
            )}
          </div>
        )}

        {(stage === "speaking" ||
          (stage === "finished" && currentReviewSection === "speaking")) && (
          <div className="section__content-narrow">
            {questionsData && renderQuestionList(questionsData)}
            {stage === "finished" && (
              <div className="question__feedback">
                <h4 className="question__text">{t("ADMIN.FEEDBACK")}</h4>
                <p>{sectionMappings["speaking"][0].feedback}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import { Button, Icon, List, Tabs } from "@components/common";
import { RightSidebar } from "../RightSidebar";
import "./NotificationsSidebar.scss";
import { useAction, useTranslate } from "@helpers/hooks";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { NotFilledTabs } from "@components/common/NotFilledTabs";
import { NotificationBox } from "./NotificationBox";
import { useMemo } from "react";

export default function NotificationSidebar({ isOpen, setIsOpen }) {
  const tabs = {
    unread: "NOTIFICATIONS.UNREAD",
    read: "NOTIFICATIONS.READ",
  };
  const [selectedTab, setSelectedTab] = useState(tabs.unread);
  const { getMyNotifications, markMyNotifications } = useAction();
  const { notifications = [] } = useSelector(
    (state) => state.notifications || {}
  );
  const [marked, setMarked] = useState(false);
  const { t } = useTranslate();

  useEffect(() => {
    if (isOpen) {
      getMyNotifications();
    }
  }, [isOpen]);

  const { readNotifs, unreadNotifs } = useMemo(() => {
    return {
      readNotifs: notifications.filter((notif) => notif.is_read),
      unreadNotifs: notifications.filter((notif) => !notif.is_read),
    };
  }, [notifications]);

  const markAllRead = async () => {
    await markMyNotifications();
    setMarked(true);
    getMyNotifications();
    setTimeout(() => {
      setMarked(false);
    }, 5000);
  };

  const currentNotifs = selectedTab === tabs.unread ? unreadNotifs : readNotifs;
  const notifText =
    selectedTab === tabs.unread
      ? t("NOTIFICATIONS.NO_NEW")
      : t("NOTIFICATIONS.NO_OLD");

  return (
    <RightSidebar isOpen={isOpen} setIsOpen={setIsOpen}>
      <div>
        <div className="notif-inbox__box-1">
          <div className="notif-inbox__title-box">
            <div className="notif-inbox__title">
              {t("NOTIFICATIONS.NOTIFICATIONS")}
            </div>
            <div className="notif-inbox__num">{unreadNotifs?.length}</div>
          </div>
          {unreadNotifs?.length > 0 && (
            <div className="notif-inbox__mark">
              <Button
                text={marked ? "Marked" : "Mark all as read"}
                iconLeft={<Icon name={"notif-mark"} />}
                className="notif-inbox__mark-button"
                onClick={markAllRead}
              />
            </div>
          )}
        </div>
        <div className="notif-inbox-section__tabs">
          <NotFilledTabs
            className="notif-inbox__tabs"
            tabClassName={(tab) =>
              classNames(
                "notif-inbox__tabs__tab",
                tab === selectedTab && "notif-inbox__tabs__tab--selected"
              )
            }
            tabs={[tabs.unread, tabs.read]}
            selected={selectedTab}
            setSelected={setSelectedTab}
          />
          <div className="notif-inbox__line" />
          <div className="notif-inbox__list">
            {currentNotifs.length > 0 ? (
              <List
                items={currentNotifs}
                renderItem={(notif, index) => (
                  <div key={notif.id}>
                    <NotificationBox notification={notif} />
                    {index !== currentNotifs?.length - 1 && (
                      <div className="notif-inbox__line" />
                    )}
                  </div>
                )}
              />
            ) : (
              <div className="notif-inbox__no-notif">{notifText}</div>
            )}
          </div>
        </div>
      </div>
    </RightSidebar>
  );
}

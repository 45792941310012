import React from "react";
import { List, CustomLink } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { parseStrToArr } from "@utils/functions";
import "./ScholarshipsOverview.scss";

export default function ScholarshipsOverview({ item }) {
  const { t } = useTranslate();

  const OVERVIEW_HEADER = [
    {
      id: 1,
      name: "SCHOLARSHIPS.COUNTRY",
      value: item.country,
    },
    {
      id: 2,
      name: "SCHOLARSHIPS.DEGREE",
      value: item.degree,
    },
    {
      id: 3,
      name: "SCHOLARSHIPS.NUMBER",
      value: item.number_of_scholarships,
    },
    {
      id: 4,
      name: "SCHOLARSHIPS.LINK",
      value: item.url,
      label: "SCHOLARSHIPS.WEBSITE",
    },
  ];

  const OVERVIEW_DETAILS = [
    {
      id: 1,
      isBullet: true,
      label: "SCHOLARSHIPS.DEADLINE",
      text: parseStrToArr(item.deadline),
    },
    {
      id: 2,
      label: "SCHOLARSHIPS.FOR_WHOM",
      text: item.for_whom,
    },
    {
      id: 3,
      label: "SCHOLARSHIPS.AMOUNT",
      text: item.amount,
    },
    {
      id: 4,
      isBullet: true,
      label: "SCHOLARSHIPS.DOCUMENTS",
      text: parseStrToArr(item.document),
    },
  ];

  const renderListItem = (listItem) => {
    if (listItem.name === "SCHOLARSHIPS.LINK") {
      return (
        <li key={listItem.name} className="scholarships__overview-item">
          <h4>{t(listItem.label)}</h4>
          <CustomLink isExternal={true} to={listItem.value}>
            {t(listItem.name)}
          </CustomLink>
        </li>
      );
    } else {
      return (
        <li key={listItem.name} className="scholarships__overview-item">
          <h4>{listItem.value}</h4>
          <p>{t(listItem.name)}</p>
        </li>
      );
    }
  };

  return (
    <div className="scholarships__overview-parent">
      <List
        className="scholarships__overview"
        items={OVERVIEW_HEADER}
        renderItem={renderListItem}
      />
      <List
        className="scholarships__overview-details"
        items={OVERVIEW_DETAILS}
        renderItem={(item) => {
          if (item.text == "NaN") {
            return ;
          } else {
            return (
              <li key={item.id} className="scholarships__overview-details-item">
              <h5>{t(item.label)}</h5>
              {item.isBullet ? (
                <List
                  items={item.text}
                  renderItem={(listItem) => <li key={listItem}>{listItem}</li>}
                />
              ) : (
                <p>{item.text}</p>
              )}
            </li>
            )
          }
        }}
      />
    </div>
  );
}

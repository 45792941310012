import React, { useEffect } from "react";
import { BlogArticleCard } from "@components/feature";
import { List } from "@components/common";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import "./BlogArticlesList.scss";

export default function BlogArticlesList({ blogId, isAdditional }) {
  const { articles } = useSelector((state) => state.blog);
  const { fetchBlogArticles } = useAction();
  const isMobile = useIsMobile()

  useEffect(() => {
    fetchBlogArticles();
  }, []);

  const filterAndSortBlogs = (blogId) => {
    const filteredBlogs = articles?.filter(article => article.id !== Number(blogId));
    return filteredBlogs.slice(0, 3);
};

  return (
    <div>

        <List className={`blog__list ${isMobile && `blog__list-mobile`}`} items={isAdditional ? filterAndSortBlogs(blogId) : articles} renderItem={(article) => <BlogArticleCard key={article.id} article={article} />} />

      

    </div>
  );
}

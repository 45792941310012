import { AudioPlayer } from '@components/feature'
import {React, useState} from 'react'

export default function ListeningQuestion(question) {
  const audioURL = question && question.question.question_listening.audio_question
  const text = question && question.question.text

  return (
    <div className='long__text'>
        <p className='question__heading'>{text}</p>
        <AudioPlayer src={audioURL} isSettings={true} />
    </div>
  )
}

import './LmsBadgeProfileSpecifications.scss';

const LmsBadgeProfileSpecifications = () => {
    return (
        <div>
            Specifications
        </div>
    );
}

export default LmsBadgeProfileSpecifications;

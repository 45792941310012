// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-card-selected__title {
  font-size: 18px;
}
.hero-card-selected__description {
  margin: 16px 0 24px 0;
  font-size: 14px;
  font-weight: 700;
}
.hero-card-selected__image {
  width: 160px;
  object-fit: contain;
}
.hero-card-selected__info {
  display: flex;
  column-gap: 37px;
}
.hero-card-selected__info-group {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.hero-card-selected__level {
  font-size: 14px;
  font-weight: 400;
}
.hero-card-selected__btn {
  width: 100%;
  height: 44px;
}
@media screen and (max-width: 682px) {
  .hero-card-selected__info {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Onboarding/HeroClassCardSelected/HeroClassCardSelected.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAGI;EACI,qBAAA;EACA,eAAA;EACA,gBAAA;AADR;AAII;EACI,YAAA;EACA,mBAAA;AAFR;AAKI;EACI,aAAA;EACA,gBAAA;AAHR;AAKQ;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AAHZ;AAOI;EACI,eAAA;EACA,gBAAA;AALR;AASI;EACI,WAAA;EACA,YAAA;AAPR;AAUI;EACI;IACI,sBAAA;EARV;AACF","sourcesContent":[".hero-card-selected {\n    &__title {\n        font-size: 18px;\n    }\n\n    &__description {\n        margin: 16px 0 24px 0;\n        font-size: 14px;\n        font-weight: 700;\n    }\n\n    &__image {\n        width: 160px;\n        object-fit: contain;\n    }\n\n    &__info {\n        display: flex;\n        column-gap: 37px;\n        \n        &-group {\n            display: flex;\n            flex-direction: column;\n            row-gap: 20px;\n        }\n    }\n\n    &__level {\n        font-size: 14px;\n        font-weight: 400;\n    }\n\n\n    &__btn {\n        width: 100%;\n        height: 44px;\n    }\n\n    @media screen and (max-width: 682px) {\n        &__info {\n            flex-direction: column;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favorites-tooltip {
  display: flex;
  align-items: flex-start;
}
.favorites-tooltip__action {
  cursor: pointer;
  margin-left: 16px;
}
.favorites-tooltip__action:first-child {
  margin-left: 0;
}
.favorites-tooltip__action:first-child > .tooltip__content {
  left: -212px;
}
.favorites-tooltip__action:first-child > .tooltip__content > .tooltip__box {
  padding-left: 14px;
  padding-right: 14px;
}
.favorites-tooltip__action:first-child > .tooltip__content > .tooltip__box:after {
  left: 89%;
}
.favorites-tooltip__action:last-child > .tooltip__content {
  left: -192px;
}
.favorites-tooltip__action:last-child > .tooltip__content > .tooltip__box {
  padding-left: 18px;
  padding-right: 18px;
}
.favorites-tooltip__action:last-child > .tooltip__content > .tooltip__box:after {
  left: 86%;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Favorites/FavoritesTooltip/FavoritesTooltip.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;AACJ;AACI;EACE,cAAA;AACN;AACM;EACE,YAAA;AACR;AACQ;EACE,kBAAA;EACA,mBAAA;AACV;AACU;EACE,SAAA;AACZ;AAMM;EACE,YAAA;AAJR;AAMQ;EACE,kBAAA;EACA,mBAAA;AAJV;AAMU;EACE,SAAA;AAJZ","sourcesContent":[".favorites-tooltip {\n  display: flex;\n  align-items: flex-start;\n\n  &__action {\n    cursor: pointer;\n    margin-left: 16px;\n\n    &:first-child {\n      margin-left: 0;\n\n      & > .tooltip__content {\n        left: -212px;\n\n        & > .tooltip__box {\n          padding-left: 14px;\n          padding-right: 14px;\n\n          &:after {\n            left: 89%;\n          }\n        }\n      }\n    }\n\n    &:last-child {\n      & > .tooltip__content {\n        left: -192px;\n\n        & > .tooltip__box {\n          padding-left: 18px;\n          padding-right: 18px;\n\n          &:after {\n            left: 86%;\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const getActionsData = (
  fetchMockListening,
  fetchMockReading,
  fetchMockWriting,
  fetchMockSpeaking,
  saveMockListening,
  saveMockReading,
  saveMockWriting,
  saveMockSpeaking,
  mockListening,
  mockReading,
  mockWriting,
  mockSpeaking
) => ({
  fetchActions: {
    listening: fetchMockListening,
    reading: fetchMockReading,
    writing: fetchMockWriting,
    speaking: fetchMockSpeaking,
  },
  saveActions: {
    listening: saveMockListening,
    reading: saveMockReading,
    writing: saveMockWriting,
    speaking: saveMockSpeaking,
  },
  contentData: {
    listening: mockListening,
    reading: mockReading,
    writing: mockWriting,
    speaking: mockSpeaking,
  },
});

export default getActionsData;

import { VoiceRecord } from "@components/feature";
import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { IeltsVoiceRecord } from "@components/feature";
import "./SpeakingQuestion.scss";

export default function SpeakingQuestion({
  question,
  userAnswer,
  onAnswerChange,
}) {
  const { pathname } = useLocation();
  const isMock = pathname.includes("mock");

  return (
    <div className="speaking__group">
      {isMock ? (
        <IeltsVoiceRecord
          userAnswer={userAnswer}
          onAnswerChange={onAnswerChange}
          question={question}
        />
      ) : (
        <VoiceRecord
          userAnswer={userAnswer}
          onAnswerChange={onAnswerChange}
          question={question}
        />
      )}
    </div>
  );
}

const PROGRAM_STATUS = (t) => [
  { id: 1, name: t("common:FAVORITE.STATUS.SAVED"), value: "Saved" },
  { id: 2, name: t("common:FAVORITE.STATUS.APPLYING"), value: "Applying" },
];

const APPLICATION_STATUS = (t) => [
  { id: 1, name: t("common:APPLICATION.STATUS.APPLYING"), value: "Applying", statusNumber: "1" },
  { id: 2, name: t("common:APPLICATION.STATUS.APPLICATION_SENT"), value: "Application sent", statusNumber: "2" },
  { id: 3, name: t("common:APPLICATION.STATUS.ACCEPTED"), value: "Accepted", statusNumber: "3" },
  { id: 4, name: t("common:APPLICATION.STATUS.UNSUCCESSFUL"), value: "Unsuccessful", statusNumber: "4" },
];

const APPLICATION_STATUS_FILTER = (t) => [
  { id: 0, name: t("common:APPLICATION.ALL"), value: "All", statusNumber: "0" },
  { id: 1, name: t("common:APPLICATION.STATUS.APPLYING"), value: "Applying", statusNumber: "1" },
  { id: 2, name: t("common:APPLICATION.STATUS.APPLICATION_SENT"), value: "Application sent", statusNumber: "2" },
  { id: 3, name: t("common:APPLICATION.STATUS.ACCEPTED"), value: "Accepted", statusNumber: "3" },
  { id: 4, name: t("common:APPLICATION.STATUS.UNSUCCESSFUL"), value: "Unsuccessful", statusNumber: "4" },
];

const PROGRAM_STATUS_FILTER = (t) => [
  { id: 0, name: t("common:FAVORITE.STATUS.ALL"), value: "All" },
  { id: 1, name: t("common:FAVORITE.STATUS.SAVED"), value: "Saved" },
  { id: 2, name: t("common:FAVORITE.STATUS.APPLYING"), value: "Applying" },
];

const STATUSID_TO_STATUSNAME = (t) => ({
  1: t("common:APPLICATION.STATUS.APPLYING"),
  2: t("common:APPLICATION.STATUS.APPLICATION_SENT"),
  3: t("common:APPLICATION.STATUS.ACCEPTED"),
  4: t("common:APPLICATION.STATUS.UNSUCCESSFUL"),
});

const COURSE_STATUS_FILTER = (t) => [
  { id: 0, name: t("common:APPLICATION.ALL"), value: "All"  },
  { id: 1, name: t("common:APPLICATION.PUBLISHED"), value: "published" },
  { id: 2, name: t("common:APPLICATION.COMPLETED"), value: "Completed" },
];

export { PROGRAM_STATUS, APPLICATION_STATUS, STATUSID_TO_STATUSNAME, APPLICATION_STATUS_FILTER, PROGRAM_STATUS_FILTER, COURSE_STATUS_FILTER };

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__close-button--light {
  color: #ffffff;
}

.quote-text {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.quote-author {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/LessonCompletion/LessonCompletion.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACJ","sourcesContent":[".Toastify__close-button--light {\n    color: #ffffff;\n}\n\n.quote-text {\n    font-size: 16px;\n    font-weight: 500;\n    color: #ffffff;\n}\n\n.quote-author {\n    font-size: 12px;\n    font-weight: 400;\n    color: #ffffff;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

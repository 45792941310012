import Skeleton from "react-loading-skeleton";
import "./FavoriteSkeleton.scss";

export default function FavoriteSkeleton({count=10}) {
    return (
        <div className={"favorite-skeleton"}>
            <div className={"favorite-skeleton__title"}>
                <Skeleton/>
            </div>
            <div className={"favorite-skeleton__table"}>
                {
                    Array(10).fill(Infinity).map((item, itemId) => (
                        <Skeleton key={itemId} count={count}/>
                    ))
                }
            </div>
        </div>
    );
};
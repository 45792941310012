import {useMemo} from "react";
import {List} from "@components/common"
import findRequirements from "./findRequirements";
import {replaceAllNullable} from "@utils/functions";
import "./ProgramRequirements.scss";

export default function ProgramRequirements({program}) {
    const requirements = useMemo(() => [
        {
            id: 1, label: "Academic", options: [
                {label: "GPA", value: replaceAllNullable(program.exam_requirements?.gpa_range)},
                {label: "IB", value: program.requirements?.IB},
                {label: "Percentage", value: program.requirements?.percentage ? `${program.requirements?.percentage}%` : ""},
                {label: "Recommendations", value: findRequirements(program.requirements?.recommendations)},
                {label: "Motivation Letter", value: findRequirements(program.requirements?.motivation_letter)}
            ]
        },
        {
            id: 2, label: "Test scores", options: [
                {label: "IELTS", value: replaceAllNullable(program.exam_requirements?.ielts_range)},
                {label: "TOEFL", value: replaceAllNullable(program.exam_requirements?.toefl_range)},
                {label: "SAT", value: replaceAllNullable(program.exam_requirements?.sat_range)}
            ]
        },
    ], [program]);
    return (
        <div className={"program-requirements"}>
            <List
                className={"program-requirements__list"}
                items={requirements}
                renderItem={(requirement) => (
                    <li key={requirement.id} className={"program-requirements__item"}>
                        <p className={"program-requirements__name"}>
                            {(requirement.label)}
                        </p>
                        <List
                            className={"program-requirements__options"}
                            items={requirement.options}
                            renderItem={(option, optionId) => (
                                option.value && <li key={optionId} className={"program-requirements__option"}>
                                    <p className={"program-requirements__label"}>
                                        {(option.label)}
                                    </p>
                                    <p className={"program-requirements__value"}>
                                        {option.value}
                                    </p>
                                </li>
                            )}
                        />
                    </li>
                )}
            />
        </div>
    );
};
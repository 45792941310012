// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-rating__item {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.program-rating__item:first-child {
  margin: 0;
}
.program-rating__name {
  font-weight: 400;
  font-size: 18px;
}

@media screen and (max-width: 574px) {
  .program-requirements__name, .program-requirements__value {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Catalog/ProgramRating/ProgramRating.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;AAFJ;AAII;EACE,SAAA;AAFN;AAME;EACE,gBAAA;EACA,eAAA;AAJJ;;AAQA;EAEI;IACE,eAAA;EANJ;AACF","sourcesContent":["@import \"src/styles/variables\";\n\n.program-rating {\n  &__item {\n    margin-top: 24px;\n    display: flex;\n    justify-content: space-between;\n\n    &:first-child {\n      margin: 0;\n    }\n  }\n\n  &__name {\n    font-weight: 400;\n    font-size: 18px;\n  }\n}\n\n@media screen and (max-width: 574px){\n  .program-requirements {\n    &__name, &__value {\n      font-size: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

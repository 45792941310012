// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-class__cards {
  display: flex;
  justify-content: space-between;
  column-gap: 32px;
  margin-top: 20px;
}
.hero-class__cards-mobile {
  flex-direction: column;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  column-gap: 32px;
  margin-top: 20px;
}
.hero-class__cards-mobile > div {
  width: 100%;
}
.hero-class__cards > div {
  width: 33%;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Onboarding/HeroClass/HeroClass.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,gBAAA;AAAR;AAEQ;EACI,sBAAA;EACA,SAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,gBAAA;AAAZ;AACY;EACI,WAAA;AAChB;AAGQ;EACI,UAAA;AADZ","sourcesContent":[".hero-class {\n    &__cards {\n        display: flex;\n        justify-content: space-between;\n        column-gap: 32px;\n        margin-top: 20px;\n\n        &-mobile {\n            flex-direction: column;\n            gap: 20px;\n            display: flex;\n            justify-content: space-between;\n            column-gap: 32px;\n            margin-top: 20px;\n            & > div {\n                width: 100%;\n            }\n        }\n        \n        & > div {\n            width: 33%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

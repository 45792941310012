import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MAJOR_STUDY_LEVEL } from "@utils/consts";
import { useAction, useTranslate, useIsMobile } from "@helpers/hooks";
import { BtnLoader } from "@components/common";
import { UniversityDetailsTabs, MajorList } from "@components/feature";
import "./Major.scss";

const Major = ({ selectedFilters }) => {

  const isMobile = useIsMobile();

  const TABS = {
    MATCHING: "RECOMMENDATIONS.MAJOR.LABEL_14",
    ALL: "RECOMMENDATIONS.MAJOR.LABEL_15",
  };

  const parseObj = selectedFilters && getSubjectValue(selectedFilters);
  const subjectMatch = parseObj.subject || parseObj.subjects;


  const { t } = useTranslate(),
    { university, isUniversityLoading } = useSelector((state) => state.university),
    { fetchSubjects, fetchPrograms } = useAction(),
    { subjects, isLoading: directoryLoading } = useSelector((state) => state.directory),
    { results, isLoading: programLoading } = useSelector((state) => state.program),
    [selectedStudyLevel, setSelectedStudyLevel] = useState(null),
    [selectedSubject, setSelectedSubject] = useState(null),
    [currentTab, setCurrentTab] = useState(TABS.MATCHING),
    subjectNames = useMemo(() => (subjects ? subjects : []), [subjects]);

  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (currentTab === TABS.MATCHING) {
      fetchPrograms({
        university: university.id,
        subject: subjectMatch,
      });
    } else if (currentTab === TABS.ALL) {
      fetchPrograms({ university: university.id });
    }
  }, [currentTab, university, subjectMatch]);

  useEffect(() => {
    if (!university) return;
    if (selectedStudyLevel && selectedSubject) {
      fetchPrograms({
        university: university.id,
        study_level: selectedStudyLevel,
        subject: selectedSubject,
      });
    } else if (selectedStudyLevel) {
      fetchPrograms({ university: university.id, study_level: selectedStudyLevel });
    } else if (selectedSubject) {
      fetchPrograms({ university: university.id, subject: selectedSubject });
    }
  }, [selectedStudyLevel, selectedSubject, university]);

  const universityInfo = useMemo(() => {
    return {
      header: [
        {
          id: 1,
          label: t("RECOMMENDATIONS.MAJOR.LABEL_1"),
          field: "dropdown",
          name: "study_level",
          value: selectedStudyLevel,
          options: MAJOR_STUDY_LEVEL(t),
        },
        {
          id: 2,
          label: t("RECOMMENDATIONS.MAJOR.LABEL_2"),
          field: "dropdown",
          name: "disciplines",
          value: selectedSubject,
          options: subjectNames,
        },
      ],
    };
  }, [university, subjectNames, t]);

  const handleChange = async (e, field, name) => {
    if (name === "disciplines") {
      await setSelectedSubject(e.value);
    }
    if (name === "study_level") {
      await setSelectedStudyLevel(e.value);
    }
  };

  const getContent = (tab) => {
    switch (tab) {
      case TABS.MATCHING:
        return (
          <MajorList
            universityInfo={universityInfo}
            isFilters={false}
            results={results}
            programLoading={programLoading}
            handleChange={handleChange}
            selectedSubject={subjectMatch}
          />
        );
      case TABS.ALL:
        return (
          <MajorList
            universityInfo={universityInfo}
            isFilters={true}
            results={results}
            programLoading={programLoading}
            handleChange={handleChange}
            selectedSubject={selectedSubject}
          />
        );
      default:
        return;
    }
  };

  function getSubjectValue(obj) {
    let subjectValue = null;
    
    const key = obj.hasOwnProperty("subject") ? "subject" : (obj.hasOwnProperty("subjects") ? "subjects" : null);
    
    if (key) {
        const firstItem = obj[key][0];
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
            if (firstItem.hasOwnProperty("id")) {
                subjectValue = firstItem.id.toString();
            } else if (firstItem.hasOwnProperty("value")) {
                subjectValue = firstItem.value.toString();
            }
        }
    }

    return { subject: subjectValue };
}


  if (isUniversityLoading) return <BtnLoader className="btn-loader--blue-large" />;
  if (subjectMatch === undefined) {
    return (
      <MajorList
        universityInfo={universityInfo}
        isFilter={true}
        results={results}
        programLoading={programLoading}
        handleChange={handleChange}
        selectedSubject={selectedSubject}
      />
    );
  }

  return (
    <div className="major">
     {!isMobile && <h1 className="major__title">{t("CATALOG.MAJOR")}</h1>}

      <UniversityDetailsTabs
        classNameTabs={"university-details__tabs"}
        classNameTab={"university-details__tab"}
        tabs={Object.values(TABS)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {getContent(currentTab)}
    </div>
  );
};

export default Major;

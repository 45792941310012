import { useMemo } from "react";
import { useSelector } from "react-redux";
import { UniversityDetailsCheck, trimNewline, replaceNewLinesWithComma } from "@utils/functions";
import { BtnLoader } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import {filterItemsWithValues} from "@utils/functions";
import "./Admissions.scss";

const Admissions = () => {
  const isMobile = useIsMobile()
  const { t } = useTranslate();
  const { university, isUniversityLoading } = useSelector((state) => state.university); 

  const universityInfo = useMemo(() => {
      return {
        header: [
          {
            id: 1,
            title: t("RECOMMENDATIONS.OVERVIEW.LABEL_2"),
            value: UniversityDetailsCheck("acceptance_rate", university.degree?.acceptance_rate, t),
          },
          {
            id: 2,
            title: t("RECOMMENDATIONS.OVERVIEW.LABEL_3"),
            value: trimNewline(university.degree?.application_deadline),
          },
          {
            id: 3,
            title: t("RECOMMENDATIONS.OVERVIEW.LABEL_9"),
            value: UniversityDetailsCheck(
              "application_fee",
              university.more_info?.application_fee,
              t
            ),
          },
        ],
        info: [
          {
            id: 1,
            title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_7"),
            value: university.degree?.SAT_ACT_requirements,
          },
          {
            id: 2,
            title: t("CATALOG.UNIVERSITIES.TEXT_21"),
            value: university.more_info?.college_Prep_Courses,
          },
          {
            id: 3,
            title: t("CATALOG.UNIVERSITIES.TEXT_19"),
            value: university.more_info?.recommendations,
          },
          {
            id: 4,
            title: t("CATALOG.UNIVERSITIES.TEXT_20"),
            value: university.more_info?.personal_statement,
          },
          {
            id: 5,
            title: t("CATALOG.UNIVERSITIES.TEXT_17"),
            value: university.more_info?.high_School_Rank,
          },
          {
            id: 6,
            title: t("CATALOG.UNIVERSITIES.TEXT_18"),
            value: university.more_info?.high_School_Transcript,
          },
          {
            id: 7,
            title: t("CATALOG.UNIVERSITIES.TEXT_35"),
            value: handleDetails(university.degree?.application_types_accepted, "application_type"),
          },
          {
            id: 8,
            title: t("RECOMMENDATIONS.OVERVIEW.LABEL_3"),
            value: replaceNewLinesWithComma(university.degree?.application_deadline),
          },
          {
            id: 9,
            title: t("CATALOG.UNIVERSITIES.TEXT_36"),
            value: university.degree?.early_action_application_deadline,
          },
          {
            id: 10,
            title: t("CATALOG.UNIVERSITIES.TEXT_37"),
            value: university.degree?.early_decision_1_application_deadline,
          },
          {
            id: 11,
            title: t("CATALOG.UNIVERSITIES.TEXT_38"),
            value: university.degree?.early_decision_2_application_deadline,
          },
          {
            id: 12,
            title: t("CATALOG.UNIVERSITIES.TEXT_39"),
            value: university.degree?.regular_decision_application_deadline,
          },

        ],

        exams: [
          { id: 1, title: "ACT", value: handleDetails(university?.degree?.ACT_range, "ACT") },
          { id: 2, title: "SAT", value: handleDetails(university?.degree?.SAT_range, "SAT") },
          { id: 3, title: "GPA", value: university?.degree?.average_high_school_GPA },
          { id: 4, title: "TOEFL", value: university?.more_info?.toefl },
          { id: 5, title: "IELTS", value: university?.more_info?.ielts },
        ],
      };
    }, [university, t]);

  const filteredExams = filterItemsWithValues(universityInfo.exams)
  const filteredHeader = filterItemsWithValues(universityInfo.header)
  const filteredInfo = filterItemsWithValues(universityInfo.info)

  function handleDetails(text, type) {
    switch (type) {
      case "application_type":
        if (text?.includes("college's own website")) {
          return "University website";
        }
      case "ACT":
      case "SAT":
        return text?.split(" - ")[0];
    }
  }

  return (
    <>
      {!isUniversityLoading ? (
        <div className="admissions">
          {!isMobile && <h1 className="admissions__title">{t("RECOMMENDATIONS.ADMISSIONS.TITLE")}</h1>}
          <div className="admissions__header">
            {filteredHeader.map((item) => (
              <div className="admissions__header-item" key={item.id}>
                <h3>{item.title}</h3>
                <p>{item.value}</p>
              </div>
            ))}
          </div>
          {filteredInfo.length > 0 && <div className="admissions__info">
            {filteredInfo.map((item) => (
              <div className="admissions__info-item" key={item.id}>
                <p>{item.title}</p>
                <h5>{item.value}</h5>
              </div>
            ))}
          </div>}
          <div className="admissions__exams">
           {!isMobile && <h3>{t("EDUCATIONS_STATUS.BACHELOR")}</h3>}
            <div className="admissions__exams-list">
              {filteredExams.map((item) => (
                <div className="admissions__exams-item" key={item.id}>
                  <h5 className="admissions__exams-item-score">{item.value}</h5>
                  <p className="admissions__exams-item-title">{item.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <BtnLoader className={"btn-loader--blue-large"} />
      )}
    </>
  );
};

export default Admissions;

import React from "react";
import { Button } from "@components/common";
import { useTranslate } from "@helpers/hooks";

const MockCard = ({ item, handleStart, handleBuy }) => {
  const { t } = useTranslate();
  const examKey = `${item.type}_exam`;
  const discountedPrice = 10000;
  const maxScore =
    item.type === "ielts_mock" ? "9.0" : item.type === "nuet_mock" ? 240 : 1600;

  const calculateDiscountedPrice = (sum) =>
    ((sum / discountedPrice - 1) * 100).toFixed(0);

  return (
    <li className={"mock__card"}>
      <div className="mock__card_text">
        <div className="mock__card_text_nums">
          <h4 className="mock__card-title">{item.title}</h4>
          {item[examKey]?.completed ? (
            <p className="mock__card_text_nums-nums-score">
              Score: {Math.round(item[examKey].score)}/{maxScore}
            </p>
          ) : (
            !item.access && (
              <div className="mock__card_text_nums-nums">
                <p className="mock__card_text_nums-nums-original">
                  {discountedPrice}
                </p>
                <p className="mock__card_text_nums-nums-discount">{item.sum}</p>
              </div>
            )
          )}
        </div>
        {!item.access && (
          <div className="mock__card_text_circle">
            <div className="mock__card_circle">
              {calculateDiscountedPrice(item.sum)}%
            </div>
          </div>
        )}
      </div>

      <div className="mock__card_text__buttons">
        <Button
          className={
            item[examKey]?.completed
              ? "mock__card_text__buttons_widy card__button"
              : "card__button card__button-primary card__buttons-width"
          }
          text={
            item[examKey]?.completed
              ? t("MOCK.FEEDBACK")
              : item.access
              ? t("MOCK.START_EXAM")
              : t("BUTTON.BUY")
          }
          onClick={
            item.access ? () => handleStart(item) : () => handleBuy(item)
          }
        />
      </div>
    </li>
  );
};

export default MockCard;

const getChallengeQuestion = (question) => {
  switch (question.type) {
    case "challenge_one_choice":
      return question.challenge_one_choice;
    case "challenge_multiple_choice":
      return question.challenge_multiple_choice;
    case "challenge_insert_text":
      return question.challenge_insert_text;
    case "challenge_true_false":
      return question.challenge_true_false;
    default:
      break;
  }
};

export default getChallengeQuestion;

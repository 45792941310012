// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-mobile {
  padding-bottom: 80px;
}
.section__modules {
  display: flex;
  flex-direction: column;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Section/SectionModulesList/SectionModulesList.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;AAAR;AAGI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AADR","sourcesContent":[".section {\n    &-mobile {\n        padding-bottom: 80px;\n    }\n\n    &__modules {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

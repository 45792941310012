import { useState } from "react";
import { useTranslate } from "@helpers/hooks";
import { Icon, Button, FormItem, Divider, ShowMoreToggle } from "@components/common";
import { useAction } from "@helpers/hooks";
import { useParams } from "react-router-dom";
import { convertSecondsToMinutesAndSeconds } from "@utils/functions";
import "./VideoNotesItem.scss";

export default function VideoNotesItem({ note, goToSecond, timestamp, addingNote, setAddingNote, setTime, isLast, editingNote, setEditingNote }) {
  const { lessonId } = useParams();
  const { createNote, getLessonNotes, deleteNote, editNote } = useAction();
  const { t } = useTranslate();
  const [inputValue, setInputValue] = useState("");

  const onEditClick = () => {
    setEditingNote(note.id); 
    setInputValue(note.text); 
  };


  const onSaveClick = async () => {
    setTime();
    let data = {};
    if (editingNote) {
       data = {
        text: inputValue,
        lesson_id: lessonId,
      };
    } else {
      data = {
        text: inputValue,
        timestamp: parseInt(timestamp),
        lesson_id: lessonId,
      };
  }
    
    try {
      if (editingNote) {
        await editNote({ id: note.id, data });
        setEditingNote(null);
        await getLessonNotes(lessonId);
      } else {
        await createNote(data);
        await getLessonNotes(lessonId);
      }
     
    } catch {
      console.error("Error happened");
    }
    setAddingNote(false);
  };


  const onDeleteClick = () => {
    deleteNote(note.id);
  };

  const onCloseClick = () => {
      
      if (editingNote) {
        setEditingNote(null);
      } else {
        setAddingNote(false);
      }
  }


  return (
    <div className={addingNote ? `note__container-new` : `note__container`}>
      <div className="note__buttons">
        {!addingNote && (
          <Button
            className="btn--transaprrent"
            iconLeft={<Icon name={"notes-play"} />}
            text={`${t("LMS.BUTTON.PLAY_VIDEO")} ${convertSecondsToMinutesAndSeconds(note.timestamp)}`}
            onClick={() => goToSecond(note.timestamp)}
          />
        )}
      </div>

      {note && 
        <ShowMoreToggle text={note.text} className="note__text"/>
      }

      {addingNote && (
        <FormItem
          item={{
            inputClassName: "note__input",
            placeholder: t("LMS.VIDEO.ENTER"),
            value: inputValue,
          }}
          handleChange={(e) => setInputValue(e.target.value)}
        />
      )}
      {editingNote && (
        <FormItem
          item={{
            inputClassName: "note__input",
            placeholder: t("LMS.VIDEO.ENTER"),
            value: inputValue,
          }}
          handleChange={(e) => setInputValue(e.target.value)}
        />
      )}
      {editingNote || addingNote ? (
        <div className="note__buttons">
          <Button className={"btn--primary btn--pill"} text={t("LMS.BUTTON.SAVE")} onClick={() => onSaveClick()} />
          <Button
            className={"btn--teritary btn--pill"}
            text={t("LMS.BUTTON.CANCEL")}
            onClick={() => onCloseClick()}
          />
        </div>
      ) : (
        <div className="note__buttons">
          <Button
            iconLeft={<Icon className="note--blue-icon" name="writing-builder" />}
            className={"btn--transaprrent btn--pill"}
            text={t("LMS.BUTTON.EDIT_NOTE")}
            onClick={() => onEditClick()}
          />
          <Button
            iconLeft={<Icon className="note--red-icon" name="notes-delete" />}
            className={"btn--transaprrent btn--pill note__button-delete"}
            text={t("LMS.BUTTON.DELETE")}
            onClick={() => onDeleteClick()}
          />
        </div>
      )}
      {!isLast && <Divider className={"note__divider"} />}
    </div>
  );
}

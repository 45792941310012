const universityOrders = [
    {id: 1, name: "ORDER.TUITION_FEE", value: "tuition_fee"},
    {id: 2, name: "ORDER.TUITION_FEE_MINUS", value: "-tuition_fee"},
    {id: 3, name: "ORDER.TITLE", value: "title"},
    {id: 4, name: "ORDER.TITLE_MINUS", value: "-title"},
    {id: 5, name: "ORDER.RANKINGS_POSITION", value: "rating__QS"},
    {id: 6, name: "ORDER.RANKINGS_POSITION_MINUS", value: "-rating__QS"}
];

const programOrders = [
    {id: 1, name: "ORDER.TUITION_FEE", value: "fees_field_test"},
    {id: 2, name: "ORDER.TUITION_FEE_MINUS", value: "-fees_field_test"},
    {id: 3, name: "ORDER.TITLE", value: "name"},
    {id: 4, name: "ORDER.TITLE_MINUS", value: "-name"},
    {id: 5, name: "ORDER.RANKINGS_POSITION", value: "rating__QS"},
    {id: 6, name: "ORDER.RANKINGS_POSITION_MINUS", value: "-rating__QS"}
];

export {
    universityOrders, programOrders
};
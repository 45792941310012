// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-program {
  max-width: 729px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard-program__text {
  width: 40%;
}
.dashboard-program__select {
  max-width: 432px;
  width: 60%;
}

@media screen and (max-width: 524px) {
  .dashboard-program {
    flex-wrap: wrap;
    max-width: 100%;
  }
  .dashboard-program__text {
    font-size: 18px;
    width: 100%;
  }
  .dashboard-program__select {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Dashboard/DashboardProgram/DashboardProgram.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AADF;AAGE;EACE,UAAA;AADJ;AAIE;EACE,gBAAA;EACA,UAAA;AAFJ;;AAMA;EACE;IACE,eAAA;IACA,eAAA;EAHF;EAKE;IACE,eAAA;IACA,WAAA;EAHJ;EAME;IACE,WAAA;EAJJ;AACF","sourcesContent":["@import \"src/styles/variables\";\n\n.dashboard-program {\n  max-width: 729px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  &__text {\n    width: 40%;\n  }\n\n  &__select {\n    max-width: 432px;\n    width: 60%;\n  }\n}\n\n@media screen and (max-width: 524px) {\n  .dashboard-program {\n    flex-wrap: wrap;\n    max-width: 100%;\n\n    &__text {\n      font-size: 18px;\n      width: 100%;\n    }\n\n    &__select {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

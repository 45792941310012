import Skeleton from "react-loading-skeleton";
import {Divider, List} from "@components/common";
import "./ProgramAboutSkeleton.scss";

export default function ProgramAboutSkeleton() {
    return (
        <div className={"program-about-skeleton"}>
            <div className={"program-about-skeleton__about"}>
                <div className={"program-about-skeleton__img"}>
                    <Skeleton/>
                </div>
                <div className={"program-about-skeleton__info"}>
                    <div className={"program-about-skeleton__desc"}>
                        <Skeleton count={10}/>
                    </div>
                    <div className={"program-about-skeleton__link"}>
                        <Skeleton/>
                    </div>
                </div>
            </div>
            <Divider
                className={"program-about-skeleton__divider"}
                height={1}
                color={"#F3F2F2"}
            />
            <div className={"program-about-skeleton__items"}>
                <div className={"program-about-skeleton__label"}>
                    <Skeleton/>
                </div>
                <List
                    className={"program-about-skeleton__options"}
                    items={Array(8).fill(Infinity).map((item, itemId) => itemId)}
                    renderItem={(character, characterId) => (
                        <li key={characterId} className={"program-about-skeleton__option"}>
                            <Skeleton height={25}/>
                            <Skeleton height={25}/>
                        </li>
                    )}
                />
            </div>
            <Divider
                className={"program-about-skeleton__divider"}
                height={1}
                color={"#F3F2F2"}
            />
            <div className={"program-about-skeleton__tabs"}>
                <Skeleton count={4} width={100}/>
            </div>
            <div className={"program-about-skeleton__pane"}>
                <Skeleton width={100}/>
                <Skeleton width={200}/>
            </div>
        </div>
    );
};
import { Author } from "./Author";
import { useAction, useTranslate } from "@helpers/hooks";
import "./AboutCourse.scss";
import ReactMarkdown from "react-markdown";
import { Button, Icon, List } from "@components/common";
import { useEffect } from "react";
import { useState } from "react";
import { PopupTariffs } from "@components/feature/Tariffs";
import { trackAmplitude } from "@utils/functions";

export default function AboutCourse({ course }) {
  const { t } = useTranslate();
  const [isFixed, setIsFixed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const descriptionItems = parseDescription(course?.description);
  const { getPaymentLinks } = useAction();

  const onBuyClick = async () => {
    try {
      const paymentLinks = await getPaymentLinks({ courseId: course?.id });
      setPaymentInfo(paymentLinks.payload);
      setIsModalOpen(true);
      localStorage.setItem(
        "course_purchase",
        JSON.stringify({ course_id: course.id, enrolled: course.enrolled })
      );
    } catch (error) {
      console.error("Error fetching payment links:", error);
    } finally {
      trackAmplitude("learning_platfrom_learn_click", {
        description: "Пользователь успешно открыл тарифы курса для покупки",
        course_name: course.title,
      });
    }
  };

  function formatCurrency(amount) {
    return amount.toLocaleString("en-US") + " ₸";
  }

  function parseDescription(description) {
    return description?.split("\r\n").map((item, index) => ({
      id: index,
      text: item.trim(),
    }));
  }

  function findSmallestPrice(data) {
    let minPrice = Infinity;
    for (const item of data) {
      if (item.plantype && item.plantype.price_per_recur < minPrice) {
        minPrice = item.plantype.price_per_recur;
      }
    }
    return minPrice;
  }

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("header");
      const headerBottom = header ? header.offsetHeight : 0;
      const scrollPosition = window.scrollY;

      if (scrollPosition >= headerBottom + 60) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="about-course">
      {isFixed && <div className="about-course-fixed-placeholder"></div>}
      <div className={`about-course-fixed ${isFixed ? "fixed-bottom" : ""}`}>
        <p className="about-course-title">{course.title}</p>
        <div className="about-course-price-box">
          <p className="about-course-price">
            {formatCurrency(findSmallestPrice(course.package_types))}
          </p>
          <Button
            className="btn--primary btn--pill"
            text={t("BUTTON.BUY_COURSE")}
            onClick={() => onBuyClick()}
          />
        </div>
      </div>
      <div className="about-course-upper">
        <Author author={course.author} />
        <div className="about-course-container">
          <p className="about-course-about">{t("LMS.COURSE.INFO.ABOUT")}</p>
          <List
            items={descriptionItems}
            renderItem={(item) => (
              <li key={item.id} className={"about-course-about-item"}>
                <p>{item.text}</p>
              </li>
            )}
          />
        </div>
      </div>
      {isModalOpen && (
        <PopupTariffs
          handleClose={() => setIsModalOpen(false)}
          paymentInfo={paymentInfo}
          course={course}
        />
      )}
    </div>
  );
}

const EDUCATION_STATUS = [
    {id: "school", name: "EDUCATIONS_STATUS.SCHOOL", value: "school"},
    {id: "bachelor", name: "EDUCATIONS_STATUS.BACHELORS", value: "bachelor"},
    {id: "magistracy", name: "EDUCATIONS_STATUS.MAGISTRACY", value: "magistracy"},
    {id: "doctoral_studies", name: "EDUCATIONS_STATUS.DOCTOR", value: "doctor"},
    {id: "different", name: "EDUCATIONS_STATUS.DIFFERENT", value: "al_studies"},
    {id: "dont_know", name: "EDUCATIONS_STATUS.DONT_KNOW", value: "dont_know"}
];

const QUESTIONNAIRE_EDUCATION_STATUS = [
    // old values
    // {id: "high_school", name: "EDUCATIONS_STATUS.HIGH SCHOOL", value: "high school"},
    // {id: "bachelors", name: "EDUCATIONS_STATUS.BACHELORS", value: "Bachelor"},
    // {id: "magistracy", name: "EDUCATIONS_STATUS.MAGISTRACY", value: "Master"},
    // {id: "mba", name: "EDUCATIONS_STATUS.MBA", value: "MBA"},
    // {id: "phd", name: "EDUCATIONS_STATUS.PHD", value: "PHD"},
    // {id: "research", name: "EDUCATIONS_STATUS.RESEARCH", value: "Research"},
    // {id: "certification ", name: "EDUCATIONS_STATUS.CERTIFICATION", value: "Certification"},
    // new values
    {id: "school", name: "EDUCATIONS_STATUS.SCHOOL", value: "school"},
    {id: "bachelor", name: "EDUCATIONS_STATUS.BACHELORS", value: "bachelor"},
    {id: "magistracy", name: "EDUCATIONS_STATUS.MAGISTRACY", value: "magistracy"},
    {id: "doctor", name: "EDUCATIONS_STATUS.DOCTOR", value: "doctor"},
    {id: "al_studies", name: "EDUCATIONS_STATUS.DIFFERENT", value: "al_studies"},
    {id: "dont_know", name: "EDUCATIONS_STATUS.DONT_KNOW", value: "dont_know"}
];

const EDUCATION_OLYMPIADS = [
    {id: 1, name: "Olympiad 1", value: 1},
    {id: 2, name: "Olympiad 2", value: 2},
    {id: 3, name: "Olympiad 3", value: 3},
    {id: 4, name: "Olympiad 4", value: 4}
];

const EDUCATION_INFOS = [
    {id: 1, name: "BUTTON.ADD_OLYMPIAD", label: "QUESTIONNAIRE.INFOS.LABEL_1", field: "select", options: EDUCATION_OLYMPIADS},
    {id: 2, name: "BUTTON.ADD_SPORT_ACHIEVEMENT", label: "QUESTIONNAIRE.INFOS.LABEL_2", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 3, name: "BUTTON.ADD_CREATIVE_ACHIEVEMENT", label: "QUESTIONNAIRE.INFOS.LABEL_3", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 4, name: "BUTTON.ADD_POST", label: "QUESTIONNAIRE.INFOS.LABEL_4", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 5, name: "BUTTON.ADD_EXCHANGE_PROGRAM", label: "QUESTIONNAIRE.INFOS.LABEL_5", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 6, name: "BUTTON.ADD_ACTIVITY", label: "QUESTIONNAIRE.INFOS.LABEL_6", field: "input", placeholder: "FIELD.ENTER_TEXT"},
    {id: 7, name: "BUTTON.ADD_PROJECT", label: "QUESTIONNAIRE.INFOS.LABEL_7", field: "input", placeholder: "FIELD.ENTER_TEXT"}
];

export {
    EDUCATION_STATUS,
    EDUCATION_OLYMPIADS,
    EDUCATION_INFOS,
    QUESTIONNAIRE_EDUCATION_STATUS
};
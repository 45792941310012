import { createSlice } from "@reduxjs/toolkit";
import { fetchBlogArticle, fetchBlogArticles, fetchResource, fetchResources} from "@store/actions/blogAction";

const initialState = {
  articles: [],
  article: null,
  resources: [],
  resource: null,
  isArticlesLoading: false,
  isArticleLoading: false,
  isResourcesLoading: false,
  isResourceLoading: false
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBlogArticles.pending.type]: (state) => {
      state.isArticlesLoading = true;
    },
    [fetchBlogArticles.fulfilled.type]: (state, action) => {
      state.isArticlesLoading = false;
      state.error = null;
      state.articles = action.payload.results;
    },
    [fetchBlogArticles.rejected.type]: (state, action) => {
      state.isArticlesLoading = false;
      state.error = action.payload;
    },


    [fetchBlogArticle.pending.type]: (state) => {
      state.isArticleLoading = true;
    },
    [fetchBlogArticle.fulfilled.type]: (state, action) => {
      state.isArticleLoading = false;
      state.error = null;
      state.article = action.payload;
    },
    [fetchBlogArticle.rejected.type]: (state, action) => {
      state.isArticleLoading = false;
      state.error = action.payload;
    },


    [fetchResources.pending.type]: (state) => {
      state.isResourcesLoading = true;

    },
    [fetchResources.fulfilled.type]: (state, action) => {
      state.isResourcesLoading = false;
      state.error = null;
      state.resources = action.payload.results;
    },
    [fetchResources.rejected.type]: (state, action) => {
      state.isResourcesLoading = false;
      state.error = action.payload.results;
    },

    [fetchResource.pending.type]: (state) => {
      state.isResourceLoading = true;
    },
    [fetchResource.fulfilled.type]: (state, action) => {
      state.isResourceLoading = false;
      state.error = null;
      state.resource = action.payload.results;
    },
    [fetchResource.rejected.type]: (state, action) => {
      state.isResourceLoading = false;
      state.error = action.payload;
    },
  },
});

export default blogSlice.reducer;

import {useSelector} from "react-redux";
import {useTranslate} from "@helpers/hooks";

export default function ApplicationTitle() {
    const {t} = useTranslate();
    const {applications, totalCountApplication} = useSelector(state => state.program);
    return (
        <h6 className={"favorites__title"}>
            {applications?.length > 0 ? `${t("APPLICATION.TITLE")} (${totalCountApplication})` : t("APPLICATION.TITLE")}
        </h6>
    );
}
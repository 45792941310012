import { useWindowSize } from "@helpers/hooks";
import "./RightSidebar.scss";
import { useEffect } from "react";
import classNames from "classnames";
export default function RightSidebar({
  isOpen,
  setIsOpen,
  className,
  children,
}) {
  const windowSize = useWindowSize();

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      body.style.overflow = "auto";
    };
  }, [windowSize]);

  return (
    <div className="modal-overlay" onClick={() => setIsOpen(false)}>
      <div
        className={classNames("notif-sidebar", isOpen && "open", className)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

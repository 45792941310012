import React, { useEffect, useState, useRef } from "react";
import { formatTime } from "@utils/functions";
import "./ChallengeTimer.scss";

export default function ChallengeTimer({
  endTime,
  handleSubmit,
  completed,
  finishChallenge,
}) {
  const [timeLeft, setTimeLeft] = useState(null);
  const hasSubmittedRef = useRef(false);
  const warningTime = 60000;

  useEffect(() => {
    if (endTime && !completed) {
      const end = new Date(endTime).getTime();
      let timer;

      const updateRemainingTime = () => {
        const now = new Date().getTime();
        const distance = end - now;
        if (distance <= 0 && !hasSubmittedRef.current) {
          clearInterval(timer);
          finishChallenge();
          handleSubmit();
          hasSubmittedRef.current = true;
        } else {
          setTimeLeft(Math.max(distance, 0));
        }
      };

      updateRemainingTime();
      timer = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timer);
    }
  }, [endTime, completed]);

  return (
    <div>
      <p
        className={`timer__numbers ${
          completed
            ? "timer__blue"
            : timeLeft <= warningTime
            ? "timer__red"
            : "timer__green"
        }`}
      >
        {completed ? "Review" : formatTime(timeLeft)}
      </p>
    </div>
  );
}

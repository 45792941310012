import { Icon, Image } from "@components/common";
import { COURSE_ROUTE } from "@utils/consts";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./DashboardCourseCard.scss";

const DashboardCourseCard = ({ course }) => {
  const history = useHistory();
  const handleCardClick = () => {
    history.push(`${COURSE_ROUTE.replace(":courseId", course.id)}/sections/0/onboarding`);
  };
  return (
    <li className="dashboard__course-card" onClick={handleCardClick}>
      <Image className={"dashboard__course-image"} src={course.image} />
      <div className="dashboard__course-content">
        <p>{course.title}</p>
        <div className="dashboard__course-icon">
          <Icon name={"arrow-right"} />
        </div>
      </div>
    </li>
  );
};

export default DashboardCourseCard;

import { COURSE_LESSON_ROUTE } from "@utils/consts";

function getPrevLessonLink(courseId, sectionId, lessonId, currentCourse) {
  if (!currentCourse) return null;

  const currentSection = currentCourse.sections.find((section) => section.id === Number(sectionId));

  if (!currentSection) return null;

  const currentLessonIndex = currentSection.lessons.findIndex((lesson) => lesson.id === Number(lessonId));
  if (currentLessonIndex > 0) {
    // Previous lesson in current section exists
    const prevLesson = currentSection.lessons[currentLessonIndex - 1];
    return COURSE_LESSON_ROUTE.replace(":courseId", courseId)
      .replace(":sectionId", sectionId)
      .replace(":lessonId", prevLesson.id);
  }

  if (currentLessonIndex !== 0) return null;

  // Previous lesson in current section does not exist, but there are lessons in current section
  const prevSection = currentCourse.sections.find((section) => section.id < currentSection.id);

  if (!prevSection || prevSection.lessons.length === 0) return null;

  const lastLesson = prevSection.lessons[prevSection.lessons.length - 1];
  return COURSE_LESSON_ROUTE.replace(":courseId", courseId)
    .replace(":sectionId", prevSection.id)
    .replace(":lessonId", lastLesson.id);
}

export default getPrevLessonLink;

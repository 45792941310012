// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz__table {
  display: flex;
  flex-direction: column;
}
.quiz__table-container {
  border: 1px solid #dde1e5;
  border-radius: 16px;
}
.quiz__table .table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
}
.quiz__table .table__header-label {
  width: 200px;
}
.quiz__table .table__header-item {
  flex: 1 1;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/LessonQuizQuestions/TableOneChoice/TableOneChoice.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,yBAAA;EACA,mBAAA;AADJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,WAAA;AAHJ;AAKI;EACE,YAAA;AAHN;AAMI;EACE,SAAA;EACA,kBAAA;AAJN","sourcesContent":["@import \"@styles/variables\";\n\n.quiz__table {\n  display: flex;\n  flex-direction: column;\n\n  &-container {\n    border: 1px solid $gray_tabs_bg;\n    border-radius: 16px;\n   \n  }\n\n  .table__header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px;\n    width: 100%;\n\n    &-label {\n      width: 200px; // Adjust width according to your need\n    }\n\n    &-item {\n      flex: 1;\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

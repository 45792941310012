function convertQuestions(inputArray) {
  return inputArray?.map((item, index) => {
    let questionData = null;
    let questionKey = "";
    let questionType = "";

    if (item.type === "sat_mock_math_one_choice_question") {
      questionKey = "sat_mock_math_one_choice_question_2";
      questionData = item[questionKey];

      if (!questionData) {
        questionKey = "sat_mock_math_one_choice_question";
        questionData = item[questionKey];
      }

      questionType = "one_choice";
    } else if (item.type === "sat_mock_math_open_question") {
      questionKey = "sat_mock_math_open_question_2";
      questionData = item[questionKey];
      if (!questionData) {
        questionKey = "sat_mock_math_open_question";
        questionData = item[questionKey];
      }

      questionType = "insert_text";
    }

    if (questionType === "insert_text") {
      return {
        id: item.id,
        text: item.question,
        type: questionType,
        question_true_false: null,
        question_multiple_choice: null,
        question_one_choice: null,
        question_image: item.question_image,
        question_insert_text: questionData
          ? {
              id: questionData.id,
              question_image: questionData?.question_image,
              question_id: questionData.id,
              correct_answer: item?.correct_answer,
              is_correct: item?.is_correct,
              explanation: questionData?.explanation,
              explanation_image: questionData?.explanation_image,
              response: item?.response,
            }
          : null,
        question_practice: null,
        question_images: null,
        question_speaking: null,
        question_listening: null,
        position: index + 1,
      };
    } else if (questionType === "one_choice") {
      return {
        id: item.id,
        text: item.question,
        type: questionType,
        question_true_false: null,
        question_multiple_choice: null,
        question_image: item.question_image,
        question_one_choice: questionData
          ? {
              id: questionData.id,
              answer_options: questionData.options,
              question_id: questionData.id,
              correct_answer: item?.correct_answer,
              is_correct: item?.is_correct,
              explanation: questionData?.explanation,
              explanation_image: questionData?.explanation_image,
              response: item?.response,
            }
          : null,
        question_insert_text: null,
        question_practice: null,
        question_images: null,
        question_speaking: null,
        question_listening: null,
        position: index + 1,
      };
    }

    return {
      id: item.id,
      text: item.question,
      type: "one_choice",
      question_true_false: null,
      question_image: item.question_image,
      question_multiple_choice: null,
      question_one_choice: {
        id: item.id,
        answer_options: item.answer_options,
        question_image: item.question_image,
        question_id: item.id,
        correct_answer: item?.correct_answer,
        is_correct: item?.is_correct,
        explanation: item.explanation,
        explanation_image: item?.explanation_image,
        response: item?.response,
      },
      question_insert_text: null,
      question_practice: null,
      question_images: null,
      question_speaking: null,
      question_listening: null,
      position: index + 1,
    };
  });
}

export default convertQuestions;

import { ScholarshipService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchScholarshipsNew = createAsyncThunk("scholarship/fetchAll", async (params, thunkAPI) => {
  try {
    const res = await ScholarshipService.fetchScholarshipsNew(params);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchScholarshipNew = createAsyncThunk("scholarship/fetchOne", async (id, thunkAPI) => {
  try {
    const res = await ScholarshipService.fetchScholarshipNew(id);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchScholarshipsPrograms = createAsyncThunk("scholarship/programs", async (params, thunkAPI) => {
  try {
    const res = await ScholarshipService.fetchScholarshipsPrograms(params);
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export { fetchScholarshipsNew, fetchScholarshipNew, fetchScholarshipsPrograms };

import { useEffect } from "react";
import { ButtonLink, Divider } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { COURSE_LESSON_TEST_ROUTE } from "@utils/consts";
import { useAction } from "@helpers/hooks";
import { useSelector } from "react-redux";
import {useIsMobile} from "@helpers/hooks";
import { checkIfMarathon } from "@utils/functions";
import "./LessonQuizContent.scss";

export default function LessonQuizContent({ lesson, courseId, sectionId, lessonId }) {
  const { t } = useTranslate(), 
   { changeLessonStatus } = useAction(),
   { course } = useSelector((state) => state.courses),
   practiceId = lesson.content[0]?.content_quiz?.content_id;

  const quizContent = lesson.content[0]?.content_quiz;
  const score = quizContent?.score;
  const passedQuiz = score >= 70;

  const isMobile = useIsMobile()

  const link = `${COURSE_LESSON_TEST_ROUTE.replace(":courseId", courseId)
    .replace(":sectionId", sectionId)
    .replace(":lessonId", lessonId)
    .replace(":practiceId", practiceId)}`;

    useEffect(() => {
      const isMarathon = checkIfMarathon(courseId, 13);
    
      if ((isMarathon || passedQuiz) && !lesson.completed) {
        changeLessonStatus(lessonId, 100);
      }
    }, [passedQuiz, lesson.completed, courseId, lessonId]);

  return (
    <div className="lesson__container lesson__container-bottom">
      <div className="lesson__text__group">
        <h3 className="lesson__text">{lesson.content[0].content_quiz?.title}</h3>
        <div className="lesson__dot"></div>
        <p className="lesson__text">
          {lesson.duration} {t("LMS.MINUTES")}
        </p>
      </div>

      <div className={"lesson__container-horizontal"}>
        <p className="lesson__heading two-third">{t("LMS.QUIZ.SUBMIT")}</p>
        {!isMobile && <ButtonLink className={"button-primary"} text={score ? t("LMS.BUTTON.VIEW_FEEDBACK") : t("LMS.BUTTON.START_ASSIGNMENT")} link={link} />}
      </div>
      <Divider className={"section__divider"} />

      <div className="lesson__container-horizontal">
        <div className="lesson__container-vertical two-third right-divider">
          <p className="lesson__heading">{t("LMS.QUIZ.RECEIVE_GRADE")}</p>
          <p className="lesson__text">{t("LMS.QUIZ.PASS")}</p>
        </div>
        <div className="lesson__container-vertical ">
          <p className="lesson__heading">{t("LMS.QUIZ.YOUR_GRADE")}</p>
          <div className={`lesson__text ${passedQuiz ? "lesson__pass" : "lesson__fail"}`}>
            {quizContent?.completed ? `${score}%` : "-"}
          </div>
        </div>
      </div>
      <Divider className={"section__divider"} />
      {isMobile && <ButtonLink className={"button-primary button-mobile"} text={score ? t("LMS.BUTTON.VIEW_FEEDBACK") : t("LMS.BUTTON.START_ASSIGNMENT")} link={link} />}
    </div>
  );
}

import {useTranslate} from "@helpers/hooks";
import {universityOrders, programOrders} from "@utils/consts";
import {Search, Select} from "@components/common";
import classNames from "classnames";
import "./CatalogOrder.scss";

export default function CatalogOrder({className, tabs, selectedTab, search, setSearch, setOrder, isResetFilter}) {
    const {t} = useTranslate();

    const handleOrder = (order) => setOrder(order.value);
    const handleChange = (e) => setSearch(e.target.value);
    return (
        <div className={classNames("catalog-order", className)}>
            <Search
                className={"catalog-order__search"}
                label={t("CATALOG.SEARCH_LABEL")}
                placeholder={t("FIELD.START_SEARCH")}
                value={search}
                onChange={handleChange}
            />
            <Select
                className={"catalog-order__select select--border"}
                label={t("BUTTON.SORT_BY")}
                placeholder={t("FIELD.NOT_CHOSEN")}
                options={selectedTab === tabs.universities ? universityOrders : programOrders}
                icon={"sort"}
                isResetFilter={isResetFilter}
                handleChange={handleOrder}
            />
        </div>
    );
};
import { ProgramService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchPrograms = createAsyncThunk("programs/fetchAll", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchPrograms(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchSimplePrograms = createAsyncThunk("programs/fetchAllSimple", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchSimplePrograms(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchProgram = createAsyncThunk("programs/fetchOne", async (id, thunkAPI) => {
  try {
    return await ProgramService.fetchProgram(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const setProgramsPageChange = createAsyncThunk("programs/pageChange", async (currentPage, thunkAPI) => {
  try {
    return currentPage;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const setProgramsPageSizeChange = createAsyncThunk("programs/pageSizeChange", async (pageSize, thunkAPI) => {
  try {
    return pageSize;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchProgramSuccessRate = createAsyncThunk("program/fetchSuccessRate", async (id, thunkAPI) => {
  try {
    return await ProgramService.fetchProgramSuccessRate(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchFavorites = createAsyncThunk("favorites/fetchAll", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchPrograms(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchFavoritesTotalCount = createAsyncThunk("favorites/fetchTotalCount", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchPrograms(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const addFavorite = createAsyncThunk("favorites/add", async (id, thunkAPI) => {
  try {
    return await ProgramService.addProgramToShortlist(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const deleteFavorite = createAsyncThunk("favorites/delete", async (id, thunkAPI) => {
  try {
    return await ProgramService.addProgramToShortlist(id);
  } catch (e) {
    debugger;
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchApplications = createAsyncThunk("applications/fetchAll", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchPrograms(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchApplicationsTotalCount = createAsyncThunk("applications/fetchTotalCount", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchAllApplications(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const addApplication = createAsyncThunk("applications/add", async (id, thunkAPI) => {
  try {
    return await ProgramService.addProgramToAplication(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const deleteApplication = createAsyncThunk("applications/delete", async (id, thunkAPI) => {
  try {
    return await ProgramService.addProgramToAplication(id);
  } catch (e) {
    debugger;
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const fetchAllApplication = createAsyncThunk("applications/fetchApplications", async (params, thunkAPI) => {
  try {
    return await ProgramService.fetchAllApplications(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const changeApplicationStatus = createAsyncThunk("applications/change_status", async ({ id, status }, thunkAPI) => {
  try {
    return await ProgramService.changeApplicationStatus({ id, status });
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export {
  fetchPrograms,
  fetchSimplePrograms,
  setProgramsPageChange,
  setProgramsPageSizeChange,
  fetchProgram,
  fetchFavorites,
  addFavorite,
  deleteFavorite,
  fetchProgramSuccessRate,
  fetchFavoritesTotalCount,
  fetchApplications,
  deleteApplication,
  addApplication,
  fetchApplicationsTotalCount,
  fetchAllApplication,
  changeApplicationStatus,
};

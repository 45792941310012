import './LmsBadgeProfileAchievements.scss';

const LmsBadgeProfileAchievements = () => {
    return (
        <div>
            Achievements
        </div>
    );
}

export default LmsBadgeProfileAchievements;

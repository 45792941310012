const findDuration = (duration) => {
    if (duration) {
        if (typeof duration === "string") {
            const findSquareBrackets = duration.search(/\[.*?\]/g);
            if (findSquareBrackets !== -1) {
                return `${duration.substring(1, duration.length - 1)} months`;
            } else {
                return `${duration} months`;
            }
        }

        if (typeof duration === "object") {
            return `${Array(duration).flat(Infinity)} months`
        }

        return `${duration} months`;
    } else {
        return "";
    }
};

export default findDuration;
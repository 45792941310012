import React, { useEffect } from "react";
import { FormItem, List } from "@components/common";
import { getQuestionData } from "@utils/functions";
import "./TableOneChoice.scss";

const TableOneChoice = ({ question, userAnswer, onAnswerChange, disabled }) => {
  let questionData = getQuestionData(question);
  const isReviewMode = questionData.some(
    (item) => item.correct_answer !== undefined && item.response !== undefined
  );

  const columns = questionData[0].answer_options;

  const handleChange = (questionId, value) => {
    const newAnswers = { ...userAnswer, [questionId]: value };
    onAnswerChange(newAnswers);
  };

  useEffect(() => {
    if (isReviewMode) {
      const updatedAnswers = {};
      questionData.forEach((item) => {
        updatedAnswers[item.id] = item.response;
      });
      onAnswerChange(updatedAnswers);
    }
  }, [isReviewMode, questionData, onAnswerChange]);

  return (
    <div className="quiz__table">
      <div className="table__header">
        <div className="table__header-label" />
        {columns.map((col, index) => (
          <div key={index} className="table__header-item">
            {col}
          </div>
        ))}
      </div>
      <List
        className={"quiz__table-container"}
        items={questionData}
        renderItem={(question) => (
          <FormItem
            key={question.id}
            item={{
              id: question.id,
              placeholder: "",
              field: "table-quiz",
              value: userAnswer?.[question.id] || "",
              options: question.answer_options || [],
              label: question.text,
              correctAnswer: question?.correct_answer,
              isCorrect: question?.is_correct,
            }}
            handleChange={(e) => handleChange(question.id, e.target.value)}
            disabled={disabled || isReviewMode}
          />
        )}
      />
    </div>
  );
};

export default TableOneChoice;

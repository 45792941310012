// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auto-complete-empty {
  padding: 17.5px 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/AutoComplete/AutoCompleteEmpty/AutoCompleteEmpty.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF","sourcesContent":["@import \"src/styles/variables\";\n\n.auto-complete-empty {\n  padding: 17.5px 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

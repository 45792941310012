import { Icon, NewModalLayout } from '@components/common';
import { useTranslate } from '@helpers/hooks';
import ReactPlayer from 'react-player';
import './FirstTimeVisitPopup.scss';

const FirstTimeVisitPopup = ({setFirstTime}) => {
    const {t} = useTranslate();

    function handleClose() {
        setFirstTime(false);
    }

    const ONBOARDING_VIDEO = "https://www.youtube.com/watch?v=r9UJEoSN2ss&ab_channel=SmarTestPrep";

    return (
        <NewModalLayout className="first-time-visit">
            <div className="first-time-visit__header">
                <h1>🎉 {t("DASHBOARD.POPUP.LABEL_1")} 🎉</h1>
                <Icon name="close-rounded" className="first-time-visit__close" handleClick={handleClose}/>
            </div>
            <p>{t("DASHBOARD.POPUP.LABEL_2")}</p>
            <ReactPlayer
                className="player__video"
                url={ONBOARDING_VIDEO}
                width="100%"
                height="400px"
                controls={true}
                config={{
                    file: {
                        attributes: {
                        controlsList: "nodownload",
                        onContextMenu: (e) => e.preventDefault(),
                        },
                    },
                }}
            />
        </NewModalLayout>
    );
}

export default FirstTimeVisitPopup;

import { useTranslate } from '@helpers/hooks';
import { Button, NewModalLayout } from "@components/common";
import './PopupWindow.scss';

const PopupWindow = (props) => {
    const {confirmationTitle, confirmationText, handleCancel, handleConfirm} = props;
    const {t} = useTranslate();

    return (
        <NewModalLayout className="pop-up">
            <h5 className="pop-up__title">{t(confirmationTitle)}</h5>
            <p className="pop-up__text">{t(confirmationText)}</p>
            <div className="pop-up__buttons">
                <Button
                    text={t("LMS.BUTTON.CANCEL")}
                    className="btn--white-popup btn--pill"
                    onClick={handleCancel}
                />
                <Button
                    text={t("LMS.BUTTON.YES_CONTINUE")}
                    className="btn--secondary btn--pill pop-up__btn"
                    onClick={handleConfirm}
                />
            </div>
        </NewModalLayout>
    );
}

export default PopupWindow;

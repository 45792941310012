import {useTranslate} from "@helpers/hooks";
import {Icon, Tag} from "@components/common";
import classNames from "classnames";
import "../AdditionalSelect.scss";

export default function AdditionalSelectOption(props) {
    const {
        showOptions, selectedOptions, placeholder,
        showOptionsHandler, deleteSuggestionHandler
    } = props;
    const {t} = useTranslate();
    return (
        <div className={classNames("additional-select__option", {active: showOptions})} onClick={showOptionsHandler}>
            {
                selectedOptions.length > 0
                    ? <Tag
                        className={"additional-select__tag"}
                        name={t(selectedOptions[0].name)}
                        handleDelete={(e) => {
                            e.stopPropagation();
                            deleteSuggestionHandler(selectedOptions[0]);
                        }}
                    />
                    : <p className={"additional-select__placeholder"}>{placeholder}</p>
            }
            <div className={"additional-select__helper"}>
                {
                    selectedOptions.length > 1 && <p>{`+ ${selectedOptions.length - 1}`}</p>
                }
                <Icon name={showOptions ? "arrow-up" : "arrow-down"}/>
            </div>
        </div>
    );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-modal__input {
  margin-top: 28px;
}
.password-modal__btn {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password-modal__success {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Modal/PasswordModal/PasswordModal.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAJJ","sourcesContent":["@import \"src/styles/variables\";\n\n.password-modal {\n  &__input {\n    margin-top: 28px;\n  }\n\n  &__btn {\n    margin-top: 32px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  &__success {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

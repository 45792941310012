import {useTranslate} from "@helpers/hooks";
import {Icon} from "@components/common";
import "../EmailModal.scss";

export default function EmailSuccess() {
    const {t} = useTranslate();
    return (
        <div className={"email-modal__success"}>
            <Icon name={"correct"}/>
            <p>{t("SETTINGS.EMAIL_SUCCESS")}</p>
        </div>
    );
};
import {useMemo} from "react";
import {List} from "@components/common";
import "./ProgramRating.scss";

export default function ProgramRating({program}) {
    const ratings = useMemo(() => [
        {id: 1, label: "US News", value: program.rating?.us_news},
        {id: 2, label: "QS", value: program.rating?.QS},
        {id: 3, label: "Shanghai", value: program.rating?.shanghai}
    ], [program]);

    return (
        <div className={"program-rating"}>
            <List
                className={"program-rating__list"}
                items={ratings}
                renderItem={(rating) => (
                    rating.value ?
                        <li key={rating.id} className={"program-rating__item"}>
                            <p className={"program-rating__name"}>
                                {rating.label}
                            </p>
                            <p className={"program-rating__value"} dangerouslySetInnerHTML={{__html: String((''+rating.value)?.replace(/\r\n/gi, "<br/>"))}}></p>
                        </li>
                    : ""
                )}
            />
        </div>
    );
};
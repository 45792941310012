// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-skeleton__name:last-child {
  margin-top: 60px;
}
.settings-skeleton__list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.settings-skeleton__item {
  display: flex;
  margin-bottom: 24px;
}
.settings-skeleton__item:last-child {
  margin-bottom: 0;
}
.settings-skeleton__field {
  width: 200px;
  margin-right: 24px;
}
.settings-skeleton__field > span > span {
  height: 40px;
}
.settings-skeleton__value {
  width: 340px;
}
.settings-skeleton__value > span > span {
  height: 40px;
}

@media screen and (max-width: 474px) {
  .settings-skeleton__item {
    flex-wrap: wrap;
  }
  .settings-skeleton__field {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .settings-skeleton__field > span > span {
    height: 22px;
  }
  .settings-skeleton__value {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Settings/SettingsSkeleton/SettingsSkeleton.scss"],"names":[],"mappings":"AAII;EACE,gBAAA;AAHN;AAOE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AALJ;AAQE;EACE,aAAA;EACA,mBAAA;AANJ;AAQI;EACE,gBAAA;AANN;AAUE;EACE,YAAA;EACA,kBAAA;AARJ;AAUI;EACE,YAAA;AARN;AAYE;EACE,YAAA;AAVJ;AAYI;EACE,YAAA;AAVN;;AAeA;EAEI;IACE,eAAA;EAbJ;EAgBE;IACE,eAAA;IACA,mBAAA;EAdJ;EAgBI;IACE,YAAA;EAdN;EAkBE;IACE,WAAA;EAhBJ;AACF","sourcesContent":["@import \"src/styles/variables\";\n\n.settings-skeleton {\n  &__name {\n    &:last-child {\n      margin-top: 60px;\n    }\n  }\n\n  &__list {\n    margin-top: 30px;\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__item {\n    display: flex;\n    margin-bottom: 24px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n\n  &__field {\n    width: 200px;\n    margin-right: 24px;\n\n    & > span > span {\n      height: 40px;\n    }\n  }\n\n  &__value {\n    width: 340px;\n\n    & > span > span {\n      height: 40px;\n    }\n  }\n}\n\n@media screen and (max-width: 474px){\n  .settings-skeleton {\n    &__item {\n      flex-wrap: wrap;\n    }\n\n    &__field {\n      margin-right: 0;\n      margin-bottom: 10px;\n\n      & > span > span {\n        height: 22px;\n      }\n    }\n\n    &__value {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

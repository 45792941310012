import { Divider, List } from "@components/common";
import { isEmptyArray } from "formik";
import React from "react";
import { SectionModule } from "../SectionModule";
import { useIsMobile } from "@helpers/hooks";
import "./SectionModulesList.scss"

export default function SectionModulesList({ section, courseId, sectionId }) {
  const isMobile = useIsMobile()
  return (
<div className={isMobile ? `section-mobile` : ""}>
      <List
        className="section__modules"
        items={section.lessons}
        renderItem={(lesson) => (
          <li key={lesson.id} className={"section__indicator-item"}>
            <SectionModule lesson={lesson} courseId={courseId} sectionId={sectionId}/>
          </li>
        )}
      />
    </div>
  );
}

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '@helpers/hooks';
import { List, PageTitle } from '@components/common';
import './MobileLanguage.scss';

const MobileLanguage = () => {
    const { i18n, t } = useTranslate(),
    currentLang = localStorage.getItem("lang"),
    history = useHistory(),
    LANGUAGES = [
        {
            id: 1,
            label: "RU",
            title: t("MOBILE.LANGUAGE.RU"),
        },
        {
            id: 2,
            label: "EN",
            title: t("MOBILE.LANGUAGE.EN"),
        },
    ];

    const saveLang = async (lang) => {
        localStorage.setItem("lang", lang);
        await i18n.changeLanguage(lang);
    };

    const handleChangeLanguage = (label) => {
        if (label === "RU") {
            saveLang("ru");
        }
        if (label === "EN") {
            saveLang("en");
        }
    }

    const handleGoBack = () => {
        history.push("/profile-mobile");
    }

    return (
        <div className="mobile-language">
            <PageTitle title={'MOBILE.SYSTEM_LANGUAGE'} iconLeft={"arrow_title"} isGoBack/>
            
            <List
                items={LANGUAGES}
                className="mobile-language__list"
                renderItem={({ id, label, title }) => (
                    <li key={id} className={classNames("mobile-language__item")} onClick={() => handleChangeLanguage(label)}>
                        <div className={"mobile-language__link"}>
                            <div className="mobile-language__icon-circle">
                                <h6>{label}</h6>
                            </div>
                            <div className="mobile-language__item">
                                <p>{title}</p>
                            </div>
                        </div>
                        {label === currentLang.toUpperCase() && (
                            <input
                                type="checkbox"
                                className="mobile-language__checkbox"
                                checked={true}
                                readOnly
                            />
                        )}
                    </li>
                )}
            />
        </div>
    );
}

export default MobileLanguage;
const getQuestionData = (question) => {
  switch (question.type) {
    case "one_choice":
      return question.question_one_choice;
    case "multiple_choice":
      return question.question_multiple_choice;
    case "insert_text":
      return question.question_insert_text;
    case "true_false":
      return question.question_true_false;
    case "practice":
      return question.question_practice;
    case "speaking_question":
      return question.question_speaking;
    case "dnd":
      return question.question_dnd;
    case "table":
      return question.question_table;
    case "question_images":
      return question.question_images;
    default:
      break;
  }
};

export default getQuestionData;

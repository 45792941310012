// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.university-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.university-list > li:first-child {
  margin-top: 0;
}
.university-list__skeleton {
  display: flex;
  flex-direction: column;
}
.university-list__skeleton > div {
  margin-top: 16px;
}
.university-list__skeleton > div:first-child {
  margin-top: 0;
}
.university-list__pagination {
  margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Catalog/UniversityList/UniversityList.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AADF;AAKI;EACE,aAAA;AAHN;AAOE;EACE,aAAA;EACA,sBAAA;AALJ;AAOI;EACE,gBAAA;AALN;AAOM;EACE,aAAA;AALR;AAUE;EACE,gBAAA;AARJ","sourcesContent":["@import \"src/styles/variables\";\n\n.university-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 30px;\n\n  & > li {\n\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n\n  &__skeleton {\n    display: flex;\n    flex-direction: column;\n\n    & > div {\n      margin-top: 16px;\n\n      &:first-child {\n        margin-top: 0;\n      }\n    }\n  }\n\n  &__pagination {\n    margin-top: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

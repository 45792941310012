import React from "react";
import { Icon, NewModalLayout } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { AmoForm } from "@components/feature";
import "./MockFormModal.scss";

const MockFormModal = ({ isMobile, onClose }) => {
  const { t } = useTranslate();

  return (
    <NewModalLayout
      className={`pop-up ${
        isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
      }`}
    >
      <h2 className="update-modal__title">
        Оставить заявку
      </h2>
      <Icon
        name="close-rounded"
        className="update-modal__close"
        handleClick={onClose}
      />
      <AmoForm onClose={onClose} />
    </NewModalLayout>
  );
};

export default MockFormModal;

import ReactDOM from "react-dom";
import {AppContainer} from "@containers";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    defaultTracking: true,
  })

ReactDOM.render(
    <AppContainer/>,
    document.getElementById("root")
);

import { createSlice } from "@reduxjs/toolkit";
import { fetchScholarshipsNew, fetchScholarshipNew, fetchScholarshipsPrograms } from "@store/actions/scholarshipsAction";

const initialState = {
  scholarships: [],
  scholarship: null,
  scholarshipsPrograms: [],
  isProgramsLoading: false,
  isScholarshipsLoading: false,
  isScholarshipLoading: false,
};

export const scholarshipsSlice = createSlice({
  name: "scholarships",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchScholarshipsNew.pending.type]: (state) => {
      state.isScholarshipsLoading = true;
    },
    [fetchScholarshipsNew.fulfilled.type]: (state, action) => {
      state.isScholarshipsLoading = false;
      state.error = null;
      state.scholarships = action.payload;
    },
    [fetchScholarshipsNew.rejected.type]: (state, action) => {
      state.isScholarshipsLoading = false;
      state.error = action.payload;
    },


    [fetchScholarshipNew.pending.type]: (state) => {
      state.isScholarshipLoading = true;
    },
    [fetchScholarshipNew.fulfilled.type]: (state, action) => {
      state.isScholarshipLoading = false;
      state.error = null;
      state.scholarship = action.payload;
    },
    [fetchScholarshipNew.rejected.type]: (state, action) => {
      state.isScholarshipLoading = false;
      state.error = action.payload;
    },

    [fetchScholarshipsPrograms.pending.type]: (state) => {
      state.isProgramsLoading = true;
    },
    [fetchScholarshipsPrograms.fulfilled.type]: (state, action) => {
      state.isProgramsLoading = false;
      state.error = null;
      state.scholarshipsPrograms = action.payload.results.map(program => ({
        ...program,
        value: program.id
    }));
    },
    [fetchScholarshipsPrograms.rejected.type]: (state, action) => {
      state.isProgramsLoading = false;
      state.error = action.payload;
    },
  },
});

export default scholarshipsSlice.reducer;

import {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAction} from "@helpers/hooks";
import {parseAllObjToStr} from "@utils/functions";
import {List, Pagination} from "@components/common";
import {CatalogEmpty, CatalogError, ProgramModal, ProgramSkeleton, ProgramItem} from "@components/feature";
import "./ProgramsList.scss";

export default function ProgramsList(props) {
    const {
        debouncedValue, selectedFilters, order, additionalParams, setSelectedTab,
        tabs, setUniversityId, setUniversityOpen, selectedProgram, setSearch,
        setOrder, setSelectedOrder, setIsResetFilter, setSelectedFilters,
        initialFilters
    } = props;
    const {results: programs, isLoading, totalCount, pageSize, currentPage, offset, error} = useSelector(state => state.program),
        {fetchPrograms, fetchProgram, setProgramsPageChange, setProgramsPageSizeChange} = useAction(),
        [selectedProgramId, setSelectedProgramId] = useState(null),
        [programOpen, setProgramOpen] = useState(false);

    const onSelectProgram = (id) => {
        setSelectedProgramId(id);
        setProgramOpen(true);
    };

    const onSelectUniversity = (universityId) => {
        setSearch("");
        setOrder(null);
        setSelectedOrder({});
        setIsResetFilter(true);
        setSelectedFilters(initialFilters);
        setProgramOpen(false);
        setSelectedTab(tabs.universities);
        setUniversityId(universityId);
        setUniversityOpen(true);
    };

    useEffect(() => {
        const parseObj = parseAllObjToStr(selectedFilters);
        const params = {
            limit: pageSize,
            offset,
            search: debouncedValue,
            ordering: order,
            region: parseObj.region,
            country: parseObj.country,
            city: parseObj.city,
            subject: parseObj.subject,
            degree: parseObj.degree,
            university: parseObj.university,
            duration_field_test: parseObj.duration_field_test,
            study_mode: parseObj.study_mode,
            study_level: parseObj.primary_study_level,
            fees_field_test: parseObj.tuition_fee,
            scholarships: parseObj.scholarships,
            rating__QS: parseObj.rating__QS,
            ...additionalParams?.programs
        };
        fetchPrograms(params);
        // eslint-disable-next-line
    }, [debouncedValue, currentPage, pageSize, order]);

    useEffect(() => {
        if (selectedProgramId) {
            fetchProgram(selectedProgramId);
        }
        // eslint-disable-next-line
    }, [selectedProgramId]);

    useEffect(() => {
        if (selectedProgram) {
            setProgramOpen(true);
            fetchProgram(selectedProgram.id);
        }
    }, [selectedProgram]);

    if (programs?.length === 0 && !isLoading) {
        return (
            <CatalogEmpty/>
        );
    } else if (error) {
        return (
            <CatalogError message={error}/>
        );
    }
    return (
        <Fragment>
            {
                programs && !isLoading
                    ? <List
                        className={"program-list"}
                        items={programs}
                        renderItem={(program) => (
                            <ProgramItem
                                key={program.id}
                                program={program}
                                onSelectProgram={() => onSelectProgram(program.id)}
                                onSelectUniversity={() => onSelectUniversity(program.university.id)}
                            />
                        )}
                    />
                    :
                    <div className={"program-list__skeleton"}>
                        {
                            Array(10).fill(Infinity).map((item, itemId) => itemId).map((program, programId) => (
                                <ProgramSkeleton key={programId}/>
                            ))
                        }
                    </div>
            }
            <Pagination
                className={"program-list__pagination"}
                totalCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                siblingCount={0}
                onPageChange={(page) => setProgramsPageChange(page)}
                onPageSizeChange={(pageSize) => setProgramsPageSizeChange(pageSize)}
            />
            <ProgramModal
                open={programOpen}
                setOpen={setProgramOpen}
                onSelectUniversity={onSelectUniversity}
            />
        </Fragment>
    );
};
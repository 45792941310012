import { Feedback, HeaderLanguageToggle } from "@components/feature";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../Icon";
import "./PageTitle.scss";
import { Image } from "../Image";
import { useState } from "react";

export default function PageTitle({
  title,
  iconLeft,
  iconRight,
  handleLeftClick,
  handleRightClick,
  isGoBack,
  isLogo,
  isLanguage
}) {
  const isMobile = useIsMobile();
  const history = useHistory()
  const {t} = useTranslate();
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="page__title">
        {isMobile && <Icon name={isLogo ? 'logo_stp' : isGoBack ? 'arrow_title' : iconLeft} handleClick={isGoBack ? handleGoBack : handleLeftClick} className="page__icon-leading" />}
      {title && <h2 className="page__title-text">{t(title)}</h2>}
      {isMobile && isLanguage && (
        <>
        <Image className="page__title-feedback" name={"lamp_feedback.png"}  onClick={() => setIsFeedbackActive(true)}  />
        <HeaderLanguageToggle className={"page__icon-trailing"} />
        </>
      )}
      {isMobile && iconRight && (
        <Icon name={iconRight} handleClick={handleRightClick} className="page__icon-trailing" />
      )}
      {isFeedbackActive && <Feedback handleClose={() => setIsFeedbackActive(false)} />}
    </div>
  );
}

import {
  Button,
  ButtonV2,
  Icon,
  List,
  ModalSheet,
  NewModalLayout,
} from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { STP_OPEN_SIDE } from "@utils/consts";
import React, { useState } from "react";
import "./DashboardProftest.scss";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";

export default function DashboardProftest({ proftest }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const [modalState, setModalState] = useState({ isOpen: false, item: null });

  const RESULTS = [
    {
      id: 0,
      name: "Artist",
      description: "Artist are good",
    },
    {
      id: 1,
      name: "Developer",
      description: "Developer are the best",
    },
    {
      id: 2,
      name: "Manager",
      description: "Product are mid",
    },
  ];

  const handleItemClick = (item) => {
    setModalState({ isOpen: true, item });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, item: null });
  };

  const handleButtonClick = () => {
    window.location.href = `${STP_OPEN_SIDE}/test`;
  };

  return (
    <div className="dashboard__proftest">
      <div className="dashboard__proftest-heading">
        <h2 className="dashboard__subheading">
          {t(proftest ? "PROFTEST.TITLE" : "PROFTEST.CTA")}
        </h2>
        {!isMobile && (
          <ButtonV2
            text={t(proftest ? "PROFTEST.BUTTON" : "PROFTEST.START")}
            className="button--primary-small btn--pill"
            onClick={handleButtonClick}
          />
        )}
      </div>
      {proftest && (
        <div className="dashboard__proftest-subtitle">
          {t("PROFTEST.GROUP")}{" "}
          <div className="dashboard__proftest-group" onClick={() => handleItemClick(proftest?.personality_type)}>
            {proftest?.personality_type.name}
            <Icon name="pagination-arrow-right" />
          </div>

        </div>
      )}
      <p className="dashboard__proftest-subtitle">
        {t("PROFTEST.RECOMMENDATIONS")}
      </p>
      <List
        className={`dashboard__proftest-items ${!proftest && "placeholder"}`}
        items={proftest?.professions || RESULTS}
        renderItem={(item) => (
          <li
            key={item.name}
            className="dashboard__proftest-item"
            onClick={() => handleItemClick(item)}
          >
            {item.name}
            <Button
              text={t("PROFTEST.MORE")}
              className="dashboard__proftest-more"
            />
          </li>
        )}
      />

      <div className="dashboard__proftest-description">
        <h5>{proftest?.profession_type?.name}</h5>
        <ReactMarkdown className="dashboard__proftest-description-text">
          {proftest?.profession_type?.description}
        </ReactMarkdown>
      </div>

      {modalState.isOpen && (
        <NewModalLayout className="dashboard__proftest-modal">
          <div className="dashboard__proftest-title">
            <h3>{modalState.item.name}</h3>
            <Icon
              name="close-rounded"
              className="lms-badge-profile__close"
              handleClick={closeModal}
            />
          </div>
          {modalState.item.video && (
            <ReactPlayer
              className="player__video"
              url={modalState.item.video}
              width="100%"
              height="fit-content"
              controls={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    onContextMenu: (e) => e.preventDefault(),
                  },
                },
              }}
            />
          )}
          <ReactMarkdown className="dashboard__proftest-modal-text">
            {modalState.item.description}
          </ReactMarkdown>
          {modalState.item.file && (
            <Button
              onClick={() => window.open(modalState.item.file, "_blank")}
              iconLeft={
                <Icon
                  className="dashboard__proftest-download"
                  name="download"
                />
              }
              className="btn--primary btn--pill"
              text={t("PROFTEST.DOWNLOAD")}
            />
          )}
        </NewModalLayout>
      )}
      {isMobile && (
        <ButtonV2
          text={t(proftest ? "PROFTEST.BUTTON" : "PROFTEST.START")}
          className="button--primary-small btn--pill"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
}

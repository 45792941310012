import React from "react";
import { Icon, Image } from "@components/common";
import { useHistory } from "react-router-dom";
import { BLOG_ARTICLE_ROUTE } from "@utils/consts";
import { useTranslate } from "@helpers/hooks";
import { formatData } from "@utils/functions";
import { useIsMobile } from "@helpers/hooks";
import "./BlogArticleCard.scss";

export default function BlogArticleCard({ article }) {
  const history = useHistory();
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const handleCardClick = () => {
    history.push(BLOG_ARTICLE_ROUTE.replace(":blogId", article.id));
  };

  let articleDate = formatData(article?.created_at, 2);

  return (
    <div className={`blog__card ${isMobile && 'blog__card-mobile'}`} onClick={() => handleCardClick()}>
      <div className="blog__page-group-vertical">
        <Image src={article.image} className="blog__card-image" />

        <div className="blog__gap-one">
          <p className="blog__card-author">{article.author}</p>
          <h3 className="blog__card-title">{article.title}</h3>
        </div>
      </div>

      <div className="blog__page-group-horizontal">
        <div className="blog__card-views">
          <Icon name="views" className="blog__card-views-icon" />
          <p className="blog__card-views-text">
            {article.views_count} {t("BLOG.VIEWS")}
          </p>
        </div>

        <div className="group__divider"></div>

        <div className="blog__card-views">
          {/* <Icon name="blog_date" className="blog__card-views-icon" /> */}
          <p className="blog__card-views-text">{articleDate}</p>
        </div>
      </div>
    </div>
  );
}

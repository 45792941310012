import { WithRemoveScroll } from "@helpers/hoc";
import { Burger } from "@components/common";
import { BurgerMenu, ContentShadow, BurgerCourseMenu } from "@components/feature";
import { useLocation } from "react-router-dom"; 
import "./HeaderBurger.scss";
import { COURSE_ROUTE } from "@utils/consts";


export default function HeaderBurger({ isMenuOpen, setIsMenuOpen }) {
  const { pathname } = useLocation();
  return (
    <div className={"header__burger"}>
      <Burger animate={isMenuOpen} handleClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)} />
      {isMenuOpen && <ContentShadow setIsMenuOpen={setIsMenuOpen} />}
      <WithRemoveScroll flag={isMenuOpen}>
        { pathname.includes(COURSE_ROUTE.replace(":courseId", pathname.split("/")[2])) ? (
           <BurgerCourseMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        ) : (
          <BurgerMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        )}
      </WithRemoveScroll>
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exams {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
}
.exams__blocks {
  display: flex;
  column-gap: 71px;
}
.exams__letters {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
}
.exams__letters .radio__helper {
  font-weight: 500;
  font-size: 15px;
}
.exams button {
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Profile/Exams/Exams.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AADF;AAGE;EACE,aAAA;EACA,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAFJ;AAII;EACE,gBAAA;EACA,eAAA;AAFN;AAME;EACE,oBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import \"@styles/variables\";\n\n.exams {\n  display: flex;\n  flex-direction: column;\n  row-gap: 64px;\n\n  &__blocks {\n    display: flex;\n    column-gap: 71px;\n  }\n\n  &__letters {\n    display: flex;\n    flex-direction: column;\n    row-gap: 48px;\n\n    .radio__helper {\n      font-weight: 500;\n      font-size: 15px;\n    }\n  }\n\n  button {\n    align-self: flex-end;\n    width: fit-content;\n    margin-right: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useMemo } from "react";
import { useIsMobile, useTranslate, useWindowSize } from "@helpers/hooks";
import { COURSE_STATUS_FILTER } from "@utils/consts";
import { List, FormItem, Search, Divider } from "@components/common";
import "./CourseFilters.scss"
import { trackAmplitude } from "@utils/functions";

export default function CourseFilters({ courses, searchInput, setSearchInput, selectedAuthor, setSelectedAuthor, selectedCategory, setSelectedCategory }) {
  const { t } = useTranslate();

  const statusList = useMemo(() => {
    if (!courses) return [];
    const uniqueNames = [...new Set(courses.map((course) => course?.author?.name))];
    const categories = uniqueNames.map((name, index) => ({ id: index, value: name, name }));
    return [{ id: -1, value: "All", name: t("APPLICATION.ALL") }, ...categories];
  }, [courses, t]);
  const isMobile = useIsMobile();
  const courseInfo = useMemo(() => {
    return {
      header: [
        {
          id: 1,
          label: t("LMS.COURSE.STATUS.TITLE"),
          field: "dropdown",
          name: "status",
          defaultValue: {
            name: selectedCategory ? `APPLICATION.${selectedCategory.toUpperCase()}` : "???",
            value: selectedCategory
          },
          options: COURSE_STATUS_FILTER(t),
        },
        {
          id: 2,
          label: t("LMS.COURSE.AUTHOR"),
          field: "dropdown",
          name: "author",
          defaultValue: {
            name: selectedAuthor === "All" ? `APPLICATION.${selectedAuthor.toUpperCase()}` : selectedAuthor,
            value: selectedAuthor,
          },
          options: statusList,
        },
      ],
    };
  }, [statusList, selectedAuthor, selectedCategory, t]);

  const handleChange = async (e, field, name) => {
    if (name === "status") {
      await setSelectedCategory(e.value);
      trackAmplitude("learning_platfrom_status_select", {description: 'Пользователь успешно выбрал статус курса', status: e.value})
    }
    if (name === "author") {
      await setSelectedAuthor(e.value);
      trackAmplitude("learning_platfrom_author_select", {description: 'Пользователь успешно выбрал автор курса', author: e.value})
    }
  };

  return (
    <div className="courses__group">
      
        <>
      <List
        className={isMobile?"courses_mobile__header":"courses__header"}
        items={courseInfo.header}
        renderItem={(item) => <FormItem key={item.id} item={item} handleChange={handleChange} />}
      />
      <Divider className={"courses__divider"} />
      </>
      


      <Search
        className={isMobile?"courses_mobile__search":"courses__search"}
        label={t("LMS.COURSE.SEARCH_COURSE")}
        placeholder={t("FIELD.START_SEARCH")}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
    </div>
  );
}

const editContent = (content, count) => {
    if (content === "reading") return count === 1 ? "reading" : "readings";
    if (content === "video") return count === 1 ? "video" : "videos";
    if (content === "practice") return count === 1 ? "practice quiz" : "practice quizzes";
}

const parseContentCount = (materialsMap) => {
    return Array.from(materialsMap, ([key, value]) => (
        {
            "count": value,
            "content": editContent(key, value)
        }
    ))
}

const getTotalCountMaterials = (course) => {
    if (!course) return [];
    const materialsMap = new Map()
    course.sections.map(section => {
        section.lessons?.map(lesson => {
            let count = 1
            if (materialsMap.has(lesson.type)) count += materialsMap.get(lesson.type)
            materialsMap.set(lesson.type, count)
        })
    })
    return parseContentCount(materialsMap)
};

const parseSectionCard = (section) => {
    const materialsMap = new Map()
    section.lessons?.map(lesson => {
        let count = 1
        if (materialsMap.has(lesson.type)) count += materialsMap.get(lesson.type)
        materialsMap.set(lesson.type, count)
    })

    return {
        "title": section.title,
        "description": section.description,
        "content": parseContentCount(materialsMap)
    }
}

export {getTotalCountMaterials, parseSectionCard}
export default function getDayWord(number) {
  number = Math.abs(number) % 100;
  const lastDigit = number % 10;

  if (number > 10 && number < 20) {
    return " дней";
  }

  if (lastDigit > 1 && lastDigit < 5) {
    return " дня";
  }

  if (lastDigit === 1) {
    return " день";
  }

  return " дней";
}

import { useAction } from "@helpers/hooks";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./CoinHistory.scss";
import { AppLoader, BtnLoader, List } from "@components/common";
import { formatData } from "@utils/functions";

export default function CoinHistory() {
  const { fetchCoinsHistory } = useAction();
  const { history, isLoading } = useSelector((state) => state.coins);

  useEffect(() => fetchCoinsHistory(), []);

  if (isLoading) {
    return <BtnLoader className={"btn-loader--primary"}/>;
  }

  return (
    <div>
      <List
        className={"coin__history-list"}
        items={history}
        renderItem={(item) => (
          <li key={item.date_time} className="coin__history-item">
            <h4>{item.record}</h4>
            <p>{formatData(item.date_time, 4)}</p>
          </li>
        )}
      />
    </div>
  );
}

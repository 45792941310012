import {useState} from "react";
import {useTranslate} from "@helpers/hooks";
import {ButtonV2, NewModalLayout} from "@components/common";
import {ActivitiesTable, ActivityForm} from "@components/feature"
import "./Activities.scss";


export default function Activities(props) {
  const {
    profile, 
    myAdditionalInfo, 
    handleOnClick,
  } = props;
  const {t} = useTranslate(),
  [showModal, setShowModal] = useState(false),
  [editModal, setEditModal] = useState(false),
  [activityItem, setActivityItem] = useState({});


  const openModal = () => {
    setShowModal(true);
  }

  return (
      <div className={""}>
        {showModal &&
            <NewModalLayout>
              <ActivityForm 
                profile={profile} 
                handleOnClick={handleOnClick} 
                isModal={true}
                setShowModal={setShowModal} 
              />
            </NewModalLayout>
        }
        {editModal &&
            <NewModalLayout>
              <ActivityForm
                profile={profile}
                activityItem={activityItem}
                handleOnClick={handleOnClick}
                setShowModal={setEditModal}
                isModal={true}
                isEdit={true}
              />
            </NewModalLayout>
        }
        {myAdditionalInfo.results.length > 0 ?
          <div className="activities">
            <div className="activities_header">
              <p className="profile__title">{t('PROFILE.ACTIVITIES')}</p>
              <ButtonV2
                className="button--primary"
                text={t("BUTTON.ADD_ACTIVITY")}
                onClick={openModal}
                iconLeft="add-activity"
              />
            </div>
            <ActivitiesTable
              setActivityItem={setActivityItem}
              setEditModal={setEditModal}
            />
            <div className="move-right">
              <ButtonV2
                className="button--primary"
                text="Next"
                onClick={handleOnClick}
                iconRight="arrow-right"
              />
            </div>
          </div>
          :
          <ActivityForm profile={profile} handleOnClick={handleOnClick} />
        }
      </div>
  );
};
const WHATSAPP_MOCK_NUET =
  "https://wa.me/77475760606?text=Я%20сдал%20mock-тест%20по%20NUET%20и%20у%20меня%20вопрос:";
const WHATSAPP_MOCK_SAT =
  "https://wa.me/77475760606?text=Я%20сдал%20mock-тест%20по%20SAT%20и%20у%20меня%20вопрос:";
const WHATSAPP_MOCK_IELTS =
  "https://wa.me/77475760606?text=Я%20сдал%20mock-тест%20по%20IELTS%20и%20у%20меня%20вопрос:";
const WHATSAPP_CHALLENGE =
  "https://wa.me/77475760606?text=Я%20прошел%20challenge%20и%20у%20меня%20вопрос:";
export {
  WHATSAPP_MOCK_NUET,
  WHATSAPP_MOCK_SAT,
  WHATSAPP_MOCK_IELTS,
  WHATSAPP_CHALLENGE,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
}
.education__list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.education__list .dropdown-wrapper {
  width: 100%;
}
.education__list-mobile {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.education button {
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Profile/Education/Education.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AADJ;AAGI;EACE,WAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFN;AAOE;EACE,oBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AALJ","sourcesContent":["@import \"src/styles/variables\";\n\n.education {\n  display: flex;\n  flex-direction: column;\n  row-gap: 64px;\n\n  &__list {\n    display: flex;\n    flex-direction: column;\n    row-gap: 15px;\n\n    .dropdown-wrapper {\n      width: 100%;\n    }\n\n    &-mobile {\n      display: flex;\n      flex-direction: column;\n      gap: 16px;\n    }\n  }\n\n\n  button {\n    align-self: flex-end;\n    width: fit-content;\n    margin-right: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useAction} from "@helpers/hooks";
import {getDuration} from "./functions";
import {List, Pagination} from "@components/common";
import dayJs from "dayjs";
import "./ProgramScholarships.scss";

export default function ProgramScholarships({aboutScholarship}) {
    const {scholarship} = useSelector(state => state.directory),
        {fetchScholarship} = useAction(),
        [currentPage, setCurrentPage] = useState(1),
        scholarships = useMemo(() => [
            {id: 1, label: "Description", value: scholarship?.description},
            {id: 2, label: "Requirements", value: scholarship?.requirements},
            {id: 3, label: "Submission Process", value: scholarship?.submission_process},
            {id: 4, label: "Available for", value: scholarship?.availability}
        ], [scholarship]),
        characters = useMemo(() => [
            {id: 1, label: "Organization", value: scholarship?.organization},
            {id: 2, label: "Location", value: scholarship?.location},
            {id: 3, label: "Age requirement", value: scholarship?.age},
            {id: 4, label: "Coating", value: scholarship?.covering},
            {id: 5, label: "Duration", value: getDuration(scholarship?.duration)},
            {id: 6, label: "Size", value: scholarship?.size ? `$${scholarship?.size}` : ""},
            {id: 7, label: "Flight", value: scholarship?.flight},
            {id: 8, label: "Accommodation", value: scholarship?.residence},
            {id: 9, label: "Deadline", value: scholarship?.deadline ? dayJs(scholarship?.deadline).format("DD.MM.YYYY") : ""},
        ], [scholarship]);

    useEffect(() => {
        fetchScholarship(aboutScholarship[currentPage - 1]?.id);
    }, [currentPage]);
    return (
        <div className={"program-scholarships"}>
            <List
                className={"program-scholarships__characters"}
                items={characters}
                renderItem={(character) => (
                    character.value && <li key={character.id} className={"program-scholarships__character"}>
                        <p className={"program-scholarships__value"}>
                            {(character.label)}
                        </p>
                        <p className={"program-scholarships__name"}>
                            {(character.value)}
                        </p>
                    </li>
                )}
            />
            <List
                className={"program-scholarships__list"}
                items={scholarships}
                renderItem={(scholarship) => (
                    scholarship.value && <li key={scholarship.id} className={"program-scholarships__item"}>
                        <p className={"program-scholarships__name"}>
                            {(scholarship.label)}
                        </p>
                        <p className={"program-scholarships__value"} dangerouslySetInnerHTML={{__html: String((scholarship.value)?.replace(/\r\n/gi, "<br/>"))}}/>
                    </li>
                )}
            />
            {
                scholarship && <Pagination
                    className={"program-scholarships__pagination"}
                    totalCount={aboutScholarship.length}
                    pageSize={1}
                    currentPage={currentPage}
                    siblingCount={0}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            }
        </div>
    );
};
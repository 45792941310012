import {memo} from "react";
import {useTranslate} from "@helpers/hooks";
import {Select} from "@components/common";
import "../Pagination.scss";

export default memo(function PaginationSelect({pageSizes, selectedPageSize, onSelectedPageSize}) {
    const {t} = useTranslate();
    return (
        <Select
            className={"pagination__select select--border"}
            label={t("PAGINATION.SHOW_RESULT")}
            defaultValue={selectedPageSize}
            options={pageSizes}
            handleChange={onSelectedPageSize}
        />
    );
});
import React from "react";
import { Image } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
export default function MarathonRatingCard({ user, teritaryUsers }) {

  const isMobile = useIsMobile()
  const { t } = useTranslate();

  function isLast(array, element) {
    return array.indexOf(element) === array.length - 1;
  }
  return (
    <div key={user.id} className={`rating__table-record ${isMobile ? 'rating__table-record-mobile' : 'rating__table-record-web'} ${isLast(teritaryUsers, user) && `rating__table-record-last`}`}>
      <div className={isMobile ? "rating__text-mobile" : "rating__text"}>{user.position}</div>
      <div className="rating__table-username">
        <Image src={user.user_image} className="rating__table-image" />
        <p className={isMobile ? "rating__text-mobile" : "rating__text"}>{user.user_name}</p>
      </div>
      <div className={isMobile ? "rating__text-mobile" : "rating__text-status"}>
        <div className={`rating__text-status-${user.is_alive ? "alive" : "dead"}`}>
            {user.is_alive ? t("LMS.RANKING.ALIVE") : t("LMS.RANKING.DEAD")}
        </div>
      </div>
    </div>
  );
}

import {useSelector} from "react-redux";
import {useTranslate} from "@helpers/hooks";

export default function FavoritesTitle() {
    const {t} = useTranslate();
    const {favorites, totalCountFavorites} = useSelector(state => state.program);
    return (
        <h6 className={"favorites__title"}>
            {favorites?.length > 0 ? `${t("FAVORITE.TAB_TITLE")} (${totalCountFavorites})` : t("FAVORITE.TAB_TITLE")}
        </h6>
    );
}
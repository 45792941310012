import {useEffect} from "react";

export default function WithRemoveScroll({children, flag}) {
    useEffect(() => {
        if (flag) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

        return () => {
            document.body.style.overflow = "unset";
        }
    }, [flag]);
    return (
        children
    );
};
import React, { useState } from "react";
import "./Toggle.scss";
import { useTranslate } from "@helpers/hooks";

export default function Toggle({
  onLeftClick = () => {},
  onRightClick = () => {},
  initialToggle = false
}) {
  const [isToggled, setIsToggled] = useState(initialToggle);
  const { t } = useTranslate();

  const handleToggle = () => {
    setIsToggled(!isToggled);
    if (!isToggled) {
      onRightClick();
    } else {
      onLeftClick();
    }
  };

  return (
    <div
      className={`toggle ${isToggled ? "active" : ""}`}
      onClick={handleToggle}
    >
      <div className="toggle-slider"></div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import { getQuestionData } from "@utils/functions";
import { List } from "@components/common";
import "./DragAndDrop.scss";
import { useTranslate } from "@helpers/hooks";

export default function DragAndDrop({
  question,
  userAnswer,
  onAnswerChange,
  disabled,
}) {
  let questionData = getQuestionData(question);
  const answerOptions = questionData[0].answer_options;

  const [droppedItems, setDroppedItems] = useState(userAnswer || {});
  const [draggedItems, setDraggedItems] = useState(
    Array(answerOptions.length).fill(false)
  );

  const isReviewMode = questionData.some(
    (item) => item.correct_answer !== undefined && item.response !== undefined
  );

  const { t } = useTranslate();

  useEffect(() => {
    if (userAnswer) {
      setDroppedItems(userAnswer);
      const newDraggedItems = answerOptions.map((option) =>
        Object.values(userAnswer).includes(option)
      );
      setDraggedItems(newDraggedItems);
    }
  }, [userAnswer, answerOptions]);

  useEffect(() => {
    if (isReviewMode) {
      const newDraggedItems = answerOptions.map((option) =>
        questionData.some((item) => item.response === option)
      );
      setDraggedItems(newDraggedItems);

      const newDroppedItems = {};
      questionData.forEach((item) => {
        if (item.response) {
          newDroppedItems[item.id] = item.response;
        }
      });
      setDroppedItems(newDroppedItems);
    }
  }, [isReviewMode, questionData, answerOptions]);

  const Draggable = ({ id, children, isUsed }) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } =
      useDraggable({
        id,
      });

    const styleDrag = {
      transform: transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : "none",
      padding: "16.5px 16px",
      cursor: "grab",
      border: "1px solid #DDE1E5",
      borderRadius: "10px",
      backgroundColor: isDragging ? "#4BA5EC" : isUsed ? "#f0f0f0" : "white",
      color: isDragging ? "white" : "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      height: "40px",
      opacity: isUsed ? 0.6 : 1,
      visibility: isUsed ? "hidden" : "visible",
    };

    return (
      <div ref={setNodeRef} style={styleDrag} {...listeners} {...attributes}>
        {children}
      </div>
    );
  };

  const Droppable = ({ id, children }) => {
    const { isOver, setNodeRef } = useDroppable({
      id,
    });

    const styleDrop = {
      width: "105px",
      fontSize: "14px",
      border: "1px solid #4BA5EC",
      borderRadius: "10px",
      backgroundColor: isOver ? "lightblue" : "transparent",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "nowrap",
      margin: "10px",
    };

    return (
      <div
        style={styleDrop}
        ref={setNodeRef}
        className={`droppable ${isOver ? "over" : ""}`}
      >
        {children}
      </div>
    );
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over) {
      const droppableIndex = parseInt(over.id.split("-").pop(), 10);
      const draggableIndex = parseInt(active.id.split("-").pop(), 10);

      const questionId = questionData[droppableIndex].id;

      setDroppedItems((items) => {
        const newItems = { ...items };
        newItems[questionId] = answerOptions[draggableIndex];
        onAnswerChange(newItems);

        return newItems;
      });

      setDraggedItems((items) => {
        const newItems = [...items];
        newItems[draggableIndex] = true;
        return newItems;
      });
    }
  };

  return (
    <div className="dnd">
      <DndContext onDragEnd={handleDragEnd}>
        <List
          className={"dnd__questions"}
          items={questionData}
          renderItem={(item, index) => (
            <div key={index}>
              <div className="dnd__question" key={index}>
                <p>{item.start}</p>
                <Droppable
                  key={`droppable-text-${index}`}
                  id={`droppable-text-${index}`}
                >
                  {droppedItems[item.id]}
                </Droppable>
                <p>{item.end}</p>
              </div>
              {isReviewMode && (
                <p className="dnd__correct">
                  {t("MOCK.CORRECT_ANSWER")}
                  {item.correct_answer}
                </p>
              )}
            </div>
          )}
        />

        {!isReviewMode && (
          <div className="">
            <List
              className={"dnd__options"}
              items={answerOptions}
              renderItem={(item, index) => (
                <Draggable
                  key={index}
                  id={`draggable-question-${index}`}
                  isUsed={draggedItems[index]}
                >
                  {item}
                </Draggable>
              )}
            />
          </div>
        )}
      </DndContext>
    </div>
  );
}

import { useCallback } from "react";
import { Icon, List } from "@components/common";
import { SectionCard } from "./SectionCard";
import { convertMinutesToRoundedHours, getTotalCountMaterials } from "@utils/functions";
import { useTranslate } from "@helpers/hooks";
import "./Syllabus.scss";

export default function Syllabus({ course }) {
  const getMaterials = useCallback(
    () => getTotalCountMaterials(course),
    [course]
  );
  const { t } = useTranslate();

  const syllabusItems = [
    {
      id: 1,
      icon: "video",
      duration: `${convertMinutesToRoundedHours(course.video_duration)} ${t("LMS.HOURS")} ${t("LMS.OF_VIDEO")}`,
    },
    {
      id: 2,
      icon: "practice",
      duration: `${convertMinutesToRoundedHours(course.practice_duration)} ${t("LMS.HOURS")} ${t("LMS.OF_PRACTICE")}`,
    },
    {
      id: 3,
      icon: "reading",
      duration: `${convertMinutesToRoundedHours(course.reading_duration)} ${t("LMS.HOURS")} ${t("LMS.OF_READING")}`,
    },
  ];


  return (
    <div className="syllabus">
      <p className="syllabus-title">{t("LMS.COURSE.INFO.SYLLABUS")}</p>
      <List
        className={"syllabus-duration"}
        items={syllabusItems}
        renderItem={(item) => (
          <li key={item.id} className={'syllabus-duration-item'}>
            <Icon name={item.icon} />
            <p>{item.duration}</p>
          </li>
        )}
      />
      <List
        items={course.sections}
        renderItem={(section) => (
          <SectionCard key={section.title} section={section} />
        )}
      />
    </div>
  );
}

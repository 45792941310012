import {useMemo, useState} from "react";
import {usePagination, useTranslate, useWindowSize} from "@helpers/hooks";
import {PaginationList, PaginationSelect} from "@components/common";
import classNames from "classnames";
import "./Pagination.scss";

export default function Pagination(props) {
    const {t} = useTranslate(),
        {
            className, totalCount, siblingCount = 1,
            currentPage, pageSize, onPageChange,
            onPageSizeChange
        } = props,
        windowSize = useWindowSize(),
        defaultPageSize = useMemo(() => ({
            name: t("PAGINATION.SHORT_PER_PAGE"),
            value: pageSize
        }), [pageSize, t]),
        initialPageSizes = useMemo(() => [
            {id: 1, name: t("PAGINATION.SHORT_PER_PAGE"), value: 10},
            {id: 2, name: t("PAGINATION.LONG_PER_PAGE"), value: 25}
        ], [t]),
        {paginationRange, DOTS} = usePagination({
            totalCount, pageSize, siblingCount: windowSize.width < 474 ? -1 : siblingCount, currentPage
        }),
        [pageSizes] = useState(initialPageSizes),
        [selectedPageSize, setSelectedPageSize] = useState(defaultPageSize),
        lastPage = Math.ceil(totalCount / pageSize);

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const onDots = (pageNumberId) => {
        const currentNumberId = paginationRange.findIndex((pageNumber) => pageNumber === currentPage);

        if (pageNumberId > currentNumberId) {
            onNext();
        } else {
            onPrevious();
        }
    };

    const onSelectedPageSize = (page) => {
        setSelectedPageSize(page);
        onPageSizeChange(page.value);
        onPageChange(1);
    };
    return (
        <div className={classNames("pagination", className)}>
            <PaginationSelect
                pageSizes={pageSizes}
                selectedPageSize={selectedPageSize}
                onSelectedPageSize={onSelectedPageSize}
            />
            <PaginationList
                paginationRange={paginationRange}
                currentPage={currentPage}
                lastPage={lastPage}
                onPrevious={onPrevious}
                onNext={onNext}
                onDots={onDots}
                onPageChange={onPageChange}
                DOTS={DOTS}
            />
        </div>
    );
};
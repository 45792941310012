import { useMemo, memo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslate } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import classNames from "classnames";
import { LANGUAGE_MOBILE } from "@utils/consts";
import {
  DASHBOARD_ROUTE,
  FAVORITES_ROUTE,
  SETTINGS_ROUTE,
  CATALOG_ROUTE,
  APPLICATION_ROUTE,
  LEARNING_PLATFORM_ROUTE,
  RECOMMENDATIONS_ROUTE,
  ESSAY_ROUTE,
  RESOURCES_ROUTE,
  SCHOLARSHIPS_ROUTE,
  MOCK_EXAMS,
  PROFILE_MOBILE,
} from "@utils/consts";
import "./FooterMenu.scss";

export default memo(function FooterMenu() {
  const { t } = useTranslate(),
    { pathname } = useLocation(),
    navs = useMemo(
      () => [
        {
          id: 1,
          icon: "dashboard",
          route: DASHBOARD_ROUTE,
          label: "NAV.MAIN",
        },
        {
          id: 2,
          icon: "learning-platform-outlined",
          route: LEARNING_PLATFORM_ROUTE,
          label: "NAV.COURSES",
        },
        {
          id: 4,
          icon: "mock-sidebar",
          route: MOCK_EXAMS,
          label: "NAV.MOCK",
        },
        {
          id: 3,
          icon: "profile-v2",
          route: PROFILE_MOBILE,
          label: "NAV.PROFILE",
        },
      ],
      [t]
    );

  const isActive = (route) => {
    if (pathname === route) return true;

    if (route === DASHBOARD_ROUTE) {
      const dashboardSubRoutes = [
        RECOMMENDATIONS_ROUTE,
        CATALOG_ROUTE,
        FAVORITES_ROUTE,
        ESSAY_ROUTE,
        RESOURCES_ROUTE,
        SCHOLARSHIPS_ROUTE,
        APPLICATION_ROUTE,
      ];
      return dashboardSubRoutes.some((subRoute) => pathname.startsWith(subRoute));
    }
    if (pathname !== "/" && pathname.startsWith(route)) return true;

    const subRouteMapping = {
      [PROFILE_MOBILE]: [SETTINGS_ROUTE, LANGUAGE_MOBILE],
    };

    if (subRouteMapping[route]) {
      return subRouteMapping[route].some((subRoute) => pathname.startsWith(subRoute));
    }

    return false;
  };

  return (
    <List
      className="footer__menu"
      items={navs}
      renderItem={({ id, icon, route, label }) => (
        <li key={id} className="menu__item">
          <NavLink
            to={route}
            className="sidebar__link"
            activeClassName={classNames({ "footer__menu--selected": isActive(route) })}
          >
            <div className="footer__menu-box">
              <Icon
                className={classNames({ "icon--blue": isActive(route) }, "footer__menu-icon")}
                name={icon}
              />
              <p className={classNames({ "menu__link-active": isActive(route) })}>{t(label)}</p>
            </div>
          </NavLink>
        </li>
      )}
    />
  );
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  display: flex;
  flex-direction: column;
}
.profile__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}
.profile__list .label__text {
  text-wrap: nowrap;
}
.profile__list-mobile {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.profile__list .dropdown-wrapper {
  width: 100%;
}
.profile button {
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Profile/Profile/Profile.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AADJ;AAGI;EACE,iBAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFN;AAKI;EACE,WAAA;AAHN;AASE;EACE,oBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AAPJ","sourcesContent":["@import \"@styles/variables\";\n\n.profile {\n  display: flex;\n  flex-direction: column;\n\n  &__list {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 32px;\n\n    .label__text {\n      text-wrap: nowrap;\n    }\n\n    &-mobile {\n      display: flex;\n      flex-direction: column;\n      gap: 16px;\n    }\n\n    .dropdown-wrapper {\n      width: 100%;\n    }\n  }\n\n\n\n  button {\n    align-self: flex-end;\n    width: fit-content;\n    margin-right: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

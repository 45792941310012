// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast--custom {
  border-radius: 14px !important;
  background: linear-gradient(to right, #fec656, #f5ad21) !important;
  font-weight: 400;
}
.Toastify__toast--custom p {
  color: white;
}
.Toastify__toast--custom .Toastify__toast-icon {
  visibility: hidden;
}
.Toastify__toast--custom .Toastify__progress-bar {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/utils/functions/toastStyles.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,kEAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;AAER;AAAI;EACI,kBAAA;AAER;AAAI;EACI,kBAAA;AAER","sourcesContent":[".Toastify__toast--custom {\n    border-radius: 14px !important;\n    background: linear-gradient(to right, #fec656, #f5ad21) !important;\n    font-weight: 400;\n    p {\n        color: white;\n    }\n    .Toastify__toast-icon{\n        visibility: hidden;\n    }\n    .Toastify__progress-bar{\n        visibility: hidden;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

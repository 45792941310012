import {useMemo} from "react";
import {List} from "@components/common";
import "./ProgramOverview.scss";

export default function ProgramOverview({program}) {
    const overviews = useMemo(() => [
        {id: 1, label: "Program description", value: program.overview?.program_description},
        {id: 2, label: "Career opportunities", value: program.overview?.career_opportunities},
        {id: 3, label: "What courses does it include?", value: program.overview?.including_courses}
    ], [program]);
    
    return (
        <div className={"program-overview"}>
            <List
                className={"program-overview__list"}
                items={overviews}
                renderItem={(overview) => (
                    overview.value && <li key={overview.id} className={"program-overview__item"}>
                        <p className={"program-overview__name"}>
                            {(overview.label)}
                        </p>
                        <p className={"program-overview__value"} dangerouslySetInnerHTML={{__html: String((overview.value)?.replace(/\r\n/gi, "<br/>"))}}></p>
                    </li>
                )}
            />
        </div>
    );
};
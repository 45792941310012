const ACTIVITES_TYPES = [
  {id: "Extracurriculars", name: "PROFILE.ACTIVITY_TYPES.EXTRACURRICULARS", value: "Extracurriculars"},
  {id: "Honors", name: "PROFILE.ACTIVITY_TYPES.HONORS", value: "Honors"}
]

const TIERS_EXTRACURRICULARS = [
  {id: 1, name: "PROFILE.EXTRACURRICULARS_FIELDS.1", value: 1, title: "Tier 1: \"Elite Activities\"",
  tooltip:
  `###
   * Competitiveness: Activities with a highly selective admission process, such as an exclusive research program, highly competitive academic team, or prestigious internship. Moreover, It may be receiving world recognition for any hobby or did something substantial with large numbers involved (e.g. I was quoted in WSJ about aviation multiple times; I climbed a 20,000 foot mountain; I built an online game with 15,000 users)
   * Effort: Requires a significant amount of time and dedication, including extensive research or project work and participation in highly specialized activities. 
   * Recognition: Internationally recognized
   * Contribution: Members make significant contributions to the field, including published research or other major accomplishments.`
},
  {id: 2, name: "PROFILE.EXTRACURRICULARS_FIELDS.2", value: 2, title: "Tier 2: \"Advanced Activities\"",
  tooltip: 
  `###
  * Competitiveness: Activities with a moderately selective admission process, such as a competitive club or organization, or a challenging and highly regarded academic program. Moreover, It may be taking substantial initiative with the hobby and have some sort of tangible accomplishment/documentation for it - e.g. I was an administrator for my knitting forum with 500 members
  * Effort: Requires a considerable amount of time and effort, including participation in specialized activities or extensive academic work.
  * Recognition: Nationally recognized 
  * Contribution: Members make important contributions to the field, including participation in relevant projects or publications.`
},
  {id: 3, name: "PROFILE.EXTRACURRICULARS_FIELDS.3", value: 3, title: "Tier 3: \"Intermediate Activities\"",
tooltip: `
* Competitiveness: Activities with a competitive admission process, such as a selective club or organization, or a respected academic program. It may be any hobby with something tangible to prove participation in this hobby e.g. I rebuilt a vintage car
* Effort: Requires a significant amount of time and effort, including participation in a variety of activities or academic work. 
* Recognition: Recognized within the school or region
* Contribution: Members make valuable contributions to the field, including participation in relevant projects or events.`
},
  {id: 4, name: "PROFILE.EXTRACURRICULARS_FIELDS.4", value: 4, title: "Tier 4: \"Standard Activities\"", 
tooltip:
`
* Competitiveness: Activities with a basic admission process, such as a general club or organization, or a standard academic program. Moreover, it can be participating in the hobby for an extended period of time
* Effort: Requires a moderate amount of time and effort, including participation in a variety of activities or academic work. 
* Recognition: Well-known within the school or local community.
* Contribution: Members make contributions to the field, including participation in relevant projects or events.`
},
  {id: 5, name: "PROFILE.EXTRACURRICULARS_FIELDS.5", value: 5, title: "Tier 5: \"Basic Activities\"", 
tooltip: 
`
* Competitiveness: Activities with no admission requirements, such as participation in intramural sports, volunteering, or attending university-sponsored events. Moreover, it can be participating in the hobby for an extended period of time
* Effort: Requires a minimal amount of time and effort, typically limited to participation in a single activity or event.
* Recognition: Generally not well-known outside of the school or local community.
* Contribution: Members make small contributions to the field, such as supporting community service or attending university-sponsored events.`
}
]

const TIERS_HONORS = [
  {id: 1, name: "PROFILE.HONORS_FIELDS.1", value: 1, title: "Tier 1: \"Global Excellence\" or \"International Honors\"",
  tooltip:
  `
* Competitive: Achieved by the top 5-10% of students globally.
* Mastery: Must have demonstrated exceptional mastery in the subject matter, often through outstanding performance in assessments, projects, or research.
* Effort: Must have put in a significant amount of effort, beyond what is expected of an average student, in order to excel in the subject matter.
* Recognition: Must have earned recognition from the educational community or beyond, such as winning a prestigious international award, olympiad or participating in a high-profile international competition.
  `
},
  {id: 2, name: "PROFILE.HONORS_FIELDS.2", value: 2, title : "Tier 2: \"National Excellence\" or \"National Honors\"",
  tooltip:
  `
* Competitive: Achieved by the top 5-10% of students in a country.
* Mastery: Must have demonstrated a high level of mastery in the subject matter, often through consistently high performance in assessments, projects, or research.
* Effort: Must have put in a significant amount of effort, beyond what is expected of an average student, in order to excel in the subject matter.
* Recognition: Must have earned recognition from the national educational community or beyond, such as winning a national award, olympiad or participating in a notable national competition.
  `
},
  {id: 3, name: "PROFILE.HONORS_FIELDS.3", value: 3, title: "Tier 3: \"Regional Excellence\" or \"Regional Honors\"", 
tooltip: 
`
* Competitive: Achieved by the top 15-20% of students in a region.
* Mastery: Must have demonstrated a good level of mastery in the subject matter, often through solid performance in assessments, projects, or research.
* Effort: Must have put in a notable amount of effort in order to perform well in the subject matter.
* Recognition: Must have earned recognition from the regional educational community or beyond, such as winning a regional award, olympiad or participating in a notable regional competition.
`
},
  {id: 4, name: "PROFILE.HONORS_FIELDS.4", value: 4, title: "Tier 4: \"School Excellence\" or \"School Honors\"",
  tooltip:
  `
  * Competitive: Achieved by the top 25-30% of students in a school.
  * Mastery: Must have demonstrated a satisfactory level of mastery in the subject matter, often through decent performance in assessments, projects, or research.
  * Effort: Must have put in a reasonable amount of effort in order to perform well in the subject matter.
  * Recognition: Must have earned recognition from the school community, such as winning a school award, olympiad or being selected for an honors program.
  `
},
  {id: 5, name: "PROFILE.HONORS_FIELDS.5", value: 5, title: "Tier 5: \"Effort Award\" or \"Completion Certificate\"",
  tooltip: 
`
* Competitive: Achieved by all students who complete the course or program.
* Mastery: Must have demonstrated a basic level of understanding in the subject matter, often through passing assessments or completing assignments.
* Effort: Must have completed the required work in order to earn the certificate, and have demonstrated significant effort and improvement throughout the course or program.
* Recognition: May have earned recognition from a teacher or professor for consistent improvement or strong work ethic.
`
}
]

export {
  ACTIVITES_TYPES, TIERS_EXTRACURRICULARS, TIERS_HONORS
}
import {useEffect, useRef, useMemo} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useTranslate} from "@helpers/hooks";
import classNames from "classnames";
import "./CustomRadar.scss";

am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");

export default function CustomRadar({className, data, maxData, uniData, ...props}) {
    const {t} = useTranslate(),
        chart = useRef(null);

    const calculatePercent = (value, maxValue) => {
        if (value === 0) return 0;
        const percent = (value / maxValue) * 100;
        return percent;
    };

    const findMaxValue = (value, percent) => {
        if (percent === 0) return 0;
        const maxValue = (value*percent)/100;
        return maxValue;
    };

    useEffect(() => {
        let radar = am4core.create("radar", am4charts.RadarChart);
        radar.responsive.enabled = true;
        radar.zIndex = 1;

        radar.data = [
            {
                "country": t("DASHBOARD.RADAR.LABEL_1"),
                "litres1": calculatePercent(data?.ielts_score, maxData[0].ielts_percent),
                "litres3": data?.ielts_score,
                "litres2": uniData.ielts_percent,
                "litres4": findMaxValue(uniData.ielts, uniData.ielts_percent)
            },
            {
                "country": t("DASHBOARD.RADAR.LABEL_2"),
                "litres1": calculatePercent(data?.sat_score, maxData[0].sat_percent),
                "litres3": data?.sat_score,
                "litres2": uniData.sat_percent,
                "litres4": findMaxValue(uniData.sat, uniData.sat_percent)
            },
            {
                "country": t("DASHBOARD.RADAR.LABEL_4"),
                "litres1": calculatePercent(data?.recommendation_letter_score, maxData[0].recommendation_letter_percent),
                "litres3": data?.recommendation_letter_score,
                "litres2": uniData.recommendation_letter_percent,
                "litres4": findMaxValue(uniData.recommendation_letter, uniData.recommendation_letter_percent)
            },
            {
                "country": t("DASHBOARD.RADAR.LABEL_5"),
                "litres1": calculatePercent(data?.motivational_letter_score, maxData[0].motivational_letter_percent),
                "litres3": data?.motivational_letter_score,
                "litres2": uniData.motivational_letter_percent,
                "litres4": findMaxValue(uniData.motivational_letter, uniData.motivational_letter_percent)
            },
            {
                "country": t("DASHBOARD.RADAR.LABEL_6"),
                "litres1": calculatePercent(data?.gpa_score, maxData[0].gpa_percent),
                "litres3": data?.gpa_score,
                "litres2": uniData.gpa_percent,
                "litres4": findMaxValue(uniData.gpa, uniData.gpa_percent)
            },
            {
                "country": t("DASHBOARD.RADAR.LABEL_7"),
                "litres1": calculatePercent(data.olympiads_score, maxData[0].olympiads_percent),
                "litres3": data.olympiads_score,
                "litres2": uniData.olympiads_percent,
                "litres4": uniData.olympiads
            },
        ];

        let categoryAxis = radar.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";
        categoryAxis.stroke = am4core.color("#6E738A");
        categoryAxis.fontWeight = "100"
        categoryAxis.renderer.grid.template.stroke = am4core.color("#B6D7FF");
        categoryAxis.renderer.grid.template.strokeWidth = 2;
        categoryAxis.renderer.axisFills.template.disabled = true
        categoryAxis.tooltip.disabled = true;

        let valueAxis = radar.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.renderer.gridType = "polygons";
        valueAxis.renderer.grid.template.stroke = am4core.color("#B6D7FF");
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.tooltip.disabled = true;

        let series1 = radar.series.push(new am4charts.RadarSeries());
        series1.name = t("DASHBOARD.RADAR.TITLE_1")
        series1.dataFields.valueY = "litres1";
        series1.dataFields.score = "litres3";
        // series1.dataFields.maxScore = "litres4";
        series1.dataFields.categoryX = "country";
        series1.fill = am4core.color("#008FFF");
        series1.fillOpacity = 0.1;
        series1.strokeWidth = 1;
        series1.tooltipText = `[bold]Your {categoryX} score: {score}[/]`;
        series1.tooltip.readerOrientation = "vertical";
        series1.tooltip.label.fontSize = "0.8em";
        series1.tooltip.autoTextColor = false;
        series1.tooltip.zIndex = -1;

        let series2 = radar.series.push(new am4charts.RadarSeries());
        series2.name = t("DASHBOARD.RADAR.TITLE_2")
        series2.dataFields.valueY = "litres2";
        series2.dataFields.score = "litres4";
        series2.dataFields.categoryX = "country";
        series2.fill = am4core.color("#AE2DB1");
        series2.stroke = am4core.color("#AE2DB1");
        series2.fillOpacity = 0.1;
        series2.strokeWidth = 1;
        series2.tooltipText = `[bold]Average {categoryX} score: {score}`;
        series2.tooltip.readerOrientation = "vertical";
        series2.tooltip.label.fontSize = "0.8em";
        series2.tooltip.autoTextColor = false;
        series2.tooltip.zIndex = -1;
        
        radar.legend = new am4charts.Legend();
        radar.legend.position = "top";
        radar.legend.contentAlign = "left";
        radar.legend.fontSize = 12;
        radar.legend.useDefaultMarker = true;
        radar.legend.height = 16;
        radar.legend.maxColumns = 1;
        radar.legend.labels.template.text = "[normal {color}]{name}[/]";
        radar.legend.labels.template.paddingLeft = 20;
        radar.legend.zIndex = 100;
    
        
        radar.cursor = new am4charts.RadarCursor();
        radar.cursor.behavior = "none";
        radar.cursor.lineX.strokeOpacity = 1;
        radar.cursor.lineY.strokeOpacity = 0;
        radar.cursor.lineX.stroke = radar.colors.getIndex(1);
        radar.cursor.innerRadius = am4core.percent(30);
        radar.cursor.radius = am4core.percent(50);
        radar.cursor.selection.fill = radar.colors.getIndex(1);
        radar.cursor.tooltipText = "";

        chart.current = radar;

        return () => {
            radar.dispose();
        };
    }, [data, uniData, t]);
    
    return (
    <div>
        <div className={classNames("radar", className)} id="radar"/>
    </div>
    );
};
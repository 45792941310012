import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, Divider, NewFeature } from "@components/common";
import classNames from "classnames";

export default function CourseSidebarItem({ route, icon, name, courseId, isDivider, pathname, isNew }) {
  return (
    <>
      <NavLink
        to={route.replace(":courseId", courseId)}
        className={"sidebar__link"}
        activeClassName={classNames({
          "sidebar__link--selected": pathname.includes(route.split("/").pop()),
        })}
      >
        <Icon className={classNames({ "icon--white": pathname.includes(route.split("/").pop()) })} name={icon} />
        <p
          className={classNames("sidebar__text", {
            "sidebar__text--selected": pathname.includes(route.split("/").pop()),
          })}
        >
          {name}
        </p>
        {isNew && <NewFeature isSelected={pathname.includes(route.split("/").pop())} />}
      </NavLink>
      {isDivider && <Divider className={"sidebar__divider"} />}
    </>
  );
}


import { useTranslate } from "@helpers/hooks";
import { Button, Icon } from "@components/common";
import "./EssayDetailsHeader.scss"

const EssayDetailsHeader = ({currentItem, handleGoBack, currentEssayTab, handleOpenEdit}) => {
    const { t } = useTranslate();

    const getType = (type) => {
        switch(type) {
            case "us":
                return t("ESSAY.LABELS.US");
            case "uk":
                return t("ESSAY.LABELS.UK");
            default:
                return;
        }
    }

    return (
        <div className='essay-details__header'>
            <div className='essay-details__header-titles'>
                <div className='essay-details__header-titles-route'>
                    <p className='base-route' onClick={handleGoBack}>{currentEssayTab.name}</p>
                    <Icon name="breadcrumb-arrow" />
                    <span>
                        {getType(currentItem?.type)}
                        {currentItem?.university_name}
                    </span>
                </div>
                <h1>
                    {currentEssayTab.value === "UNIVERSITY_SPECIFIC_ESSAYS" && currentItem?.topic}
                    {currentItem.type && getType(currentItem?.type)}
                    {currentItem.type && currentItem.topic && 
                        <>
                            <br />
                            ({currentItem.topic})
                        </>
                    }
                </h1>
            </div>
            <div className='essay-details__header-btn'>
                <Button 
                    text={t("ESSAY.ACTION.EDIT")}
                    iconLeft={<Icon name='edit' />}
                    className="btn--secondary"
                    onClick={handleOpenEdit}
                />
            </div>
        </div>
    );
}

export default EssayDetailsHeader;

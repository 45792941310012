import { useTranslate } from '@helpers/hooks';
import { List } from '@components/common';
import './LmsBadgeProfileInfo.scss';

const LmsBadgeProfileInfo = () => {
    const {t} = useTranslate(),
    PRIZES = [
        {
            id: 1,
            name: "iPad",
            amount: 7,
        },
    ];

    return (
        <div className="badge-profile-info">
            <p>{t("LMS.BADGES.LABEL_9")}</p>
            <p>{t("LMS.BADGES.LABEL_10")}:</p>
            <List 
                className="badge-profile-info__list"
                items={PRIZES}
                renderItem={(item) => 
                    <div key={item.id} className="badge-profile-info__chip">
                        <div className="badge-profile-info__chip-amount">х{item.amount}</div>
                        {item.name}
                    </div>
                }
            />
        </div>
    );
}

export default LmsBadgeProfileInfo;

import {UniversityService} from "@api/services";
import {createAsyncThunk} from "@reduxjs/toolkit";

const fetchUniversities = createAsyncThunk(
    "universities/fetchAll",
    async (params, thunkAPI) => {
        try {
            const res = await UniversityService.fetchUniversities(params);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const fetchUniversity = createAsyncThunk(
    "universities/fetchOne",
    async (id, thunkAPI) => {
        try {
            const res = await UniversityService.fetchUniversity(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const setUniversitiesPageChange = createAsyncThunk(
    "universities/pageChange",
    async (currentPage, thunkAPI) => {
        try {
            return currentPage;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const setUniversitiesPageSizeChange = createAsyncThunk(
    "universities/pageSizeChange",
    async (pageSize, thunkAPI) => {
        try {
            return pageSize;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const fetchUniversitySubjects = createAsyncThunk(
    "universities/subjects",
    async (params, thunkAPI) => {
        try {
            const res = await UniversityService.fetchUniversitySubjects(params);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const clearUniversitySubjects = createAsyncThunk(
    "universities/subjects/clear",
    async () => {
        return [];
    }
);


export {
    fetchUniversities, setUniversitiesPageChange, setUniversitiesPageSizeChange,
    fetchUniversity, fetchUniversitySubjects, clearUniversitySubjects
};
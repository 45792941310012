// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-order {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.catalog-order__search .search__icon {
  left: 0;
  width: 45px;
}
.catalog-order__search .search__field {
  padding-left: 45px;
}
.catalog-order > .search-wrapper {
  width: 75%;
}
.catalog-order > .select-wrapper {
  width: 35%;
}

@media screen and (max-width: 574px) {
  .catalog-order {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Catalog/CatalogOrder/CatalogOrder.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AADF;AAII;EACE,OAAA;EACA,WAAA;AAFN;AAKI;EACE,kBAAA;AAHN;AAOE;EACE,UAAA;AALJ;AAQE;EACE,UAAA;AANJ;;AAUA;EACE;IACE,aAAA;EAPF;AACF","sourcesContent":["@import \"src/styles/variables\";\n\n.catalog-order {\n  width: 100%;\n  margin-top: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n\n  &__search {\n    .search__icon {\n      left: 0;\n      width: 45px;\n    }\n\n    .search__field {\n      padding-left: 45px;\n    }\n  }\n\n  & > .search-wrapper {\n    width: 75%;\n  }\n\n  & > .select-wrapper {\n    width: 35%;\n  }\n}\n\n@media screen and (max-width: 574px){\n  .catalog-order {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-placeholder {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}
.mobile-placeholder__content {
  justify-content: center;
  align-items: center;
}
.mobile-placeholder__content h3, .mobile-placeholder__content p {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}
.mobile-placeholder__img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Mobile/MobilePlaceholder/MobilePlaceholder.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;AADJ;AAEI;EACI,uBAAA;EACA,mBAAA;AAAR;AACQ;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACZ;AAEI;EACI,WAAA;AAAR","sourcesContent":["@import \"@styles/variables\";\n\n.mobile-placeholder {\n    display: flex;\n    flex-direction: column;\n    row-gap: 100px;\n    &__content {\n        justify-content: center;\n        align-items: center;\n        h3, p {\n            font-size: 15px;\n            line-height: 20px;\n            text-align: center;\n            margin-top: 10px;\n        }\n    }\n    &__img {\n        width: 100%;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { axiosRequest } from "@api/xhr";

export default class CoinService {
  static async fetchCoins() {
    return await axiosRequest.get("directory/my-coins");
  }

  static async fetchCoinsHistory() {
    return await axiosRequest.get("directory/my-coins-history");
  }


}

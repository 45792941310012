import { Icon } from "@components/common";
import React from "react";
import "./SectionTimeIndicator.scss";

export default function SectionTimeIndicator({ indicator }) {
  return (
    <div className="indicator__group">
      <Icon name={indicator.name} className="icon--lms-grey" />
      <p className="indicator__text">
        <span className="indicator__number">{indicator.number}</span> {indicator.text}
      </p>
    </div>
  );
}

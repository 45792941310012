import { Button, ButtonV2, List } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { STP_OPEN_SIDE } from "@utils/consts";
import React, { useState } from "react";
import "./DashboardProftestV2.scss";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";

export default function DashboardProftestV2({ proftest }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);

  const RESULTS = [
    {
      id: 0,
      name: "Artist",
      description: "Artist are good",
    },
    {
      id: 1,
      name: "Developer",
      description: "Developer are the best",
    },
    {
      id: 2,
      name: "Manager",
      description: "Product are mid",
    },
  ];

  const handleButtonClick = () => {
    window.location.href = `${STP_OPEN_SIDE}/test`;
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="dashboard__proftest">
      <div className="dashboard__proftest-heading">
        <h2 className="dashboard__subheading">
          {t(proftest ? "PROFTEST.TITLE" : "PROFTEST.CTA")}
        </h2>
        {!isMobile && (
          <ButtonV2
            text={t(proftest ? "PROFTEST.BUTTON" : "PROFTEST.START")}
            className="button--primary-small btn--pill"
            onClick={handleButtonClick}
          />
        )}
      </div>

      {proftest && (
        <>
          <div className="dashboard__proftest-description-content">
            <div className="dashboard__proftest-description">
              <h5>{proftest?.personality_type.name}</h5>

              <div
                className={`markdown-container ${isExpanded ? "expanded" : ""}`}
              >
                <ReactMarkdown className="dashboard__proftest-description-text">
                  {proftest?.personality_type.description}
                </ReactMarkdown>
              </div>

              <Button
                text={t(
                  isExpanded ? "PROFTEST.SHOW_LESS" : "PROFTEST.SHOW_MORE"
                )}
                className="btn--expand"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpand();
                }}
              />
            </div>
          </div>
        </>
      )}

      <ReactPlayer
        className="player__video dashboard__proftest-player"
        url={proftest?.personality_type.video}
        width="100%"
        height="fit-content"
        controls={true}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              onContextMenu: (e) => e.preventDefault(),
            },
          },
        }}
      />

      {proftest?.professions?.length > 0 && ( 
        <>
          <p className="dashboard__proftest-subtitle">
            {t("PROFTEST.RECOMMENDATIONS")}
      </p>
      <List
        className={`dashboard__proftest-items ${!proftest && "placeholder"}`}
        items={proftest?.professions || RESULTS}
        renderItem={(item) => (
          <div key={item.name} className="dashboard__proftest-description">
            <h5>{item.name}</h5>
            <ReactMarkdown className="dashboard__proftest-description-text">
              {item.description}
            </ReactMarkdown>
          </div>
        )}
      />

      <div className="dashboard__proftest-description">
        <h5>{proftest?.profession_type?.name}</h5>
        <ReactMarkdown className="dashboard__proftest-description-text">
          {proftest?.profession_type?.description}
        </ReactMarkdown>
        </div>
      </>
      )}

      {isMobile && (
        <ButtonV2
          text={t(proftest ? "PROFTEST.BUTTON" : "PROFTEST.START")}
          className="button--primary-small btn--pill"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
}

import React from "react";
import "./FeedbackQuestionItem.scss";

import {
  RatingQuestion,
  OneChoiceQuestion,
  InsertTextQuestion,
} from "@components/feature";

export default function FeedbackQuestionItem({
  question,
  index,
  userAnswers,
  handleAnswerChange,
}) {
  const handleQuestionAnswerChange = (answer) => {
    handleAnswerChange(question.id, answer);
  };

  let QuestionComponent = null;

  switch (question.type) {
    case "feedback_rating_question":
      QuestionComponent = (
        <RatingQuestion
          question={question}
          userAnswer={userAnswers[question.id]}
          onAnswerChange={handleQuestionAnswerChange}
        />
      );
      break;
    case "feedback_one_choice":
      QuestionComponent = (
        <OneChoiceQuestion
          question={question}
          userAnswer={userAnswers[question.id]}
          onAnswerChange={handleQuestionAnswerChange}
        />
      );
      break;
    case "feedback_open_question":
      QuestionComponent = (
        <InsertTextQuestion
          question={question}
          userAnswer={userAnswers[question.id]}
          onAnswerChange={handleQuestionAnswerChange}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="feedback-question-item">
      {question?.text && <h4 className="feedback-question-text">{question.text}</h4>}
      {question?.description && <p className="feedback-question-description">{question.description}</p>}

      {QuestionComponent}
    </div>
  );
}

import { Button, Icon } from "@components/common";
import React, { useState, useEffect } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useTranslate } from "@helpers/hooks";
import { AudioPlayer } from "@components/feature";
import "./VoiceRecord.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function VoiceRecord({ userAnswer, onAnswerChange, question }) {
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [startAgainClicked, setStartAgainClicked] = useState(false);
  const { t } = useTranslate();
  const { pathname } = useLocation();
  const isStartAgainAllowed = !pathname.includes('mock')

  const addAudioElement = (blob) => {
    setAudioBlob(blob);
    setStartAgainClicked(false);
    onAnswerChange(blob);
    blob = userAnswer || null;
  };

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
    }
  }, [audioBlob]);

  const recorderControls = useAudioRecorder();

  const handleRecordClick = () => {
    if (isRecording) {
      recorderControls.stopRecording();
    } else {
      recorderControls.startRecording();
      audioURL && setAudioURL(null);
    }
    setIsRecording(!isRecording);
  };

  const handleStartAgain = () => {
    setAudioURL(null);
    setStartAgainClicked(true);
  };

  return (
    <div className="audio">
      <div className={`audio__container`}>
        {!isRecording && !audioURL && !question?.question_speaking?.response && (
          <div className={`audio__icon`}>
            <Icon name={"mic"} handleClick={handleRecordClick} />
          </div>
        )}
        {isRecording && (
          <div className={`audio__icon`}>
            <Icon name={"pause"} handleClick={() => handleRecordClick()} />
          </div>
        )}
        {question?.question_speaking?.response ? (
          <AudioPlayer src={question?.question_speaking?.response} />
        ) : audioURL && !isRecording && !startAgainClicked ? (
          <AudioPlayer src={audioURL} />
        ) : (
          <div className={` ${isRecording && "audio__group"}`}>
            <AudioRecorder
              onRecordingComplete={addAudioElement}
              recorderControls={recorderControls}
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }}
              onNotAllowedOrFound={(err) => console.table(err)}
              downloadFileExtension="mp3"
              showVisualizer={true}
            />
          </div>
        )}
      </div>

      {audioURL && !isRecording && !startAgainClicked && isStartAgainAllowed && (
        <Button className="btn--teritary audio__btn" text={t("LMS.BUTTON.START_AGAIN")} onClick={handleStartAgain} />
      )}
      {!isStartAgainAllowed && <p className="audio__warning">{t("MOCK.REPLAY")}</p>}
    </div>
  );
}

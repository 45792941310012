import { useAction } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BtnLoader, List } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import "./MarathonRating.scss";
import { MarathonRatingCard } from "../MarathonRatingCard";

export default function MarathonRating() {
  const { t } = useTranslate();
  const isMobile = useIsMobile()
  const { fetchSquidRanking } = useAction();
  const { squidRanking, isSquidRankingLoading } = useSelector((state) => state.courses);

  const teritaryUsers = squidRanking && squidRanking.map((user, index) => {
    return {
        ...user,
        position: index + 1
    }
  });

  const handleFetchRating = () => {
    fetchSquidRanking();
  };

  const HEAD_ITEMS = [
    {
      id: 1,
      name: t("LMS.RANKING.RANK"),
    },
    {
      id: 2,
      name: t("LMS.RANKING.USERNAME"),
    },
    {
      id: 3,
      name: t("LMS.RANKING.STATUS"),
    },
  ];

  useEffect(() => {
    handleFetchRating();
  }, []);

  return (
    isSquidRankingLoading ?   <BtnLoader className={"btn-loader--primary"}/> :
    <div>
      <h1 className="course_notes__title">{t("LMS.RANKING.SQUID_TITLE")}</h1>
      {squidRanking && (
        <div className="rating">
          <div className="rating__table">
            <List
              className={`rating__table-head ${isMobile ? "rating__table-head-mobile" : "rating__table-head-web"}`}
              items={HEAD_ITEMS}
              renderItem={(item) => (
                <div key={item.id}>
                  <p className={isMobile ? "rating__text-mobile-head" : "rating__text"}>{item.name}</p>
                </div>
              )}
            />

            <List
              className="rating__table-records"
              items={teritaryUsers}
              renderItem={(user) => <MarathonRatingCard  key={user.id} user={user} teritaryUsers={teritaryUsers} />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

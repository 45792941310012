import coinSheet from '../../assets/spritesheet.png'
export const startCoinAnimation = (duration) => {
  const exists = document.getElementById('gimmick');
  if (exists) {
    exists.parentNode.removeChild(exists);
    return;
  }

  const body = document.querySelector('body');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  canvas.id = 'gimmick';
  canvas.style.position = 'fixed';
  canvas.style.top = '0';
  canvas.style.left = '0';
  canvas.style.zIndex = '9999';
  body.appendChild(canvas);

  const coin = new Image();
  coin.src = coinSheet;

  const coins = [];

  coin.onload = function () {
    drawloop();
  };

  function drawloop() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (Math.random() < 0.3) {
      coins.push({
        x: Math.random() * canvas.width | 0,
        y: -50,
        dy: 3,
        s: 0.5 + Math.random(),
        state: Math.random() * 10 | 0,
        angle: Math.random() * Math.PI * 2,
        rotationSpeed: (Math.random() - 0.5) * 0.2
      });
    }

    let i = coins.length;
    while (i--) {
      const x = coins[i].x;
      const y = coins[i].y;
      const s = coins[i].s;
      const state = coins[i].state;
      const angle = coins[i].angle;

      coins[i].state = (state > 9) ? 0 : state + 0.1;
      coins[i].dy += 0.3;
      coins[i].y += coins[i].dy;
      coins[i].angle += coins[i].rotationSpeed;

      ctx.save();
      ctx.translate(x + 22 * s, y + 20 * s);
      ctx.rotate(angle);
      ctx.translate(-(x + 22 * s), -(y + 20 * s));

      ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s);

      ctx.restore();

      if (y > canvas.height) {
        coins.splice(i, 1);
      }
    }

    requestAnimationFrame(drawloop);
  }

  setTimeout(() => {
    const canvas = document.getElementById('gimmick');
    if (canvas) {
      canvas.parentNode.removeChild(canvas);
    }
  }, duration);
};

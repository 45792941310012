// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout__equal-padding {
  padding: 40px;
  background-color: white;
}

.video {
  display: flex;
  gap: 20px;
}
.video-mobile {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/LessonVideo/LessonVideo.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;AACR","sourcesContent":[".layout__equal-padding {\n    padding: 40px;\n    background-color: white;\n}\n\n.video {\n    display: flex;\n    gap: 20px;\n\n    &-mobile {\n        display: flex;\n        flex-direction: column;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {Fragment, Suspense} from "react";
import {Article, Footer, Header, Image} from "@components/common";
import "./MobileLayout.scss";
import { useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { checkIfMarathon } from "@utils/functions";
import { useAction } from "@helpers/hooks";
import { UpdateModal } from "@components/feature";


export default React.memo(function MobileLayout({ children, isBannerActive, onCloseBanner }) {
    const {pathname} = useLocation();
    const {courseId} = useParams();
    const history = useHistory();
    const {fetchCourse, fetchWorkshops, fetchMockExams} = useAction();


    const handleSquidBoard = () => {
        history.push('/courses/13/ranking')
      } 

    useEffect(() => {
        if (pathname.includes("courses/")) {
            fetchCourse(courseId);
            fetchWorkshops(courseId);
        }
    }, [courseId])

    return (
        <Fragment>
            {pathname.includes("courses/") && <Header />}
            <UpdateModal />
            <Article className={`private-layout__article ${isBannerActive ? "banner-active": ""}`}>
            {!pathname.includes("lessons") && !pathname.includes("practice") && checkIfMarathon(courseId, 13) && !pathname.includes("ongoing") && <Image handleClick={handleSquidBoard} className={"banner__img"} name={pathname.includes("ranking") ? "ad-banner-mob-s.jpg" : "ad-banner-mob.jpg"} />}
                <Suspense fallback={<div>Загрузка...</div>}>
                    {children}
                </Suspense>
            </Article>
           {!pathname.includes("ongoing") && <Footer/>}
        </Fragment>
    );
});
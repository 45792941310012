import React, { useState, useEffect } from "react";
import "./WaitListBanner.scss";
import { Button, Icon, NewModalLayout } from "@components/common";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";

export default function WaitListBanner() {
  const [isVisible, setIsVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const { submitWaitlist } = useAction();


  useEffect(() => {
    const bannerClosed = localStorage.getItem("waitListBannerClosed");
    if (bannerClosed) {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("waitListBannerClosed", "true");
    setIsVisible(false);
  };

  const handleCloseModal = () => {
    localStorage.setItem("waitListBannerClosed", "true");

    setIsModalOpen(false);
  };

  if (!isVisible) return null;

  const handleSubmit = async () => {
    const waitlistData = {
      goal: "essay",
      fio: profile?.name || "",
      email: profile?.email || "",
      message: "I want to be the first to try Writing Builder",
      phone_number: "+77777777777",
    };

    try {
      await submitWaitlist(waitlistData);
      setIsSubmitted(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setIsVisible(false);
      }, 2000);

      localStorage.setItem("waitListBannerClosed", "true");
      
    } catch (error) {
      console.error("Error submitting waitlist:", error);
    }
  };

  return (
    <div className="waitlist-banner">
      <div className="waitlist-banner__content">
        <div className="waitlist-banner__header">
          <h3 className="waitlist-banner__title">
            Твой личный помощник по эссе
          </h3>{" "}
          <Icon name="close" handleClick={handleClose} />
        </div>
        <p className="waitlist-banner__description">
          Ограниченное предложение! Забронируйте своё место сейчас и получите
          ранний доступ к обновленному <strong>AI Writing Builder</strong> с
          эксклюзивными бонусами! Нажмите кнопку, чтобы стать одним из первых
          пользователей.
        </p>
        <Button
          className="btn--primary btn--pill waitlist-banner__btn"
          text="Забронировать"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
      {isModalOpen && (
        <NewModalLayout>
          {!isSubmitted ? (
            <>
              <div className="waitlist-banner__header">
                <h4>
                  Не упустите шанс стать лучшим в написании эссе и гарантировать
                  себе успешное поступление!
                </h4>
                <Icon name="close-rounded" handleClick={handleCloseModal} />
              </div>
              <ol className="waitlist-banner__features">
                <li>Автоматический анализ эссе с рекомендациями по улучшению.</li>
                <li>
                  Подробный фидбэк по каждому загруженному эссе — от структуры до
                  грамматики.
                </li>
                <li>
                  Возможность загрузки эссе с помощью токенов или подписки —
                  выбирайте то, что удобно вам!
                </li>
                <li>Система наград и мотивация для активных пользователей.</li>
              </ol>
              <Button
                className="btn--primary btn--pill waitlist-banner__btn"
                text="Забронировать"
                onClick={handleSubmit}
              />
            </>
          ) : (
            <div className="waitlist-banner__success">
              <h4>Спасибо за регистрацию!</h4>
              <p>Мы свяжемся с вами, как только Writing Builder будет доступен.</p>
            </div>
          )}
        </NewModalLayout>
      )}
    </div>
  );
}

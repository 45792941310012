// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_activity_modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.add_activity_modal_content {
  border-radius: 40px;
  background-color: white;
  padding: 32px 48px;
}

.activities {
  display: flex;
}
.activities .move-right {
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0px;
}

.activities_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Profile/Activities/Activities.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,8BAAA;EAA8B,mBAAA;EAC9B,oCAAA;EAAmC,qBAAA;AACrC;;AAEA;EACE,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;AACE;EACE,oBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAAF","sourcesContent":["@import \"src/styles/variables\";\n\n.add_activity_modal {\n  position: fixed;\n  top:0;\n  left:0;\n  height:100%;\n  width: 100%;\n  overflow: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 300;\n  background-color: rgb(0,0,0); /* Fallback color */\n  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */\n}\n\n.add_activity_modal_content {\n  border-radius: 40px;\n  background-color: white;\n  padding: 32px 48px;\n}\n\n.activities {\n  display: flex;\n\n  .move-right {\n    align-self: flex-end;\n    width: fit-content;\n    margin-right: 0px;\n  }\n}\n\n.activities_header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from 'react';
import { useAction, useIsMobile, useStep } from '@helpers/hooks';
import { NewModalLayout } from '@components/common';
import { WelcomeQuestionnaireStart } from '../WelcomeQuestionnaireStart';
import { WelcomeQuestionnaireStep } from '../WelcomeQuestionnaireStep';
import { WelcomeQuestionnaireEnd } from '../WelcomeQuestionnaireEnd';
import './WelcomeQuestionnaire.scss';

const WelcomeQuestionnaire = ({setIsQuestionnaireOpen}) => {
    const { step, setStep, next, previous } = useStep();
    const { fetchUniversities } = useAction();
    const isMobile = useIsMobile();

    useEffect(() => {
        fetchUniversities();
    }, [])

    const content = (
        <>
            {step === 1 && <WelcomeQuestionnaireStart 
                next={next}
                setIsQuestionnaireOpen={setIsQuestionnaireOpen}
                />
            }
            {step >= 2 && step <= 6 && 
                <WelcomeQuestionnaireStep 
                    step={step}
                    next={next}
                    previous={previous}
                    setIsQuestionnaireOpen={setIsQuestionnaireOpen}
                />
            }
            {step === 7 && <WelcomeQuestionnaireEnd />}
        </>
    );

    return isMobile ? <div className="welcome-q">{content}</div> 
    : <NewModalLayout className="welcome-q">{content}</NewModalLayout>;
}

export default WelcomeQuestionnaire;

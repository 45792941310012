const quotes = [
    { text: "Знание — это сила.", author: "Фрэнсис Бэкон" },
    { text: "Учиться можно только тогда, когда делаешь ошибки.", author: "Альберт Эйнштейн" },
    { text: "Чем больше я учусь, тем больше я понимаю, как мало знаю.", author: "Сократ" },
    { text: "Неудачи — это возможность начать снова, но уже более мудро.", author: "Генри Форд" },
    { text: "Образование — это не подготовка к жизни; образование — это сама жизнь.", author: "Джон Дьюи" },
    { text: "Учение — это свет, а неучение — тьма.", author: "" },
    { text: "Жизнь — это школа, в которой каждый день — урок.", author: "" },
    { text: "Ум развивается только тогда, когда его используют.", author: "Альберт Швейцер" },
    { text: "Мудрость начинается с удивления.", author: "Сократ" },
    { text: "Путь к знаниям бесконечен, но первый шаг — самый важный.", author: "Конфуций" },
    { text: "Тот, кто не учится, потерян для общества.", author: "Мартин Лютер Кинг" },
    { text: "Будущее принадлежит тем, кто верит в красоту своей мечты.", author: "Элеонора Рузвельт" },
    { text: "Нельзя научить человека чему-либо; можно только помочь ему найти это в себе.", author: "Галилео Галилей" },
    { text: "Успех — это движение от неудачи к неудаче без потери энтузиазма.", author: "Уинстон Черчилль" },
    { text: "Никогда не поздно стать тем, кем ты мог бы стать.", author: "Джордж Элиот" },
    { text: "Учись так, как будто ты будешь жить вечно.", author: "Махатма Ганди" },
    { text: "Образование — это инвестиция, которая приносит наибольшую выгоду.", author: "Бенджамин Франклин" },
    { text: "Только те, кто осмеливается потерпеть неудачу, могут добиться великого успеха.", author: "Роберт Кеннеди" },
    { text: "Вдохновение существует, но оно должно застать вас за работой.", author: "Пабло Пикассо" },
    { text: "Знание — это сокровище, а практика — ключ к нему.", author: "Лао-цзы" },
    { text: "Каждый человек — это возможность учиться чему-то новому.", author: "" },
    { text: "Ты можешь, если веришь, что можешь.", author: "" },
    { text: "Побеждает не тот, кто сильнее, а тот, кто настойчивее.", author: "" },
    { text: "Дорога к успеху строится знаниями и усилиями.", author: "" },
    { text: "Успех приходит к тем, кто готов его встретить.", author: "" },
    { text: "Мечтайте большими мечтами и добивайтесь их.", author: "" },
    { text: "Каждый великий ученый когда-то был начинающим.", author: "" },
    { text: "Ум без знания — это как дерево без плодов.", author: "" },
    { text: "Учение без размышления бесполезно, размышление без учения опасно.", author: "Конфуций" },
    { text: "Тот, кто никогда не ошибался, никогда не пробовал ничего нового.", author: "Альберт Эйнштейн" },
    { text: "Успех — это лестница, на которую нельзя взобраться, засунув руки в карманы.", author: "Арнольд Шварценеггер" },
    { text: "Жизнь учит только тех, кто готов учиться.", author: "" },
    { text: "Ты можешь больше, чем думаешь.", author: "" },
    { text: "Секрет успеха — это постоянство цели.", author: "Бенджамин Дизраэли" },
    { text: "Чем больше вы учитесь, тем больше у вас возможностей.", author: "" },
    { text: "Если вы хотите изменить мир, начните с себя.", author: "Махатма Ганди" },
    { text: "Образование — это то, что остаётся, когда забыто всё выученное.", author: "Альберт Эйнштейн" },
    { text: "Учение делает жизнь интереснее и полнее.", author: "" },
    { text: "Сначала они игнорируют тебя, потом смеются над тобой, потом борются с тобой, а потом ты побеждаешь.", author: "Махатма Ганди" },
    { text: "Мудрость приходит не с возрастом, а с учением.", author: "" },
    { text: "Успех — это результат подготовки, упорства и учения.", author: "" },
    { text: "Всегда стремитесь к совершенству, даже если оно недостижимо.", author: "" },
    { text: "Время, потраченное на учение, никогда не потрачено зря.", author: "" },
    { text: "Каждая неудача — это шаг к успеху.", author: "" },
    { text: "Никогда не переставайте учиться, ведь жизнь никогда не перестаёт учить.", author: "" },
    { text: "Лучшее время начать — сейчас.", author: "" },
    { text: "Не бойтесь учиться и ошибаться — это часть роста.", author: "" },
    { text: "Знание без действия бесполезно.", author: "" },
    { text: "Ваши усилия сегодня определят ваш успех завтра.", author: "" },
    { text: "Кто не идёт вперед, тот идёт назад.", author: "Иоганн Вольфганг Гёте" },
    { text: "Успех — это путешествие, а не пункт назначения.", author: "" },
    { text: "Трудности преодолеваются терпением и знанием.", author: "" },
    { text: "Ум не сосуд, который нужно наполнить, а факел, который нужно зажечь.", author: "Плутарх" },
    { text: "Победа — это всего лишь сумма маленьких побед каждый день.", author: "" },
    { text: "Ты можешь достичь всего, если учишься каждый день.", author: "" },
    { text: "Тот, кто верит в себя, уже наполовину победил.", author: "" },
    { text: "Секрет успеха — это учиться у каждой ошибки.", author: "" },
    { text: "Неудача — это не конец, а возможность учиться и расти.", author: "" },
    { text: "Знание — это сила, но действие — ключ к успеху.", author: "" },
    { text: "Секрет успеха — это упорство в учении и действии.", author: "" },
    { text: "Путь к успеху состоит из многих шагов, но каждый шаг важен.", author: "" },
    { text: "Успех приходит к тем, кто не боится учиться.", author: "" },
    { text: "Учитесь не для школы, а для жизни.", author: "Сенека" },
    { text: "Вера в себя — это первый шаг к успеху.", author: "" },
    { text: "Учение — это непрерывный процесс роста.", author: "" },
    { text: "Только те, кто готов учиться, смогут изменить мир.", author: "" },
    { text: "Трудности и ошибки — это учителя на пути к успеху.", author: "" },
    { text: "Чем больше вы учитесь, тем меньше зависите от обстоятельств.", author: "" },
    { text: "Мудрость — это искусство делать правильные выводы из ошибок.", author: "" },
    { text: "Самое большое богатство — это знание и опыт.", author: "" },
    { text: "Только те, кто продолжают учиться, могут расти и развиваться.", author: "" },
    { text: "Настойчивость и знание — ключи к успеху.", author: "" },
    { text: "Самое великое достижение — это понимание, что ты можешь учиться всю жизнь.", author: "" },
    { text: "Учение и действие идут рука об руку.", author: "" },
    { text: "Образование — это величайший подарок, который вы можете дать себе.", author: "" },
    { text: "Терпение и настойчивость преодолевают всё.", author: "Бенджамин Франклин" },
    { text: "Учись так, как будто тебе предстоит жить вечно; живи так, как будто тебе предстоит умереть завтра.", author: "Махатма Ганди" },
    { text: "Каждый человек, которого я встречаю, в чем-то лучше меня. В этом я и учусь у него.", author: "Ральф Уолдо Эмерсон" },
    { text: "Секрет успеха — это ежедневные усилия.", author: "" },
    { text: "Учение без размышления бесполезно.", author: "Конфуций" },
    { text: "Мудрость начинается с любопытства.", author: "Сократ" },
    { text: "Не бойтесь медленно идти вперёд; бойтесь оставаться на месте.", author: "" },
    { text: "Учись сегодня, чтобы завтра быть лучше.", author: "" },
    { text: "Стремление к знаниям — это первый шаг на пути к успеху.", author: "" },
    { text: "Люди, которые перестают учиться, обречены на застой.", author: "Генри Форд" },
    { text: "Ты способен на большее, чем думаешь.", author: "" },
    { text: "Учение — это не процесс запоминания фактов, а процесс формирования мышления.", author: "" },
    { text: "Те, кто учатся, имеют власть изменять свою жизнь.", author: "" },
    { text: "Каждое усилие — это шаг на пути к успеху.", author: "" },
    { text: "Знание — это единственная инвестиция, которая всегда приносит прибыль.", author: "Бенджамин Франклин" },
    { text: "Образование открывает двери там, где их не было.", author: "" },
    { text: "Неудачи учат больше, чем успех.", author: "" },
    { text: "Учение никогда не заканчивается, потому что жизнь никогда не перестаёт учить.", author: "" },
    { text: "Образование — это ключ к свободе.", author: "" },
    { text: "Учение — это путешествие без конца.", author: "" },
    { text: "Упорство и обучение — ваши лучшие союзники на пути к успеху.", author: "" },
    { text: "Только учёба и действия приведут к великим достижениям.", author: "" },
    { text: "Учись на ошибках и становись сильнее.", author: "" },
    { text: "Каждый день — это новая возможность узнать что-то новое.", author: "" },
    { text: "Лучший способ предсказать будущее — это создать его.", author: "Питер Друкер" },
    { text: "Только тот, кто рискует, может достичь больших высот.", author: "Роберт Кеннеди" },
    { text: "Постоянство — залог успеха.", author: "" },
    { text: "Учение — это процесс, который никогда не завершится.", author: "" },
    { text: "Знание — это единственное, что нельзя отнять.", author: "" },
    { text: "Постоянное развитие — это ключ к успеху.", author: "" },
    { text: "Желание учиться — это начало пути к величию.", author: "" },
    { text: "Учение — это не нагрузка, а возможность.", author: "" },
    { text: "Ты можешь учиться всю жизнь и каждый день узнавать что-то новое.", author: "" },
    { text: "Каждый человек, которого вы встретите, может вас чему-то научить.", author: "" },
    { text: "Успех приходит к тем, кто никогда не перестаёт учиться.", author: "" },
    { text: "Знание — это мост к возможности.", author: "" },
    { text: "Терпение и учение — ключи к любому успеху.", author: "" },
    { text: "Если хочешь быть лучшим, никогда не переставай учиться.", author: "" },
    { text: "Образование расширяет горизонты и открывает новые двери.", author: "" },
    { text: "Учение делает невозможное возможным.", author: "" },
    { text: "Мудрость приходит к тем, кто ищет знания.", author: "" },
    { text: "Учение — это процесс, а не результат.", author: "" },
    { text: "Побеждает тот, кто не боится учиться и ошибаться.", author: "" },
    { text: "Учись у всего, что видишь, и у всех, кого встречаешь.", author: "" },
    { text: "Знание открывает бесконечные возможности.", author: "" },
    { text: "Если хочешь изменить мир, начни с учения.", author: "" },
    { text: "Путь к успеху начинается с учения.", author: "" },
    { text: "Знание без действия бесполезно.", author: "" },
    { text: "Учение помогает обрести уверенность в своих силах.", author: "" },
    { text: "Мудрость — это результат опыта и учения.", author: "" },
    { text: "Учение делает нас лучше каждый день.", author: "" },
    { text: "Постоянное учение — это путь к совершенству.", author: "" },
    { text: "Чем больше знаешь, тем больше понимаешь, что есть ещё, чему можно научиться.", author: "" },
    { text: "Упорство и желание учиться приведут к успеху.", author: "" },
    { text: "Учение — это самое мощное оружие, которое можно использовать, чтобы изменить мир.", author: "Нельсон Мандела" },
    { text: "Мир принадлежит тем, кто готов учиться и развиваться.", author: "" },
    { text: "Учение — это сила, которая всегда с тобой.", author: "" },
    { text: "Учение открывает дверь к будущим возможностям.", author: "" },
    { text: "Мудрый человек учится всю жизнь.", author: "" },
    { text: "Учение расширяет горизонты и вдохновляет на новые открытия.", author: "" },
    { text: "Каждое новое знание приближает к успеху.", author: "" },
    { text: "Учение помогает увидеть мир с новой перспективы.", author: "" },
    { text: "Важен не только результат, но и сам процесс учения.", author: "" },
    { text: "Учение без действий — это трата времени.", author: "" },
    { text: "Знание — это фундамент для любых достижений.", author: "" },
    { text: "Учение и дисциплина идут рука об руку.", author: "" },
    { text: "Учение не знает границ, как и успех.", author: "" },
    { text: "Мудрость начинается с первого шага к знаниям.", author: "" },
    { text: "Успех — это результат упорства и постоянного учения.", author: "" },
    { text: "Знание — это инструмент, который делает мир лучше.", author: "" },
    { text: "Учение даёт силу действовать смело.", author: "" },
    { text: "Чем больше учишься, тем больше понимаешь.", author: "" },
    { text: "Постоянное учение укрепляет дух и разум.", author: "" },
    { text: "Тот, кто учится, не боится изменений.", author: "" },
    { text: "Учение позволяет раскрыть весь потенциал.", author: "" },
    { text: "Учение и практика делают совершенство.", author: "" },
    { text: "Знания и действия — два главных инструмента успеха.", author: "" },
    { text: "Мудрость — это способность учиться у своих ошибок.", author: "" },
    { text: "Учение делает невозможное возможным.", author: "" },
    { text: "Каждая новая ошибка — это новый урок.", author: "" },
    { text: "Мудрость — это искусство делать правильные выводы из опыта.", author: "" },
    { text: "Знание открывает путь к новым возможностям.", author: "" },
    { text: "Учение развивает мышление и помогает добиваться целей.", author: "" },
    { text: "Учение — это ключ к самосовершенствованию.", author: "" },
    { text: "Знание — это сокровище, которое всегда с тобой.", author: "" },
    { text: "Учение помогает строить успешное будущее.", author: "" },
    { text: "Знание — это сила, которая ведёт к успеху.", author: "" },
    { text: "Учение — это фундамент для роста и развития.", author: "" },
    { text: "Мудрый человек учится на протяжении всей жизни.", author: "" },
    { text: "Учение и практика делают из новичка мастера.", author: "" },
    { text: "Успех приходит к тем, кто готов учиться и меняться.", author: "" },
    { text: "Учение позволяет раскрыть свои возможности.", author: "" },
    { text: "Чем больше учишься, тем больше можешь.", author: "" },
    { text: "Учение — это непрерывный процесс, который ведёт к успеху.", author: "" },
    { text: "Учение делает тебя сильнее и умнее каждый день.", author: "" },
    { text: "Знание открывает двери к успеху и свободе.", author: "" },
    { text: "Учение развивает и укрепляет дух.", author: "" },
    { text: "Учение открывает мир новых возможностей.", author: "" },
    { text: "Знание помогает строить светлое будущее.", author: "" },
    { text: "Мудрость приходит к тем, кто не боится учиться.", author: "" },
    { text: "Каждый новый день — это шанс научиться чему-то новому.", author: "" },
    { text: "Учение делает невозможное возможным.", author: "" },
    { text: "Знание даёт уверенность в своих силах.", author: "" },
    { text: "Учение позволяет превзойти себя.", author: "" },
    { text: "Мудрость и знание — это фундамент успеха.", author: "" },
    { text: "Учение открывает новые горизонты.", author: "" },
    { text: "Знание — это сила, которая ведёт к переменам.", author: "" },
    { text: "Учение — это процесс, который ведёт к успеху.", author: "" },
    { text: "Учение — это ключ к новому уровню понимания.", author: "" },
    { text: "Чем больше знаешь, тем больше понимаешь, что ещё многое нужно узнать.", author: "" },
    { text: "Учение открывает двери к новым возможностям.", author: "" },
    { text: "Знание позволяет контролировать свою жизнь.", author: "" },
    { text: "Мудрость приходит с опытом и учением.", author: "" },
    { text: "Учение делает тебя лучшей версией себя.", author: "" },
    { text: "Чем больше учишься, тем больше можешь достичь.", author: "" },
    { text: "Учение укрепляет дух и развивает разум.", author: "" },
    { text: "Знание открывает новые пути к успеху.", author: "" },
    { text: "Учение помогает раскрыть внутренний потенциал.", author: "" },
    { text: "Знание делает тебя сильнее каждый день.", author: "" },
    { text: "Учение — это самый важный шаг на пути к успеху.", author: "" },
    { text: "Чем больше учишься, тем больше можешь изменить.", author: "" },
    { text: "Учение — это свет на пути к успеху.", author: "" },
    { text: "Мудрость начинается с желания учиться.", author: "" },
    { text: "Учение открывает новые горизонты и возможности.", author: "" },
    { text: "Знание — это ключ к любым дверям.", author: "" }
];

export { quotes };
import {Icon} from "@components/common";
import classNames from "classnames";
import "../Pagination.scss";

export default function PaginationArrowPrevious({currentPage, onPrevious}) {
    return (
        <li
            key={"previous"}
            className={classNames("pagination__item previous", {disabled: currentPage === 1})}
            onClick={onPrevious}
        >
            <Icon name={"pagination-arrow-left"}/>
        </li>
    );
};
import {axiosRequest} from "@api/xhr";

export default class BlogService {
    static async fetchBlogArticles() {
        return await axiosRequest.get('auth/blog/');
    }

    static async fetchBlogArticle(id) {
        return await axiosRequest.get(`auth/blog/${id}/`);
    };

    static async fetchResources() {
        return await axiosRequest.get('auth/useful_resources/');
    }

    static async fetchResource(id) {
        return await axiosRequest.get(`auth/useful_resources/${id}/`);
    };

};
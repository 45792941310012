// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sheet {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.4);
}
.sheet__content {
  background-color: white;
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  min-width: none;
  margin: 0px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ModalSheet/ModalSheet.scss"],"names":[],"mappings":"AAAA;EAGE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,oCAAA;AADF;AAGE;EACE,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;AADJ","sourcesContent":[".sheet {\n\n\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n  z-index: 200;\n  background-color: rgba(0, 0, 0, 0.4);\n\n  &__content {\n    background-color: white;\n    padding: 16px;\n    position: absolute;\n    top: 0;\n    right: 0;\n    height: 100%;\n    width: 50%;\n    min-width: none;\n    margin: 0px;\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { useTranslate } from '@helpers/hooks';
import { List } from '@components/common';
import { parseStrToArr } from '@utils/functions';

export default function ScholarshipsPrograms({item}) {
  const {t} = useTranslate()
  return (
    <div className="scholarships__eligibility">
      <h4>{t("SCHOLARSHIPS.CRITERIA_2")}</h4>
      <List items={parseStrToArr(item?.program)} renderItem={(item) => <li key={item}>{item}</li>} />
    </div>
  )
}

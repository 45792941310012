import {axiosRequest} from "@api/xhr";

export default class PaymentService {
    static async getPaymentLink(courseId) {
        return await axiosRequest.get(`lms/courses/${courseId}/payment`);
    };

    static async postPaymentSuccess(courseId, data) {
        return await axiosRequest.post(`lms/courses/${courseId}/payment`, data);
    };
}

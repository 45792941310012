import {useTranslate} from "@helpers/hooks";
import {Image} from "@components/common";
import "./Plug.scss";

export default function Plug() {
    const {t} = useTranslate();
    return (
        <div className={"plug"}>
            <h3 className={"plug__title"}>
                {t("PLUG.TITLE")}
            </h3>
            <p className={"plug__desc"}>
                {t("PLUG.DESCRIPTION")}
            </p>
            <Image className={"plug__img"} name={"coming-soon.png"}/>
        </div>
    );
};
import classNames from "classnames";
import "./Form.scss";

export default function Form({className, children, ...props}) {
    return (
        <form
            className={classNames("form", className)}
            {...props}
        >
            {
                children
            }
        </form>
    );
};
import { useEffect } from 'react';
import { useWindowSize } from '@helpers/hooks';
import classNames from 'classnames';
import './NewModalLayout.scss';

const NewModalLayout = ({children, className}) => {
    const windowSize = useWindowSize();

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = 'hidden';
        return () => {
            body.style.overflow = 'auto';
        }
    }, [windowSize])

    return (
        <div className="add_modal">
            <div className={classNames("add_modal_content", className)}>
                {children}
            </div>
        </div>
    );
}

export default NewModalLayout;

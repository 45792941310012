import { Button, Icon } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { useAction } from "@helpers/hooks";
import classNames from "classnames";
import {useIsMobile} from "@helpers/hooks";
import "./TryAgainWarning.scss";

export default function TryAgainWarning({ quizId, score }) {
  const { t } = useTranslate();
  const { deleteQuizResults } = useAction();
  const isMobile = useIsMobile()

  const failedQuiz = score < 70
  const passedQuiz = score >= 70

  const containerClass = classNames({
    'warning': failedQuiz,
    'success': passedQuiz,
  });


  const headlineClass = classNames({
    'warning__headline': failedQuiz,
    'success__headline': passedQuiz,
    'warning__headline warning-mobile': failedQuiz && isMobile,
    'success__headline success-mobile': passedQuiz && isMobile,
  });

  const subheadlineClass = classNames({
    'warning__subheadline': failedQuiz,
    'success__subheadline': passedQuiz,
    'warning__subheadline warning-mobile': failedQuiz && isMobile,
    'success__subheadline success-mobile': passedQuiz && isMobile,
  });

  return (
    <div className={containerClass}>
      <div className="warning__container">
        <Icon name={failedQuiz ? `lms-warning` : `lms-correct`} />
        <div className={isMobile ? "warning__content-mobile" : "warning__content"}>
          <div className="warning__text">
            <p className={headlineClass}>{failedQuiz ? t("LMS.QUIZ.TRY_AGAIN_READY") :  t("LMS.QUIZ.SUCCESS_PASS")}</p>
            <p className={subheadlineClass}>
              {t("LMS.QUIZ.GRADE_RECEIVED")} {score}%. {t("LMS.QUIZ.PASS")}
            </p>
          </div>
          <Button
            className="btn--primary btn--pill"
            text={t("LMS.BUTTON.TRY_AGAIN")}
            onClick={() => deleteQuizResults(quizId)}
          />
        </div>
      </div>
    </div>
  );
}

import { Button, Icon } from "@components/common";
import "./UniversityDetailsPreview.scss";
import { useTranslate, useIsMobile } from "@helpers/hooks";

const UniversityDetailsPreview = ({ university, onClickSeeProgram }) => {
  const isMobile = useIsMobile()
  const {t} = useTranslate();
  return (
    <div className="preview">
      <div className="preview-content">
        <div className="preview-text">
          <div className="preview-name">
            {!isMobile && <Icon className="preview-icons" name="study-block" />}
            <h1>{university.title}</h1>
          </div>
          <div className="preview-info">
            <div className="preview-info-item">
              <Icon name="location_bold" />
              <p>{university.city.map((city) => city.name).join(", ")}</p>
            </div>
            <div className="preview-info-item">
              <Icon name="official-website" />
              <p>
                <a href={university.uni_website} target="_blank">
                  {t("MY_PREFERENCES.PREFERENCES.LABEL_15")}
                </a>
              </p>
            </div>
          </div>
        </div>
        <Button
          className="btn--secondary btn--pill"
          text={t("BUTTON.SEE_PROGRAM")}
          onClick={onClickSeeProgram}
        />
      </div>
    </div>
  );
};

export default UniversityDetailsPreview;

function remainingItems(section, lessonType, itemType) {
  let count = 0;
  let time = 0;
  section.lessons?.forEach((lesson) => {
    if (!lesson.completed && lesson.type === lessonType) {
      count += 1;
      time += lesson.duration;
    }
  });
  if (itemType === "assignments") {
    return count;
  } else if (itemType === "time") {
    return time;
  } else {
    throw new Error("Invalid item type");
  }
}

export default remainingItems;
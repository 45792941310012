import {useTranslate} from "@helpers/hooks";
import {Icon} from "@components/common";
import "../PasswordModal.scss";

export default function PasswordSuccess() {
    const {t} = useTranslate();
    return (
        <div className={"password-modal__success"}>
            <Icon name={"correct"}/>
            <p>{t("SETTINGS.PASSWORD_SUCCESS")}</p>
        </div>
    );
};
import {useTranslate} from "@helpers/hooks";
import {Image} from "@components/common";
import "./FavoriteEmpty.scss";

export default function FavoriteEmpty({title, text}) {
    const {t} = useTranslate();
    return (
        <div className={"favorite-empty"}>
            {
                title && <h6 className={"favorite-empty__title"}>
                    {title}
                </h6>
            }
            <div className={"favorite-empty__content"}>
                <Image
                    className={"favorite-empty__img"}
                    name={"favorite-empty.png"}
                    alt={"Favorite empty"}
                />
                <p className={"favorite-empty__desc"}>
                    {text}
                </p>
            </div>
        </div>
    );
};
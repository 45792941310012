import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslate } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import { HeaderLanguageToggle, Logout } from "@components/feature";
import { getNavigationData, isNewFeature } from "@utils/functions";
import classNames from "classnames";
import "./BurgerMenu.scss";

export default function BurgerMenu({ isMenuOpen, setIsMenuOpen }) {
  const { t } = useTranslate(),
    { pathname } = useLocation(),
    { favorites, totalCountFavorites } = useSelector((state) => state.program);
  return (
    <div
      className={classNames("burger-menu", {
        "burger-menu--active": isMenuOpen,
      })}
    >
      <List
        className={"burger-menu__list"}
        items={getNavigationData(t, totalCountFavorites, isNewFeature)}
        renderItem={({ id, route, icon, name, isExternal }) => (
          <li key={id} className={"burger-menu__item"}>
            {isExternal ? (
              <a
                className={classNames("burger-menu__link", {
                  "burger-menu__link--selected": pathname === route,
                })}
                href={route}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                <Icon
                  name={icon}
                  className={classNames({ "icon--white": pathname === route })}
                />
                <p
                  className={classNames("burger-menu__text", {
                    "burger-menu__text--selected": pathname === route,
                  })}
                >
                  {name}
                </p>
              </a>
            ) : (
              <NavLink
                to={{ pathname: route }}
                className={"burger-menu__link"}
                activeClassName={classNames({
                  "burger-menu__link--selected": pathname === route,
                })}
                onClick={() => setIsMenuOpen(false)}
              >
                <Icon
                  name={icon}
                  className={classNames({ "icon--white": pathname === route })}
                />
                <p
                  className={classNames("burger-menu__text", {
                    "burger-menu__text--selected": pathname === route,
                  })}
                >
                  {name}
                </p>
              </NavLink>
            )}
          </li>
        )}
      />
      <br />
      <HeaderLanguageToggle className={"burger-menu__language"} />
      <Logout className={"burger-menu__logout"} />
    </div>
  );
}

import { List, Tab } from "@components/common";
import classNames from "classnames";
import "./NotFilledTabs.scss";
import NotFilledTab from "./NotFilledTab/NotFilledTab";

export default function NotFilledTabs({
  className,
  tabClassName,
  tabs,
  selected,
  setSelected,
}) {
  return (
    <div className={classNames("not-filled-tabs", className)}>
      <List
        className={"not-filled-tabs__list"}
        items={tabs}
        renderItem={(tab) => {
          const isSelected = tab.toLowerCase() === selected.toLowerCase();
          return (
            <NotFilledTab
              key={tab}
              className={tabClassName}
              tab={tab}
              isSelected={isSelected}
              setSelected={setSelected}
            />
          );
        }}
      />
    </div>
  );
}

import {axiosRequest} from "@api/xhr";

export default class ScholarshipService {
    static async fetchScholarshipsNew(params) {
        return await axiosRequest.get("directory/scholarships-new/", { params });
    };
    

    static async fetchScholarshipNew(id) {
        return await axiosRequest.get(`directory/scholarships-new/${id}/`);
    }

    static async fetchScholarshipsPrograms(params) {
        return await axiosRequest.get("directory/scholarship-programs/", {params});
    }

}
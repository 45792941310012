import React, { useCallback } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";
import { Icon, Image } from "@components/common";
import { getQuestionInfo } from "@utils/functions";
import {
  OneChoiceQuestion,
  MultipleChoiceQuestion,
  TrueFalseQuestion,
  InsertTextQuestion,
  LongTextQuestion,
  SpeakingQuestion,
  DragAndDrop,
  TableOneChoice
} from "@components/feature";
import { useTranslate } from "@helpers/hooks";
import { getQuestionData } from "@utils/functions";
import classNames from "classnames";
import "./QuizQuestionItem.scss";

export default function QuizQuestionItem({
  question,
  index,
  userAnswers,
  handleAnswerChange,
  disabled,
  hasQuizAnswers,
  isHorizontal,
}) {
  const { t } = useTranslate();
  const questionData = question && getQuestionData(question);
  

  const explanation = questionData?.explanation;
  const explanationImage = questionData?.explanation_image;

  const userAnswer =
    questionData?.response !== undefined && questionData?.response !== null
      ? questionData?.response
      : userAnswers[question.id];

  const isAnswerCorrect = hasQuizAnswers && questionData?.is_correct;

  const shouldShowCorrect = hasQuizAnswers && isAnswerCorrect;
  const shouldShowIncorrect = hasQuizAnswers && !isAnswerCorrect;

  const questionClass = classNames({
    question__heading: true,
    question__correct: shouldShowCorrect,
    question__incorrect: shouldShowIncorrect,
  });

  const indicatorTextClass = classNames({
    "question__indicator-text": true,
    question__correct: shouldShowCorrect,
    question__incorrect: shouldShowIncorrect,
  });
  const indicatorBoxClass = classNames({
    question__correct_box: shouldShowCorrect,
    question__incorrect_box: shouldShowIncorrect,
  });

  const handleQuestionAnswerChange = useCallback(
    (answer) => {
      handleAnswerChange(question.id, answer);
    },
    [handleAnswerChange, question.id]
  );

  const selectedAnswers = userAnswer || [];

  const handleMultipleChoiceAnswerChange = useCallback(
    (answerIndex) => {
      const currentAnswers = userAnswer || [];
      const newAnswers = currentAnswers.includes(answerIndex)
        ? currentAnswers.filter((index) => index !== answerIndex)
        : [...currentAnswers, answerIndex];
      handleAnswerChange(question.id, newAnswers);
    },
    [handleAnswerChange, question.id, userAnswer]
  );

  let QuestionComponent = null;

  switch (true) {
    case question.type?.includes("one_choice"):
      QuestionComponent = (
        <OneChoiceQuestion
          question={question}
          userAnswer={userAnswer}
          onAnswerChange={handleQuestionAnswerChange}
          disabled={disabled}
        />
      );
      break;
    case question.type?.includes("multiple_choice"):
      QuestionComponent = (
        <MultipleChoiceQuestion
          question={question}
          selectedAnswers={selectedAnswers}
          onAnswerChange={handleMultipleChoiceAnswerChange}
          disabled={disabled}
        />
      );
      break;
    case question.type?.includes("insert_text"):
      QuestionComponent = (
        <InsertTextQuestion
          question={question}
          userAnswer={userAnswer}
          onAnswerChange={handleQuestionAnswerChange}
          disabled={disabled}
        />
      );
      break;
    case question.type?.includes("true_false"):
      QuestionComponent = (
        <TrueFalseQuestion
          question={question}
          userAnswer={userAnswer}
          onAnswerChange={handleQuestionAnswerChange}
          disabled={disabled}
        />
      );
      break;
    case question.type?.includes("practice") || question?.writing_text != null:
      QuestionComponent = (
        <LongTextQuestion
          question={question}
          userAnswer={userAnswer}
          onAnswerChange={handleQuestionAnswerChange}
          disabled={disabled}
          isQuizInProgress={!hasQuizAnswers}
        />
      );
      break;
    case question.type?.includes("speaking_question") || question.speaking_text != null:
      QuestionComponent = (
        <SpeakingQuestion
          question={question}
          userAnswer={userAnswer}
          onAnswerChange={handleQuestionAnswerChange}
          isQuizInProgress={!hasQuizAnswers}
        />
      );
      break;

      case question.type?.includes("dnd"):
        QuestionComponent = (
          <DragAndDrop
            question={question}
            userAnswer={userAnswer}
            onAnswerChange={handleQuestionAnswerChange}
            isQuizInProgress={!hasQuizAnswers}
            disabled={disabled}
          />
        );
        break;

        case question.type?.includes("table"):
          QuestionComponent = (
            <TableOneChoice
              question={question}
              userAnswer={userAnswer}
              onAnswerChange={handleQuestionAnswerChange}
              isQuizInProgress={!hasQuizAnswers}
              disabled={disabled}
            />
          );
          break;
        
    default:
      break;
  }

  let questionText = "";
  switch (true) {
    case question.type?.includes("listening"):
      questionText = question.listening_text;
      break;
    case question?.writing_text != null:
      questionText = question.writing_text;
      break;
    case question?.speaking_text != null:
      questionText = question.speaking_text;
      break;
    case question.type?.includes("reading"):
      questionText = question.reading_text;
      break;

    default:
      questionText = question.text;
      break;
  }


  return (
    <ul className={isHorizontal ? "question__text-horizontal" : "question__text"}>
      <div className="question__text">
      <li className={questionClass}>
        <span className="question__text-span">
        {index && `${index}. `}
          <ReactMarkdown className="question__text" remarkPlugins={[remarkGfm, remarkEmoji]}>
          {questionText}
          </ReactMarkdown>
         </span>
</li>

        {(questionData?.question_image || question?.question_image || questionData?.images) && (
          <Image
            src={questionData?.question_image || question?.question_image || questionData?.images}
            className="quiz__image"
          />
        )}
      </div>
      <div>
        {hasQuizAnswers ? (
          <div className={indicatorBoxClass}>
            <Icon name={isAnswerCorrect ? `correct_circle` : `incorrect_circle`} />
            <p className={indicatorTextClass}>
              {isAnswerCorrect ? t("LMS.QUIZ.CORRECT") : t("LMS.QUIZ.INCORRECT")}
            </p>
          </div>
        ) : (
          isHorizontal && <p className="question__text">{t("MOCK.MOCK_CHOOSE")}</p>
        )}
        {QuestionComponent}
        {hasQuizAnswers && (explanation || explanationImage) && (
          <div className="question__feedback">
            <div className="question__feedback-content">
              <Icon name={"feedback_circle"} />
              {explanation && (
                <ReactMarkdown className="step__md" remarkPlugins={[remarkGfm, remarkEmoji]}>
                  {explanation}
                </ReactMarkdown>
              )}
            </div>
            {explanationImage && <Image src={explanationImage} />}
          </div>
        )}
      </div>
    </ul>
  );
}

import {useEffect} from "react";
import {useStep, useTranslate} from "@helpers/hooks";
import {DataProvider} from "@helpers/providers";
import {Modal} from "@components/common";
import {EmailChange, EmailConfirm, EmailSuccess} from "@components/feature";
import "./EmailModal.scss";

export default function EmailModal({open, setOpen}) {
    const {t} = useTranslate(),
        {step, setStep, next} = useStep(),
        initialValues = {
            email: "",
            password: "",
            confirmPassword: ""
        };

    const switchStep = (step) => {
        switch (step) {
            case 1:
                return <EmailChange setOpen={setOpen} next={next}/>;
            case 2:
                return <EmailConfirm setOpen={setOpen} next={next}/>;
            case 3:
                return <EmailSuccess setOpen={setOpen}/>;
            default:
                return <EmailChange setOpen={setOpen}/>;
        }
    }

    const findTitle = (step) => {
        if (step === 1) {
            return t("SETTINGS.CHANGING_EMAIL");
        } else if (step === 2) {
            return t("SETTINGS.CONFIRM_EMAIL");
        } else {
            return null;
        }
    };

    useEffect(() => {
        return () => {
            if (!open) {
                setStep(1);
            }
        }
    }, [setStep, open]);
    return (
        <Modal
            className={"email-modal"}
            open={open}
            setOpen={setOpen}
            width={362}
            title={findTitle(step)}
        >
            <DataProvider initialData={initialValues}>
                {switchStep(step)}
            </DataProvider>
        </Modal>
    );
};
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useAction, useTranslate, useIsMobile} from "@helpers/hooks";
import {debounce} from "@utils/functions";
import { FormItem, List} from "@components/common";
import "./Profile.scss";


export default function Profile({profile}) {
    const isMobile = useIsMobile();
    const {t} = useTranslate(),
        {updateProfile} = useAction(),
        {countries, cities} = useSelector(state => state.directory),
        {fetchCities} = useAction(),
        initialValues = useMemo(() => ({
            name: profile.name,
            surname: profile.surname,
            gender: profile.gender,
            country: profile.country,
            city: profile.city,
        }), [profile]),
        [values, setValues] = useState(initialValues),
        PROFILE_ITEMS = useMemo(() => [
          {
            id: 1,
            label: "PROFILE.NAME",
            placeholder: "Name",
            field: "input",
            name: "name",
            type: "text",
            value: values.name,
          },

          {
            id: 2,
            label: "PROFILE.SURNAME",
            placeholder: "Surname",
            field: "input",
            name: "surname",
            type: "text",
            value: values.surname,
          },
          {
            id: 3,
            label: "PROFILE.COUNTRY",
            placeholder: "FIELD.NOT_SELECTED",
            field: "dropdown",
            name: "country",
            options: countries?.results,
            value: values.country.id,
            defaultValue: {
                id: values.country.id,
                name: values.country.name,
                value: values.country.id
            },
          },
          {
              id: 4,
              label: "PROFILE.CITY",
              placeholder: "FIELD.NOT_SELECTED",
              field: "dropdown",
              name: "city",
              options: cities?.results,
              value: values.city?.id,
              defaultValue: {
                  id: values.city?.id,
                  name: values.city?.name,
                  value: values.city?.id
              },
          },
        
        ], [values, countries, cities, t]);
    
    useEffect(() => {
        fetchCities({country: profile.country.id});
    }, [profile.country.id]);

    const handleDebounce = useMemo(() => debounce(async (data) => {
        // await updateEducation({id: myEducation.id, data});
        await updateProfile({id: profile.id, data});
        // eslint-disable-next-line
    }), []);

    const handleChange = async (e, field, name) => {
        let inputValue;
    
        if (name === 'name' || name === 'surname') {
            if (e.target) {
                inputValue = e.target.value;
                // Regular expression for validating name and surname
                const validNameRegex = /^[A-Za-z-]+$/;
                if (inputValue === "" || validNameRegex.test(inputValue)) {
                    setValues(prev => ({ ...prev, [name]: inputValue }));
                    await handleDebounce({ [name]: inputValue });
                }
            }
        } else {
            inputValue = e.value;
            if (name === 'gender') {
                setValues(prev => ({ ...prev, [name]: inputValue }));
                await handleDebounce({ [name]: inputValue });
            } else {
                setValues(prev => ({ ...prev, [name]: { name: e.name, id: inputValue } }));
                await handleDebounce({ [name]: inputValue });
                if (name === "country") {
                    setValues(prev => ({ ...prev, city: null }));
                    await handleDebounce({ [name]: inputValue, city: null });
                }
            }
        }
    };
    

    return (
        <div className={"profile"}>
            <List
                className={isMobile ? "profile__list-mobile" :"profile__list"}
                items={PROFILE_ITEMS}
                renderItem={(item) => (
                    <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                )}
            />
        </div>
    );
};
import React from "react";
import { BtnLoader, Button, Icon } from "@components/common";
import "./ScholarshipsCard.scss";
import { useTranslate, useIsMobile } from "@helpers/hooks";
import { useHistory } from "react-router-dom";
import { SCHOLARSHIPS_PROFILE_ROUTE } from "@utils/consts";
import { trackAmplitude } from "@utils/functions";

export default function ScholarshipsCard({ item }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const history = useHistory();

  
  const info = item?.scholarship_info[0]
  const dateString = info.deadline;

  const [day, month, year] = dateString.split(" ");


  const handleMore = () => {
    history.push(SCHOLARSHIPS_PROFILE_ROUTE.replace(":profileId", info.scholarship));
    trackAmplitude("scholarship_learn_click", 
    {
      description: "Пользователь успешно открыл карточку стипендии",
      scholarship_name: item.title,
      location: info.country,
      deadline: dateString
  })
  };

  return (
    <div className="scholarships__card">
      <div className="scholarships__date">
        <div className="day">{day}</div>
        <div className="month">{month}</div>
        <div className="year">{year}</div>
      </div>
      <div className="scholarships__info">
        <h5>{item.title}</h5>
        <div>
          <Icon name="location_bold" />
          <p>{info.country}</p>
        </div>

        <div className="scholarships__buttons">
          <Button onClick={handleMore} className="btn--primary btn--pill" text={t("SCHOLARSHIPS.MORE")} />
          
         
        </div>
      </div>
    </div>
  );
}

import {createSlice} from "@reduxjs/toolkit";
import {
    clearUniversitySubjects,
    fetchUniversities,
    fetchUniversity, fetchUniversitySubjects,
    setUniversitiesPageChange,
    setUniversitiesPageSizeChange
} from "@store/actions/universityAction";
import {getCurrentStudyLevel} from "@utils/functions";

const initialState = {
    results: null,
    resultsDirectory: [],
    universities: {},
    university: {},
    universitySubjects: [],
    totalCount: 0,
    pageSize: 10,
    currentPage: 1,
    offset: 0,
    isLoading: false,
    isUniversityLoading: false,
    error: null
};

export const universitySlice = createSlice({
    name: "university",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchUniversities.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchUniversities.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.totalCount = action.payload.count;
            state.results = action.payload.results;
            state.resultsDirectory = action.payload.results.map((university) => ({
                id: university.id,
                name: university.title,
                value: university.id
            }));
        },
        [fetchUniversities.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchUniversity.pending.type]: (state) => {
            state.isUniversityLoading = true;
        },
        [fetchUniversity.fulfilled.type]: (state, action) => {
            state.isUniversityLoading = false;
            state.error = null;
            state.university = {
                ...action.payload,
                study_level: getCurrentStudyLevel(action.payload?.study_level)
            };
        },
        [fetchUniversity.rejected.type]: (state, action) => {
            state.isUniversityLoading = false;
            state.error = action.payload();
        },
        [fetchUniversitySubjects.fulfilled.type]: (state, action) => {
            state.error = null;
            state.universitySubjects = action.payload.results;
        },
        [fetchUniversitySubjects.rejected.type]: (state, action) => {
            state.error = action.payload;
        },
        [clearUniversitySubjects.fulfilled.type]: (state, action) => {
            state.error = null;
            state.universitySubjects = action.payload;
        },
        [setUniversitiesPageChange.fulfilled.type]: (state, action) => {
            state.currentPage = action.payload;
            state.offset = (state.currentPage - 1) * state.pageSize;
        },
        [setUniversitiesPageSizeChange.fulfilled.type]: (state, action) => {
            state.pageSize = action.payload;
        },
    }
});

export default universitySlice.reducer;
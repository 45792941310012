import { COURSE_SECTION_ROUTE } from "@utils/consts";

export default function prevSection(course, courseId, sectionId ) {
    if (!course) return null;
    const currentSection = course.sections.find(c => !c.completed)?.id ?? +sectionId;
    const routeLesson = COURSE_SECTION_ROUTE.replace(":courseId", courseId).replace(
      ":sectionId",
      currentSection
    )

    return routeLesson
}
    
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__logo {
  cursor: pointer;
}

@media only screen and (max-width: 481px) {
  .header__logo {
    width: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Header/HeaderLogo/HeaderLogo.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE;IACE,WAAA;EACF;AACF","sourcesContent":[".header__logo {\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 481px) {\n  .header__logo {\n    width: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

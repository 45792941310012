const findCost = (cost) => {
    if (!cost) {
        return null;
    } else {
        if (Number.isInteger(cost)) {
            return `$${cost}/year`;
        }
        const maxCost = Math.max(...cost?.split(","));

        if (maxCost) {
            return `$${maxCost}/year`;
        }

        try {
            const parseCost = JSON.parse(cost);
            if (Number.isInteger(+parseCost)) {
                return `$${parseCost}/year`;
            } else {
                return parseCost;
            }
        } catch (e) {
            return cost;
        }
    }
};

export default findCost;
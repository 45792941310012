import "./ProgressBar.scss";
import classNames from 'classnames';
import { Bar, ButtonV2} from "@components/common";
import { useTranslate } from '@helpers/hooks';

const ProgressBar = ({ className, completed, title, btnIncluded, total, examTitle, iconText}) => {
    const {t} = useTranslate();
    return (
        <div className="progress">
            <div className={classNames("progess--text", className)}>
                {btnIncluded? 
                <>
                    <span className={classNames("progress--title", className)}>{t("DASHBOARD.PROGRESS_BAR.TITLE", {examTitle, completed})}</span>
                    <ButtonV2
                        className="button--transparent"
                        text = {t("DASHBOARD.PROGRESS_BAR.BUTTON_TEXT", {iconText})}
                        iconRight="edit-outlined"/>
                </> : <>
                    <span className={classNames("progress--title", className)}>{title}</span>
                    <span className={classNames("progress--label", className)}>{`${completed}%`}</span>
                </>}
            </div>

            <Bar completed={completed} total={total}/>
        </div>
    )
};


export default ProgressBar;
import { FormItem } from "@components/common";
import React from "react";

export default function OpenQuestion({ question, userAnswer, onAnswerChange, disabled }) {

const correctAnswer = question?.question_insert_text?.correct_answer;

  return (
    <FormItem
      key={question.id}
      item={{
        id: question.id,
        placeholder: "LMS.QUIZ.ENTER_ANSWER",
        field: "text-quiz",
        value: userAnswer || "",
        options: [],
        correctAnswer: correctAnswer
      }}
      disabled = {disabled}
      handleChange={(e) => onAnswerChange(e.target.value)}
    />
  );
}

const COURSES = {
    ALL_IN: '1',
    MARATHON: '2',
    ENGLISH_B2: '4',
    NUET_MATH: '5',
    NUET_CT: '6',
    SAT_HERO_MATH: '10',
    SAT_HERO_VERBAL: '11',
    SQUID_GAME: '13',
    EASY_ENG: '14',
  };

const getIntro = (courseId) => {
    const intro = {
      id: 1,
      extraStep: true,
      registration: getRegistratioonStep(courseId),
      join: {
        title: "ONBOARDING.STEP_ONE.TITLE",
        description: "ONBOARDING.STEP_ONE.SUBTITLE",
        button: "ONBOARDING.STEP_ONE.BUTTON",
        link: getTelegramLink(courseId),
      },
      practice: getPracticeStep(courseId),
      workshops: {
        title: "ONBOARDING.STEP_THREE.TITLE",
        description: getWorkshopDescription(courseId),
        time: getWorkshopTime(courseId),
        button: {
          text: "ONBOARDING.STEP_THREE.TEXT",
          link: getTelegramLink(courseId),
        },
      },
      classes: {
        title: "ONBOARDING.STEP_TWO.TITLE",
        description: "ONBOARDING.STEP_TWO.SUBTITLE",
      },
    };
  
    return intro;
  };

  const getWorkshopTime = (courseId) => {
    switch (courseId) {
      case COURSES.ALL_IN:
       return 
        case COURSES.NUET_MATH:
        case COURSES.NUET_CT:
          return `
          \nПонедельник 19:30-20:30
          \nСреда 19:30-20:30
          \nСреда 19:30-20:30
          `
        case COURSES.NUET_MATH:
          return `
          \n Понедельник 18:30-19:30
          \n Среда 18:30-19:30
          \n Суббота 15:00-16:00
          `
        default:
          return 
    }
  }

  const getWorkshopDescription = (courseId) => {
    switch (courseId) {
      case COURSES.ALL_IN: 
        return "ONBOARDING.STEP_THREE.SUBTITLE"
      case COURSES.NUET_MATH:
      case COURSES.NUET_CT:
        return "ONBOARDING.STEP_THREE.NUET"
      default:
        return 
    }
  }


  const getTelegramLink = (courseId) => {
    switch (courseId) {
      case COURSES.ALL_IN:
        return "https://t.me/+faEitO8ud6QwY2Y6";
      case COURSES.MARATHON:
        return "https://t.me/+1YMRzCnxAC9iMjgy";
      case COURSES.NUET_MATH:
      case COURSES.NUET_CT:
        return "https://t.me/+w-a62l23YFljM2Ji";
      case COURSES.SAT_HERO_MATH:
        return "https://t.me/+4HefkUPsEJIyMzIy";
      case COURSES.SAT_HERO_VERBAL:
        return "https://t.me/+4HefkUPsEJIyMzIy";
      case COURSES.SQUID_GAME:
        return "https://t.me/+TwoT0y-81ZczNzUy";
      case COURSES.EASY_ENG:
        return "https://t.me/+extHfrcX9_o5NjYy";
      default:
        return "https://t.me/+NAiAZtoqP_04MmFi";
    }
  };

  const getCourseName = (courseId) => {
    switch (courseId) {
      case COURSES.ALL_IN:
        return "IELTS all-in";
      case COURSES.ENGLISH_B2:
        return "English B2";
      case COURSES.NUET_MATH:
        return "NUET Math";
      case COURSES.NUET_CT:
        return "NUET Critical Thinking";
      default:
        return "";
    }
  };

  const getRegistratioonStep = (courseId) => {
    switch (courseId) {
      case COURSES.ALL_IN:
        return {
          title: "ONBOARDING.STEP_FIVE.TITLE",
          description: "ONBOARDING.STEP_FIVE.SUBTITLE",
        };
      default:
        return;
    }
  };

const getPracticeStep = (courseId) => {
  switch (courseId) {
    case COURSES.NUET_MATH:
    case COURSES.NUET_CT:
      return {
        welcome: "ONBOARDING.WELCOME",
        title: "ONBOARDING.STEP_FOUR.TITLE",
        subtitle: "Register for the trial test that aligns with your selected plan (tariff)",
    
        icons: [
          {
            icon: "link",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_ONE_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_ONE_TEXT_NUET",
          },
          {
            icon: "mail",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_TWO_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_TWO_TEXT_NUET",
          },
          {
            icon: "timer",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_THREE_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_THREE_TEXT_NUET",
          },
          {
            icon: "warning",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_FOUR_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_FOUR_TEXT_NUET",
          },
        ],
      };

      case COURSES.ALL_IN:
      return {
        welcome: "ONBOARDING.WELCOME",
        title: "ONBOARDING.STEP_FOUR.TITLE",
        description: "ONBOARDING.STEP_FOUR.SUBTITLE",
        subtitle: "Register for the trial test that aligns with your selected plan (tariff)",
        text: "ONBOARDING.STEP_FOUR.COORDINATOR",
        address: "ONBOARDING.STEP_FOUR.ADDRESS",
        icons: [
          {
            icon: "link",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_ONE_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_ONE_TEXT",
          },
          {
            icon: "mail",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_TWO_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_TWO_TEXT",
          },
          {
            icon: "timer",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_THREE_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_THREE_TEXT",
          },
          {
            icon: "warning",
            title: "ONBOARDING.STEP_FOUR.SUB_STEP_FOUR_TITLE",
            description: "ONBOARDING.STEP_FOUR.SUB_STEP_FOUR_TEXT",
          },
        ],
      };

    default:
      return 
  }
};



export { getIntro, getCourseName };

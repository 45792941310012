// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-q-end {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-q-end__title {
  font-size: 24px;
  font-weight: 600;
  color: black;
  text-align: center;
}
.welcome-q-end__parent-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
}
@media only screen and (max-width: 481px) {
  .welcome-q-end {
    justify-content: center;
  }
  .welcome-q-end__title {
    font-size: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/WelcomeQuestionnaire/WelcomeQuestionnaireEnd/WelcomeQuestionnaireEnd.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAAR;AAGI;EApBJ;IAqBQ,uBAAA;EAAN;EAEM;IACI,eAAA;EAAV;AACF","sourcesContent":[".welcome-q-end {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    &__title {\n        font-size: 24px;\n        font-weight: 600;\n        color: black;\n        text-align: center;\n    }\n\n    &__parent-loader {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        width: 100%;\n        height: 20vh;\n    }\n\n    @media only screen and (max-width: 481px) {\n        justify-content: center;\n\n        &__title {\n            font-size: 15px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import './Banner.scss';
import { Image, Icon, NewModalLayout, Button } from '@components/common';
import { useAction, useTranslate } from '@helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setMockNotifier } from '@store/reducers/mockSlice';
import { setCourseNotifier } from '@store/reducers/courseSlice';
import { useEffect } from 'react';

export default function Banner({ isBannerOpen, setIsBannerOpen, type }) {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const {fetchMockBannerButtons, fetchCourseBannerButtons} = useAction();
    const {mock_banner_buttons, course_banner_buttons} = useSelector(state => state.directory);

    useEffect(() => {
        switch (type) {
            case 'mock':
                fetchMockBannerButtons();
                break;
            case 'course':
                fetchCourseBannerButtons();
                break;
            default:
                break;
        }
    }, [type]);

    const handleNavigateTo = (route) => {
        window.open(route, '_blank');
    }

    const handleClose = () => {
        setIsBannerOpen(false);
        switch (type) {
            case 'mock':
                dispatch(setMockNotifier(false));
                break;
            case 'course':
                dispatch(setCourseNotifier(false));
                break;
            default:
                break;
        }
    }
   
    return (
        <NewModalLayout className="banner_modal">
            <div className="banner">
                <div className="banner-content">
                    <h1>{t('Banner.TITLE')}</h1>
                    <Icon className="banner-content__close" name="close" handleClick={handleClose} />
                    <p>{t('Banner.TEXT')}</p>
                    <div className="btn-group">
                        {type === 'mock' && mock_banner_buttons && mock_banner_buttons?.map(button => 
                            <Button 
                                key={button.id}
                                className="btn btn--primary btn--pill"
                                text={button.button_link_text}
                                onClick={() => handleNavigateTo(button.button_link)}
                            />
                        )}
                        {type === 'course' && course_banner_buttons && course_banner_buttons?.map(button => 
                            <Button 
                                key={button.id}
                                className="btn btn--primary btn--pill"
                                text={button.button_link_text}
                                onClick={() => handleNavigateTo(button.button_link)}
                            />
                        )}
                    </div>
                </div>
                <div className="banner-image">
                    <Image name="for_banner.png" alt="Books" />
                </div>
            </div>
        </NewModalLayout>
    );
}

import {axiosRequest} from "@api/xhr";

export default class ProfileService {
    static async fetchProfile(id) {
        return await axiosRequest.get(`auth/profile/${id}/`);
    };

    static async updateProfile(id, data) {
        return await axiosRequest.patch(`auth/profile/${id}/`, data);
    };
    static async updateNewUserStatus() {
        return await axiosRequest.patch(`auth/update-new-user-status/`);
    };

    static async submitProftest(data) {
        return await axiosRequest.post(`directory/test-results/submit_test/`, data);
    };

    static async fetchProftestResults() {
        return await axiosRequest.get(`directory/test-results/get_test_result/`);
    };

    static async fetchFeedback() {
        return await axiosRequest.get(`auth/feedback/forms`);
    };

    static async submitFeedback(id, data) {
        return await axiosRequest.post(`auth/feedback/forms/${id}/save/`, data);
    };

    static async submitWaitlist(data) {
        return await axiosRequest.post(`message/`, data);
    };
};

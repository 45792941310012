import findStudyLevel from "./findStudyLevel";

const getCurrentStudyLevel = (studyLevel) => {
    if (studyLevel) {
        if (typeof studyLevel === "string") {
            const findSquareBrackets = studyLevel.search(/\[.*?\]/g);

            if (findSquareBrackets !== -1) {
                const currentStudyLevel = JSON.parse(studyLevel);
                return currentStudyLevel.sort((a, b) => a - b).map((p) => findStudyLevel(Number(p)));
            }

            try {
                const currentStudyLevel = studyLevel.split(", ");
                return currentStudyLevel.sort((a, b) => a - b).map((p) => findStudyLevel(Number(p)));
            } catch (e) {
                console.log(e);
            }
        }

        if (typeof studyLevel === "number") {
            return [findStudyLevel(Number(studyLevel))];
        }

        return studyLevel.sort((a, b) => a - b).map((p) => findStudyLevel(Number(p)));
    } else {
        return [];
    }
};

export default getCurrentStudyLevel;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
}

.dropdown {
  position: relative;
  border-radius: 12px;
}
.dropdown__chance {
  width: 100% !important;
}
.dropdown:hover {
  cursor: pointer;
}
.dropdown--border > .select {
  filter: none;
}
.dropdown--border > .select > .select-option {
  border: 1px solid #a0aec0;
}
.dropdown--border > .select > .select-list {
  border-top: none;
}
.dropdown--small > .select > .select-list > .option {
  padding: 10px 18px;
}
.dropdown--small > .select > .select-list > .option p {
  font-size: 14px;
}

.dropdown-wrapper--mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown--mobile {
  position: relative;
  width: 100%;
  border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Dropdown/Dropdown.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;;AAIA;EACE,kBAAA;EAGA,mBAAA;AAHF;AAKE;EACE,sBAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAQI;EACE,YAAA;AANN;AAQM;EACE,yBAAA;AANR;AASM;EACE,gBAAA;AAPR;AAiBM;EACE,kBAAA;AAfR;AAiBQ;EACE,eAAA;AAfV;;AAsBA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAnBF;;AAsBA;EACE,kBAAA;EACA,WAAA;EACA,mBAAA;AAnBF","sourcesContent":["@import \"@styles/variables\";\n\n.dropdown-wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 360px;\n}\n\n.dropdown {\n  position: relative;\n  // width: 100%;\n  // height: 100%;\n  border-radius: 12px;\n\n  &__chance {\n    width: 100% !important;\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &--border {\n    & > .select {\n      filter: none;\n\n      & > .select-option {\n        border: 1px solid $light_gray;\n      }\n\n      & > .select-list {\n        border-top: none;\n      }\n    }\n  }\n  \n\n  &--small {\n    & > .select {\n      // height: 40px;\n\n      & > .select-list > .option {\n        padding: 10px 18px;\n\n        p {\n          font-size: 14px;\n        }\n      }\n    }\n  }\n}\n\n.dropdown-wrapper--mobile {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.dropdown--mobile {\n  position: relative;\n  width: 100%;\n  border-radius: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

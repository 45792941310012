import classNames from "classnames";
import { Icon } from "../Icon";
import "./Checkbox.scss";

export default function CheckBox({ className, labelClassName, label, isCorrect, ...otherProps }) {
  return (
    <div className={classNames("checkbox", className)}>
      <label className={"checkbox__label"} htmlFor={otherProps.id}>
        <input type={"checkbox"} className={"checkbox__input"} {...otherProps} />
        <span className={"checkbox__box"} />
        <div className="checkbox__line">
          <p className={labelClassName ? labelClassName : "checkbox__text"}>{label}</p>
          {isCorrect && <Icon name={"correct_circle"} className="correct-answer" />}
        </div>
      </label>
    </div>
  );
}

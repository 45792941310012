import React from "react";
import { NewModalLayout, Icon } from "@components/common";
import { useTranslate } from "@helpers/hooks";

const BuyMockModal = ({ isMobile, buyModalItem, onClose, handleRedirectToKaspi }) => {
  const discountedPrice = 10000;
  const { t } = useTranslate();

  const calculateDiscountedPrice = (sum) =>
    ((sum / discountedPrice - 1) * 100).toFixed(0);

  return (
    <NewModalLayout
      className={`pop-up ${
        isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
      }`}
    >
      <div className="tariff__title-button">
        <h3 className="">{t("KASPI.MOCK_TITLE")}</h3>
        <Icon
          name="close-rounded"
          className="lms-badge-profile__close"
          handleClick={onClose}
        />
      </div>
      <div className="mock__card-popup">
        <div className="mock__card-popup-top">
          <div className="mock__card-popup-info">
            <h3>{buyModalItem.title}</h3>
            <div className="mock__card-popup-price">
              <del>{discountedPrice} ₸</del>
              <p>{buyModalItem.sum} ₸</p>
            </div>
          </div>
          <div className="discount-circle">
            {calculateDiscountedPrice(buyModalItem.sum)}%
          </div>
        </div>
        <div className="mock__card-popup-mid">
          <Icon name="help-info" />
          <div className="mock__card-popup-mid-info">
            <h4 className="">{t("KASPI.GUIDE_TITLE")}</h4>
            <ol>
              <li>{t("KASPI.GUIDE_ONE")}</li>
              <li>{t("KASPI.GUIDE_TWO")}</li>
              <li>{t("KASPI.GUIDE_THREE")}</li>
            </ol>
          </div>
        </div>
        <div className="mock__card-popup-bottom">
          <button
            className="btn btn--red btn--pill"
            onClick={() => handleRedirectToKaspi(buyModalItem)}
          >
            <Icon name="kaspipay" />
          </button>
        </div>
      </div>
    </NewModalLayout>
  );
};

export default BuyMockModal;

import { Icon, List } from "@components/common";
import React from "react";
import "./MockProgress.scss";

export default function MockProgress({sections, activeSection, completedSections, progress}) {

  return (
    <div className="progress-bar-wrapper">
      <List
        className={"progress"}
        items={sections}
        renderItem={(item) => (
          <li
            key={item.name}
            className={`progress__circle ${item.name === activeSection ? "progress__active" : ""} ${
              completedSections.includes(item.name) ? "progress__completed" : ""
            }`}
          >
            <Icon
              name={item.icon}
              className={item.name === activeSection || completedSections.includes(item.name) ? "icon--white" : ""}
            />
          </li>
        )}
      />
      <div className="progress-bar-container">
        {Array.from({ length: 3 }).map((_, i) => (
          <div key={i} className={`progress-bar-section ${i < progress ? "blue" : "transparent"}`}></div>
        ))}
      </div>
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-time-visit {
  padding: 20px;
  border-radius: 12px;
}
.first-time-visit__header {
  display: flex;
  justify-content: space-between;
}
.first-time-visit__close {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
}
.first-time-visit h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  width: 80%;
}
.first-time-visit p {
  font-weight: 400;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 638px) {
  .first-time-visit {
    overflow-y: scroll;
  }
  .first-time-visit h1 {
    font-size: 1.2rem;
  }
  .first-time-visit p {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 340px) {
  .first-time-visit h1 {
    font-size: 0.8rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/FirstTimeVisitPopup/FirstTimeVisitPopup.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;AACR;AAEI;EACI,eAAA;EACA,wBAAA;EAAA,mBAAA;AAAR;AAGI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;AADR;AAII;EACI,gBAAA;EACA,mBAAA;AAFR;AAKI;EA1BJ;IA2BQ,kBAAA;EAFN;EAIM;IACI,iBAAA;EAFV;EAKM;IACI,iBAAA;EAHV;AACF;AAMI;EACI;IACI,iBAAA;EAJV;AACF","sourcesContent":[".first-time-visit {\n    padding: 20px;\n    border-radius: 12px;\n\n    &__header {\n        display: flex;\n        justify-content: space-between;\n    }\n\n    &__close {\n        cursor: pointer;\n        height: fit-content;\n    }\n\n    h1 {\n        font-size: 2rem;\n        font-weight: 700;\n        margin-bottom: 1rem;\n        width: 80%;\n    }\n\n    p {\n        font-weight: 400;\n        margin-bottom: 1rem;\n    }\n\n    @media only screen and (max-width: 638px) {\n        overflow-y: scroll;\n\n        h1 {\n            font-size: 1.2rem;\n        }\n\n        p {\n            font-size: 0.8rem;\n        }\n    }\n\n    @media only screen and (max-width: 340px) {\n        h1 {\n            font-size: 0.8rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Button, Icon } from "@components/common";
import ReactPlayer from "react-player";
import { useTranslate, useAction } from "@helpers/hooks";
import "./LessonVideoPlayer.scss";
import { LessonCompletion } from "../LessonCompletion";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Confetti from "react-confetti";
import { useState } from "react";

export default function LessonVideoPlayer({ lesson, handleAddNote, playerRef, videoRef, updateHeight }) {
  const { t } = useTranslate();
  const { courseId, sectionId, lessonId } = useParams();
  const { changeLessonStatus, fetchBadge } = useAction();
  const [showConfetti, setShowConfetti] = useState(false);
  useEffect(() => {
    if (lesson.completed) {
      fetchBadge();
    }
  }, [lesson.completed]);
  
  const handleProgress = (progress) => {
    if (progress.played >= 0.95 && progress.played <= 0.951) {
      changeLessonStatus(lessonId);
      setShowConfetti(true);  
    }
  };

  return (
    <div className="player__container">
      <div className="player__video" ref={videoRef}>
        {lesson && lesson?.content[0]?.content_media?.file && (
          <ReactPlayer
            className="player__video"
            url={lesson["content"][0]["content_media"]["file"]}
            ref={playerRef}
            width="100%"
            height="100%"
            controls={true}
            onReady={updateHeight}
            onProgress={handleProgress}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  onContextMenu: (e) => e.preventDefault(),
                },
              },
            }}
          />
        )}
      </div>
      <div className="player__buttons">
        <Button
          className="btn--transparent"
          iconLeft={<Icon name={"lesson-review"} />}
          text={t("LMS.BUTTON.ADD_NOTE")}
          onClick={() => handleAddNote()}
        />
      </div>
      <LessonCompletion courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />
      {showConfetti && (
        <Confetti
          width={1100}
          height={700}
          numberOfPieces={500}
          recycle={false}
        />
      )}
    </div>
  );
}

import { axiosRequest } from "@api/xhr";

export default class MockService {
  static async fetchMockExams() {
    return await axiosRequest.get("lms/mockexams/");
  }

  static async fetchMockExam(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/`);
  }

  static async fetchCrit(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/crit/`);
  }

  static async fetchMath(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/math/`);
  }

  static async submitMath(id, data) {
    return await axiosRequest.post(`lms/nuet/${id}/math/save/`, data);
  }

  static async submitCrit(id, data) {
    return await axiosRequest.post(`lms/nuet/${id}/crit/save/`, data);
  }

  static async getProgress(id) {
    return await axiosRequest.get(`lms/nuet/${id}/progress/`);
  }

  static async fetchVerbalOne(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/verbal1/`);
  }

  static async fetchVerbalTwo(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/verbal2/`);
  }

  static async fetchMathOne(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/math1/`);
  }

  static async fetchMathTwo(id) {
    return await axiosRequest.get(`lms/mockexams/${id}/math2/`);
  }

  static async getSatProgress(id) {
    return await axiosRequest.get(`lms/sat/${id}/progress/`);
  }

  static async submitVerbalOne(id, data) {
    return await axiosRequest.post(`lms/sat/${id}/verbal1/save/`, data);
  }

  static async submitVerbalTwo(id, data) {
    return await axiosRequest.post(`lms/sat/${id}/verbal2/save/`, data);
  }

  static async submitMathOne(id, data) {
    return await axiosRequest.post(`lms/sat/${id}/math1/save/`, data);
  }

  static async submitMathTwo(id, data) {
    return await axiosRequest.post(`lms/sat/${id}/math2/save/`, data);
  }

  static async getIeltsProgress(id) {
    return await axiosRequest.get(`lms/ielts/${id}/progress`);
  }

  static async submitListening(id, data) {
    return await axiosRequest.post(
      `lms/mockexams/ielts_mock/${id}/listening/save/`,
      data
    );
  }

  static async submitReading(id, data) {
    return await axiosRequest.post(
      `lms/mockexams/ielts_mock/${id}/reading/save/`,
      data
    );
  }

  static async submitSpeaking(id, data) {
    return await axiosRequest.post(
      `lms/mockexams/ielts_mock/${id}/speaking/save/`,
      data
    );
  }

  static async submitWriting(id, data) {
    return await axiosRequest.post(
      `lms/mockexams/ielts_mock/${id}/writing/save/`,
      data
    );
  }

  static async fetchMockListening(id) {
    return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/listening/`);
  }

  static async fetchMockReading(id) {
    return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/reading/`);
  }

  static async fetchMockWriting(id) {
    return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/writing/`);
  }

  static async fetchMockSpeaking(id) {
    return await axiosRequest.get(`lms/mockexams/ielts_mock/${id}/speaking/`);
  }

  static async fetchUncheckedIeltsMockResponses() {
    return await axiosRequest.get(`lms_admin/ielts_mock/grades/`);
  }

  static async fetchIeltsMockGrades(id) {
    return await axiosRequest.get(`lms_admin/ielts_mock/grades/${id}`);
  }

  static async fetchIeltsMockResponse(userId, mockId) {
    return await axiosRequest.get(
      `lms_admin/ielts_mock/responses/user/${userId}/mock/${mockId}/`
    );
  }

  static async updateIeltsMockGrade(userId, mockId, data) {
    return await axiosRequest.patch(
      `lms_admin/ielts_mock/grades/${mockId}/user/${userId}/`,
      data
    );
  }

  static async fetchNuetMockGrades(id) {
    return await axiosRequest.get(`lms_admin/nuet_mock/grades/${id}`);
  }

  static async fetchSatMockGrades(id) {
    return await axiosRequest.get(`lms_admin/sat_mock/grades/${id}`);
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-profile-info-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.dashboard-profile-info-modal__header h2 {
  font-size: 24px;
}

.dashboard__modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Dashboard/DashboardProfileInfoModal/DashboardProfileInfoModal.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACI;EACE,eAAA;AACN;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF","sourcesContent":[".dashboard-profile-info-modal {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  &__header {\n    h2 {\n      font-size: 24px;\n    }\n  }\n}\n\n.dashboard__modal-body {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Image, List, PdfView } from "@components/common";
import { LessonMarkdown } from "../LessonMarkdown";
import "./LessonContent.scss";

export default function LessonContent({ lesson }) {
  
  const renderItem = (item) => {
    switch (item.type) {
      case "content_text":
        return <LessonMarkdown key={item.content_text.content_id} lesson={lesson} />;
      case "content_media":
        switch (item.content_media.type) {
          case "image":
            return <Image key={item.content_media.content_id} src={item.content_media.file} className={"content__image"} />;
          case "pdf":
          case "resource":
            return <PdfView key={item.content_media.content_id} link={item.content_media.file} className="content__pdf" />;
          default:
            return;
        }
      default:
        return;
    }
  };

  return (
    <div>
      <List className={"content__group"} items={lesson.content} renderItem={renderItem} />
    </div>
  );
}
import { LayoutContainer } from '@components/common';
import { GeneralEssays, UniversitySpecificEssays } from '@components/feature';
import './EssayContent.scss';

const EssayContent = (props) => {
    const {
        currentEssayTab,
        onSelectEssay,
        onEditEssay,
        isEditOpenInEssays,
        setIsEditOpenInEssays,
        essayId,
        TABS
    } = props;
    return (
        <div className="essays__layout">
            <LayoutContainer>
                {currentEssayTab.value === TABS.GENERAL_ESSAYS.value && 
                <GeneralEssays
                    currentEssayTab={currentEssayTab}
                    onSelectEssay={onSelectEssay}
                    onEditEssay={onEditEssay}
                    isEditOpenInEssays={isEditOpenInEssays}
                    setIsEditOpenInEssays={setIsEditOpenInEssays}
                    essayId={essayId}
                />
                }
                {currentEssayTab.value === TABS.UNIVERSITY_SPECIFIC_ESSAYS.value &&
                <UniversitySpecificEssays
                    currentEssayTab={currentEssayTab}
                    onSelectEssay={onSelectEssay}
                    onEditEssay={onEditEssay}
                    isEditOpenInEssays={isEditOpenInEssays}
                    setIsEditOpenInEssays={setIsEditOpenInEssays}
                    essayId={essayId}
                />
                }
            </LayoutContainer>
        </div>
    );
}

export default EssayContent;

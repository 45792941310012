import { useTranslate } from "@helpers/hooks";
import "./Author.scss";

export default function Author({ author }) {
  const { t } = useTranslate();
  return (
    <div className="course-author">
      {author.profile_photo && (
        <img
          className="course-author-avatar"
          src={author.profile_photo}
          alt="avatar"
        />
      )}
      <div>
        <p className="course-author-about">{author.about}</p>
        <p className="course-author-name">{author.name}</p>
      </div>
    </div>
  );
}

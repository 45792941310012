import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkEmoji from 'remark-emoji';
import { formatData } from "@utils/functions";
import { useAction, useTranslate } from "@helpers/hooks";
import { Button, CustomLink, FormItem } from "@components/common";
import './WorkshopContent.scss';

const WorkshopContent = ({workshop, workshopId}) => {
    const {t} = useTranslate(),
    { isSuccessCode } = useSelector((state) => state.courses),
    { postAttendanceQuizCode } = useAction(),
    [attendanceQuizCode, setAttendanceQuizCode] = useState(""),
    [isInputVisible, setIsInputVisible] = useState(false),
    [isButtonDisabled, setIsButtonDisabled] = useState(true),
    CODE_INPUT = {
        id: 1,
        label: t("LMS.ATTENDANCE_QUIZ_CODE"),
        inputClassName: "workshop__input",
        labelClassName: "workshop__label",
        field: "input",
        name: "attendance-quiz-code",
        type: "text",
        value: attendanceQuizCode,
    }
        
    const handleChange = (e) => {
        setAttendanceQuizCode(e.target.value);
    }

    const handleSendCode = async () => {
        await postAttendanceQuizCode({workshopId: +workshopId, code: attendanceQuizCode});
    }

    useEffect(() => {
        const cleanCode = attendanceQuizCode.trim();
        setIsButtonDisabled(cleanCode.length <= 0);
    }, [attendanceQuizCode]);

    useEffect(() => {
        const targetDatetime = new Date(workshop?.datetime);
        const now = new Date();
        const twoHoursInMillis = 2 * 60 * 60 * 1000;
        const timeDifference = now - targetDatetime + (now.getTimezoneOffset() - targetDatetime.getTimezoneOffset()) * 60 * 1000;
        setIsInputVisible(timeDifference < twoHoursInMillis && now >= targetDatetime);
      }, [workshop?.datetime]);

    return (
        <div>
            <h3 className="workshop__title">{workshop?.title}</h3>
            <p className="workshop__datetime">{formatData(workshop?.datetime, 4)}</p>
            <div className="workshop__description">
                <ReactMarkdown remarkPlugins={[remarkGfm, remarkEmoji]}>
                    {workshop?.description}
                </ReactMarkdown>
            </div>
            <div className="workshop__link">
                <p>{t("LMS.LINK")}</p>
                <CustomLink 
                    to={workshop?.meet_link}
                    className="workshop__hyperlink" 
                    isExternal={true}
                >
                    {workshop?.meet_link}
                </CustomLink>
            </div>
            {isInputVisible && !isSuccessCode && !workshop?.attendance[0]?.attended && (
                <>
                    <FormItem item={CODE_INPUT} handleChange={handleChange} />
                    <Button
                        className="workshop__btn-submit btn--primary btn-pill"
                        text="Submit"
                        onClick={handleSendCode}
                        disabled={isButtonDisabled}
                    />
                </>
            )}
            {
                workshop && workshop?.recording && 
                <div className="workshop__video-container">
                    <h1 className="workshop__recording">{t("LMS.RECORDING")}</h1>
                    <ReactPlayer
                        className="player__video"
                        url={workshop && workshop?.recording}
                        width="100%"
                        height="100%"
                        controls={true}
                        config={{
                            file: {
                                attributes: {
                                controlsList: "nodownload",
                                onContextMenu: (e) => e.preventDefault(),
                                },
                            },
                        }}
                    />
                </div>
            }
        </div>
    );
}

export default WorkshopContent;

import React from "react";
import { NewModalLayout, Icon, List } from "@components/common";
import "./PopupTariffs.scss";
import { PopupTariffsCard } from "@components/feature";
import { useIsMobile } from "@helpers/hooks";

export default function PopupTariffs({ handleClose, paymentInfo, course }) {

  const isMobile = useIsMobile()

  return (
    <NewModalLayout className={`pop-up ${isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`}`}>
      <div className="tariff__title-button">
        <h3 className="">Выберите тариф</h3>
        <Icon name="close-rounded" className="lms-badge-profile__close" handleClick={handleClose} />
      </div>

      <List
        className={isMobile ? "tariff__list-mobile" :"tariff__list"}
        items={paymentInfo}
        renderItem={(tariff) => (
          <PopupTariffsCard key={tariff.plan_type} tariff={tariff} course={course}/>
        )}
      />
    </NewModalLayout>
  );
}
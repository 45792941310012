import { Image, List } from "@components/common";
import React from "react";
import "./ImageQuestion.scss";

export default function ImageQuestion({ question }) {
  return (
    <div>
      <List
        className={"quiz__images"}
        items={question.question_images.images_store}
        renderItem={(item) => <Image key={item.id} className={"quiz__image"} src={item.image} />}
      />
    </div>
  );
}

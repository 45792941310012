// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-modal__image {
  width: 100%;
  border-radius: 10px;
}
.update-modal__title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 768px) {
  .update-modal__title {
    margin-top: 10px;
    font-size: 16px;
  }
}
.update-modal__close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .update-modal__close {
    top: 20px;
    right: 20px;
  }
}
.update-modal__content {
  padding: 10px;
}

@media (max-width: 768px) {
  .add_modal_content {
    padding: 10px !important;
    border-radius: 20px !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/UpdateModal/UpdateModal.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAGI;EAPF;IAQI,gBAAA;IACA,eAAA;EAAJ;AACF;AAGE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AADJ;AAGI;EANF;IAOI,SAAA;IACA,WAAA;EAAJ;AACF;AAGE;EACE,aAAA;AADJ;;AAQA;EACE;IACE,wBAAA;IACA,8BAAA;IACA,mCAAA;IAAA,8BAAA;EALF;AACF","sourcesContent":[".update-modal {\n  &__image {\n    width: 100%;\n    border-radius: 10px;\n  }\n\n  &__title {\n    margin-top: 10px;\n    margin-bottom: 20px;  \n    font-size: 20px;\n    font-weight: 700;\n    text-align: center;\n\n    @media (max-width: 768px) {\n      margin-top: 10px;\n      font-size: 16px;\n    }\n  }\n\n  &__close {\n    position: absolute;\n    top: 30px;\n    right: 30px;\n    cursor: pointer;\n\n    @media (max-width: 768px) {\n      top: 20px;\n      right: 20px;\n    }\n  }\n\n  &__content {\n    padding: 10px;\n\n  }\n}\n\n\n\n@media (max-width: 768px) {\n  .add_modal_content {\n    padding: 10px !important;\n    border-radius: 20px !important;\n    height: fit-content !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useTranslate } from "@helpers/hooks";
import "./RecommendationTabs.scss";

const RecommendationTabs = ({ handleChangeTab, currentTab, tabs }) => {
  const { t } = useTranslate();
  return (
    <div className="recommendation-tabs">
      <div
        className={`recommendation-tabs-option${currentTab === tabs.myPreference ? "-selected" : ""}`}
        onClick={() => handleChangeTab(tabs.myPreference)}
      >
        {t("RECOMMENDATIONS.TABS.LABEL_1")}
      </div>
      <div
        className={`recommendation-tabs-option${currentTab === tabs.recommendedUniversities ? "-selected" : ""}`}
        onClick={() => handleChangeTab(tabs.recommendedUniversities)}
      >
        {t("RECOMMENDATIONS.TABS.LABEL_2")}
      </div>
    </div>
  );
};

export default RecommendationTabs;

const getAcceptanceRate = (acceptanceRate) => {
    if (acceptanceRate) {
        if (Number.isInteger(acceptanceRate)) {
            return `${acceptanceRate}%`;
        }

        if (!Number.isNaN(acceptanceRate)) {
            return `${acceptanceRate}%`;
        }

        return acceptanceRate;
    } else {
        return null;
    }
};

export default getAcceptanceRate;
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {useTranslate} from "@helpers/hooks";
import {ButtonV2, FormItem, List} from "@components/common";
import "./PreferencesShort.scss";

export default function PreferencesShort({handleChangeTab}) {
    const {t} = useTranslate(),
    {myPrefer} = useSelector(state => state.questionnaire),
    PREFERENCES = useMemo(() => [
        {
            id: 1,
            label: "PROFILE.PREFERENCES.LABEL_1",
            field: "input",
            name: "countries",
            value: (idx) => myPrefer?.countries[idx]?.name,
            isDisabled: true,
        },
        {
            id: 2,
            label: "PROFILE.PREFERENCES.LABEL_2",
            field: "input",
            name: "subjects",
            value: myPrefer?.subjects[0]?.name,
            isDisabled: true,
        },
      ], [myPrefer, t]);
  return (
    <div className="preferences_short">
      <div className="preferences_short_header">
        <p className="preferences_short_text">{t("PROFILE.PREFERENCES.TITLE")}</p>
        <ButtonV2
          className="button--transparent"
          text={t("BUTTON.EDIT")}
          onClick={handleChangeTab}
          iconLeft="edit-filled"
          />
      </div>
      <List
        className="preferences_short_list"
        items={PREFERENCES}
        renderItem={(item) => (
          item.id == 1 ?
          
            <div key={item.id}>
              <FormItem
                  item={{
                      ...item, 
                      value: item.value(0)}}/>
            </div>
           :
            <FormItem
              key={item.id}
              item={item}/>)}
        />
    </div>
  )
}
import { combineReducers } from "@reduxjs/toolkit";
import directoryReducer from "./directorySlice";
import essaysReducer from "./essaysSlice";
import profileReducer from "./profileSlice";
import programReducer from "./programSlice";
import questionnaireReducer from "./questionnaireSlice";
import universityReducer from "./universitySlice";
import courseSlice from "./courseSlice";
import blogSlice from "./blogSlice";
import scholarshipsSlice from "./scholarshipsSlice";
import mockSlice from "./mockSlice";
import challengeSlice from "./challengeSlice";
import coinSlice from "./coinSlice";
import notificationSlice from "./notificationSlice";

const rootReducer = combineReducers({
  profile: profileReducer,
  directory: directoryReducer,
  university: universityReducer,
  program: programReducer,
  questionnaire: questionnaireReducer,
  essays: essaysReducer,
  courses: courseSlice,
  blog: blogSlice,
  scholarships: scholarshipsSlice,
  mocks: mockSlice,
  challenges: challengeSlice,
  coins: coinSlice,
  notifications: notificationSlice,
});

export default rootReducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grade__acc-item {
  display: flex;
  column-gap: 16px;
  cursor: pointer;
}
.grade__sub-item {
  display: flex;
}
.grade__sub-item > p:nth-child(1) {
  padding: 12px 56px;
  width: 70%;
}
.grade__sub-item > p:nth-child(2) {
  padding: 12px 24px;
  width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Courses/CourseGradesMockItem/CourseGradesMockItem.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,gBAAA;EACA,eAAA;AADR;AAII;EACI,aAAA;AAFR;AAKY;EACI,kBAAA;EACA,UAAA;AAHhB;AAMY;EACI,kBAAA;EACA,UAAA;AAJhB","sourcesContent":[".grade {\n\n    &__acc-item {\n        display: flex;\n        column-gap: 16px;\n        cursor: pointer;\n    }\n\n    &__sub-item {\n        display: flex;\n\n        & > p {\n            &:nth-child(1) {\n                padding: 12px 56px;\n                width: 70%;\n            }\n            \n            &:nth-child(2) {\n                padding: 12px 24px;\n                width: 30%;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

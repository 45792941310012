const findWithOne = (firstField, secondField) => {
    if (firstField && secondField) {
        return `${firstField} / ${secondField}`;
    }

    if (firstField) {
        return firstField;
    }

    if (secondField) {
        return firstField;
    }

    return "";
};

export default findWithOne;
import Skeleton from "react-loading-skeleton";
import "./DashboardSkeleton.scss";

export default function DashboardSkeleton() {
    return (
        <div className={"dashboard-skeleton"}>
            <div className={"dashboard-skeleton__program"}>
                <div className={"dashboard-skeleton__title"}>
                    <Skeleton/>
                </div>
                <div className={"dashboard-skeleton__select"}>
                    <Skeleton/>
                </div>
            </div>
            <div className={"dashboard-skeleton__radar"}>
                <Skeleton/>
            </div>
            <div className={"dashboard-skeleton__list"}>
                <div className={"dashboard-skeleton__field"}>
                    <Skeleton count={3}/>
                </div>
                <div className={"dashboard-skeleton__value"}>
                    <Skeleton count={3}/>
                </div>
            </div>
        </div>
    );
};

  const getInitialDuration = (section) => {
    switch (section) {
      case "listening":
        return 3600;
      case "reading":
        return 3600;
      case "writing":
        return 3600;
      default:
        return 1200;
    }
  };


  export default getInitialDuration
import { Button, Input, NewModalLayout } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./DashboardProfileInfoModal.scss";

export default function DashboardProfileInfoModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({});
  const { profile } = useSelector((state) => state.profile);
  const { updateProfile } = useAction();

  const { t } = useTranslate();

  useEffect(() => {
    if (profile?.is_nis) {
      setIsOpen(true);
    }
  }, [profile?.is_nis]);

  const onSubmit = async () => {
    await updateProfile({
      id: profile.id,
      data: { class_level: values.class_level },
    });
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <NewModalLayout>
          <div className="dashboard-profile-info-modal">
            <div className="dashboard-profile-info-modal__header">
              <h2>{t("DASHBOARD.CLASS_LEVEL_PLACEHOLDER")}</h2>
            </div>
            <div className="dashboard__modal-body">
              <Input
                label={t("DASHBOARD.CLASS_LEVEL")}
                name="class_level"
                placeholder="1-12"
                value={values.class_level}
                onChange={(e) =>
                  setValues({ ...values, class_level: e.target.value })
                }
              />
              <Button
                className="btn--primary btn--pill"
                onClick={onSubmit}
                text={t("BUTTON.SAVE")}
              />
            </div>
          </div>
        </NewModalLayout>
      )}
    </>
  );
}

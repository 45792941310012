import React, { useState } from "react";
import { useTranslate } from "@helpers/hooks";
import { List } from "@components/common";
import { filterItemsWithValues } from "@utils/functions";

export default function OverviewIntro({ universityInfo, studentsInfo }) {
  const { t } = useTranslate();
  const [expandedTextId, setExpandedTextId] = useState(null);

  const filteredAbout = filterItemsWithValues(universityInfo.about);
  const filteredHeader = filterItemsWithValues(universityInfo.header);
  const filteredInfo = filterItemsWithValues(universityInfo.info);

  const infoItems = [
    {
      id: 1,
      number: studentsInfo.inter,
      text: "RECOMMENDATIONS.OVERVIEW.LABEL_16",
      percent: studentsInfo.interPercent,
      isTotal: false,
    },
    {
      id: 2,
      number: studentsInfo.total,
      text: "RECOMMENDATIONS.OVERVIEW.LABEL_15",
      percent: 100,
      isTotal: true,
    },
  ];

  const barStyle = { width: `${studentsInfo.interPercent}%` };

  function toggleText(id) {
    setExpandedTextId((prevId) => (prevId === id ? null : id));
  }

  function getFirstSentence(text) {
    return text.split(".")[0] + ".";
  }

  return (
    <div>
      <div className="overview__content">
        {filteredAbout.map((item) => {
          const firstSentence = getFirstSentence(item.value);
          const isExpanded = item.id === expandedTextId;

          return (
            <div key={item.id}>
              <p>
                {isExpanded ? item.value : firstSentence}{" "}
                <span className="overview__toggle" onClick={() => toggleText(item.id)}>
                  {isExpanded ? `[${t("BUTTON.VIEW_MORE")}]` : `[${t("BUTTON.VIEW_LESS")}]`}
                </span>
              </p>
            </div>
          );
        })}
      </div>

      <List
        className="overview__header"
        items={filteredHeader}
        renderItem={(item) => (
          <div className="overview__header-item" key={item.id}>
            <h3>{item.title}</h3>
            <p>{item.value}</p>
          </div>
        )}
      />

      {studentsInfo.total && (
        <div className="overview__enrollment">
          <h4 className="overview__enrollment-title">{t("RECOMMENDATIONS.OVERVIEW.LABEL_14")}</h4>
          <div className="overview__bar-total">
            <div className="overview__bar-inter" style={barStyle}></div>
          </div>

          <div className="overview__enrollment-info">
            <List
              className="overview__enrollment-info"
              items={infoItems}
              renderItem={(item) => (
                <div key={item.id} className="overview__enrollment-info-item">
                  <div
                    className={`overview__enrollment-info-circle ${
                      item.isTotal ? "total" : "inter"
                    }`}
                  />
                  <p className="overview__enrollment-info-text">
                    {t(item.text)}: <span className="students__number">{item.number}</span>{" "}
                    {!item.isTotal && `(${item.percent}%)`}
                  </p>
                </div>
              )}
            />
          </div>
        </div>
      )}

      {filteredInfo.length > 0 && <List
        className="overview__info"
        items={filteredInfo}
        renderItem={(item) => (
          <div className="overview__info-item" key={item.id}>
            <p>{item.title}</p>
            <h5>{item.value}</h5>
          </div>
        )}
      />}
    </div>
  );
}

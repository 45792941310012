import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Icon } from "@components/common";
import "./SentryFeedback.scss";
import { useIsMobile } from "@helpers/hooks";

export default function SentryFeedback() {
  const [feedback, setFeedback] = useState();
  const isMobile = useIsMobile();
  useEffect(() => {
    setFeedback(Sentry.getFeedback());
  }, []);

  if (!feedback) {
    return null;
  }

  return (
    <button
      className="feedback-button"
      type="button"
      onClick={async () => {
        const form = await feedback.createForm();
        form.appendToDom();
        form.open();
      }}
    >
      <Icon className="feedback-button__icon" name="bug" />
      {!isMobile && "Report a bug"}
    </button>
  );
}

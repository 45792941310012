import React from "react";
import { BtnLoader } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import "./AdminMockSatTable.scss";

export default function AdminMockSatTable({ mockGrades, isLoading }) {
  const { t } = useTranslate();

  return (
    <div className="mock-admin">
      {mockGrades?.length > 0 ? (
        <div className="mock-table">
          <div className="mock-table__header">
            <div className="mock-table__cell--student">Student</div>
            <div className="mock-table__cell">Math 1</div>
            <div className="mock-table__cell">Math 2</div>
            <div className="mock-table__cell">Verbal 1</div>
            <div className="mock-table__cell">Verbal 2</div>
            <div className="mock-table__cell">Total</div>
            <div className="mock-table__cell">Date</div>
          </div>
          {mockGrades.map((grade) => {
            const totalScore =
              (grade.module_math1_score || 0) +
              (grade.module_math2_score || 0) +
              (grade.module_verbal1_score || 0) +
              (grade.module_verbal2_score || 0);

            return (
              <div key={grade.user_id} className="mock-table__row">
                <div className="mock-table__cell--student">
                  <img
                    src={grade.user_image}
                    alt={grade.user_name}
                    className="mock-table__user-image"
                  />
                  <span>{grade.user_name}</span>
                </div>
                <div className="mock-table__cell">{grade.module_math1_score}</div>
                <div className="mock-table__cell">{grade.module_math2_score}</div>
                <div className="mock-table__cell">{grade.module_verbal1_score}</div>
                <div className="mock-table__cell">{grade.module_verbal2_score}</div>
                <div className="mock-table__cell">{totalScore}</div>
                <div className="mock-table__cell">
                  {new Date(grade.datetime).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        isLoading && <BtnLoader className="btn-loader-bg-large" />
      )}
    </div>
  );
}

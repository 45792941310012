import {Fragment} from "react";
import {Icon} from "@components/common";
import classNames from "classnames";
import "./ButtonV2.scss";

export default function ButtonV2(props) {
    const {
        className, text, iconLeft, iconRight, ...otherProps
    } = props;
    return (
        <button
            className={classNames("button", className)}
            {...otherProps}
        >
            <Fragment>
              {iconLeft && <div className={"button__icon--left"}>
                <Icon className={className === "button--transparent" || className === "button--transparent-border" ? "icon--blue": "icon--white"} name={iconLeft}/>
              </div>}
              {text}
              {iconRight && <div className={"button__icon--right"}>
                <Icon className={className === "button--transparent" ? "icon--blue": "icon--white"} name={iconRight}/>
              </div>}
            </Fragment>
        </button>
    );
};
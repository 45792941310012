import { Icon } from "@components/common";
import "./HeaderNotifications.scss";
import { useState } from "react";
import { NotificationsSidebar } from "@components/feature/Notifications";
import { useEffect } from "react";
import { useAction } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export default function HeaderNotifications() {
  const [isOpen, setIsOpen] = useState(false);
  const { getMyNotifications } = useAction();
  const { notifications } = useSelector((state) => state.notifications || {});

  useEffect(() => {
    getMyNotifications();
    const intervalId = setInterval(() => {
      getMyNotifications();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const unreadNotifs = useMemo(
    () => notifications.filter((notif) => !notif.is_read),
    [notifications]
  );

  return (
    <>
      {" "}
      {isOpen && (
        <NotificationsSidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      )}{" "}
      <div
        className="header__notif"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Icon name={"inbox"} />

        {unreadNotifs?.length > 0 && (
          <div className="header__notif-num">{unreadNotifs?.length}</div>
        )}
      </div>
    </>
  );
}

import { useTranslate } from "@helpers/hooks";
import classNames from "classnames";
import "./NotFilledTab.scss";

export default function NotFilledTab({
  className,
  tab,
  isSelected,
  setSelected,
}) {
  const { t } = useTranslate();

  const selectTabHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelected(tab);
  };
  return (
    <li
      className={classNames("not-filled-tab", className, {
        active: isSelected,
      })}
      onClick={selectTabHandler}
    >
      <p className={"not-filled-tab__text"}>{t(tab)}</p>
    </li>
  );
}

import {useCallback} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {useTranslate} from "@helpers/hooks";
import {Activities, Education, Exams, Preferences, Profile} from "@components/feature";
import "./TabsLayout.scss"


export default function TabsLayout({selectedTab, tabs, handleOnClick}) {
  const {t} = useTranslate(),
    {myEducation, myAdditionalInfo} = useSelector(state => state.questionnaire),
    {profile} = useSelector(state => state.profile);

  const getCurrentStep = useCallback(() => {
    switch (selectedTab) {
      case (tabs.profile):
        return 1;
      case (tabs.education):
        return 2;
      case (tabs.exams):
        return 3;
      case (tabs.activites):
        return 4;
      case (tabs.preferences):
        return 5;
      default:
        return 5;
    }
  }, [tabs, selectedTab]);

  return (
    <>
      {[tabs.profile, tabs.education, tabs.exams].includes(selectedTab) &&
        <>
          <p className="profile__title">{t(`PROFILE.${selectedTab}`)}</p>
        </>
      }
      {[tabs.preferences].includes(selectedTab) &&
        <>
          <p className="profile__title">{t(`PROFILE.${selectedTab}.TITLE`)}</p>
        </>
      }
      <div className="profile_steps">
        <p className="progress_text">{t('STEP')} {getCurrentStep()}/5</p>
        <div className={classNames("progress_steps", `progress_steps__step_${getCurrentStep()}`)}></div>
        <p className="profile__placeholder">{t("PROFILE.TAB_PLACEHOLDER")}</p>
      </div>
      {selectedTab === tabs.profile && <Profile profile={profile} handleOnClick={handleOnClick(tabs.education)} />}
      {selectedTab === tabs.education && <Education profile={profile} myEducation={myEducation} handleOnClick={handleOnClick(tabs.exams)} />}
      {selectedTab === tabs.exams && <Exams myEducation={myEducation} handleOnClick={handleOnClick(tabs.activites)} />}
      {selectedTab === tabs.activites && <Activities profile={profile} myAdditionalInfo={myAdditionalInfo} handleOnClick={handleOnClick(tabs.preferences)} />}
      {selectedTab === tabs.preferences && <Preferences handleOnClick={handleOnClick(tabs.final)} />}
    </>
  )
}
import {useTranslate} from "@helpers/hooks";
import {Tag} from "@components/common";
import "./AutoCompleteTag.scss";

export default function AutoCompleteTag({selectedSuggestion, deleteSuggestionHandler, removeTags}) {
    const {t} = useTranslate();
    return (
        selectedSuggestion.length > 1 && <li className={"auto-complete-tags"}>
            <div className={"auto-complete-tags__item"}>
                {
                    selectedSuggestion.slice(1).map((suggestion, suggestionId) => (
                        <Tag
                            key={suggestionId}
                            className={"auto-complete-tags__tag"}
                            name={t(suggestion?.name)}
                            handleDelete={() => deleteSuggestionHandler(suggestion)}
                        />
                    ))
                }
            </div>
            <div
                className={"auto-complete-tags__helper"}
                onClick={removeTags}
            >
                <p>
                   {t("BUTTON.CLEAR_ALL")}
                </p>
            </div>
        </li>
    );
};
export default function getLessonOptions(notes) {
    const uniqueValues = new Set();
    const array = notes.reduce((acc, note, index) => {
      const value = note.lesson_id.title;
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        acc.push({ id: index + 1, name: value, value: value });
      }
      return acc;
    }, []);
    return [{ id: 0, name: "LMS.NOTES.ALL_NOTES", value: "LMS.NOTES.ALL_NOTES" }, ...array];
  };
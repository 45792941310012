import { COURSE_LESSON_ROUTE, COURSE_SECTION_ROUTE } from "@utils/consts";

function getNextLessonLink(courseId, sectionId, lessonId, currentCourse) {
  if (!currentCourse) return null;

  const currentSection = currentCourse.sections.find((section) => section.id === Number(sectionId));
  if (!currentSection) return null;

  const currentLessonIndex = currentSection.lessons.findIndex((lesson) => lesson.id === Number(lessonId));

  if (currentLessonIndex >= 0 && currentLessonIndex < currentSection.lessons.length - 1) {
    
    // Next lesson in current section exists
    const nextLesson = currentSection.lessons[currentLessonIndex + 1];
    return COURSE_LESSON_ROUTE.replace(":courseId", courseId)
      .replace(":sectionId", sectionId)
      .replace(":lessonId", nextLesson.id);
  }

  // Next lesson in current section does not exist
  const currentSectionIndex = currentCourse.sections.findIndex((section) => section.id === Number(sectionId));
  if (currentSectionIndex >= currentCourse.sections.length - 1) return null; // There is no next section

  const nextSection = currentCourse.sections[currentSectionIndex + 1];
  if (nextSection.lessons.length <= 0) return null; // The next section has no lessons

  // const firstLesson = nextSection.lessons[0];
  return COURSE_SECTION_ROUTE.replace(":courseId", courseId)
    .replace(":sectionId", nextSection.id)
}

export default getNextLessonLink;

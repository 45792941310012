import React from "react";
import { useTranslate } from "@helpers/hooks";
import { List, BtnLoader } from "@components/common";
import { ScholarshipsCard } from "../ScholarshipsCard";
import { useSelector } from "react-redux";
import "./ScholarshipsList.scss";

export default function ScholarshipsList({ scholarships }) {
  const { t } = useTranslate();

  const { isScholarshipsLoading } = useSelector((state) => state.scholarships);

  if (isScholarshipsLoading) {
    return <BtnLoader className="btn-loader-bg-large" />;
  }

  return (
    <div>
      <p className="scholarships__count">
        {scholarships.length} {t("SCHOLARSHIPS.RESULT")}
      </p>
      <List
        className={"scholarships__list"}
        items={scholarships}
        renderItem={(item) => <ScholarshipsCard key={item?.scholarship_info[0]?.id} item={item} />}
      />
    </div>
  );
}

import { Icon } from "@components/common";
import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import "./SectionModule.scss"
import {capitalizeFirstLetter} from "@utils/functions"
import {COURSE_LESSON_ROUTE} from "@utils/consts";
import { useIsMobile } from "@helpers/hooks";


export default function SectionModule({ lesson, courseId, sectionId }) {
  const lessonRoute = COURSE_LESSON_ROUTE.replace(":courseId", courseId).replace(":sectionId", sectionId).replace(":lessonId", lesson.id);
  const isMobile = useIsMobile()
  let icon;
  switch (lesson.type) {
    case "video":
      icon = "lms-video";
      break;
    case "reading":
      icon = "lms-reading";
      break;
    case "practice":
      icon = "lms-practice";
      break;
    default:
      icon = "unknown";
  }

  return (
    <NavLink
    to={lessonRoute}
    >
    <div className={isMobile ? "module-mobile" : "module"}>
      <Icon name={lesson.completed ? "completed-lesson-circle" : icon} className={lesson.completed ? "icon--completed-circle" : "icon--lms-dark"}/>
      <div className="module__text">
        <p className={isMobile ? "module__title-mobile": "module__title"}>{capitalizeFirstLetter(lesson.title)}</p>
        <p className={isMobile ? "module__subtitle-mobile" : "module__subtitle"}>
          {capitalizeFirstLetter(lesson.type)}, {lesson.duration} min
        </p>
      </div>
    </div>
    </NavLink>
  );
}

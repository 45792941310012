import classNames from 'classnames';
import { useTranslate } from '@helpers/hooks'
import './UniversityDetailsTabs.scss';

const UniversityDetailsTabs = ({tabs, classNameTabs, classNameTab, currentTab, setCurrentTab}) => {
    const { t } = useTranslate()
    return (
        <div className={classNames(classNameTabs)}>
                {tabs.map((tab, index) => (
                    <div 
                        key={index}
                        className={`${classNameTab} ${t(currentTab) === t(tab) ? 'active-tab' : ""}`}
                        onClick={() => setCurrentTab((tab))}>
                        {t(tab)}
                    </div>
                ))}
            </div>
    );
}

export default UniversityDetailsTabs;

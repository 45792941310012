import { List } from '@components/common';
import { CatalogEmpty } from '@components/feature';
import { useTranslate } from '@helpers/hooks';
import { useSelector } from 'react-redux';
import { UniversityCard } from '@components/feature';
import {useIsMobile} from "@helpers/hooks";
import './UniversityListRecommended.scss';

const UniversityListRecommended = ({universities, onSelectUniversity}) => {
  const isMobile = useIsMobile()
    const {t} = useTranslate(),
    {isLoading} = useSelector(state => state.questionnaire);
    if (universities?.length === 0 && !isLoading) return <CatalogEmpty/>
    return (
      <>
        <List
            className={isMobile ? "university__list-mobile" : "university-list"}
            items={universities}
            renderItem={(university, universityId) => (
                <UniversityCard
                    key={universityId}
                    university={university.university}
                    matchingPrograms={university.matching_programs}
                    onSelectUniversity={onSelectUniversity}
                />
            )}
        />
      </>
    );
}

export default UniversityListRecommended;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { Icon, AutoComplete, Button } from "@components/common";
import { DEGREES } from "@utils/consts";
import { parseArrToStr, trackAmplitude } from "@utils/functions";
import classNames from "classnames";
import "./ScholarshipsFilter.scss";

export default function ScholarshipsFilter({ setSelectedFilters, selectedFilters, className }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const { countries } = useSelector((state) => state.directory);
  const { scholarshipsPrograms } = useSelector((state) => state.scholarships);
  const { fetchCountries, fetchScholarshipsPrograms, fetchScholarshipsNew } = useAction();

  useEffect(() => {
    fetchCountries();
  }, [selectedFilters.region]);

  useEffect(() => {
    fetchScholarshipsPrograms();
  }, [selectedFilters.program]);

  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(true);
  const [additionalClear, setAdditionalClear] = useState(null);

  const initialFilters = useMemo(
      () => [
        {
          id: 3,
          label: t("CATALOG.DEGREE"),
          name: "primary_study_level",
          options: DEGREES(t),
          selectedSuggestion: selectedFilters.primary_study_level,
          setSelectedFilters: setSelectedFilters,
        },
        {
          id: 4,
          label: t("CATALOG.COUNTRY"),
          name: "country",
          method: ({ search }) => fetchCountries({ search }),
          options: countries?.results,
          selectedSuggestion: selectedFilters.country,
          setSelectedFilters: setSelectedFilters,
        },

        {
          id: 2,
          label: t("SCHOLARSHIPS.PROGRAM_FILTER"),
          name: "program",
          method: ({ search }) => fetchScholarshipsPrograms({ search }),
          options: scholarshipsPrograms,
          selectedSuggestion: selectedFilters.program,
          setSelectedFilters: setSelectedFilters,
        },
      ],
      [countries, scholarshipsPrograms, t, selectedFilters]
    ),
    [filters, setFilters] = useState(initialFilters || []),
    [isClear, setIsClear] = useState(false);

  const getData = (params) => {
    fetchScholarshipsNew({
      degree: params.primary_study_level,
      country: params.country,
      program: params.program,
    });
  };

  const handleFilter = (option, name) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: option.option }));
  };

  const handleClear = (name) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: [] }));
  };

  const onClearAll = () => {
    getData({});
    setSelectedFilters({});
    setAdditionalClear(true);
    setIsClear((prev) => !prev);
  };

  const parseAllObjToStrNames = (obj) => {
    let res = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value.length > 0) {
        if (key === "country" || key == "primary_study_level") {
          res[key] = parseArrToStr(value);
        } else {
          const names = value.map((item) => item.name);
          res[key] = names.join(", ");
        }
      }
    }
    return res;
  };

  //for amplitude
  const extractNamesForKeys = (obj, keys) => {
    let result = {};
    keys.forEach(key => {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        result[key] = obj[key].map(item => item.name).join(", ");
      } else {
        result[key] = '';
      }
    });
    return result;
  };

  const onFilter = () => {
    const filterKeys = ['program', 'country', 'primary_study_level'];
    const filterValues = extractNamesForKeys(selectedFilters, filterKeys);
    const res = parseAllObjToStrNames(selectedFilters);
    getData(res);
    
    trackAmplitude("scholarship_find_click", {
      description: 'Пользователь успешно сохранил свои предпочтения для поиска университетов',
      ...filterValues
    })
  };

  const toggleFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  useEffect(() => {
    setFilters(initialFilters);
    return () => {
      setFilters([]);
    };
  }, [initialFilters]);

  return (
    <div>
      {isMobile && (
        <div
          className={`${isMobileFilterOpen ? "filter-open" : "filter-closed"} scholarship__filter`}
        >
          <Button className="btn__filter" iconLeft={<Icon name="filter2" />} text={"Filter"} />
          <Icon
            name={isMobileFilterOpen ? "arrow-up" : "arrow-down"}
            handleClick={() => toggleFilter()}
          />
        </div>
      )}

      <div
        className={classNames(
          `catalog-filter ${
            isMobileFilterOpen ? "scholarship__filter-open" : "scholarship__filter-closed"
          }`,
          className
        )}
      >
        <div className={"catalog-filter__list"}>
          {filters.map(({ id, label, name, method, options, selectedSuggestion, disabled }) => (
            <div key={id} className={"catalog-filter__item"}>
              <AutoComplete
                label={label}
                name={name}
                placeholder={t("FIELD.NOT_CHOSEN")}
                disabled={disabled}
                isClear={isClear}
                setIsClear={setIsClear}
                suggestions={options}
                method={method}
                handleChange={(option) => handleFilter({ option }, name)}
                handleClear={handleClear}
                selectedSuggestion={selectedSuggestion || []}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
              />
            </div>
          ))}
        </div>
        <div className={"catalog-filter__actions"}>
          <Button
            className={"catalog-filter__btn btn--text"}
            text={t("BUTTON.RESET_ALL_FILTERS")}
            iconLeft={<Icon name={"reset"} />}
            onClick={onClearAll}
          />
          <Button
            className={"catalog-filter__btn btn--secondary btn--pill"}
            text={t("BUTTON.APPLY")}
            onClick={onFilter}
          />
        </div>
      </div>
    </div>
  );
}

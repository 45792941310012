import { Button } from "@components/common";
import "./NotificationBox.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CHALLENGES_ROUTE_ONGOING, MOCK_EXAM_ONGOING } from "@utils/consts";

export default function NotificationBox({ notification }) {
  const history = useHistory();
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { month: "short", day: "numeric" };

    return date.toLocaleDateString("en-US", options);
  };
  const sections = {
    challenges: { route: CHALLENGES_ROUTE_ONGOING, idKey: ":challengeId" },
    ielts_mocks: { route: MOCK_EXAM_ONGOING, idKey: ":mockId" },
  };

  const handleButtonRedirect = (section, id) => {
    const { route, idKey } = sections[section] || {};
    if (route) {
      history.push(route.replace(idKey, id));
    }
  };

  return (
    <div className="notif-box">
      <div className="notif-box__inner">
        {" "}
        <div className="notif-box__dot-box">
          <div className="notif-box__dot"></div>
        </div>
        <div className="notif-box__info">
          <div className="notif-box__text">{notification?.title}</div>
          <div className="notif-box__more-info">
            <Button
              text="Подробнее"
              className="notif-box__more-info__button"
              onClick={() =>
                handleButtonRedirect(
                  notification?.section,
                  notification?.section_id
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="notif-box__date">
        {formatDate(notification?.date_time)}
      </div>
    </div>
  );
}

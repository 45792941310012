import Plyr from "plyr";
import React, { useEffect, useRef } from "react";
import "plyr/dist/plyr.css";
import "./AudioPlayer.scss";

export default function AudioPlayer({ src, isSettings, isMock }) {
  const playerContainer = useRef(null);
  let player = null;

  useEffect(() => {
    if (playerContainer.current) {
      const audioElement = document.createElement("audio");
      audioElement.innerHTML = `<source src=${src} />`;
      playerContainer.current.appendChild(audioElement);

      const controls = isMock ? ['play', 'volume'] : ['play', 'progress', 'current-time', 'mute', 'volume'];
      const settings = isSettings ? ['speed'] : [];

      player = new Plyr(audioElement, {
        controls,
        settings,
      });

      if (isMock) {
        player.on('play', () => {
          player.muted = false;
        });
        player.on('pause', () => {
          player.play();
        });
        player.on('seeking', () => {
          player.currentTime = 0;
        });

        player.on('timeupdate', () => {
          if (player.currentTime > 0 && !player.playing) {
            player.play();
          }
        });

        // Hide additional controls if mock
        const controlsToHide = ['.plyr__controls__item--rewind', '.plyr__controls__item--fast-forward', '.plyr__progress'];
        controlsToHide.forEach(selector => {
          const control = playerContainer.current.querySelector(selector);
          if (control) {
            control.style.display = 'none';
          }
        });
      }

      return () => {
        if (player) {
          player.destroy();
        }
        if (playerContainer.current && playerContainer.current.contains(audioElement)) {
          playerContainer.current.removeChild(audioElement);
        }
      };
    }
  }, [src, isSettings, isMock]);

  return <div ref={playerContainer}></div>;
}

import { useAction } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BtnLoader, List } from "@components/common";
import { LmsRatingPrimaryCard, LmsRatingTeritaryCard } from "@components/feature";
import { useTranslate } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import "./LmsRating.scss";

export default function LmsRating() {
  const { t } = useTranslate();
  const isMobile = useIsMobile()
  const { fetchBadgeRating } = useAction();
  const { badgeRating, isBadgeRatingLoading } = useSelector((state) => state.courses);

  const primaryUsers = badgeRating && badgeRating.slice(0, 5);
  const teritaryUsers = badgeRating && badgeRating.slice(5);

  

  const handleFetchRating = () => {
    fetchBadgeRating();
  };

  const HEAD_ITEMS = [
    {
      id: 1,
      name: t("LMS.RANKING.RANK"),
    },
    {
      id: 2,
      name: t("LMS.RANKING.USERNAME"),
    },
    {
      id: 3,
      name: t("LMS.RANKING.LEVEL"),
    },
    {
      id: 5,
      name: t("LMS.RANKING.COINS"),
    },
    {
      id: 4,
      name: t("LMS.RANKING.POINTS"),
    },
  ];

  useEffect(() => {
    handleFetchRating();
  }, []);

  return (
    isBadgeRatingLoading ?   <BtnLoader className={"btn-loader--primary"}/> :
    <div>
      <h1 className="course_notes__title">{t("LMS.RANKING.TITLE")}</h1>
      {badgeRating && (
        <div className="rating">
          <List className= {isMobile ? `rating__list-mobile` : `rating__list`} items={primaryUsers} renderItem={(user) => <LmsRatingPrimaryCard key={user.id} user={user} />} />
          <div className="rating__table">
            <List
              className={`rating__table-head ${isMobile ? "rating__table-head-mobile" : "rating__table-head-web"}`}
              items={HEAD_ITEMS}
              renderItem={(item) => (
                <div key={item.id}>
                  <p className={isMobile ? "rating__text-mobile-head" : "rating__text"}>{item.name}</p>
                </div>
              )}
            />

            <List
              className="rating__table-records"
              items={teritaryUsers}
              renderItem={(user) => <LmsRatingTeritaryCard  key={user.id} user={user} teritaryUsers={teritaryUsers} />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

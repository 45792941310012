import React, { useState, useEffect } from "react";
import { Button } from "@components/common";
import "react-circular-progressbar/dist/styles.css";
import { convertSecondsToMinutesAndSeconds } from "@utils/functions";
import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslate } from "@helpers/hooks";
import "./MockBreak.scss";

export default function MockBreak({startNewSection, activeSection, timeInSeconds, timeLeftBreak, setTimeLeftBreak }) {
  const { t } = useTranslate();

  
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeftBreak((timeLeft) => {
        const newTimeLeft = Math.max(0, timeLeft - 100);
        if (newTimeLeft === 0) {
          clearInterval(interval);
        }
        return newTimeLeft;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);
  

  const percentage = ((timeInSeconds * 1000 - timeLeftBreak) / (timeInSeconds * 1000)) * 100;


  return (
    <div className="break__bg">
      <div className="break__card">
        <div className="break__container">
          <p className="break__title">{t("LMS.MOCK.BREAK")}</p>
          <CircularProgressbar
          strokeWidth={7}
            value={percentage}
            text={convertSecondsToMinutesAndSeconds(Math.round(timeLeftBreak / 1000))}
          />
          <Button onClick={startNewSection} text={`${t("LMS.MOCK.START")} ${activeSection}`} className={"btn--primary break__btn"} />
        </div>
      </div>
    </div>
  );
}

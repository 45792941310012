const SECTIONS = [
  {
    name: "listening",
    icon: "listening-mock",
  },
  {
    name: "reading",
    icon: "reading-mock",
  },
  {
    name: "writing",
    icon: "writing-mock",
  },
  {
    name: "speaking",
    icon: "speaking-mock",
  },
];

const QUESTION_TYPES = {
    reading: "mock_reading_question",
    listening: "mock_listening_question",
    writing: "mock_writing_question",
    speaking: "mock_speaking_question",
  };

const BREAK_DURATION = 300;

const SECTION_DURATION = 1800;

export { SECTIONS, BREAK_DURATION, SECTION_DURATION, QUESTION_TYPES };

import React, { useState, useEffect } from "react";
import { Icon } from "../Icon";
import { useIsMobile } from "@helpers/hooks";
import { CustomLink } from "../CustomLink";
import { PdfWindow } from "../PdfWindow";
import { useTranslate } from "@helpers/hooks";
import { pdfjs } from "react-pdf";
import "./PdfView.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export default function PdfView({ link, className }) {
  const { t } = useTranslate();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useIsMobile();
  const handleFullScreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className={`${className}${isFullscreen ? " pdf__fullscreen" : ""} pdf__container`}>
      <div className="pdf__nav">
        <Icon
          className={
            isMobile ? "pdf__button-mobile icon--pointer icon--mobile" : "pdf__button icon--pointer"
          }
          name={isFullscreen ? "minimize-pdf" : "maximize-pdf"}
          handleClick={handleFullScreen}
        />
        <CustomLink className="pdf__new" to={link} newTab={true} isExternal={true}>
          <p className="pdf__text">{t("LMS.OPEN")}</p>
          <Icon
            className={
              isMobile
                ? "pdf__button-mobile icon--pointer icon--mobile"
                : "pdf__button icon--pointer"
            }
            name={"new_window"}
          />
        </CustomLink>
      </div>

      {isMobile ? (
        <PdfWindow link={link} />
      ) : (
        <iframe src={link} title="" width="100%" height="100%" />
      )}
    </div>
  );
}

const findRequirements = (requirement) => {
    if (requirement === "considered_but_not_required") {
        return "Considered but not required";
    }

    if (requirement === "recommended") {
        return "Recommended";
    }

    if (requirement === "required") {
        return "Required";
    }

    return requirement;
};

export default findRequirements;
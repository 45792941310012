import InfiniteScroll from "react-infinite-scroller";
import {useTranslate} from "@helpers/hooks";
import {BtnLoader, Option, Search} from "@components/common";
import classNames from "classnames";
import "./SelectList.scss";

export default function SelectList(props) {
    const {
        showOptions, infinity, name,
        hasMore, onScrollChange, isSearch,
        search, options, selectedOptions,
        setSearch, isSearching, selectedOption,
        isUnDisabled
    } = props;
    const {t} = useTranslate();
    return (
        showOptions && <ul className={classNames("select-list", {scroll: options.length > 5})}>
            {
                isSearch && <Search
                    className={"search--select"}
                    placeholder={t("FIELD.START_SEARCH")}
                    autoComplete={"off"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            }
            {
                infinity
                    ? <InfiniteScroll
                        pageStart={0}
                        loadMore={(e) => onScrollChange(e, name)}
                        hasMore={hasMore}
                        useWindow={false}
                        loader={<div className={"select-list__loader"} key={0}>
                            <BtnLoader className={"btn-loader--blue"}/>
                        </div>}
                    >
                        {
                            selectedOptions.length > 0
                                ? selectedOptions.map((option) => (
                                    <Option
                                        key={option.id}
                                        name={t(option.name)}
                                        value={option.value ?? option.id}
                                        disabled={!isUnDisabled && selectedOption && (option.value === selectedOption.value)}
                                    />
                                ))
                                : options.map((option) => (
                                    <Option
                                        key={option.id}
                                        name={t(option.name)}
                                        value={option.value ?? option.id}
                                        disabled={!isUnDisabled && selectedOption && (option.value === selectedOption.value)}
                                    />
                                ))
                        }
                    </InfiniteScroll>
                    : selectedOptions.length > 0
                        ? selectedOptions.map((option) => (
                            <Option
                                key={option.id}
                                name={t(option.name)}
                                value={option.value ?? option.id}
                                disabled={!isUnDisabled && selectedOption && (option.value === selectedOption.value)}
                            />
                        ))
                        : !isSearching
                        ? options.length > 0
                        ? options.map((option) => (
                            <Option
                                key={option.id}
                                name={t(option.name)}
                                value={option.value ?? option.id}
                                disabled={!isUnDisabled && selectedOption && (option.value === selectedOption.value)}
                            />
                        ))
                        : <Option
                            name={t("FIELD.NO_DATA")}
                            disabled={true}
                        />
                        : <div className={"select-list__loader"}>
                            <BtnLoader className={"btn-loader--blue"}/>
                        </div>
            }
        </ul>
    );
};
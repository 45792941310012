import React, { useState } from "react";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import "./DashboardWritingBuilder.scss";
import { BtnLoader, Button, ButtonV2, Image, List } from "@components/common";
import {
  EssayContent,
  EssayDetails,
  EssaysGeneralListItem,
  EssayTabs,
} from "@components/feature/Essays";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ESSAY_ROUTE } from "@utils/consts";

export default function DashboardWritingBuilder() {
  const isMobile = useIsMobile();

  const history = useHistory();

  const { t } = useTranslate(),
    TABS = {
      GENERAL_ESSAYS: {
        id: 1,
        name: t("ESSAY.LABELS.LABEL_1"),
        value: "GENERAL_ESSAYS",
      },
      UNIVERSITY_SPECIFIC_ESSAYS: {
        id: 2,
        name: t("ESSAY.LABELS.LABEL_2"),
        value: "UNIVERSITY_SPECIFIC_ESSAYS",
      },
    },
    [currentEssayTab, setCurrentEssayTab] = useState(TABS.GENERAL_ESSAYS),
    [isEditOpenInEssays, setIsEditOpenInEssays] = useState(false),
    [isEssayOpen, setIsEssayOpen] = useState(false),
    [essayId, setEssayId] = useState(null);

  const [foundEssay, setFoundEssay] = useState(null);
  const { fetchGeneralEssays } = useAction();
  const { generalEssays, isLoading, isUpdating } = useSelector(
    (state) => state.essays
  );

  const onSelectEssay = (essayId) => {
    setIsEssayOpen(true);
    setEssayId(essayId);
  };

  const onEditEssay = (essayId) => {
    setIsEditOpenInEssays(true);
    setEssayId(essayId);
  };

  useEffect(() => {
    fetchGeneralEssays();
  }, [isUpdating]);

  // if (isMobile) {
  //   return <MObil title={t("ESSAY.TITLE")}/>;
  // }

  const handleButtonClick = () => {
    history.push(ESSAY_ROUTE)
  };

  return (
    <div className="dashboard__writing-builder">
      <div className="dashboard__proftest-heading">
        <h2 className="dashboard__subheading">{t("ESSAY.TITLE")}</h2>
        {!isMobile && generalEssays?.results?.length > 0 && (
          <ButtonV2
            text={t("DASHBOARD.ESSAYS.VIEW_ALL")}
            className="button--primary-small btn--pill"
            onClick={handleButtonClick}
          />
        )}
      </div>
      <div className="essays">
        {generalEssays?.results?.length === 0 && (
          <div className="essays__placeholder-container">
            <Image
              className="essays__placeholder"
              name="papers_placeholder.png"
            />
            <p className="essays__placeholder-message">
              {t("DASHBOARD.ESSAYS.NO_ESSAYS")}
            </p>
            <ButtonV2
              text={t("DASHBOARD.ESSAYS.BUTTON_FREE")}
              className="button--primary-small btn--pill"
              onClick={handleButtonClick}
            />
          </div>
        )}

        {isEssayOpen ? (
          <EssayDetails
            setIsEssayOpen={setIsEssayOpen}
            currentEssayTab={currentEssayTab}
          />
        ) : (
          <>
            {!isUpdating && !isLoading && generalEssays.results ? (
              <List
                className={"essays-list"}
                items={generalEssays.results}
                renderItem={(item) => (
                  <EssaysGeneralListItem
                    key={item.id}
                    item={item}
                    isDashboard={true}
                    onSelectEssay={onSelectEssay}
                    onEditEssay={onEditEssay}
                  />
                )}
              />
            ) : (
              <div className="parent-loader">
                <BtnLoader className={"btn-loader--blue-large"} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

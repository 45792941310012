// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog__list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.blog__list-mobile {
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Blog/BlogArticlesList/BlogArticlesList.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,SAAA;EACA,eAAA;AADR;AAGI;EACI,mBAAA;AADR","sourcesContent":["\n.blog {\n    &__list {\n        display: flex;\n        gap: 20px;\n        flex-wrap: wrap;\n\n    &-mobile {\n        margin-bottom: 40px;\n    }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

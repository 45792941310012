import {useContext} from "react";
import {DataContext} from "@helpers/context";

export default function useData() {
    const data = useContext(DataContext);

    if (!data) {
        throw new Error("Error in creating the context");
    }

    return data;
};
const STUDY_YEAR_COST = [
  {
    id: 1,
    text: "- $10000",
    value: "- 10000",
  },
  {
    id: 2,
    text: "$20000",
    value: "- 20000",
  },
  {
    id: 3,
    text: "$30000",
    value: "- 30000",
  },
  {
    id: 4,
    text: "$40000",
    value: "- 40000",
  },
  {
    id: 5,
    text: "$50000",
    value: "- 50000",
  },
  {
    id: 6,
    text: "$60000",
    value: "- 60000",
  },
  {
    id: 7,
    text: "$70000",
    value: "- 70000",
  },
  {
    id: 8,
    text: "$70000 -",
    value: "70000 -",
  },
];

const UNIVERSITY_RANKINGS = [
  {
    id: 1,
    text: "1-50",
    value: "1-50",
  },
  {
    id: 2,
    text: "50-100",
    value: "50-100",
  },
  {
    id: 3,
    text: "100-200",
    value: "100-200",
  },
  {
    id: 4,
    text: "200-400",
    value: "200-400",
  },
  {
    id: 5,
    text: "400-800",
    value: "400-800",
  },
  {
    id: 6,
    text: "800-",
    value: "800-",
  },
];

const RESEARCH_OUTPUT = [
  {
    id: 1,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_4",
    value: "Low",
  },
  {
    id: 2,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_5",
    value: "Medium",
  },
  {
    id: 3,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_6",
    value: "High",
  },
  {
    id: 4,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_7",
    value: "Very High",
  },
];

const AMOUNT_OF_STUDENTS = [
  {
    id: 1,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_8",
    value: "- 2000",
  },
  {
    id: 2,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_9",
    value: "2000 - 10000",
  },
  {
    id: 3,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_10",
    value: "10000 - 20000",
  },
  {
    id: 4,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_11",
    value: "20000 - 30000",
  },
  {
    id: 5,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_12",
    value: "30000 - 40000",
  },
  {
    id: 6,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_13",
    value: "40000 - 50000",
  },
  {
    id: 7,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_14",
    value: "50000 -",
  },
];

const STUDENT_FACULTY_RATIO = [
  {
    id: 1,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_15",
    value: "- 10",
  },
  {
    id: 2,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_16",
    value: "- 20",
  },
  {
    id: 3,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_17",
    value: "- 30",
  },
  {
    id: 4,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_18",
    value: "- 40",
  },
  {
    id: 5,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_19",
    value: "- 50",
  },
  {
    id: 6,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_20",
    value: "50 -",
  },
];

const PERCENTAGE_OF_INTERNATIONAL_STUDENTS = [
  {
    id: 1,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_21",
    value: "0 - 5",
  },
  {
    id: 2,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_22",
    value: "5 - 10",
  },
  {
    id: 3,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_23",
    value: "10 - 20",
  },
  {
    id: 4,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_24",
    value: "20 - 30",
  },
  {
    id: 5,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_25",
    value: "30 -",
  },
];

const ON_CAMPUS_HOUSING = [
  {
    id: 1,
    name: "MY_PREFERENCES.DROPDOWN_FIELDS.TRUE",
    value: true,
  },
  {
    id: 2,
    name: "MY_PREFERENCES.DROPDOWN_FIELDS.FALSE",
    value: false,
  },
];

const GRADUATION_RATE = [
  {
    id: 1,
    name: "0 - 100",
    value: "0 - 100",
  },
  {
    id: 2,
    name: "40 - 100",
    value: "40 - 100",
  },
  {
    id: 3,
    name: "60 - 100",
    value: "60 - 100",
  },
  {
    id: 4,
    name: "80 - 100",
    value: "80 - 100",
  },
  {
    id: 5,
    name: "90 - 100",
    value: "90 - 100",
  },
];

const COLLEGE_SETTING = [
  {
    id: 1,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_26",
    value: "Urban",
  },
  {
    id: 2,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_27",
    value: "Suburban",
  },
  {
    id: 3,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_28",
    value: "Rural",
  },
  {
    id: 4,
    text: "MY_PREFERENCES.PREFERENCES.OPTION_29",
    value: "Small Town",
  },
];

const EARNINGS_AFTER_GRADUATION = [
  {
    id: 1,
    name: "20000 -",
    value: "20000 -",
  },
  {
    id: 2,
    name: "40000 -",
    value: "40000 -",
  },
  {
    id: 3,
    name: "60000 -",
    value: "60000 -",
  },
  {
    id: 4,
    name: "80000 -",
    value: "80000 -",
  },
];

const SINGLE_SELECT_CHIP_FIELDS = ["student_faculty_ratio", "study_year_cost"];

export {
  AMOUNT_OF_STUDENTS,
  COLLEGE_SETTING,
  EARNINGS_AFTER_GRADUATION,
  GRADUATION_RATE,
  ON_CAMPUS_HOUSING,
  PERCENTAGE_OF_INTERNATIONAL_STUDENTS,
  RESEARCH_OUTPUT,
  SINGLE_SELECT_CHIP_FIELDS,
  STUDENT_FACULTY_RATIO,
  STUDY_YEAR_COST,
  UNIVERSITY_RANKINGS,
};

import React from "react";
import { List } from "@components/common";
import { filterItemsWithValues } from "@utils/functions";
import "./OverviewRanking.scss";

export default function OverviewRanking({ ratingInfo }) {
  const filteredItems = filterItemsWithValues(ratingInfo);
  return (
    <>
      {ratingInfo && (
        <List
          className={"overview__rating"}
          items={filteredItems}
          renderItem={(item) => {
            if (item.value) {
              return (
                <div key={item.id} className="overview__rating-item">
                  <p className="overview__rating-item-rank">{item.value}</p>
                  <p className="overview__rating-item-title">{item.title}</p>
                </div>
              );
            }
            return;
          }}
        />
      )}
    </>
  );
}

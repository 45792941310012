import React from "react";
import { getIntro, getCourseName } from "@utils/functions";
import { useParams } from "react-router-dom";
import { useTranslate } from "@helpers/hooks";
import "./WelcomeTitle.scss"

export default function WelcomeTitle() {
  const { courseId } = useParams();
  const {t} = useTranslate()
  const intro = getIntro(courseId)
  const courseName = getCourseName(courseId)



  return (
    <div>
    <h2 className="welcome__title">{t(intro.practice?.welcome, {courseName})}</h2>
    </div>
  );
}

import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useAction, useDebounce, useTranslate} from "@helpers/hooks";
import {parseAllObjToStr, parseArrToStr} from "@utils/functions";
import {ADDITIONAL_SELECTS, DEGREES} from "@utils/consts";
import {WithRemoveScroll} from "@helpers/hoc";
import {Button, Icon, List, Search, Checkbox, BtnLoader} from "@components/common";
import classNames from "classnames";
import "./MobileFilter.scss";

export default function MobileFilter(props) {
    const {t} = useTranslate(),
        {
            filterShow, setFilterShow,
            debouncedValue, order,
            tabs, selectedTab,
            setSelectedFilters, setOrder, setIsResetFilter,
            setSearch: setFilterSearch, setSelectedOrder
        } = props,
        {regions, countries, cities, subjects, directoryUniversities} = useSelector(state => state.directory),
        {fetchUniversities, fetchPrograms, fetchCountries, fetchCities, fetchSubjects, fetchDirectoryUniversities, setUniversitiesPageChange, setProgramsPageChange} = useAction();

    const initialOptions = {
            region: [],
            country: [],
            city: [],
            subject: [],
            primary_study_level: [],
            university: [],
            rating__QS: [],
            duration_field_test: [],
            tuition_fee: [],
            study_mode: [],
            scholarships: []
        },
        [selectedOptions, setSelectedOptions] = useState(initialOptions),
        initialFilters = useMemo(() => [
            {id: 1, label: t("CATALOG.REGION"), name: "region", options: regions},
            {id: 2, label: t("CATALOG.COUNTRY"), name: "country", options: countries?.results, method: ({search}) => fetchCountries({search, region: parseArrToStr(selectedOptions.region)})},
            {id: 3, label: t("CATALOG.CITY"), name: "city", options: cities?.results, method: ({search}) => fetchCities({search, region: parseArrToStr(selectedOptions.region), country: parseArrToStr(selectedOptions.country)})},
            {id: 4, label: t("CATALOG.DEGREE"), name: "primary_study_level", options: DEGREES(t)},
            {id: 5, label: t("CATALOG.SUBJECT"), name: "subject", options: subjects?.results, method: ({search}) => fetchSubjects({search})},
            {id: 6, label: t("CATALOG.UNIVERSITIES.TITLE"), name: "university", disabled: selectedTab === tabs.universities, options: directoryUniversities, method: ({search}) => fetchDirectoryUniversities({search})},
            {id: 7, label: t("CATALOG.RATING"), name: "rating__QS", options: ADDITIONAL_SELECTS(selectedTab, tabs)[0].fields},
            {id: 8, label: t("CATALOG.DURATION"), name: "duration_field_test", disabled: selectedTab === tabs.universities, options: ADDITIONAL_SELECTS(selectedTab, tabs)[1].fields},
            {id: 9, label: t("CHECKBOX.TUITION_FEE"), name: "tuition_fee", options: ADDITIONAL_SELECTS(selectedTab, tabs)[2].fields},
            {id: 10, label: t("CATALOG.PROGRAMS.LEARN_FORMAT"), name: "study_mode", disabled: selectedTab === tabs.universities, options: ADDITIONAL_SELECTS(selectedTab, tabs)[3].fields},
            {id: 11, label: t("CATALOG.PROGRAMS.SCHOLARSHIP"), name: "scholarships", options: ADDITIONAL_SELECTS(selectedTab, tabs)[4].fields},
        ], [regions, subjects, countries, cities, selectedTab, directoryUniversities, tabs, t, selectedOptions.region, selectedOptions.country]),
        [filters, setFilters] = useState(initialFilters),
        [selectedFilter, setSelectedFilter] = useState(filters[0]),
        [isSearching, setIsSearching] = useState(false),
        [search, setSearch] = useState(""),
        debouncedSearch = useDebounce(search, 700);

    const handleCheckbox = (option, name) => {
        setSelectedOptions(prev => (
            {
                ...prev,
                [name]: prev[name].map((p) => p.name).includes(option.name)
                    ? [...prev[name].filter((p) => p.name !== option.name)]
                    : [...prev[name], option]
            })
        );
    };

    const getData = (params) => {
        setUniversitiesPageChange(1);
        setProgramsPageChange(1);
        if (selectedTab === tabs.universities) {
            fetchUniversities(
                {
                    limit: 10,
                    offset: 0,
                    search: debouncedValue,
                    ordering: order,
                    region: params.region,
                    country: params.country,
                    city: params.city,
                    subject: params.subject,
                    study_level: params.primary_study_level,
                    tuition_fee: params.tuition_fee,
                    scholarships: params.scholarships,
                    rating__QS: params.rating__QS
                }
            );
        } else {
            fetchPrograms(
                {
                    limit: 10,
                    offset: 0,
                    search: debouncedValue,
                    ordering: order,
                    region: params.region,
                    country: params.country,
                    city: params.city,
                    subject: params.subject,
                    university: params.university,
                    duration_field_test: params.duration_field_test,
                    fees_field_test: params.tuition_fee,
                    scholarships: params.scholarships,
                    study_mode: params.study_mode,
                    study_level: params.primary_study_level,
                    rating__QS: params.rating__QS
                }
            );
        }
    };

    const onFilter = () => {
        setSelectedFilters(selectedOptions);
        const res = parseAllObjToStr(selectedOptions);
        getData(res);
        setFilterShow(false);
        setSearch("");
    };

    const onClear = () => {
        setFilterSearch("");
        setIsResetFilter(true);
        setSearch("");
        setOrder(null);
        setSelectedOrder({});
        setSelectedOptions(initialOptions);
        setSelectedFilters({});
        getData(initialOptions);
    };

    useEffect(() => {
        if (debouncedSearch && selectedFilter.method) {
            setIsSearching(true);
            selectedFilter.method({search: debouncedSearch}).then(() => {
                setIsSearching(false);
            }).finally(() => {
                setIsSearching(false);
            });
        }
    }, [debouncedSearch, selectedFilter]);

    useEffect(() => {
        if (initialFilters) {
            setFilters(initialFilters);
        }
    }, [initialFilters])

    useEffect(() => {
        if (search.length === 0 && selectedFilter.method) {
            setIsSearching(true);
            selectedFilter.method({search: null}).then(() => {
                setIsSearching(false);
            }).finally(() => setIsSearching(false));
        }
    }, [search, selectedFilter]);

    useEffect(() => {
        setSelectedFilter(prev => {
            if (prev.name === "country") {
                prev.options = countries.results;
            } else if (prev.name === "city") {
                prev.options = cities.results;
            } else if (prev.name === "subject") {
                prev.options = subjects.results;
            } else if (prev.name === "university") {
                prev.options = directoryUniversities;
            }
            return prev;
        });
    }, [countries, cities, subjects, directoryUniversities])
    return (
        <WithRemoveScroll flag={filterShow}>
            <div className={classNames("mobile-filter", {active: filterShow})}>
                <div className={"mobile-filter__header"}>
                    <p className={"mobile-filter__title"}>
                        {t("BUTTON.FILTERS")}
                    </p>
                    <Icon name={"burger-close"} handleClick={() => setFilterShow(false)}/>
                </div>
                <div className={"mobile-filter__filters"}>
                    <List
                        className={"mobile-filter__list"}
                        items={filters}
                        renderItem={(filter) => (
                            <li key={filter.id} className={classNames("mobile-filter__filter", {disabled: filter.disabled})}>
                                <div
                                    className={classNames("filter__block", {selected: filter.name === selectedFilter.name})}
                                    onClick={() => {
                                        setSelectedFilter(filter);
                                        setSearch("");
                                    }}
                                >
                                    <p className={"filter__text"}>{filter.label}</p>
                                </div>
                            </li>
                        )}
                    />
                    <div className={"mobile-filter__item"}>
                        {
                            selectedFilter && (selectedFilter.name === "country" || selectedFilter.name === "city" || selectedFilter.name === "university" || selectedFilter.name === "subject") &&
                            <>
                                <Search
                                    className={"search--select mobile-filter__search"}
                                    placeholder={t("FIELD.START_SEARCH")}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                {isSearching && <div className={"mobile-filter__loader"}>
                                    <BtnLoader className={"btn-loader--blue"}/>
                                </div>}
                            </>
                        }
                        {!isSearching && <List
                            items={selectedFilter.options}
                            renderItem={(filter) => (
                                <Checkbox
                                    key={filter.id}
                                    className={"mobile-filter__checkbox"}
                                    label={t(filter.name)}
                                    disabled={selectedFilter.disabled}
                                    checked={selectedOptions[selectedFilter.name].map((o) => o.name).includes(filter.name)}
                                    onChange={() => handleCheckbox(filter, selectedFilter.name)}
                                />
                            )}
                        />}
                    </div>
                </div>
                <div className={"mobile-filter__footer"}>
                    <Button
                        className={"btn--text"}
                        text={t("BUTTON.CLEAR_ALL_FILTERS")}
                        iconLeft={<Icon name={"clear"}/>}
                        height={24}
                        onClick={onClear}
                    />
                    <Button
                        className={"btn--primary btn--pill"}
                        text={t("BUTTON.APPLY_FILTERS")}
                        height={46}
                        onClick={onFilter}
                    />
                </div>
            </div>
        </WithRemoveScroll>
    );
};
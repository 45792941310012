import {useHistory} from "react-router-dom";
import {useAction, useTranslate} from "@helpers/hooks";
import {DASHBOARD_ROUTE} from "@utils/consts";
import {Icon, Tooltip} from "@components/common";
import "./FavoritesTooltip.scss";

export default function FavoritesTooltip({favorite, onReloadFavorites}) {
    const {t} = useTranslate(),
        {push} = useHistory(),
        {deleteFavorite} = useAction();

    const onDeleteProgramFromFavorite = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onReloadFavorites();
        deleteFavorite(favorite.id);
    };

    const onCalculateChance = (id) => push({
        pathname: DASHBOARD_ROUTE,
        search: `?favoriteId=${id}`
    });
    return (
        <div className={"favorites-tooltip"}>
            <Tooltip
                className={"favorites-tooltip__action"}
                position={"top"}
                text={t("TOOLTIP.CALCULATE_CHANCE")}
            >
                <Icon
                    className={"icons--pointer"}
                    name={"info-success"}
                    handleClick={() => onCalculateChance(favorite.id)}
                />
            </Tooltip>
            <Tooltip
                className={"favorites-tooltip__action"}
                position={"top"}
                text={t("BUTTON.DELETE_FROM_WISHLIST")}
            >
                <Icon
                    className={"icons--pointer"}
                    name={"delete-danger"}
                    handleClick={onDeleteProgramFromFavorite}
                />
            </Tooltip>
        </div>
    );
};
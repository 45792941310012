// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 200000;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-overlay .notif-sidebar {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  background-color: white;
  box-shadow: -5px 0 60px rgba(0, 0, 0, 0.1), -5px 0 20px rgba(0, 0, 0, 0.05);
  z-index: 1001;
  transition: right 5s ease;
  overflow-y: auto;
}
.modal-overlay .notif-sidebar.open {
  right: 0;
}

@media screen and (max-width: 481px) {
  .add_modal {
    align-items: center !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/RightSidebar/RightSidebar.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,oCAAA;AADF;AAGE;EACE,eAAA;EACA,MAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,2EAAA;EACA,aAAA;EACA,yBAAA;EACA,gBAAA;AADJ;AAIE;EACE,QAAA;AAFJ;;AAMA;EACE;IACE,8BAAA;EAHF;AACF","sourcesContent":["@import \"@styles/variables\";\n\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n  z-index: 200000;\n  background-color: rgba(0, 0, 0, 0.4);\n\n  .notif-sidebar {\n    position: fixed;\n    top: 0;\n    right: -450px;\n    width: 450px;\n    height: 100%;\n    background-color: white;\n    box-shadow: -5px 0 60px rgba(0, 0, 0, 0.1), -5px 0 20px rgba(0, 0, 0, 0.05);\n    z-index: 1001;\n    transition: right 5s ease;\n    overflow-y: auto;\n  }\n\n  .notif-sidebar.open {\n    right: 0;\n  }\n}\n\n@media screen and (max-width: 481px) {\n  .add_modal {\n    align-items: center !important;\n    .notif-sidebar {\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

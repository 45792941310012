import {Fragment, useState} from "react";
import {useTranslate, useWindowSize} from "@helpers/hooks";
import {Search, TabPane, Tabs} from "@components/common";
import {ProgramsList, UniversityList} from "@components/feature";
import "./CatalogTabs.scss";

export default function CatalogTabs(props) {
    const {t} = useTranslate(),
        {
            tabs, selectedTab, setSelectedTab, debouncedValue,
            selectedFilters, search, setSearch, order, additionalParams,
            setSelectedFilters, initialFilters, setIsResetFilter,
            setOrder, setSelectedOrder, setUniversityId, setUniversityOpen,
            universityId, universityOpen
        } = props,
        windowSize = useWindowSize(),
        [selectedProgram, setSelectedProgram] = useState(null);

    return (
        <Fragment>
            <Tabs
                className={"catalog-tabs"}
                tabClassName={"catalog-tabs__tab"}
                tabs={[tabs.universities, tabs.programs]}
                selected={selectedTab}
                setSelected={(tab) => {
                    setSearch("");
                    setOrder(null);
                    setSelectedOrder({});
                    setIsResetFilter(true);
                    setSelectedFilters(initialFilters);
                    setSelectedTab(tab);
                }}
            />
            {
                windowSize.width < 574 && <Search
                    className={"catalog-tabs__search"}
                    placeholder={windowSize.width < 374 ? t("FIELD.START_SEARCH") : t("CATALOG.SEARCH")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            }
            <TabPane
                className={"catalog-tabs__pane"}
                isSelected={selectedTab === tabs.universities}
            >
                <UniversityList
                    debouncedValue={debouncedValue}
                    selectedFilters={selectedFilters}
                    additionalParams={additionalParams}
                    universityId={universityId}
                    setUniversityId={setUniversityId}
                    universityOpen={universityOpen}
                    setUniversityOpen={setUniversityOpen}
                    order={order}
                    selectedProgram={selectedProgram}
                    setSelectedProgram={setSelectedProgram}
                    tabs={tabs}
                    setSelectedTab={setSelectedTab}
                    setSearch={setSearch}
                    setIsResetFilter={setIsResetFilter}
                    setOrder={setOrder}
                />
            </TabPane>
            <TabPane
                className={"catalog-tabs__pane"}
                isSelected={selectedTab === tabs.programs}
            >
                <ProgramsList
                    debouncedValue={debouncedValue}
                    selectedFilters={selectedFilters}
                    additionalParams={additionalParams}
                    tabs={tabs}
                    setSelectedTab={setSelectedTab}
                    order={order}
                    setUniversityId={setUniversityId}
                    setUniversityOpen={setUniversityOpen}
                    selectedProgram={selectedProgram}
                    setSelectedProgram={setSelectedProgram}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setSelectedOrder={setSelectedOrder}
                    setIsResetFilter={setIsResetFilter}
                    setSelectedFilters={setSelectedFilters}
                    initialFilters={initialFilters}
                />
            </TabPane>
        </Fragment>
    );
};
import {useEffect, useMemo, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import {useAction, useTranslate} from "@helpers/hooks";
import {Icon, AutoComplete, Button, AdditionalSelect} from "@components/common";
import {ADDITIONAL_SELECTS, DEGREES} from "@utils/consts";
import {parseAllObjToStr, parseArrToStr, trackAmplitude} from "@utils/functions";
import classNames from "classnames";
import "./CatalogFilter.scss";

export default function CatalogFilter(props) {
    const {t} = useTranslate(),
        {
            className, setSelectedFilters, selectedFilters,
            debouncedValue, order,
            tabs, selectedTab, isResetFilter,
            setSearch, setOrder, 
        } = props,
        {regions, countries, cities, subjects, moreInfos, directoryUniversities} = useSelector(state => state.directory),
        {fetchUniversities, fetchPrograms, fetchCountries, fetchSubjects, fetchCities, fetchRegions, fetchDirectoryUniversities, setUniversitiesPageChange, setProgramsPageChange} = useAction();
        
    const [additionalClear, setAdditionalClear] = useState(null);

    const initialFilters = useMemo(() => [
        {
            id: 1, 
            label: t("CATALOG.REGION"), 
            name: "region", 
            method: fetchRegions, 
            options: regions,  
            selectedSuggestion: selectedFilters.region
        },
        {
            id: 2, 
            label: t("CATALOG.SUBJECT"), 
            name: "subject", 
            method: fetchSubjects, 
            options: subjects, 
            selectedSuggestion: selectedFilters.subject, 
            setSelectedFilters: setSelectedFilters
        },
        {
            id: 3, 
            label: t("CATALOG.DEGREE"), 
            name: "primary_study_level", 
            options: DEGREES(t), 
            selectedSuggestion: selectedFilters.primary_study_level, 
            setSelectedFilters: setSelectedFilters
        },
        {
            id: 4, 
            label: t("CATALOG.COUNTRY"), 
            name: "country", 
            method: ({search}) => fetchCountries({search, region: parseArrToStr(selectedFilters.region)}), 
            options: countries?.results, 
            selectedSuggestion: selectedFilters.country, 
            setSelectedFilters: setSelectedFilters
        },
        {
            id: 5, 
            label: t("CATALOG.CITY"), 
            name: "city", 
            method: ({search}) => fetchCities({search, region: parseArrToStr(selectedFilters.region), 
            country: parseArrToStr(selectedFilters.country)}), 
            options: cities?.results, 
            selectedSuggestion: selectedFilters.city, 
            setSelectedFilters: setSelectedFilters
        },
        {
            id: 6, 
            label: t("CATALOG.UNIVERSITIES.TITLE"), 
            name: "university", 
            method: fetchDirectoryUniversities, 
            options: directoryUniversities, 
            disabled: selectedTab === tabs.universities
        },
        {
            id: 7, 
            label: t("CATALOG.MORE"), 
            name: "more", 
            options: moreInfos
        },
    ], [regions, subjects, countries, cities, moreInfos, directoryUniversities, t, tabs.programs, selectedTab, selectedFilters]),
        [filters, setFilters] = useState(initialFilters || []),
        [isClear, setIsClear] = useState(false);
        
    const getData = (params) => {
        setUniversitiesPageChange(1);
        setProgramsPageChange(1);
        if (selectedTab === tabs.universities) {
            fetchUniversities(
                {
                    limit: 10,
                    offset: 0,
                    search: debouncedValue,
                    ordering: order,
                    region: params.region,
                    country: params.country,
                    city: params.city,
                    subject: params.subject,
                    study_level: params.primary_study_level,
                    tuition_fee: params.tuition_fee,
                    scholarships: params.scholarships,
                    rating__QS: params.rating__QS,
                    rankings_position: params.rating__QS, // works
                }
            );
        } else {
            fetchPrograms(
                {
                    limit: 10,
                    offset: 0,
                    search: debouncedValue,
                    ordering: order,
                    region: params.region,
                    country: params.country,
                    city: params.city,
                    subject: params.subject,
                    university: params.university,
                    duration_field_test: params.duration_field_test,
                    fees_field_test: params.tuition_fee,
                    scholarships: params.scholarships,
                    study_mode: params.study_mode,
                    study_level: params.primary_study_level,
                    rating__QS: params.rating__QS
                }
            );
        }
    };

    const handleFilter = (option, name) => {
        setSelectedFilters(prev => ({...prev, [name]: option.option}));
    };

    const handleClear = (name) => {
        setSelectedFilters(prev => ({...prev, [name]: []}));
    };
    
    const onClearAll = () => {
        setSearch("");
        getData({})
        setOrder(null);
        setSelectedFilters({});
        setAdditionalClear(true);
        setIsClear(prev => !prev);
    };

    const extractNamesForKeys = (obj, keys) => {
        let result = {};
        keys.forEach(key => {
          if (Array.isArray(obj[key]) && obj[key].length > 0) {
            result[key] = obj[key].map(item => item.name).join(", ");
          } else {
            result[key] = '';
          }
        });
        return result;
      };

    const onFilter = () => {
        const filterKeys = ['region', 'country', 'city', 'subject', 'university', 'duration_field_test'];
        const filterValues = extractNamesForKeys(selectedFilters, filterKeys);
        const res = parseAllObjToStr(selectedFilters);
      
        // Track the filter application event
        trackAmplitude("Catalog Filters Applied", {
          ...filterValues
          // You can add any other relevant data you want to track here
        });
      
        getData(res);
      };
    
    useEffect(() => {
        setFilters(initialFilters);
        return () => {
            setFilters([]);
        };
    }, [initialFilters]);
    
    const additionalSelectOnClickHandler = useCallback((values) => {
        setSelectedFilters({...selectedFilters, ...values})
    }, [selectedFilters]);

    return (
        <div className={classNames("catalog-filter", className)}>
            <div className={"catalog-filter__list"}>
                {
                    filters.map(({id, label, name, method, options, selectedSuggestion, disabled}) => (
                        <div key={id} className={"catalog-filter__item"}>
                            {
                                name === "more"
                                    ? <AdditionalSelect
                                        label={label}
                                        placeholder={t("FIELD.NOT_CHOSEN")}
                                        options={ADDITIONAL_SELECTS(selectedTab, tabs)}
                                        isClear={additionalClear}
                                        setIsClear={setAdditionalClear}
                                        setSelectedFilters={setSelectedFilters}
                                        onClick={additionalSelectOnClickHandler}
                                        isResetFilter={isResetFilter}
                                    />
                                    :
                                    <AutoComplete
                                        label={label}
                                        name={name}
                                        placeholder={t("FIELD.NOT_CHOSEN")}
                                        disabled={disabled}
                                        isClear={isClear}
                                        setIsClear={setIsClear}
                                        suggestions={options}
                                        method={method}
                                        handleChange={(option) => handleFilter({option}, name)}
                                        handleClear={handleClear}
                                        selectedSuggestion = {selectedSuggestion || []}
                                        setSelectedFilters = {setSelectedFilters}
                                        selectedFilters={selectedFilters}
                                    />
                            }
                        </div>
                    ))
                }
            </div>
            <div className={"catalog-filter__actions"}>
                <Button
                    className={"catalog-filter__btn btn--text"}
                    text={t("BUTTON.RESET_ALL_FILTERS")}
                    iconLeft={<Icon name={"reset"}/>}
                    onClick={onClearAll}
                />
                <Button
                    className={"catalog-filter__btn btn--secondary btn--pill"}
                    text={t("BUTTON.APPLY")}
                    onClick={onFilter}
                />
            </div>
        </div>
    );
};
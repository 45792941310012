import { ButtonLink, Icon } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { prevSection } from "@utils/functions";
import "./SectionBlocked.scss"


export default function SectionBlocked({ course, courseId, sectionId, isEnrolled }) {
  const {t} = useTranslate();
    return (
        <div className='blockedSection'>
          {
            isEnrolled ?
            <>
              <Icon name={"blocked"} />
              <h3 className='blockedSection-text'>{t("LMS.BLOCKED.LOCKED")}</h3>
              <span className='blockedSection-text'>{t("LMS.BLOCKED.SUBTITLE")}</span>
              <ButtonLink  text={t("LMS.BLOCKED.BUTTON")} 
              link={prevSection(course, courseId, sectionId )} className=" button-primary" />
            </>
            :
            <>
              <Icon name={"blocked"} />
              <h3 className='blockedSection-text'>{t("LMS.BLOCKED.NOT_ENROLLED")}</h3>
            </> 
          }
        </div>
      );
}
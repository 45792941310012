import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@helpers/hooks';
import { UniversityDetailsCheck } from '@utils/functions';
import { BtnLoader, CustomLink, Icon } from '@components/common';
import './Costs.scss';

const Costs = () => {
    const { t } = useTranslate(),
    { university, isUniversityLoading } = useSelector(state => state.university),
    costs = useMemo(() => [
        {
            id: 1, 
            title: t("RECOMMENDATIONS.COSTS.LABEL_5"), 
            value: UniversityDetailsCheck("average_first_year_fin_aid_package", 
            university.cost?.average_first_year_fin_aid_package, t),
        },
        {
            id: 2, 
            title: t("RECOMMENDATIONS.COSTS.LABEL_6"), 
            value: UniversityDetailsCheck("acceptance_rate", university.cost?.percent_of_need_met, t)
        },
        {
            id: 3, 
            title: t("RECOMMENDATIONS.COSTS.LABEL_7"), 
            value: UniversityDetailsCheck("students_receiving_financial_aid", 
            university.cost?.students_receiving_financial_aid, t)
        }
    ], [university, t]),
    NET_CALC_LINKS = [
        {
            id: 1,
            to: university.cost?.net_price_calculator_url,
            isExternal: true,
            target: "_blank",
            text: t("RECOMMENDATIONS.COSTS.LABEL_3"),
        },
        {
            id: 2,
            to: university.cost?.net_price_calculator_url,
            isExternal: true,
            target: "_blank",
            text: <Icon name='external-link' />,
        }
    ]

    return (
        <>
            {!isUniversityLoading ?
                <div className='costs'>
                    <h1 className='costs__title'>
                        {t("RECOMMENDATIONS.DETAILS_TABS.LABEL_4")}
                    </h1>
                    <div className='costs__content'>
                        <div className='costs__content-left'>
                            <div className='net-price'>
                                <h3>{t("RECOMMENDATIONS.COSTS.LABEL_1")}</h3>
                                <p>{UniversityDetailsCheck("net_price_costs", university.cost?.net_price, t)}</p>
                            </div>
                            <div className='tuition-fee'>
                                {t("RECOMMENDATIONS.COSTS.LABEL_2")}{": "}
                                <span>{UniversityDetailsCheck("tuition_fee", university?.tuition_fee, t)}</span>
                            </div>
                            <div className='net-calculator'>
                                {NET_CALC_LINKS.map(item => (    
                                        <CustomLink
                                            key={item.id}
                                            to={item.to}
                                            isExternal={item.isExternal}
                                            target={item.target}
                                        >
                                            {item.text}
                                        </CustomLink>
                                    ))
                                }
                            </div>
                            <p className='info'>{t("RECOMMENDATIONS.COSTS.LABEL_4")}</p>
                        </div>
                        <div className='costs__content-right'>
                            {costs.map(item => (
                                <div key={item.id} className='item'>
                                    <h3>{item.title}</h3>
                                    <p>{item.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                :
                <BtnLoader className={"btn-loader--blue-large"}/>
            }
        </>
        
    );
}

export default Costs;

import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, Divider } from "@components/common";
import classNames from "classnames";

export default function CourseButton({ route, icon, name, isDivider }) {
  return (
    <>
      <NavLink to={route} className={"sidebar__button"}>
        <Icon className={classNames("icon--blue")} name={icon} />
        <p className={classNames("sidebar__button-text")}>{name}</p>
      </NavLink>
      {isDivider && <Divider className={"sidebar__divider"} />}
    </>
  );
}
import React from "react";
import { useState } from "react";
import { useAction, useTranslate } from "@helpers/hooks";
import { AutoComplete, List } from "@components/common";
import { useSelector } from "react-redux";
import {useIsMobile} from "@helpers/hooks";
import "./DashboardAutocomplete.scss";

export default function DashboardAutocomplete({ data, setSelectedFilters, selectedFilters }) {
  const isMobile = useIsMobile()
  const { t } = useTranslate();
  //autocomplete
  const { fetchDirectoryUniversities, fetchSimplePrograms } = useAction();
  const { results, isProgramLoading } = useSelector((state) => state.program); 
  const { directoryUniversities } = useSelector((state) => state.directory); 
  const [isClear, setIsClear] = useState(false);

  const filters =  [
      {
        id: 1,
        label: t("DASHBOARD.CHANCES.TEXT_1"),
        name: "university",
        method: fetchDirectoryUniversities,
        options: directoryUniversities,
        selectedSuggestion: isProgramLoading ? [] : selectedFilters.university || [],
        setSelectedFilters: setSelectedFilters,
      },

      {
        id: 2,
        label: t("DASHBOARD.CHANCES.TEXT_2"),
        name: "program",
        method: ({ search }) =>
        fetchSimplePrograms({ search, university: selectedFilters.university[0].value }),
        selectedSuggestion: selectedFilters.program,
        options: results || [],
        selectedSuggestion: selectedFilters.program || [],
        setSelectedFilters: setSelectedFilters,
      },
    ]

  const handleFilter = (option, name) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: option.option }));
  };

  const handleClear = (name) => {
    setSelectedFilters((prev) => ({ ...prev, [name]: [] }));
  };
  return (
    <div className={isMobile ? "dashboard__filters-mobile" : "dashboard__filters"}>
      <List
        className={"dashboard__inputs"}
        items={filters}
        renderItem={(item) => (
          <AutoComplete
            key={item.id}
            isOne={true}
            label={item.label}
            name={item.name}
            placeholder={t("FIELD.NOT_CHOSEN")}
            disabled={false}
            isClear={isClear}
            setIsClear={setIsClear}
            suggestions={item.options}
            method={item.method}
            handleChange={(option) => handleFilter(option, item.name)}
            handleClear={handleClear}
            selectedSuggestion={item.selectedSuggestion}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
          />
        )}
      />
    </div>
  );
}

import { Admissions } from "./Admissions";
import { Costs } from "./Costs";
import { EntryRequirements } from "./EntryRequirements";
import { Major } from "./Major";
import { MajorList } from "./MajorList";
import { MajorProgramCard } from "./MajorProgramCard";
import { MyPreference } from "./MyPreference";
import { Overview, OverviewCosts, OverviewIntro, OverviewRanking } from "./Overview";
import { RecommendedUniversities } from "./RecommendedUniversities";
import { RecommendationTabs } from "./RecommendationTabs";
import { UniversityCard } from "./UniversityCard";
import { UniversityDetails } from "./UniversityDetails";
import { UniversityDetailsPreview } from "./UniversityDetailsPreview";
import { UniversityDetailsTabs } from "./UniversityDetailsTabs";
import { UniversityListRecommended } from "./UniversityListRecommended";

export {
    Admissions,
    Costs,
    EntryRequirements,
    Major,
    MajorList,
    MajorProgramCard,
    MyPreference, 
    Overview,
    OverviewCosts,
    OverviewIntro,
    OverviewRanking,
    RecommendedUniversities, 
    RecommendationTabs,
    UniversityCard, 
    UniversityDetails, 
    UniversityDetailsTabs,
    UniversityDetailsPreview,
    UniversityListRecommended
};

import {Icon} from "@components/common";
import classNames from "classnames";
import "./SelectOption.scss";

export default function SelectOption({showOptions, selectedOption, placeholder, showOptionsHandler, icon}) {
    return (
        <div
            className={classNames("select-option", {"active": showOptions})}
            onClick={showOptionsHandler}
        >
            <div className={"select-option__name"}>
                {icon && <Icon name={icon}/>}
                <p className={"select-option__text"}>
                    {
                        selectedOption.name && selectedOption.value
                            ? selectedOption.name
                            : placeholder
                    }
                </p>
            </div>
            <Icon name={showOptions ? "arrow-up" : "arrow-down"}/>
        </div>
    );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activities {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}
.activities textarea {
  resize: none;
  height: 20vh;
}
.activities input {
  width: 360px;
}
.activities__row {
  display: flex;
  column-gap: 28px;
}

.move-right {
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 40px;
}

.add-activity-modal-buttons {
  align-self: flex-end;
  display: flex;
  column-gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Profile/Activities/ActivityForm/ActivityForm.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AADF;AAGE;EACE,YAAA;EACA,YAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,aAAA;EACA,gBAAA;AAHJ;;AAOA;EACE,oBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AAJF;;AAOA;EACE,oBAAA;EACA,aAAA;EACA,gBAAA;AAJF","sourcesContent":["@import \"@styles/variables\";\n\n.activities {\n  display: flex;\n  flex-direction: column;\n  row-gap: 28px;\n\n  textarea {\n    resize: none;\n    height: 20vh;\n  }\n\n  input {\n    width: 360px;\n  }\n\n  &__row {\n    display: flex;\n    column-gap: 28px;\n  }\n}\n\n.move-right {\n  align-self: flex-end;\n  width: fit-content;\n  margin-right: 40px;\n}\n\n.add-activity-modal-buttons {\n  align-self: flex-end;\n  display: flex;\n  column-gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

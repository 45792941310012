import { useMemo, useState, useEffect } from 'react';
import { getLessonOptions } from '@utils/functions';
import { useTranslate } from '@helpers/hooks';
import { CourseNotesItem } from '../CourseNotesItem';
import { FormItem, List } from '@components/common';
import './CourseNotesContent.scss';

const CourseNotesContent = ({notes}) => {
    const { t } = useTranslate(),
    lessonOptions = getLessonOptions(notes),
    [selectedFilter, setSelectedFilter] = useState(notes && lessonOptions[0]),
    [filteredNotes, setFilteredNotes] = useState(notes),
    filters = useMemo(() => [
        {
            id: 1,
            label: t("BUTTON.FILTER"),
            field: "dropdown",
            name: "notes",
            defaultValue: selectedFilter,
            options: lessonOptions,
        },
    ], [t, selectedFilter, lessonOptions]);


    const handleChange = async (e) => {
      setSelectedFilter({
        name: e.value,
        value: e.value,
      });
    };

    useEffect(() => {
      if (selectedFilter.value === "LMS.NOTES.ALL_NOTES") {
        setFilteredNotes(notes);
      } else {
        setFilteredNotes(notes.filter((note) => note.lesson_id.title === selectedFilter.value));
      }
    }, [selectedFilter, notes]);

  return (
    <div className="course_notes">
      <h1 className="course_notes__title">{t("LMS.NOTES.TITLE")}</h1>
      <List
        items={filters}
        renderItem={(item) => <FormItem key={item.id} item={item} handleChange={handleChange} />}
      />
      <List
        className={"course_notes__list"}
        items={filteredNotes}
        renderItem={(note) => <CourseNotesItem key={note.id} note={note}/> }
      />
    </div>
  );
}

export default CourseNotesContent;
import React, { useState } from "react";
import { FormItem, BtnLoader, Button, Icon, List } from "@components/common";
import { MajorProgramCard } from "@components/feature";
import { useIsMobile } from "@helpers/hooks";

export default function MajorList({
  universityInfo,
  results,
  programLoading,
  isFilters,
  selectedSubject,
  handleChange,
}) {
  const isMobile = useIsMobile();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      {isFilters && (
        <div className="major__header">
          {isMobile && (
            <div className="major__filter">
              <Button className="btn__filter" iconLeft={<Icon name="filter2" />} text={"Filter"} />
              <Icon
                name={isFilterOpen ? "arrow-up" : "arrow-down"}
                handleClick={() => toggleFilter()}
              />
            </div>
          )}
          <List
            className={isFilterOpen ? "major__filter-open" : "major__filter-closed"}
            items={universityInfo.header}
            renderItem={(item) => (
              <FormItem key={item.id} item={item} handleChange={handleChange} />
            )}
          />
        </div>
      )}
      <div className="major__info">
        {results && !programLoading ? (
          results.map((item) => (
            <MajorProgramCard key={item.id} item={item} selectedSubject={selectedSubject} />
          ))
        ) : (
          <div className="parent-loader">
            <BtnLoader className={"btn-loader--blue-large"} />
          </div>
        )}
      </div>
    </>
  );
}

import React, { useCallback } from "react";
import { useTranslate } from "@helpers/hooks";
import { Button } from "@components/common";
import "./ProgramCard.scss";

export default function ProgramCard({ info_items, handleClick, buttonText, action_menu, status }) {
  const { t } = useTranslate();
  const getContent = useCallback(
    (item) => {
      switch (item.name) {
        case t("RECOMMENDATIONS.MAJOR.LABEL_3"):
          return (
            <div className="program__info-group" key={item.id}>
              <div className="program__info-group__program">
                <img src={item.value[0]} />
                <p className="program__text">{item.value[1]}</p>
              </div>
            </div>
          );

        case t("FAVORITE.STATUS.TITLE"):
          return (
            <div className="program__info-item" key={item.id}>
              {status ? (
                <div className="program__info-item__status">{status}</div>
              ) : (
                <div className={`program__info-status ${item.value ? "blue-bg" : "green-bg"}`}>
                  <div className={`program__info-circle ${item.value ? "blue-circle" : "green-circle"}`}></div>
                  <p className={`program__text ${item.value ? "program__applying" : "program__saved"}`}>
                    {item.value ? t("common:APPLICATION.STATUS.APPLYING") : t("common:APPLICATION.STATUS.SAVED")}
                  </p>
                </div>
              )}
            </div>
          );

        case t("RECOMMENDATIONS.MAJOR.LABEL_9"):
          return (
            <div className="program__info-item" key={item.id}>
              <p className="program__text">
                {item.value[0] != undefined && item.value[0] != undefined ? `${item.value[0]}, ${item.value[1]}` : "-"}
              </p>
            </div>
          );
        case t("RECOMMENDATIONS.MAJOR.LABEL_11"):
          return (
            <div className="program__info-item" key={item.id}>
              <p className="program__text">{item.value} (USD) / year</p>
            </div>
          );
        default:
          return (
            <div className="program__info-item" key={item.id}>
              <p className="program__text">{item.value}</p>
            </div>
          );
      }
    },
    [info_items]
  );

  return (
    <div className="program">
      <div className="program__info">
        {info_items.map((item) => getContent(item))}
        {action_menu}
      </div>
      <Button onClick={handleClick} text={buttonText} className="btn--rounded btn--white" />
    </div>
  );
}

import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { CustomLink, Icon, Image } from "@components/common";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";
import ReactMarkdown from "react-markdown";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { trackAmplitude } from "@utils/functions";
import { KASPI_PAYMENT_ROUTE } from "@utils/consts";
import kaspiBlackTextWithLogo from "../../../../assets/kaspikz-logo-1.png"
import "./PopupTariffsCard.scss";

export default function PopupTariffsCard({ tariff, course }) {
  const isMobile = useIsMobile();
  const history = useHistory();
  const {t} = useTranslate();

  function formatNumber(number) {
    return new Intl.NumberFormat("en-US", { useGrouping: true }).format(number).replace(/,/g, " ");
  }

  function formatOldPrice(number) {
    let newNumber = (Math.round((number*10)/9000))*1000
    return new Intl.NumberFormat("en-US", { useGrouping: true }).format(newNumber).replace(/,/g, " ");
  }

  const titleClassName = classNames({
    "tariff__list-title-silver": tariff.plan_type === "Silver",
    "tariff__list-title-gold": tariff.plan_type === "Gold",
    "tariff__list-title-platinum": tariff.plan_type === "Platinum",
  });

  const cardClassName = classNames({
    "tariff__list-card-silver": tariff.plan_type === "Silver",
    "tariff__list-card-gold": tariff.plan_type === "Gold",
    "tariff__list-card-platinum": tariff.plan_type === "Platinum",
  });

  const priceClassName = classNames({
    "tariff__list-price-silver": tariff.plan_type === "Silver",
    "tariff__list-price-gold": tariff.plan_type === "Gold",
    "tariff__list-price-platinum": tariff.plan_type === "Platinum",
  });

  

  const textClassName = classNames({
    "tariff__list-text-silver": tariff.plan_type === "Silver",
    "tariff__list-text-gold": tariff.plan_type === "Gold",
    "tariff__list-text-platinum": tariff.plan_type === "Platinum",
  });

  const btnClassName = classNames({
    "btn--main-white": tariff.plan_type === "Gold",
    "btn--primary": tariff.plan_type === "Platinum" || tariff.plan_type === "Silver",
  });

  const isTariffAnotherCase = tariff.plan_type !== "Silver" && tariff.plan_type !== "Gold" && tariff.plan_type !== "Platinum";

  let imageLink;

  switch (tariff.plan_type) {
    case "Silver":
      imageLink = "silver.png";
      break;

    case "Gold":
      imageLink = "gold.png";
      break;

    case "Platinum":
      imageLink = "platinum.png";
      break;

    default:
      imageLink = "platinum.png";
  }


  const handleLinkClick = () => {
    trackAmplitude("learning_platfrom_tariff_buy_click", {
      description: 'Пользователь успешно выбрал тариф курса для покупки',
      course_name: course.title,
      package_type: tariff.plan_type,
      course_price: tariff.cost
    });
  };

  const packageId = course.package_types.find(item => item.name === tariff.plan_type)?.id;
  const packageType = course.package_types.find(item => item.name === tariff.plan_type)?.name;
  const KASPI_REDIRECT_ROUTE = KASPI_PAYMENT_ROUTE
    .replace(":type", "course")
    .replace(":courseId", course.id)
    .replace(":packageId", packageId)
    .replace(":courseName", course.title)
    .replace(":packageType", packageType)
    .replace(":price", tariff.cost)
  ;

  const handleRedirectToKaspi = () => {
    history.push(KASPI_REDIRECT_ROUTE);
  }
    
  return (
    <li key={tariff.plan_type} className={`${isMobile ? `tariff__list-card-mobile` : `tariff__list-card`} ${cardClassName}`}>
      <div className="tariff__list-group">
        <p className={`tariff__list-title ${titleClassName}`}>{tariff.plan_type}</p>
        <div className="tariff__list-price-group">
          <p className={`tariff__list-price ${priceClassName}`}>{formatNumber(tariff.cost)} ₸</p>
          <p className={`tariff__list-price-old ${priceClassName}`}>{formatOldPrice(tariff.cost)} ₸</p>
        </div>
        <div className={`tariff__list-text ${textClassName}`}>
          <ReactMarkdown remarkPlugins={[remarkGfm, remarkEmoji]}>{tariff.text}</ReactMarkdown>
        </div>
      </div>
      <div className="tariff__list-buttons">

        <div className="mock__card-popup-bottom">
        <button
          className="btn btn--red btn--pill"
          onClick={handleRedirectToKaspi}
        >
          <Icon name="kaspipay" />
        </button>
      </div>

        <CustomLink
          to={tariff.payment_link}
          className={`btn btn--pill ${isTariffAnotherCase ? "btn--primary" : btnClassName}`}
          isExternal={true}
          onClick={handleLinkClick}
        >
          {t("BUTTON.BUY")}
        </CustomLink>
      </div>

    </li>
  );
}

import { Button, ButtonV2, CustomLink } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { getIntro } from "@utils/functions";
import { useParams } from "react-router-dom";
import React from "react";
import "./JoinChat.scss";

export default function JoinChat() {
  const isMobile = useIsMobile()
  const {t} = useTranslate()
  const { courseId } = useParams();
  const intro = getIntro(courseId)
  return (
     <div className="chat">
      <div className="step__subtitle-group">
        <h4 className={`${isMobile && "step__title-mobile"} white step__title`}>{t(intro.join.title)}</h4>
        <p className="step__description">{t(intro.join.description)}</p>
      </div>

      <CustomLink isExternal={true} to={intro.join.link}>
        <ButtonV2 className="button--transparent white" text={t(intro.join.button)} iconRight={"arrow-right"} />
      </CustomLink>
    </div>
  );
}

import { FormItem, List } from '@components/common';
import './ExamsBlock.scss';

const ExamsBlock = ({items, blockTitle, handleChange}) => {
    return (
        <div className="exams-block">
            <p className="exams-block__title">{blockTitle}</p>
            <List
                className="exams-block__list"
                items={items}
                renderItem={(item) => (
                    <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                )}
            />
        </div>
    );
}

export default ExamsBlock;

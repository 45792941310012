// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radar {
  width: 100%;
  height: 450px;
}
@media screen and (max-width: 524px) {
  .radar tspan {
    font-size: 12px;
  }
}
@media screen and (max-width: 490px) {
  .radar tspan {
    font-size: 10px;
  }
}
@media screen and (max-width: 425px) {
  .radar tspan {
    font-size: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Chart/CustomRadar/CustomRadar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AACE;EACE;IACE,eAAA;EACJ;AACF;AAEE;EACE;IACE,eAAA;EAAJ;AACF;AAGE;EACE;IACE,cAAA;EADJ;AACF","sourcesContent":[".radar {\n  width: 100%;\n  height: 450px;\n\n  @media screen and (max-width: 524px){\n    tspan {\n      font-size: 12px;\n    }\n  }\n\n  @media screen and (max-width: 490px){\n    tspan {\n      font-size: 10px;\n    }\n  }\n\n  @media screen and (max-width: 425px){\n    tspan {\n      font-size: 8px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz__image {
  width: 100%;
  max-width: 750px;
  border-radius: 20px;
}
.quiz__images {
  display: flex;
  flex-direction: column;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/LessonQuizQuestions/ImageQuestion/ImageQuestion.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ","sourcesContent":["@import \"@styles/variables\";\n\n.quiz {\n  &__image {\n    width: 100%;\n    max-width: 750px;\n    border-radius: 20px;\n  }\n\n  &__images {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

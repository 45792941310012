import React, { useState } from "react";
import { ScholarshipsFilter } from "@components/feature";
import { useDebounce } from "@helpers/hooks";
import { ScholarshipsList } from "../ScholarshipsList";

export default function ScholarshipsSearch({scholarships}) {
  const initialFilters = {
    country: [],
    program: [],
    primary_study_level: [],
  };
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  

  
  return (
    <div>

      <ScholarshipsFilter
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        className={""}
      />

      <ScholarshipsList scholarships={scholarships} />
    </div>
  )
}

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate, useWindowSize } from "@helpers/hooks";
import { logout } from "@utils/functions";
import { STP_OPEN_SIDE } from "@utils/consts";
import { MobileNavItem } from "../MobileNavItem";
import { PageTitle } from "@components/common";
import { List } from "@components/common";
import './MobileProfile.scss';

const MobileProfile = () => {
    const {t} = useTranslate(),
    history = useHistory(),
    windowSize = useWindowSize(),
    MOBILE_ROUTES = [
        {
            id: 1,
            icon: "profile-v2",
            route: "/settings",
            title: t("HEADER.PROFILE.MY_PROFILE"),
        },
        {
            id: 2,
            icon: "globe",
            route: "/language-mobile",
            title: t("MOBILE.SYSTEM_LANGUAGE"),
    },
        {
            id: 4,
            icon: "help",
            route: `${STP_OPEN_SIDE}/faq/`,
            title: t("MOBILE.HELP"),
            isExternal: true
        },
        {
            id: 5,
            icon: "logout",
            route: `${STP_OPEN_SIDE}/auth/`,
            title: t("HEADER.PROFILE.LOGOUT"),
            isExternal: true,
            handleClick: logout,
        },
    ];

    useEffect(() => {
        if (windowSize.width > 481) history.push("/profile");
    }, [windowSize]);

    return (
        <div className="mobile-profile">
            <PageTitle title={'MOBILE.PERSONAL_ACC'}/>
            <List
                items={MOBILE_ROUTES}
                className="mobile-profile__list"
                renderItem={({ id, icon, route, title, description, isExternal, handleClick }) => (
                    <MobileNavItem key={id} icon={icon} route={route} title={title} description={description} isExternal={isExternal} handleClick={handleClick} />
                )}
            />
        </div>
    );
}

export default MobileProfile;

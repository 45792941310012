import { createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import { fetchCoins, fetchCoinsHistory } from "@store/actions/coinAction";
import { notify } from "@utils/functions";

const initialState = {
  coins: null,
  history: [],
  isLoading: false,
};

export const coinSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setCourseNotifier: (state, action) => {
      state.notifier = action.payload;
    },
  },
  extraReducers: {
    [fetchCoins.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchCoins.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.coins = action.payload.coins;
    },
    [fetchCoins.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    [fetchCoinsHistory.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchCoinsHistory.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.history = action.payload;
    },
    [fetchCoinsHistory.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setCourseNotifier } = coinSlice.actions;

export default coinSlice.reducer;

import React from "react";
import { Image } from "@components/common";
import { useIsMobile } from "@helpers/hooks";
export default function LmsRatingTeritaryCard({ user, teritaryUsers }) {
  const isMobile = useIsMobile();

  

  function isLast(array, element) {
    return array.indexOf(element) === array.length - 1;
  }
  return (
    <div
      key={user.id}
      className={`rating__table-record ${
        isMobile ? "rating__table-record-mobile" : "rating__table-record-web"
      } ${isLast(teritaryUsers, user) && `rating__table-record-last`}`}
    >
      <div className={isMobile ? "rating__text-mobile" : "rating__text"}>
        {user.position}
      </div>
      <div className="rating__table-username">
        {user?.user_image && (
          <Image src={user.user_image} className="rating__table-image" />
        )}
        {user?.user?.user_image && (
          <Image src={user?.user?.user_image} className="rating__table-image" />
        )}
        <p className={isMobile ? "rating__text-mobile" : "rating__text"}>
          {user.user_name || user.user.user_name}
        </p>
      </div>
      <div className={isMobile ? "rating__text-mobile" : "rating__text"}>
        {user.level || user.grade}{" "}
      </div>
      <div className={isMobile ? "rating__text-mobile" : "rating__text"}>
        {user.coins || user.grade || 0}{" "}
      </div>
      <div className={isMobile ? "rating__text-mobile" : "rating__text"}>
        {user.points}
      </div>
    </div>
  );
}

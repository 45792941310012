import parseArrToStr from "./parseArrToStr";

const parseAllObjToStr = (obj) => {
    let res = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value.length > 0) {
            res = {
                ...res,
                [key]: parseArrToStr(value)
            }
        }
    }
    return res;
};

export default parseAllObjToStr;
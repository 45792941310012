import {useMemo, useState} from "react";
import {useAction, useTranslate} from "@helpers/hooks";
import {debounce, numberFormat, numberFormatWithDash} from "@utils/functions";
import {ExamsBlock} from "@components/feature";
import {ButtonV2, FormItem, List} from "@components/common";
import "./Exams.scss";


const LANGUAGE_EXAMS = ['ielts_score', 'act_score', 'toefl_score', 'sat_score']
const LANGUAGE_EXAM_TYPE = {
  'ielts_score': 1,
  'act_score': 5, 
  'toefl_score': 2,
  'sat_score': 4,
}
const getLanguageTestScore = (lang_tests, type) => {
  return lang_tests.find(test => test.exam_type === LANGUAGE_EXAM_TYPE[type])?.points
}
const updateLangTest = (lang_tests, type, points) => {
    const exam_type = LANGUAGE_EXAM_TYPE[type]
    const test = {...lang_tests.find(test => test.exam_type === exam_type), exam_type, points}
    return [...lang_tests.filter(test => test.exam_type !== exam_type), test]
}


export default function Exams({myEducation, handleOnClick}) {
    const {t} = useTranslate(),
      {updateEducation} = useAction(),
      initialValues = useMemo(() => ({
        ib_score: myEducation.ib_score,
        has_cover_letter: myEducation.has_cover_letter,
        has_recommendation: myEducation.has_recommendation,
        lang_tests: myEducation.lang_tests,
        duolingo_score: myEducation.duolingo_score,
      }), [myEducation]),
      [values, setValues] = useState(initialValues),
      EXAM_ITEMS = useMemo(() => [
        {
          id: 1,
          label: "PROFILE.EXAM_ITEMS.LABEL_1",
          placeholder: "1600",
          field: "input",
          name: "sat_score",
          type: "number",
          min: 400,
          max: 1600,
          maxLength: 4,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "sat_score"),
          onKeyPress: numberFormat,
          validateRange: true
        },
        {
          id: 2,
          label: "PROFILE.EXAM_ITEMS.LABEL_2",
          placeholder: "5",
          field: "input",
          name: "act_score",
          type: "number",
          min: 1,
          max: 36,
          maxLength: 2,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "act_score"),
          validateRange: true
        },
        {
          id: 3,
          label: "PROFILE.EXAM_ITEMS.LABEL_3",
          placeholder: "45",
          field: "input",
          name: "ib_score",
          type: "number",
          value: values.ib_score,
          min: 24,
          max: 45,
          maxLength: 2,
          boxWidth: true,
          onKeyPress: numberFormat,
          validateRange: true
        },
        {
          id: 4,
          label: "PROFILE.EXAM_ITEMS.LABEL_4",
          placeholder: "9.0",
          field: "input",
          name: "ielts_score",
          type: "number",
          min: 1,
          max: 9,
          step: "0.5",
          maxLength: 3,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "ielts_score"),
          onKeyPress: numberFormatWithDash,
          validateRange: true
        },
        {
          id: 5,
          label: "PROFILE.EXAM_ITEMS.LABEL_5",
          placeholder: "120",
          field: "input",
          name: "toefl_score",
          type: "number",
          min: 0,
          max: 120,
          maxLength: 3,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "toefl_score"),
          onKeyPress: numberFormatWithDash,
          validateRange: true
        },
        {
          id: 6,
          label: "PROFILE.EXAM_ITEMS.LABEL_6",
          placeholder: "1",
          field: "input",
          name: "duolingo_score",
          type: "number",
          value: values.duolingo_score,
          min: 0,
          max: 160,
          maxLength: 3,
          boxWidth: true,
          onKeyPress: numberFormat,
          validateRange: true
        },
      ], [values]),
      LETTERS_ITEMS = useMemo(() => [
        {
          id: 1,
          label: "PROFILE.LETTERS_ITEMS.LABEL_1",
          field: "radio",
          placeholder: "PROFILE.LETTERS_ITEMS.LABEL_3",
          name: "has_cover_letter",
          value: values.has_cover_letter,
          options: [
              {
                  id: "r-1",
                  label: "PROFILE.LETTERS_ITEMS.OPTION_1",
                  name: "message",
                  value: true
              },
              {
                  id: "r-2",
                  label: "PROFILE.LETTERS_ITEMS.OPTION_2",
                  name: "message",
                  value: false
              }
          ],
        },
        {
          id: 2,
          label: "PROFILE.LETTERS_ITEMS.LABEL_2",
          field: "radio",
          placeholder: "PROFILE.LETTERS_ITEMS.LABEL_4",
          name: "has_recommendation",
          value: values.has_recommendation,
          options: [
              {
                  id: "r-3",
                  label: "PROFILE.LETTERS_ITEMS.OPTION_1",
                  name: "recommendation",
                  value: true
              },
              {
                  id: "r-4",
                  label: "PROFILE.LETTERS_ITEMS.OPTION_2",
                  name: "recommendation",
                  value: false
              }
          ],
        },
      ], [values]);

      const BLOCK_EXAM_ITEMS = useMemo(() => [
        {
          id: 1,
          blockTitle: t("PROFILE.EXAM_ITEMS.LABEL_7"),
          items: EXAM_ITEMS.slice(0, 3)
        },
        {
          id: 2,
          blockTitle: t("PROFILE.EXAM_ITEMS.LABEL_8"),
          items: EXAM_ITEMS.slice(3, 6)
        }
      ], [EXAM_ITEMS, t]);

    const handleDebounce = useMemo(() => debounce(async (data) => {
        await updateEducation({id: myEducation.id, data});
        // eslint-disable-next-line
    }), []);

    const handleChange = async (e, field, name) => {
      if (field === "select") {
          setValues(prev => ({...prev, [name]: e.value}));
          await handleDebounce({[name]: e.value});
      }else if (field === "dropdown") {
          setValues(prev => ({...prev, [name]: e.value}));
          await handleDebounce({[name]: e.value});
      } else {
          if (name === "has_cover_letter" || name === "has_recommendation") {
              const value = e.target.value === "true";
              setValues(prev => ({...prev, [name]: value}));
              await handleDebounce({[name]: value});
              return;
          }
          let {value, min, max, maxLength} = e.target;
          if (value.length >= 2 && value[0] === "0" && value[1] === "0") {
              value = value.substring(1);
          }

          if (name === "ielts_score") {
              const splitValue = value.split(".");
              if (splitValue.length === 2) {
                  if (+splitValue[1] >= 5) {
                      value = `${splitValue[0]}.5`;
                  } else {
                      value = `${splitValue[0]}.0`;
                  }
              }
          }

          if (maxLength !== -1) {
              value = value.slice(0, maxLength);
          }

          // if ((+value > max) || (value && (+value < min))) {
          //     e.target.style.border = '2px solid #EE3153';
          // } else {
          //     e.target.style.border = '2px solid #FFFFFF';
          // }

          if (value.length === 0) {
              value = null;
          }

          if (LANGUAGE_EXAMS.includes(name)) { // update lang_tests array
              setValues(prev => ({...prev, lang_tests: updateLangTest(prev.lang_tests, name, value)}));
              await handleDebounce({lang_tests: updateLangTest(values.lang_tests, name, value)});
          } else { // update individual field
              setValues(prev => ({...prev, [name]: value}));
              await handleDebounce({[name]: value});
          }
      }
    }

    return (
        <div className={"exams"}>
            <List
              className="exams__blocks"
              items={BLOCK_EXAM_ITEMS}
              renderItem={(item) => (
                  <ExamsBlock
                    key={item.id}
                    items={item.items}
                    blockTitle={t(item.blockTitle)}
                    handleChange={handleChange}
                  />
                )}
            />
            <List
                className="exams__letters"
                items={LETTERS_ITEMS}
                renderItem={(item) => (
                    <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                )}
            />
            <ButtonV2
              className="button--primary"
              text="Next"
              onClick={handleOnClick}
              iconRight="arrow-right"
            />
        </div>
    );
};
import React from "react";
import { NewModalLayout, Icon, Button, ButtonLink } from "@components/common";
import { MOCK_EXAM_ONGOING } from "@utils/consts";
import { useTranslate } from "@helpers/hooks";

const InstructionModal = ({ isMobile, selectedItem, onClose }) => {
  const { t } = useTranslate();
  const handleInstructions = (type) => {
    const instructionsMap = {
      nuet_mock: "MOCK.NUET_INSTRUCTION",
      ielts_mock: "MOCK.IELTS_INSTRUCTION",
    };
    return instructionsMap[type] || null;
  };

  return (
    <NewModalLayout
      className={`pop-up ${
        isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`
      }`}
    >
      <div className="tariff__title-button">
        <h3 className="">{selectedItem.title}</h3>
        <Icon
          name="close-rounded"
          className="lms-badge-profile__close"
          handleClick={onClose}
        />
      </div>

      <p>{t(handleInstructions(selectedItem.type))}</p>
      <div className="mock__warning">
        <Icon name={`lms-warning`} />
        <p>{t("MOCK.WARNING")}</p>
      </div>

      <div className="popup__buttons">
        <Button
          className="button-secondary popup__button"
          text={t("MOCK.CANCEL")}
          onClick={() => onClose()}
        />
        <ButtonLink
          className="button-primary popup__button"
          text={t("MOCK.START_EXAM")}
          link={MOCK_EXAM_ONGOING.replace(":mockId", selectedItem.id)}
        />
      </div>
    </NewModalLayout>
  );
};

export default InstructionModal;


import { List } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import "./Conditions.scss";


export default function Conditions({ course }) {
    const {t} = useTranslate()
    const info = [
        {
            "key": t("LMS.COURSE.INFO.COMMITMENT"),
            "value": course.commitment, 
        },
        {
            "key": t("LMS.COURSE.INFO.LANGUAGE"),
            "value": t("LMS.COURSE.INFO.ENGLISH"),
        },
        {
            "key": t("LMS.COURSE.INFO.PASS"),
            "value": t("LMS.COURSE.INFO.PASS_CONDITION"),
        },
    ]

    return (
        <div className="conditions">
            <List 
                items={info}
                renderItem={(item) => (
                    <section key={item.key}>
                        <p className="condition-header">{item.key}</p>
                        <p className="condition-body">{item.value}</p>
                    </section>
                )}
            />
        </div>
    )
}
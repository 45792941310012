import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAction, useTranslate, useIsMobile, useScrollToTop } from "@helpers/hooks";
import { BtnLoader, Icon, LayoutContainer, PageTitle } from "@components/common";
import {
  Admissions,
  Major,
  Overview,
  UniversityDetailsTabs,
  UniversityDetailsPreview,
} from "@components/feature";
import "./UniversityDetails.scss";

const UniversityDetails = ({ setUniversityOpen, universityId, selectedFilters }) => {
  const isMobile = useIsMobile()
  const { t } = useTranslate(),
  { university, isUniversityLoading } = useSelector(state => state.university),
    { fetchUniversity } = useAction(),
    TABS = {
      OVERVIEW: "RECOMMENDATIONS.DETAILS_TABS.LABEL_1",
      ADMISSIONS: "RECOMMENDATIONS.DETAILS_TABS.LABEL_2",
      MAJOR: "RECOMMENDATIONS.DETAILS_TABS.LABEL_3",
    },
    [currentTab, setCurrentTab] = useState(TABS.OVERVIEW);

  useEffect(() => {
    fetchUniversity(universityId);
  }, []);

  const handleClickProgram = () => {
    setCurrentTab(TABS.MAJOR);
  };

  useScrollToTop();

  const getContent = (tab) => {
    switch (tab) {
      case TABS.OVERVIEW:
        return (
          <LayoutContainer>
            <Overview />
          </LayoutContainer>
        );
      case TABS.ADMISSIONS:
        return (
          <>
            <LayoutContainer>
              <Admissions />
            </LayoutContainer>
          </>
        );
      case TABS.MAJOR:
        return (
          <LayoutContainer>
            <Major selectedFilters={selectedFilters} />
          </LayoutContainer>
        );
      default:
        return <LayoutContainer></LayoutContainer>;
    }
  };

  return (
    <div className="university-details">
     {isMobile ?  <PageTitle title={university?.title} iconLeft={'arrow_title'} handleLeftClick={() => setUniversityOpen(false)} /> :  <div
        className="btn-back-to-list"
        onClick={() => setUniversityOpen(false)}
      >
        <Icon name="arrow-left" />
        <p>{t("BUTTON.BACK_TO_LIST")}</p>
      </div>}

      {Object.keys(university).length !== 0 && !isUniversityLoading ? (
        <>
          <UniversityDetailsPreview
            university={university}
            onClickSeeProgram={handleClickProgram}
          />
          <UniversityDetailsTabs
            classNameTabs={"university-details__tabs university-details__tabs-main"}
            classNameTab={"university-details__tab"}
            tabs={Object.values(TABS)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {getContent(currentTab)}
        </>
      ) : (
        <div className="parent-loader">
          <BtnLoader className={"btn-loader--blue-large"} />
        </div>
      )}
    </div>
  );
};

export default UniversityDetails;

import { axiosRequest } from "@api/xhr";

export default class MockService {
  static async fetchChallenge(id) {
    return await axiosRequest.get(`directory/challenges/${id}`);
  }

  static async fetchChallenges() {
    return await axiosRequest.get(`directory/challenges`);
  }

  static async getChallengeProgress(id) {
    return await axiosRequest.get(`directory/challenges/start/${id}/`);
  }

  static async submitChallengeAnswers(id, data) {
    return await axiosRequest.post(
      `directory/challenges-responses/save/${id}/`,
      data
    );
  }
  static async getChallengeRating(id) {
    return await axiosRequest.get(`directory/challenges/rating/${id}/`);
  }
}

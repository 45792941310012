import { Icon, Image, NewModalLayout } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./HeaderCoins.scss";
import { useState } from "react";
import { CoinHistory } from "@components/feature";

export default function HeaderCoins() {
  const { fetchCoins } = useAction();
  const { coins } = useSelector((state) => state.coins);
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslate();
  const handlePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchCoins();
  }, []);

  return (
    <>
      <div className="coins" onClick={handlePopup}>
        <Image name={"coin.png"} className={"coins__img"} />
        {coins || 0}
      </div>
      {isOpen && (
        <NewModalLayout className={"coins__modal"}>
          <div className="coins__title">
            <h3 className="">{t("LMS.RANKING.HISTORY")}</h3>
            <Icon
              name="close-rounded"
              className="lms-badge-profile__close"
              handleClick={handlePopup}
            />
          </div>
          <h3></h3>
          <CoinHistory />
        </NewModalLayout>
      )}
    </>
  );
}

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction, useTranslate } from '@helpers/hooks';
import { BtnLoader, List, NewModalLayout } from '@components/common';
import { EssayModal, EssaysHeader, EssaysSpecificListItem } from '@components/feature';
import './UniversitySpecificEssays.scss';

const UniversitySpecificEssays = ({onSelectEssay, onEditEssay, isEditOpenInEssays, setIsEditOpenInEssays, essayId}) => {
    const { t } = useTranslate(),
    [newEssayModalOpen, setNewEssayModalOpen] = useState(false),
    [foundEssay, setFoundEssay] = useState(null),
    { fetchUniversitySpecificEssays } = useAction(),
    { universitySpecificEssays, isLoading, isUpdating } = useSelector(state => state.essays);
    
    useEffect(() => {
        fetchUniversitySpecificEssays();
    }, [isUpdating])

    useEffect(() => {
        if (universitySpecificEssays) {
            const essay = universitySpecificEssays?.results?.find(essay => essay.id === essayId);
            setFoundEssay(essay);
        }
    }, [universitySpecificEssays, essayId])

    const handleAddNewEssay = () => {
        setNewEssayModalOpen(true);
    }

    return (
        <div className='university-specific-essays'>
            <EssaysHeader headerTitle={t("ESSAY.LABELS.LABEL_2")} btnOnClick={handleAddNewEssay} />
            {
                !isUpdating && !isLoading && universitySpecificEssays.results ?
                    <List 
                        className={"essays-list"}
                        items={universitySpecificEssays.results}
                        renderItem={(item) => (
                            <EssaysSpecificListItem
                                key={item.id}
                                item={item}
                                onSelectEssay={onSelectEssay}
                                onEditEssay={onEditEssay}
                            />
                        )}
                    />
                : 
                <div className="parent-loader">
                    <BtnLoader className={"btn-loader--blue-large"} />
                </div>
            }
            {
                newEssayModalOpen && 
                <NewModalLayout>
                    <EssayModal
                        title={t("ESSAY.LABELS.LABEL_9")}
                        isModalOpen={newEssayModalOpen}
                        setIsModalOpen={setNewEssayModalOpen}
                        isEdit={false}
                        isUniversitySpecific={true}
                    />
                </NewModalLayout>
            }
            {
                isEditOpenInEssays && foundEssay &&
                <NewModalLayout>
                    <EssayModal
                        title={t("ESSAY.LABELS.LABEL_10")}
                        setIsModalOpen={setIsEditOpenInEssays}
                        isModalOpen={isEditOpenInEssays}
                        essay={foundEssay}
                        isEdit={true}
                        isUniversitySpecific={true}
                    />
                </NewModalLayout>
            }
        </div>
    );
}

export default UniversitySpecificEssays;

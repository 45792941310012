import { CustomLink } from "@components/common";
import "./OnboardingStep.scss";
import { getIntro } from "@utils/functions";
import { useParams } from "react-router-dom";
import { useIsMobile, useTranslate } from "@helpers/hooks";

const OnboardingStep = () => {
  const isMobile = useIsMobile();
  const { courseId } = useParams();
  const { t } = useTranslate();
  const intro = getIntro(courseId);
  return (
    <>
     {intro.registration && <div className="onboarding-step">
        {intro.registration && (
          <div className="step__subtitle-group" key={intro.registration.id}>
            <h4 className={`${isMobile && "step__title-mobile"} step__title`}>{t(intro.registration.title)}</h4>
            <p className="step__description">{t(intro.registration.description)}</p>
          </div>
        )}
        {intro.practice?.links &&
          intro.practice?.links.map((link) => <CustomLink isExternal={true} to={link.link} key={link.id}>{`${link.name}: ${link.link}`}</CustomLink>)}
      </div>}
    </>
  );
};

export default OnboardingStep;

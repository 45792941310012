import {createSlice} from "@reduxjs/toolkit";
import {
    fetchCities, fetchCity,
    fetchCountries, fetchCountry,
    fetchDegrees, fetchDirectoryUniversities,
    fetchExamTypes,
    fetchLanguages,
    fetchMajors,
    fetchMoreInfos,
    fetchRegions, fetchScholarship,
    fetchSubjects,
    fetchCourseBannerButtons,
    fetchMockBannerButtons, fetchAnnounces
} from "@store/actions/directoryAction";

const initialState = {
    announces: [],
    regions: [],
    countries: [],
    country: null,
    cities: null,
    city: null,
    subjects: [],
    degrees: [],
    majors: [],
    moreInfos: [],
    languages: [],
    examTypes: [],
    directoryUniversities: [],
    scholarship: null,
    isLoading: false,
    minLimit: 500,
    maxLimit: 4000,
    error: null,
    course_banner_buttons: [],
    mock_banner_buttons: [],
};

export const directorySlice = createSlice({
    name: "directory",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchRegions.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchRegions.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.regions = action.payload;
        },
        [fetchRegions.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchCountries.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchCountries.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.countries = action.payload;
        },
        [fetchCountries.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchCountry.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchCountry.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.country = action.payload;
        },
        [fetchCountry.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchCities.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchCities.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.cities = action.payload;
        },
        [fetchCities.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchCity.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchCity.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.city = action.payload;
        },
        [fetchCity.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchSubjects.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchSubjects.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.subjects = action.payload;
        },
        [fetchSubjects.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchDegrees.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchDegrees.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.degrees = action.payload.map((degree) => ({
                id: degree.id,
                name: degree.primary_study_level,
                value: degree.primary_study_level
            }));
        },
        [fetchDegrees.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchMajors.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchMajors.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.majors = action.payload;
        },
        [fetchMajors.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchMoreInfos.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchMoreInfos.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.moreInfos = action.payload;
        },
        [fetchMoreInfos.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchLanguages.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchLanguages.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.languages = action.payload.results;
        },
        [fetchLanguages.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchExamTypes.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchExamTypes.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.examTypes = action.payload.results;
        },
        [fetchExamTypes.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchScholarship.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchScholarship.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.scholarship = action.payload;
        },
        [fetchScholarship.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchDirectoryUniversities.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchDirectoryUniversities.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.directoryUniversities = action.payload;
        },
        [fetchDirectoryUniversities.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchCourseBannerButtons.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchCourseBannerButtons.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.course_banner_buttons = action.payload.results;
        },
        [fetchCourseBannerButtons.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchMockBannerButtons.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchMockBannerButtons.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.mock_banner_buttons = action.payload.results;
        },
        [fetchMockBannerButtons.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [fetchAnnounces.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchAnnounces.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.announces = action.payload.results;
        },
        [fetchAnnounces.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export default directorySlice.reducer;
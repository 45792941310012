import './Bar.scss'

const Bar = ({completed, total}) => {
    const fillerStyles = {
        width: `${(completed / total) * 100}%`,
    }
  return (
    <div className="progress--container">
        <div className="progress--filler" style={fillerStyles}></div>
    </div>
  )
}

export default Bar;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.plug__title {
  text-align: center;
}
.plug__desc {
  margin-top: 20px;
  text-align: center;
}
.plug__img {
  margin-top: 20px;
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Plug/Plug.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,gBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAHJ","sourcesContent":["@import \"src/styles/variables\";\n\n.plug {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  &__title {\n    text-align: center;\n  }\n\n  &__desc {\n    margin-top: 20px;\n    text-align: center;\n  }\n\n  &__img {\n    margin-top: 20px;\n    max-width: 600px;\n    max-height: 600px;\n    width: 100%;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import {
  fetchMockExams,
  fetchMockExam,
  fetchMath,
  fetchCrit,
  submitMath,
  submitCrit,
  getProgress,
  fetchVerbalOne,
  fetchVerbalTwo,
  fetchMathOne,
  fetchMathTwo,
  getSatProgress,
  fetchListening,
  fetchReading,
  fetchSpeaking,
  fetchWriting,
  submitListening,
  submitReading,
  submitSpeaking,
  submitWriting,
  getIeltsProgress,
  fetchIeltsMockGrades,
  fetchIeltsMockResponse,
  updateIeltsMockGrade,
  fetchNuetMockGrades,
  fetchSatMockGrades,
  fetchUncheckedIeltsMockResponses
} from "@store/actions/mockAction";
import { notify } from "@utils/functions";

const initialState = {
  mocks: [],
  mock: null,
  isLoading: false,
  crit: null,
  math: null,
  mathEndTime: null,
  critEndTime: null,
  verbalOne: null,
  verbalTwo: null,
  mathOne: null,
  mathTwo: null,
  verbalOneEndTime: null,
  verbalTwoEndTime: null,
  mathOneEndTime: null,
  mathTwoEndTime: null,
  stage: null,
  isIeltsLoading: false,
  ieltsStage: null,
  ieltsProgress: null,
  listening: null,
  reading: null,
  writing: null,
  speaking: null,
  postListening: null,
  postWriting: null,
  postReading: null,
  postSpeaking: null,
  readingEndTime: null,
  listeningEndTime: null,
  speakingEndTime: null,
  writingEndTime: null,
  notifier: false,
  mockGrades: null,
  mockResponse: null,
};

export const mockSlice = createSlice({
  name: "mocks",
  initialState,
  reducers: {
    setMockNotifier: (state, action) => {
      state.notifier = action.payload;
    }
  },
  extraReducers: {
    [fetchMockExams.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mocks = action.payload.results;      
      const mock_purchase = JSON.parse(localStorage.getItem("mock_purchase"));      
      const foundMock = action.payload.results.find(mock => mock.id === mock_purchase?.mock_id);    
      if (mock_purchase) {
        if (foundMock && 
            mock_purchase.mock_id === foundMock.id && 
            mock_purchase.access !== foundMock.access) {
          notify("Вы успешно приобрели пробный экзамен!", "success");
          state.notifier = true;
          localStorage.removeItem("mock_purchase");
        } else if (!foundMock) {
          localStorage.removeItem("mock_purchase");
        }
      }
    },
    [fetchMockExams.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchMockExams.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchMockExam.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mock = action.payload;
    },
    [fetchMockExam.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchMockExam.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchMath.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.math = action.payload.results;
    },
    [fetchMath.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchMath.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchCrit.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.crit = action.payload.results;
    },
    [fetchCrit.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchCrit.pending.type]: (state) => {
      state.isLoading = true;
    },

    [submitMath.pending.type]: (state) => {
      state.isLoading = true;
    },
    [submitMath.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;

      const response = action.payload;

      const mathQuestions = state.math?.[0]?.math_questions;

      if (mathQuestions) {
        response.question_data.forEach((questionData) => {
          const questionIndex = mathQuestions.findIndex(
            (question) => question.id === +questionData.question_id
          );
          if (questionIndex !== -1) {
            const question = mathQuestions[questionIndex];

            question.is_correct = questionData.is_correct;
            question.correct_answer = questionData.correct_answer;
            question.response = questionData.response;
          }
        });
      }
    },

    [submitMath.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [submitCrit.pending.type]: (state) => {
      state.isLoading = true;
    },
    [submitCrit.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;

      const response = action.payload;

      const critQuestions = state.crit?.[0]?.crit_questions;

      if (critQuestions) {
        response.question_data.forEach((questionData) => {
          const questionIndex = critQuestions.findIndex(
            (question) => question.id === +questionData.question_id
          );
          if (questionIndex !== -1) {
            const question = critQuestions[questionIndex];

            question.is_correct = questionData.is_correct;
            question.correct_answer = questionData.correct_answer;
            question.response = questionData.response;
          }
        });
      }
    },

    [submitCrit.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getProgress.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mathEndTime = action.payload.mockProgress.math_end_time;
      state.critEndTime = action.payload.mockProgress.crit_end_time;
      state.stage = action.payload.stage;
    },
    [getProgress.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getProgress.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchVerbalOne.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.verbalOne = action.payload.results;
    },
    [fetchVerbalOne.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchVerbalOne.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchVerbalTwo.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.verbalTwo = action.payload.results;
    },
    [fetchVerbalTwo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchVerbalTwo.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchMathOne.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mathOne = action.payload.results;
    },
    [fetchMathOne.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchMathOne.pending.type]: (state) => {
      state.isLoading = true;
    },

    [fetchMathTwo.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mathTwo = action.payload.results;
    },
    [fetchMathTwo.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchMathTwo.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getSatProgress.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.verbalOneEndTime = action.payload.mockProgress.module_verbal1_end_time;
      state.verbalTwoEndTime = action.payload.mockProgress.module_verbal2_end_time;
      state.mathOneEndTime = action.payload.mockProgress.module_math1_end_time;
      state.mathTwoEndTime = action.payload.mockProgress.module_math2_end_time;
      state.stage = action.payload.stage;
    },
    [getSatProgress.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getSatProgress.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getIeltsProgress.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [getIeltsProgress.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.stage = action.payload.stage;
      state.readingEndTime = action.payload.mockProgress.reading_end_time;
      state.listeningEndTime = action.payload.mockProgress.listening_end_time;
      state.writingEndTime = action.payload.mockProgress.writing_end_time;
      state.speakingEndTime = action.payload.mockProgress.speaking_end_time;
    },
    [getIeltsProgress.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },    
    [fetchListening.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [fetchListening.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.listening = action.payload.results
    },
    [fetchListening.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },
    [fetchReading.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [fetchReading.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.reading = action.payload.results
    },
    [fetchReading.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },
    [fetchSpeaking.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [fetchSpeaking.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.speaking = action.payload.results
    },
    [fetchSpeaking.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },
    [fetchWriting.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [fetchWriting.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.writing = action.payload.results
    },
    [fetchWriting.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },
    [submitListening.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [submitListening.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.postListening = action.payload
    },
    [submitListening.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },
    [submitWriting.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [submitWriting.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.postWriting = action.payload
    },
    [submitWriting.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },
    [submitSpeaking.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [submitSpeaking.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.postSpeaking = action.payload
    },
    [submitSpeaking.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },

    [submitReading.pending.type]: (state) => {
      state.isIeltsLoading = true;
    },
    [submitReading.fulfilled.type]: (state, action) => {
      state.isIeltsLoading = false;
      state.error = null;
      state.postReading = action.payload
    },
    [submitReading.rejected.type]: (state, action) => {
      state.isIeltsLoading = false;
    },

    [fetchIeltsMockGrades.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchIeltsMockGrades.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mockGrades = action.payload.results
    },
    [fetchIeltsMockGrades.rejected.type]: (state) => {
      state.isLoading = false;
    },

    [fetchNuetMockGrades.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchNuetMockGrades.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mockGrades = action.payload.results
    },
    [fetchNuetMockGrades.rejected.type]: (state) => {
      state.isLoading = false;
    },

    [fetchSatMockGrades.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchSatMockGrades.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mockGrades = action.payload.results
    },
    [fetchSatMockGrades.rejected.type]: (state) => {
      state.isLoading = false;
    },

    [fetchIeltsMockResponse.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchIeltsMockResponse.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mockResponse = action.payload
    },
    [fetchIeltsMockResponse.rejected.type]: (state) => {
      state.isLoading = false;
    },

    [updateIeltsMockGrade.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateIeltsMockGrade.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.mockGrades = state.mockGrades.map(grade => grade.id === action.payload.id ? action.payload : grade)
      state.error = null;
      notify("Данные сохранены", "success");
    },
    [updateIeltsMockGrade.rejected.type]: (state) => {
      state.isLoading = false;
    },

    [fetchUncheckedIeltsMockResponses.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchUncheckedIeltsMockResponses.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.mockGrades = action.payload.results
    },    
    [fetchUncheckedIeltsMockResponses.rejected.type]: (state) => {
      state.isLoading = false;
    },

  },
});

export const { setMockNotifier } = mockSlice.actions;

export default mockSlice.reducer;

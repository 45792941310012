import { useState, useMemo } from 'react';
import { useTranslate } from '@helpers/hooks';
import { Icon, List } from '@components/common';
import { CourseGradesHomework, CourseGradesMockExams, UniversityDetailsTabs } from '@components/feature';
import './CourseGradesContent.scss';

const CourseGradesContent = ({grades}) => {
    const { t } = useTranslate(),
    TABS = {
        HOMEWORK: "LMS.GRADES.HOMEWORK",
        MOCK_EXAM: "LMS.GRADES.MOCK_EXAM",
    },
    [sortDescending, setSortDescending] = useState(true),
    [currentTab, setCurrentTab] = useState("LMS.GRADES.HOMEWORK"),
    HEAD_ITEMS = useMemo(() => [
        {
            id: 1,
            name: t("LMS.GRADES.ITEM"),
            onClick: handleSortByDate,
            icon: sortDescending ? "arrow-down-sort" : "arrow-up-sort"
        },
        {
            id: 2,
            name: t("LMS.GRADES.DATE"),
        },
        {
            id: 3,
            name: t("LMS.GRADES.GRADE"),
        },
    ], [sortDescending, t]);
    
    function handleSortByDate() {
        setSortDescending(prev => !prev);
    }

    const getContent = (currentTab) => {
        switch(currentTab) {
            case "LMS.GRADES.HOMEWORK":
                return <CourseGradesHomework grades={grades} sortDescending={sortDescending}/>
            case "LMS.GRADES.MOCK_EXAM":
                return <CourseGradesMockExams sortDescending={sortDescending} />
            default:
                return <CourseGradesHomework grades={grades} sortDescending={sortDescending}/>
        }
    }

  return (
    <div className="grade">
        <h1 className="grade__title">{t("LMS.GRADES.TITLE")}</h1>
        <UniversityDetailsTabs
            classNameTabs={"grade__tabs"}
            classNameTab={"grade__tab"}
            tabs={Object.values(TABS)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
        />
        <div className="grade__table">
            <List
                className="grade__table-head"
                items={HEAD_ITEMS}
                renderItem={(item) => (
                    <div key={item.id} onClick={item.onClick}>
                        <p>{item.name}</p>
                        {item.icon && <Icon name={item.icon}/>}
                    </div>
                )}
            />
            {getContent(currentTab)}
        </div>
    </div>
  );
}

export default CourseGradesContent;
import { useState, useRef, useEffect } from "react";
import { LessonVideoPlayer, LessonVideoNotes } from "@components/feature";
import { useAction } from "@helpers/hooks";
import { useParams } from "react-router-dom";
import { DividerVertical } from "@components/common";
import {useIsMobile} from "@helpers/hooks";
import "./LessonVideo.scss";

export default function LessonVideo({ lesson }) {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const { lessonId } = useParams();
  const { getLessonNotes } = useAction();
  const videoRef = useRef(null);
  const notesRef = useRef(null);
  const playerRef = useRef(null);
  const isMobile = useIsMobile()


  useEffect(() => {
    getLessonNotes(lessonId);
  }, [lessonId]);

  const handleAddNote = () => {
      setTimestamp(playerRef.current.getCurrentTime());
      setAddingNote(true);
      setIsNotesOpen(true);
  };

  const updateHeight = () => {
    if (videoRef.current && notesRef.current) {
      notesRef.current.style.maxHeight = `${videoRef.current.offsetHeight + 120}px`;
    }
  }
  const setTime = () => setTimestamp(playerRef.current.getCurrentTime());

  const goToSecond = (second) => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(second, "seconds");
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [isNotesOpen]);


  return (
    <div className={`${isMobile ? `video-mobile` : `video`} lesson`}>
      <LessonVideoPlayer
        lesson={lesson}
        setIsNotesOpen={setIsNotesOpen}
        setAddingNote={setAddingNote}
        handleAddNote={handleAddNote}
        updateHeight={updateHeight}
        playerRef={playerRef}
        videoRef={videoRef}
      />
      {isNotesOpen && (
        <div className="video">
          {<DividerVertical className={"divider__video"} notesRef={notesRef} />}
          <LessonVideoNotes
            addingNote={addingNote}
            editingNote={editingNote}
            setEditingNote={setEditingNote}
            setAddingNote={setAddingNote}
            setIsNotesOpen={setIsNotesOpen}
            goToSecond={goToSecond}
            timestamp={timestamp}
            setTime={setTime}
            notesRef={notesRef}
          />
        </div>
      )}
    </div>
  );
}

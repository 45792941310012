import {memo} from "react";
import {FooterMenu} from "@components/feature";
import "./Footer.scss";

export default memo(function Footer() {

    return (
        <header className={"footer"}>
            <nav className={"footer__nav"}>
                {
                    <FooterMenu />
                }
            </nav>
        </header>
    );
});
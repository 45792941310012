const resources = [
    {id: 3, name: "RESOURCE.RESOURCE_ONE.TITLE", href: "https://drive.google.com/file/d/1RDcnU6D3cSGGLkBJwb5fkveHFPRj6aAO/view"},
    {id: 4, name: "RESOURCE.RESOURCE_TWO.TITLE", href: "https://drive.google.com/file/d/15FV5urz-2vCtoLQ8Sp5pqmYHAuTfbdLv/view?usp=drive_link"},
    {id: 5, name: "RESOURCE.RESOURCE_THREE.TITLE", href: "https://drive.google.com/file/d/1dlB6_Jb2ksThbb7yRacdTElN7uc94Vyx/view?usp=drive_link"},
    {id: 6, name: "RESOURCE.RESOURCE_FOUR.TITLE", href: "https://drive.google.com/file/d/1HR5vW-ivbarJXu_CpsbIopc9La2bJ1G6/view"},
    {id: 7, name: "RESOURCE.RESOURCE_FIVE.TITLE", href: "https://drive.google.com/file/d/15V9x3GwDtxqrL1k7OdztAbUU2CmsFKKk/view?usp=drive_link"},
    {id: 8, name: "RESOURCE.RESOURCE_SIX.TITLE", href: "https://drive.google.com/file/d/1eCEa5TeYPkslnYtozt8CHTHOkFtQdZOc/view?usp=drive_link"},
    {id: 9, name: "RESOURCE.RESOURCE_ELEVEN.TITLE", href: "https://drive.google.com/file/d/10ywsmxI99-HO6u5goQ1p_M-0WEeCP6K1/view?usp=sharing"},
];

export {resources};
import { EssaysService } from "@api/services";
import {createAsyncThunk} from "@reduxjs/toolkit";

const fetchGeneralEssays = createAsyncThunk(
    "essays/fetchGeneralEssays",
    async (params, thunkAPI) => {
        try {
            const res = await EssaysService.fetchGeneralEssays(params);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const fetchAvailableTypes = createAsyncThunk(
    "essays/fetchAvailableTypes",
    async (params, thunkAPI) => {
        try {
            const res = await EssaysService.fetchAvailableTypes(params);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const fetchGeneralEssay = createAsyncThunk(
    "essays/fetchGeneralEssay",
    async (id, thunkAPI) => {
        try {
            const res = await EssaysService.fetchGeneralEssay(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const createGeneralEssay = createAsyncThunk(
    "essays/createGeneralEssay",
    async (data, thunkAPI) => {
        try {
            const res = await EssaysService.createGeneralEssay(data);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const updateGeneralEssay = createAsyncThunk(
    "essays/updateGeneralEssay",
    async (data, thunkAPI) => {
        try {
            const res = await EssaysService.updateGeneralEssay(data.id, data);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const deleteGeneralEssay = createAsyncThunk(
    "essays/deleteGeneralEssay",
    async (id, thunkAPI) => {
        try {
            const res = await EssaysService.deleteGeneralEssay(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const fetchUniversitySpecificEssays = createAsyncThunk(
    "essays/fetchUniversitySpecificEssays",
    async (params, thunkAPI) => {
        try {
            const res = await EssaysService.fetchUniversitySpecificEssays(params);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const fetchUniversitySpecificEssay = createAsyncThunk(
    "essays/fetchUniversitySpecificEssay",
    async (id, thunkAPI) => {
        try {
            const res = await EssaysService.fetchUniversitySpecificEssay(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const createUniversitySpecificEssay = createAsyncThunk(
    "essays/createUniversitySpecificEssay",
    async (data, thunkAPI) => {
        try {
            const res = await EssaysService.createUniversitySpecificEssay(data);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const updateUniversitySpecificEssay = createAsyncThunk(
    "essays/updateUniversitySpecificEssay",
    async (data, thunkAPI) => {
        try {
            const res = await EssaysService.updateUniversitySpecificEssay(data.id, data);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const deleteUniversitySpecificEssay = createAsyncThunk(
    "essays/deleteUniversitySpecificEssay",
    async (id, thunkAPI) => {
        try {
            const res = await EssaysService.deleteUniversitySpecificEssay(id);
            return res;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export {
    createGeneralEssay,
    deleteGeneralEssay,
    fetchAvailableTypes,
    fetchGeneralEssay, 
    fetchGeneralEssays, 
    fetchUniversitySpecificEssay,
    fetchUniversitySpecificEssays,
    createUniversitySpecificEssay,
    deleteUniversitySpecificEssay,
    updateUniversitySpecificEssay,
    updateGeneralEssay
};

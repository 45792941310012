import { Icon, Image } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import classNames from "classnames";
import "./LmsRatingPrimaryCard.scss";

export default function LmsRatingPrimaryCard({ user }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile()

  const isPrimary = user.position === 1;

  const cardClassName = classNames({
    "rating__card-primary": isPrimary,
    "rating__card-secondary": !isPrimary,
  });

  const positionClassName = classNames({
    "rating__card-position-primary": isPrimary,
    "rating__card-position-secondary": !isPrimary || isMobile,
  });

  const groupClassName = classNames({
    "rating__card-group-primary": isPrimary || isMobile,
    "rating__card-group-secondary": !isPrimary && !isMobile,
  });

  const imageClassName = classNames({
    "rating__card-image-primary": isPrimary,
    "rating__card-image-secondary": !isPrimary,
  });

  return (
    <div className={`rating__card ${cardClassName}`}>
      <p className={`rating__card-position ${positionClassName}`}>{user.position}</p>
      <div className={`rating__card-group ${groupClassName}`}>
        <Image className={`rating__card-image ${imageClassName}`} src={user.user_image} />
        <div className={`rating__card-group-secondary`}>
          <p className="rating__card-username">{user.user_name}</p>
          <div className="rating__card-group">
            <p className="rating__card-text">
              {t("LMS.RANKING.LEVEL")}: <span className="rating__bold">{user.level}</span>
            </p>
            <p className="rating__card-text">
              {t("LMS.RANKING.POINTS")}: <span className="rating__bold">{user.points}</span>
            </p>
            <p className="rating__card-text">
              <span><Icon name='coin' /></span> <span className="rating__bold">{user.coins}</span>
            </p>
          </div>
        </div>
      </div>
      {isPrimary && <span className="rating__card-crown"><Icon name='crown' className={isMobile ? 'rating__card-crown-icon-mobile' : 'rating__card-crown-icon'}/></span>}
    </div>
  );
}

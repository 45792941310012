import {Icon} from "@components/common";
import classNames from "classnames";
import "../Pagination.scss";

export default function PaginationArrowNext({currentPage, lastPage, onNext}) {
    return (
        <li
            key={"next"}
            className={classNames("pagination__item next", {disabled: currentPage === lastPage})}
            onClick={onNext}
        >
            <Icon name={"pagination-arrow-right"}/>
        </li>
    );
};
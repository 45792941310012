import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BtnLoader, Button, Icon, PageTitle } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import { UniversityListRecommended } from "@components/feature";
import { useIsMobile } from "@helpers/hooks";
import "./RecommendedUniversities.scss";

const RecommendedUniversities = ({ onSelectUniversity, handleFilter }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslate(),
    { myUniversitiesRecommend, isLoading, isUniLoading } = useSelector(
      (state) => state.questionnaire
    ),
    { fetchUniversitiesRecommend } = useAction();

  const programsCount = myUniversitiesRecommend && myUniversitiesRecommend[0]?.programs_count;
  const uniCount = myUniversitiesRecommend && myUniversitiesRecommend[0]?.universities_count;

  useEffect(() => {
    if (isLoading) return;
    fetchUniversitiesRecommend();
  }, [isLoading]);

  const handleCount = () => {
    return t("RECOMMENDATIONS.TABS.LABEL_3", { uniNumber: uniCount, programsCount: programsCount });
  };

  return (
    <div className={isMobile ? "recommendations-list-mobile" : "recommendations-list"}>
      {isMobile && (
        <PageTitle title={t("RECOMMENDATIONS.TITLE")} iconLeft={"arrow_title"} isGoBack />
      )}
      {!isUniLoading && myUniversitiesRecommend ? (
        <div className={isMobile ? "recommendations-container" : ""}>
          {isMobile && (
            <div className="recommendations-block-mobile">
              <Button
                className="btn__filter"
                iconLeft={<Icon name="filter2" />}
                text={"Filter"}
                onClick={handleFilter}
              />
            </div>
          )}

          {myUniversitiesRecommend?.length > 0 && (
            <p
              className={
                isMobile ? "recommendations-subheader-mobile" : "recommendations-subheader"
              }
            >
              {handleCount()}{" "}
            </p>
          )}

          <UniversityListRecommended
            universities={myUniversitiesRecommend}
            onSelectUniversity={onSelectUniversity}
          />
        </div>
      ) : (
        <div className="parent-loader">
          <BtnLoader className={"btn-loader--blue-large"} />
        </div>
      )}
    </div>
  );
};

export default RecommendedUniversities;

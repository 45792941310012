import { List } from "@components/common";
import { TryAgainWarning, ListeningQuestion } from "@components/feature";
import { useSelector } from "react-redux";
import { QuizQuestionItem, ImageQuestion } from "@components/feature";
import { useIsMobile } from "@helpers/hooks";
import "./LessonQuizQuestions.scss";
import { useParams } from "react-router-dom";

export default function LessonQuizQuestions({ handleAnswerChange, userAnswers, quizId, quizContent }) {
  const { isResultLoading } = useSelector((state) => state.courses);
  const {courseId} = useParams()
  const isMobile = useIsMobile()

  let questionIndex = 0;
  const isSquidGame = +courseId !== 13;
  const renderQuestion = (question) => {
    let questionKey = question.id;
    if (question.type != "image_question" && question.type != "listening_question") {
      questionIndex++;
    }


    switch (question.type) {
      case "image_question":
        return (
          <div key={questionKey} className="question__card-image">
            <ImageQuestion  question={question} />
          </div>
        );
      case "listening_question":
        return (
          <div key={questionKey} className="question__card">
            <ListeningQuestion  question={question} />
          </div>
        );
      default:
        return (
          <div  key={questionKey} className="question__card">
            <QuizQuestionItem
              question={question}
              index={questionIndex}
              userAnswers={userAnswers}
              handleAnswerChange={handleAnswerChange}
              disabled={quizContent.completed}
              hasQuizAnswers={quizContent.completed}
            />
          </div>
        );
    }
  };

  return (
    <div className={isMobile ? `questions-container-mobile` : `questions-container`}>
      {!isResultLoading && quizContent.completed && isSquidGame && <TryAgainWarning quizId={quizId} score={quizContent.score} />}
      {!isResultLoading && (
        <form className="questions__form">
          <List className="question__group" items={quizContent.questions} renderItem={renderQuestion} />
        </form>
      )}
    </div>
  );
}

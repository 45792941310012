import {Fragment} from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslate } from "@helpers/hooks";
import classNames from "classnames";
import { Icon } from "../Icon";
import "./Label.scss";

export default function Label({className, htmlFor, label, definition}) {
    const {t} = useTranslate();
    return (
        <Fragment>
            {
                label && <label className={classNames("label", {"label__info": definition}, className)} htmlFor={htmlFor}>
                    <p className={"label__text"}>
                        {
                            label
                        }
                    </p>
                    {
                        definition && 
                        <>
                            <a data-tooltip-id="my-tooltip" data-tooltip-content={t(definition)}>
                                <Icon name="help-info" />
                            </a>
                            <ReactTooltip 
                                id="my-tooltip"
                                style={{width: "500px"}}
                            />
                        </>
                        
                    }
                </label>
            }
        </Fragment>
    );
};
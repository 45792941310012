import { NavLink } from 'react-router-dom';
import { List } from '@components/common';
import { formatData } from '@utils/functions';
import './CourseGradesHomework.scss';

const CourseGradesHomework = ({grades, sortDescending}) => {
    
    const getListItems = (grades) => {
        const sortedGrades = [...grades].sort((a, b) => {
            if (sortDescending) {
                return new Date(a.datetime) - new Date(b.datetime);
            } else {
                return new Date(b.datetime) - new Date(a.datetime);
            }
        });
        return sortedGrades;
    }

    const getLinkToQuiz = (grade) => {
        const { lesson_id, section_id } = grade;
        return `sections/${section_id}/lessons/${lesson_id}`
    }

    return (
        <List
            className="grade__table-records"
            items={getListItems(grades)}
            renderItem={(grade) => (
                <NavLink to={getLinkToQuiz(grade)} key={grade.id} className="grade__table-record">
                    <div>
                        <h5 className="quiz-title">{grade.quiz_id.title}</h5>
                        <p className="quiz-section">{grade.section_title}</p>
                    </div>
                    <div className="record-p">{formatData(grade.datetime, 4)}</div>
                    <div className="record-p">{`${grade.grade}%`}</div>
                </NavLink>
            )}
        />
    );
}

export default CourseGradesHomework;

import { useTranslate } from "@helpers/hooks";
import classNames from "classnames";
import { Input } from "../Input";
import "./RadioWithInput.scss";

export default function RadioWithInput({className, label, labelClassName, ...otherProps}) {
    const {id, checked, name, onChange, value, input_type, input_name, input_placeholder, input_value, inputonchange,
    input_step, input_max, input_min, input_maxlength, input_onkeypress, input_validaterange
    } = otherProps;
    const {t} = useTranslate();
    return (
        <div className={classNames("radio-with-input", className)}>
            <label
                className={"radio-with-input__label"}
                htmlFor={id}
            >
                <input
                    id={id}
                    type={"radio"}
                    className={"radio-with-input__input"}
                    name={name}
                    checked={checked}
                    value={value}
                    onChange={onChange}
                />
                <span className={"radio-with-input__box"}/>
                <p className={classNames("radio-with-input__text", {labelClassName: "radio__text-quiz"})}>{label}</p>
            </label>
            {
                checked &&
                <Input
                    type={input_type ? input_type : "text"}
                    name={input_name}
                    placeholder={t(input_placeholder)}
                    value={input_value ? input_value : ""}
                    onChange={inputonchange}
                    step={input_step}
                    min={input_min}
                    max={input_max}
                    maxLength={input_maxlength}
                    onKeyPress={input_onkeypress}
                    validateRange={input_validaterange}
                />
            }
        </div>
    );
};

import React, { useState, useEffect, useRef } from "react";
import Joyride, { STATUS } from "react-joyride";
import { useTranslate, useAction } from "@helpers/hooks";
import "./TourGuide.scss";

const TourGuide = () => {
  const { t } = useTranslate();
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);
  const hasToggledUser = useRef(false);
  const { updateNewUserStatus } = useAction();
  const isTablet = window.innerWidth <= 1024;

  useEffect(() => {
    setStepIndex(0);
    setRun(true);
  }, [])
  

  const customLocale = {
    back: t("JOYRIDE.BUTTON.BACK"),
    close: t("JOYRIDE.BUTTON.CLOSE"),
    last: t("JOYRIDE.BUTTON.LAST"),
    next: t("JOYRIDE.BUTTON.NEXT"),
    skip: t("JOYRIDE.BUTTON.SKIP"),
  };
  

  useEffect(() => {
    if (!isTablet) {
      setSteps([
        {
          target: "body",
          content: (
            <div>
              <h3 className="guide__header">{t("JOYRIDE.HEADER")}</h3>
              <p>{t("JOYRIDE.INTRO")}</p>
            </div>
          ),
          placement: "center",
        },
        {
          target: ".profile-v2",
          content: <p>{t("JOYRIDE.FIRST")}</p>,
          placement: "right",
        },
        {
          target: ".heart",
          content: <p>{t("JOYRIDE.SECOND")}</p>,
          placement: "auto",
        },
        {
          target: ".university-program",
          content: <p>{t("JOYRIDE.THIRD")}</p>,
          placement: "auto",
        },
        {
          target: ".scholarships",
          content: <p>{t("JOYRIDE.FOURTH")}</p>,
          placement: "auto",
        },
        {
          target: ".writing-builder",
          content: <p>{t("JOYRIDE.FIFTH")}</p>,
          placement: "auto",
        },
        {
          target: ".learning-platform-outlined",
          content: <p>{t("JOYRIDE.SIXTH")}</p>,
          placement: "auto",
        },
        {
          target: ".button_bcc8",
          content: <p>{t("JOYRIDE.SEVENTH")}</p>,
        },
        {
          content: <p>{t("JOYRIDE.LAST")}</p>,
          placement: "center",
          target: "body",
        },
      ]);
    }
  }, [t, isTablet]);

  const toggleIsNewUser = () => {
    if (!hasToggledUser.current) {
      updateNewUserStatus();
      hasToggledUser.current = true;
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, action, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && !hasToggledUser.current) {
      toggleIsNewUser();
      setRun(false);
    } else if (action === "next" || action === "prev") {
      setStepIndex(index);
    }
  };

  if (isTablet) {
    return null;
  }

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      disableScrolling={true}
      steps={steps}
      locale={customLocale}
      styles={{
        options: {
          primaryColor: "#4ba5ec",
          width: 500,
          textColor: "#4ba5ec",
          zIndex: 1000,
        },
        buttonNext: {
          backgroundColor: "#4ba5ec",
          color: "#FFFFFF",
          padding: "12px 16px",
          borderRadius: "10px",
        },

        buttonBack: {
          color: "#4ba5ec",
          backgroundColor: "#FFFFFF",
          border: "1px solid #4ba5ec",
          padding: "12px 16px",
          borderRadius: "10px",
        },

        tooltipContainer: {
          borderRadius: "20px",
        },
      }}
    />
  );
};

export default TourGuide;

import {createSlice} from "@reduxjs/toolkit";
import {
    createAdditionalInfo,
    deleteAdditionalInfo,
    fetchMyAdditionalInfo,
    fetchMyEducation,
    fetchMyPrefer,
    fetchUniversitiesRecommend,
    updateAdditionalInfo,
    updateEducation,
    updatePrefer,
    updateUniversitiesRecommend
} from "@store/actions/questionnaireAction";

const initialState = {
    myEducation: null,
    myAdditionalInfo: null,
    myPrefer: null,
    myUniversitiesRecommend: null,
    isLoading: false,
    isUniLoading: false,
    error: null
};

export const questionnaireSlice = createSlice({
    name: "questionnaire",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchMyEducation.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchMyEducation.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myEducation = action.payload;
        },
        [fetchMyEducation.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateEducation.pending.type]: (state) => {
            state.isLoading = false;
        },
        [updateEducation.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myEducation = {...state.myEducation, ...action.payload};
        },
        [updateEducation.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchMyPrefer.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchMyPrefer.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            const updatedSubjects = action.payload.subjects.map(subject => ({
             ...subject,
             value: subject.id 
            }));
            state.myPrefer = {
                ...action.payload,
            subjects: updatedSubjects
         };
        },
        [fetchMyPrefer.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updatePrefer.pending.type]: (state) => {
            state.isLoading = false;
        },
        [updatePrefer.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myPrefer = {...state.myPrefer, ...action.payload};
        },
        [updatePrefer.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchMyAdditionalInfo.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchMyAdditionalInfo.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myAdditionalInfo = action.payload;
        },
        [fetchMyAdditionalInfo.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateAdditionalInfo.pending.type]: (state) => {
            state.isLoading = false;
        },
        [updateAdditionalInfo.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myAdditionalInfo.results = state.myAdditionalInfo.results.map(item => {
                if (item.id === action.payload.id) return action.payload;
                return item;
            });
        },
        [updateAdditionalInfo.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [createAdditionalInfo.pending.type]: (state) => {
            state.isLoading = false;
        },
        [createAdditionalInfo.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myAdditionalInfo.results = [...state.myAdditionalInfo.results, action.payload];
            state.myAdditionalInfo.count = state.myAdditionalInfo.count + 1;
        },
        [createAdditionalInfo.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [deleteAdditionalInfo.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteAdditionalInfo.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.myAdditionalInfo.results = state.myAdditionalInfo.results.filter(item => item.id !== action.payload);
            state.myAdditionalInfo.count = state.myAdditionalInfo.count - 1;
        },
        [deleteAdditionalInfo.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchUniversitiesRecommend.pending.type]: (state) => {
            state.isLoading = false;
            state.isUniLoading = true;
        },
        [fetchUniversitiesRecommend.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isUniLoading = false;
            state.error = null;
            state.myUniversitiesRecommend = action.payload.results;
        },
        [fetchUniversitiesRecommend.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.isUniLoading= false;
            state.error = action.payload;
        },
        [updateUniversitiesRecommend.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateUniversitiesRecommend.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
        },
        [updateUniversitiesRecommend.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export default questionnaireSlice.reducer;
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@helpers/hooks';
import { Icon, Image, NewModalLayout } from '@components/common';
import { 
    HeroProgressBar, 
    UniversityDetailsTabs, 
    LmsBadgeProfileInfo, 
    LmsBadgeProfileSpecifications, 
    LmsBadgeProfileAchievements 
} from '@components/feature';
import './LmsBadgeProfile.scss';

const LmsBadgeProfile = ({badge, completed, handleClose}) => {
    const {t} = useTranslate(),
    {profile} = useSelector(state => state.profile),
    fullName = `${profile?.name} ${profile?.surname}`,
    TABS = {
        INFO: t("LMS.BADGES.LABEL_5"),
        SPECIFICATIONS: t("LMS.BADGES.LABEL_6"),
        ACHIEVEMENTS: t("LMS.BADGES.LABEL_7"),
    },
    [currentTab, setCurrentTab] = useState(TABS.INFO);

      const getContent = (tab) => {
        switch (tab) {
          case TABS.INFO:
            return <LmsBadgeProfileInfo />;
          case TABS.SPECIFICATIONS:
            return <LmsBadgeProfileSpecifications />
          case TABS.ACHIEVEMENTS:
            return <LmsBadgeProfileAchievements />
          default:
            return <LmsBadgeProfileInfo />;
        }
      };

    return (
        <NewModalLayout className="lms-badge-profile">
            <Icon name="close-rounded" className="lms-badge-profile__close" handleClick={handleClose}/>
            <div className="lms-badge-profile__info">
                <Image src={badge.level.image} name={'badge_empty.png'} className="lms-badge-profile__image" />
                <div className='lms-badge-profile__info-content'>
                    <h4 className='hero-card-selected__title'>{fullName}</h4>
                    <p className='hero-card-selected__level'>{t("LMS.BADGES.LEVEL")} {badge.level.level}</p>
                    <HeroProgressBar completed={completed.points} />
                </div>
            </div>
            <p className="help-p">{t("LMS.BADGES.LABEL_8")}</p>
            {/* <UniversityDetailsTabs
                classNameTabs={"lms-badge-profile__tabs"}
                classNameTab={"lms-badge-profile__tab"}
                tabs={Object.values(TABS)}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            {getContent(currentTab)} */}
            {/* commented until more information on tabs provided */}
        </NewModalLayout>
    );
}

export default LmsBadgeProfile;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction, useTranslate } from '@helpers/hooks';
import { Button, Image, NewModalLayout } from '@components/common';
import {HeroProgressBar, LmsBadgeProfile} from "@components/feature";
import './LmsBadgeHeader.scss';

const LmsBadgeHeader = () => {
    const {t} = useTranslate(),
    {profile} = useSelector(state => state.profile),
    {badge} = useSelector(state => state.courses),
    {fetchBadge} = useAction(),
    fullName = `${profile?.name} ${profile?.surname}`,
    [completed, setCompleted] = useState({points: 0, levelUp: false}),
    [profileOpen, setProfileOpen] = useState(false);
    
    useEffect(() => {
        fetchBadge();
    }, [])

    useEffect(() => {
        if (badge) {
            setCompleted(prev => {
                if (prev.points > badge.points % 100) {
                    return ({...prev, points: badge.points % 100, levelUp: true})
                }
                return ({...prev, points: badge.points % 100})
            });
        }
    }, [badge?.points]);


    const handleClosePopup = () => {
        setCompleted(prev => ({...prev, levelUp: false}));
    }

    const handleOpenBadgeProfile = () => {
        setProfileOpen(true);
    }

    const handleCloseBadgeProfile = () => {
        setProfileOpen(false);
    }

    return (
        <>
            <div className="lms-badge__header" onClick={handleOpenBadgeProfile}>
                {badge?.level.image && <Image className="lms-badge__image" src={badge?.level.image} name={'badge_empty.png'} />}
                <div className='lms-badge__info'>
                    <h4 className='lms-badge__title'>{fullName}, <span className='lms-badge__level'>{t("LMS.BADGES.LEVEL")} {badge?.level.level}</span></h4>
                    <HeroProgressBar completed={completed.points} />
                </div>
            </div>
            {profileOpen && <LmsBadgeProfile badge={badge} completed={completed} handleClose={handleCloseBadgeProfile} /> }
            {completed.levelUp && 
                <NewModalLayout className="level-up">
                    <h1 className="level-up__title">{t("LMS.BADGES.CONGRATS", {levelNumber: badge.level.level})}</h1>
                    <Image className="level-up__image" src={badge.level.image} />
                    <Button text={t("LMS.BADGES.LETS_GO")} className="btn--secondary btn--pill level-up__btn" onClick={handleClosePopup} />
                </NewModalLayout>
            }
        </>
    );
}

export default LmsBadgeHeader;

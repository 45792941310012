import { createSlice } from "@reduxjs/toolkit";
import {
    fetchPrograms,
    fetchSimplePrograms,
    fetchProgram,
    setProgramsPageChange,
    setProgramsPageSizeChange,
    fetchFavorites,
    fetchAllApplication,
    addFavorite,
    addApplication,
    deleteApplication,
    deleteFavorite,
    fetchProgramSuccessRate,
    fetchFavoritesTotalCount,
} from "@store/actions/programAction";
import { findWithOne, replaceAllNullable } from "@utils/functions";
import { ACCESS_LANG } from "@utils/consts";

const initialState = {
    results: null,
    programDirectory: [],
    program: {},
    favorites: null,
    applications: null,
    successRate: null,
    totalCount: 0,
    totalCountFavorites: 0,
    totalCountApplication: 0,
    pageSize: 10,
    currentPage: 1,
    offset: 0,
    isLoading: false,
    isProgramLoading: false,
    isBtnLoading: false,
    isDashboardLoading: false,
    error: null,
    successRateError: null
};

export const programSlice = createSlice({
    name: "program",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPrograms.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchPrograms.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.totalCount = action.payload.count;
            state.programDirectory = action.payload.results.map((program) => ({
                id: program.id,
                name: program.name,
                value: program.id,
            }));
            state.results = action.payload.results.map(program => ({
                ...program,
                value: program.id
            }));
        },
        [fetchPrograms.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [fetchSimplePrograms.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchSimplePrograms.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.totalCount = action.payload.count;
            state.results = action.payload.map(program => ({
                ...program,
                value: program.id
            }));
        },
        [fetchSimplePrograms.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [fetchProgram.pending.type]: (state) => {
            state.isProgramLoading = true;
        },
        [fetchProgram.fulfilled.type]: (state, action) => {
            state.isProgramLoading = false;
            state.error = null;
            state.program = action.payload;
        },
        [fetchProgram.rejected.type]: (state, action) => {
            state.isProgramLoading = false;
            state.error = action.payload;
        },
        [setProgramsPageChange.fulfilled.type]: (state, action) => {
            state.currentPage = action.payload;
            state.offset = (state.currentPage - 1) * state.pageSize;
        },
        [setProgramsPageSizeChange.fulfilled.type]: (state, action) => {
            state.pageSize = action.payload;
        },
        [fetchProgramSuccessRate.pending.type]: (state) => {
            state.isDashboardLoading = true;
        },
        [fetchProgramSuccessRate.fulfilled.type]: (state, action) => {
            state.isDashboardLoading = false;
            state.successRateError = false;
            state.successRate = action.payload;
        },
        [fetchProgramSuccessRate.rejected.type]: (state, action) => {
            state.isDashboardLoading = false;
            state.successRateError = true;
        },
        [fetchFavorites.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchFavorites.fulfilled.type]: (state, action) => {
            const lang = localStorage.getItem(ACCESS_LANG);
            state.isLoading = false;
            state.error = null;
            state.totalCountFavorites = action.payload.count;
            state.selectFavorites = action.payload.results.map((program) => ({
                id: program.id,
                name: program.name,
                value: program.id
            }))
            state.favorites = action.payload.results.map((program) => ({
                id: program.id,
                name: program.name,
                logo: program.university.logo,
                city: program.university.city[0]?.name,
                country: program.university.city[0]?.country.name,
                tuition_fee: program.tuition_fee,
                is_applying: program.is_applying,
                university: program.university.title,
                application_deadline: program.university.degree?.application_deadline ? program.university.degree.application_deadline : "-",
                ielts: findWithOne(replaceAllNullable(program.exam_requirements?.ielts_range), replaceAllNullable(program.exam_requirements?.toefl_range)),
                sat: findWithOne(replaceAllNullable(program.exam_requirements?.sat_range), replaceAllNullable(program.exam_requirements?.act_range)),
                fees_field_test: program.fees_field_test ? `$${program.fees_field_test}/year` : "",
                duration_field_test: program?.duration_field_test ? `${program?.duration_field_test} ${lang === "en" ? "months" : "месяц-(ев)"}` : "",
                start_date: program.start_date
            }));
        },
        [fetchFavorites.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [fetchFavoritesTotalCount.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchFavoritesTotalCount.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.totalCountFavorites = action.payload.count;
        },
        [fetchFavoritesTotalCount.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [addFavorite.pending.type]: (state, action) => {
            state.isBtnLoading = true;
            
           
            state.results = state.results.map((program) => {
                if (program.id === action.meta.arg) {
                    program.isLoading = true;
                }
                return program;
            });
        },
        [addFavorite.fulfilled.type]: (state, action) => {
            const newFavorite = {
                id: action.payload.id,
                name: action.payload.name,
                university: action.payload.university.title,
                ielts: action.payload.requirements?.IELTS,
                sat: action.payload.requirements?.SAT,
                fees_field_test: action.payload.fees_field_test,
                duration_field_test: action.payload?.duration_field_test?.substring(1, action.payload?.duration_field_test?.length - 1),
                start_date: action.payload.start_date
                
            };
            state.isBtnLoading = false;
            state.error = null;
            state.totalCountFavorites = state.totalCountFavorites + 1;
            state.program.is_shortlisted = action.payload.is_shortlisted;
            state.results = state.results.map((program) => {
                if (program.id === action.payload.id) {
                    program.is_shortlisted = action.payload.is_shortlisted;
                    program.isLoading = false;
                }
                return program;
            });
            state.favorites = state.favorites ? [...state.favorites, newFavorite] : [newFavorite];
        },
        
        [addFavorite.rejected.type]: (state, action) => {
            state.isBtnLoading = false;
            state.error = action.payload;
        },
        [deleteFavorite.pending.type]: (state, action) => {
            state.isBtnLoading = true;
            state.results = state.results?.map((program) => {
                if (program.id === action.meta.arg) {
                    program.isLoading = true;
                }
                return program;
            });
        },
        [deleteFavorite.fulfilled.type]: (state, action) => {
            state.isBtnLoading = false;
            state.error = null;
            state.totalCountFavorites = state.totalCountFavorites - 1;
            state.program.is_shortlisted = action.payload.is_shortlisted;
            state.results = state.results?.map((program) => {
                if (program.id === action.payload.id) {
                    program.is_shortlisted = action.payload.is_shortlisted;
                    program.isLoading = false;
                }
                return program;
            });
            state.favorites = state.favorites.filter((favorite) => favorite.id !== action.payload.id);
        },
        [deleteFavorite.rejected.type]: (state, action) => {
            state.isBtnLoading = false;
            state.error = action.payload;
        },


        // APPLICATIONS 
        [fetchAllApplication.pending.type]: (state) => {
            state.isLoading = true;
        },
        [fetchAllApplication.fulfilled.type]: (state, action) => {
            const lang = localStorage.getItem(ACCESS_LANG);
            state.isLoading = false;
            state.error = null;
            state.totalCountApplication = action.payload.count;
            state.selectApplications = action.payload.results.map((application) => ({
                id: application.id,
                name: application.name,
                value: application.id
            }))
            state.applications = action.payload.results.map((application) => ({
                id: application.id,
                program_id: application.program.id,
                name: application.program?.name ? application.program.name : "",
                logo: application.program.university.logo,
                city: application.program.university.city[0]?.name ? application.program.university.city[0].name : "-", 
                country: application.program.university.city[0]?.country.name ? application.program.university.city[0].country.name : "-",
                tuition_fee: application.program.fees_field_test,
                university: application.program.university.title,
                status: application.status,
                graduation_rate: application.program?.university.general.graduation_rate ? `${application.program.university.general.graduation_rate}%` : "-",
                duration_field_test: application.program.duration_field_test ? `${application.program.duration_field_test} ${lang === "en" ? "months" : "месяц-(ев)"}` : "",
            }));
        },
    
        [addApplication.fulfilled.type]: (state, action) => {
            const newApplication = {
                id: action.payload.id,
                name: action.payload.name,
                university: action.payload.university.title,
                ielts: action.payload.requirements?.IELTS,
                sat: action.payload.requirements?.SAT,
                fees_field_test: action.payload.fees_field_test,
                duration_field_test: action.payload?.duration_field_test?.substring(1, action.payload?.duration_field_test?.length - 1),
                start_date: action.payload.start_date
            };
            state.isBtnLoading = false;
            state.error = null;
            state.totalCountApplication = state.totalCountApplication + 1;
            state.program.is_applying = action.payload.is_applying;

            state.favorites = state.favorites.map((program) => {
                if (program.id === action.payload.id) {
                    program.is_applying = action.payload.is_applying;
                    program.isLoading = false;
                }
                return program;
            });
      
            state.applications = state.applications ? [...state.applications, newApplication] : [newApplication];
        },
        [addApplication.rejected.type]: (state, action) => {
            state.isBtnLoading = false;
            state.error = action.payload;
        },
        [deleteApplication.pending.type]: (state, action) => {
            state.isBtnLoading = true;
            state.results = state.results?.map((program) => {
                if (program.id === action.meta.arg) {
                    program.isLoading = true;
                }
                return program;
            });
        },
        [deleteApplication.fulfilled.type]: (state, action) => {
            state.isBtnLoading = false;
            state.error = null;
            state.totalCountApplication = state.totalCountApplication - 1;
            state.program.is_applying = action.payload.is_applying;

            state.results = state.results?.map((program) => {
                if (program.program_id === action.payload.id) {
                    program.is_applying = action.payload.is_applying;
                    program.isLoading = false;
                }
                return program;
            });
            state.applications = state.applications.filter((application) => application.program_id !== action.payload.id);
        },
        [deleteApplication.rejected.type]: (state, action) => {
            state.isBtnLoading = false;
            state.error = action.payload;
        },
    }
});

export default programSlice.reducer;
import { useState, useRef, useEffect } from "react";
import { useTranslate } from "@helpers/hooks"; // assuming you're using some i18n library like react-i18next
import { Icon } from "@components/common";
import "./HeaderLanguageToggle.scss";

const HeaderLanguageToggle = () => {
  const { i18n } = useTranslate();
  const lastValue = localStorage.getItem("lang");
  const [lang, setLang] = useState(lastValue || "en");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const languages = [
    { code: "en", label: "English", shortLabel: "Eng" },
    { code: "kz", label: "Қазақша", shortLabel: "Қаз" },
    { code: "ru", label: "Русский", shortLabel: "Рус" },
    { code: "uz", label: "O'zbek", shortLabel: "O'zb" },
  ];

  useEffect(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLangChange = (newLang) => {
    setLang(newLang);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="language-dropdown" ref={dropdownRef}>
      <div className="language-dropdown__toggle" onClick={toggleDropdown}>
        <Icon name="globe_lang" className="language-dropdown__icon" />
        <span className="language-dropdown__label">
          {languages.find((item) => item.code === lang)?.shortLabel || "Lang"}
        </span>
        <Icon
          name="arrow-down"
          className={`dropdown-arrow ${isDropdownOpen ? "open" : ""}`}
        />
      </div>

      {isDropdownOpen && (
        <div className="language-dropdown__menu">
          {languages.map(({ code, label }) => (
            <button
              key={code}
              className={`language-dropdown__item ${
                lang === code ? "active" : ""
              }`}
              onClick={() => handleLangChange(code)}
            >
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderLanguageToggle;

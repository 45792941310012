// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-profile__title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Mobile/MobileProfile/MobileProfile.scss"],"names":[],"mappings":"AAGI;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AAFR","sourcesContent":["@import \"@styles/variables\";\n\n.mobile-profile {\n    &__title {\n        font-size: 24px;\n        font-weight: 700;\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState } from "react";
import { useSelector } from "react-redux";
import {useTranslate} from "@helpers/hooks";
import {ButtonV2, NewModalLayout} from "@components/common";
import {ActivitiesTable, ActivityForm} from "@components/feature";
import "./ActivitiesAndAwards.scss";


export default function ActivitiesAndAwards({handleChangeTab}) {
  const {t} = useTranslate(),
  {profile} = useSelector(state => state.profile),
  [editModal, setEditModal] = useState(false),
  [activityItem, setActivityItem] = useState({});

  return (
    <div className="activities_and_awards">
      {editModal &&
          <NewModalLayout>
            <ActivityForm
              profile={profile}
              activityItem={activityItem}
              setShowModal={setEditModal}
              isModal={true}
              isEdit={true}
            />
          </NewModalLayout>
      }
      <div className="academic_results_header">
        <p className="academic_results_text">{t("PROFILE.ACTIVITIES_AND_AWARDS")}</p>
        <ButtonV2
          className="button--transparent"
          text={t("BUTTON.ADD_ACTIVITY")}
          onClick={handleChangeTab}
          iconLeft="add-activity"
          />
      </div>
      <ActivitiesTable 
        setActivityItem={setActivityItem}
        setEditModal={setEditModal}
      />
    </div>
  )
}
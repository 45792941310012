import React from 'react'
import { useMemo } from 'react'
import { useTranslate } from '@helpers/hooks'
import DashboardExamCard from '../DashboardExamCard/DashboardExamCard'

const DashboardIncreaseChances = () => {
    const {t} = useTranslate(),
        exams = useMemo(() => [
        {
            id: 1,
            title: t("DASHBOARD.EXAM_CARD.SAT"),
            iconText: "value",
            score: 0,
            total: 1600,
            btnIncluded: true,
            examTitle: "SAT",
        },
        {
            id: 2,
            title: t("DASHBOARD.EXAM_CARD.IELTS"),
            iconText: "value",
            score: 0,
            total: 36,
            btnIncluded: true,
            examTitle: "ACT",
        }],[t]) 
  return (
    <>
    <h1 className="dashboard-heading">Increase Admission Chances</h1>
    
        <DashboardExamCard examTitle={"SAT"}/>
    </>
  )
}

export default DashboardIncreaseChances
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@components/common';
import {HeroProgressBar} from "@components/feature";
import { useAction, useTranslate } from '@helpers/hooks';
import './HeroClassCardSelected.scss';

const HeroClassCardSelected = () => {
    const { t } = useTranslate(),
    {profile} = useSelector(state => state.profile),
    {badge, isBadgeLoading} = useSelector(state => state.courses),
    {fetchBadge} = useAction(),
    fullName = `${profile?.name} ${profile?.surname}`,
    [completed, setCompleted] = useState(0);

    useEffect(() => {
        fetchBadge();
    }, []);

    useEffect(() => {
        if (badge?.points !== null && badge?.points !== undefined) {
            setTimeout(() => setCompleted(badge?.points % 100), 500);
        }
    }, [badge?.points])

    const getHeroClass = (t, name, gender) => {
        const GENDER = gender?.toUpperCase();
        const NAME = name?.toUpperCase();
        return t(`LMS.BADGES.HEROES.${GENDER}.${NAME}`)
    }

    const getHeroDescription = (t, name) => {
        const NAME = name?.toUpperCase();
        return t(`LMS.BADGES.HEROES.DESCRIPTION.${NAME}`)
    }

    return (
        <>
            {!isBadgeLoading && badge &&
                <div className="hero-card">
                    <h4 className="hero-card-selected__title">{getHeroClass(t, badge.badge_category.name, badge.badge_category.gender)}</h4>
                    <p className="hero-card-selected__description">{getHeroDescription(t, badge.badge_category.name)}</p>
                    <div className='hero-card-selected__info'>
                        <Image className="hero-card-selected__image" src={badge.level.image} />
                        <div className="hero-card-selected__info-group">
                            <h4 className='hero-card-selected__title'>{fullName}</h4>
                            <p className='hero-card-selected__level'>{t("LMS.BADGES.LEVEL")} {badge.level.level}</p>
                            <HeroProgressBar completed={completed} />
                            <p>{t("LMS.BADGES.LABEL_1")}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default HeroClassCardSelected;

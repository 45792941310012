import { useMemo } from "react";
import { formatData } from "@utils/functions";
import { useAction, useTranslate } from "@helpers/hooks";
import { ActionsMenu } from "@components/common";
import "./EssaysSpecificListItem.scss";

const EssaysSpecificListItem = ({item, onSelectEssay, onEditEssay}) => {
    const { t } = useTranslate(),
    { deleteUniversitySpecificEssay, fetchUniversitySpecificEssay } = useAction(),
    ITEMS_INFO = useMemo(() => [
        {
            id: 1,
            key: t("ESSAY.LABELS.LABEL_7"),
            value: item.university_name || "???",
        },
        {
            id: 2,
            key: t("ESSAY.LABELS.LABEL_4"),
            value: item.topic || "???",
        },
        {
            id: 3,
            key: t("ESSAY.LABELS.LABEL_5"),
            value: item.updated_at && formatData(item.updated_at, 3) || "???",
        },
        {
            id: 4,
            key: t("ESSAY.LABELS.LABEL_6"),
            value: item.word_limit || "???",
        },
    ], [t, item]);
  
    const handleOpenModal = () => {
        onSelectEssay(item.id);
        fetchUniversitySpecificEssay(item.id);
    }

    const handleEdit = () => {
        onEditEssay(item.id);
    }
    
    const handleDelete = () => {
        deleteUniversitySpecificEssay(item.id);
    }

    const ACTIONS = useMemo(() => [
        {id: 1, name: t("ESSAY.ACTION.CHECK_THE_ESSAY"), click: handleOpenModal, icon: 'question'},
        {id: 2, name: t("ESSAY.ACTION.EDIT"), click: handleEdit, icon: 'edit'},
        {id: 3, name: t("ESSAY.ACTION.DELETE"), click: handleDelete, icon: 'delete-trash'},
    ], [t]);
    
    return (
        <div className="essays-list-item">
            <div className="essays-list-item__content">
                {
                    ITEMS_INFO.map(el => (
                        <div key={el.id} className="essays-list-item__pair">
                            <h1 className="essays-list-item__key">{el.key}</h1>
                            <p className="essays-list-item__value">{el.value}</p>
                        </div>
                    ))
                }
            </div>
            <ActionsMenu actions={ACTIONS}/>
        </div>
    );
}

export default EssaysSpecificListItem;

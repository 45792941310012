import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from '@utils/functions';
import { useAction, useTranslate } from '@helpers/hooks';
import { Label, ButtonV2, FormItem, Icon, List } from '@components/common';
import './Preferences.scss';
import classNames from 'classnames';

const Preferences = ({handleOnClick}) => {
    const {t} = useTranslate(),
    {updatePrefer} = useAction(),
    {myPrefer} = useSelector(state => state.questionnaire),
    {countries, subjects} = useSelector(state => state.directory),
    initialValues = useMemo(() => ({
        countries: myPrefer.countries,
        subjects: myPrefer.subjects,
    }), [myPrefer]),
    [values, setValues] = useState(initialValues),
    [counter, setCounter] = useState(values.countries?.length || 1),
    PROFILE_ITEMS = useMemo(() => [
        {
            id: 1,
            // label: "PROFILE.PREFERENCES.LABEL_1",
            placeholder: "FIELD.NOT_SELECTED",
            field: "dropdown",
            name: "countries",
            options: countries?.results,
            value: (index) => values?.countries[index]?.name,
            defaultValue: (index) => {
                return {
                    id: values.countries[index]?.id,
                    name: values.countries[index]?.name,
                    value: values.countries[index]?.id
                }
            },
        },
        {
            id: 2,
            label: "PROFILE.PREFERENCES.LABEL_2",
            placeholder: "FIELD.NOT_SELECTED",
            field: "dropdown",
            name: "subjects",
            options: subjects,
            value: values?.subjects[0]?.value,
            defaultValue: {
                id: values?.subjects[0]?.id,
                name: values?.subjects[0]?.name,
                value: values?.subjects[0]?.id
            },
        },
      ], [values, t]);

    const handleDebounce = useMemo(() => debounce(async (data) => {
        await updatePrefer({id: myPrefer.id, data});
    }), []);

    const handleAddCountry =  () => {
        setCounter(counter + 1);
        setValues((prevValues) => ({
            ...prevValues,
            countries: [...prevValues.countries],
        }));
    }
    
    const handleDeleteCountry = async (index) => {
        setCounter(counter - 1);
        const newCountries = values.countries.filter((_, idx) => idx !== index);
        setValues((prevValues) => ({
            ...prevValues,
            countries: newCountries,
            }));
        let countryIds = newCountries.map((country) => country.id);
        await handleDebounce({ countries: countryIds });
      };

    const handleChange = async (e, field, name, index) => {
        if (name === "countries") {
            const foundCountry = countries.results.find((country) => country.id === e.value);
            const newCountries = [...values.countries];
            newCountries[index] = { ...foundCountry };
            setValues((prevValues) => ({
            ...prevValues,
            countries: newCountries,
            }));
            let countryIds = newCountries.map((country) => country.id);
            await handleDebounce({ countries: countryIds });
        }
        if (name === "subjects") {
            const foundSubject = subjects.filter(subject => subject.id === e.value);
            setValues({...values, [name]: foundSubject});
            await handleDebounce({subjects: [e.value]});
        }
        return;
    };
    
    return (
        <div className='preferences'>
            <List
                className="preferences__list"
                items={PROFILE_ITEMS}
                renderItem={(item) => (
                    item.id == 1 ?
                    <React.Fragment key={item.id}>
                    <Label className="label__v2" htmlFor={"select"} label={t('PROFILE.PREFERENCES.LABEL_1')} />
                    {Array.from({length:counter}).map((_, ind) => (
                        <div className="dropdown-country" key={item.id}>

                        <FormItem
                            item={{
                                ...item,
                                label: ind === 0 ? item.label : "",
                                value: item.value(ind),
                                defaultValue: item.defaultValue(ind),
                              }
                              
                            }
                            className="preferences__dropdown"
                            handleChange={(newValue) => handleChange(newValue, item.field, item.name, ind)}
                        /> 
                        <div className="delete-country-button">
                            
                        <Icon
                            name="delete-country"
                            handleClick={() => handleDeleteCountry(ind)}
                        />
                        </div>
                        
                        </div>
                        ))}
                        <div className="add-country-button">
                            <ButtonV2
                                className="button--primary"
                                text={t("BUTTON.ADD_COUNTRY")}
                                onClick={handleAddCountry}
                                iconLeft="add-activity"
                            />
                        </div>
                </React.Fragment> : <FormItem
                        key={item.id}
                        item={item}
                        handleChange={handleChange}
                    />
                )}
            />
            <ButtonV2
                className="button--primary"
                text={t("PROFILE.NEXT")}
                onClick={handleOnClick}
                iconRight="arrow-right"
            />
        </div>
    );
}

export default Preferences;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkEmoji from 'remark-emoji';
import { useTranslate } from "@helpers/hooks";
import { remainingItems } from "@utils/functions";
import { COURSE_LESSON_ROUTE } from "@utils/consts";
import { ButtonLink, List } from "@components/common";
import { SectionTimeIndicator } from "@components/feature";
import {useIsMobile} from '@helpers/hooks';
import "./SectionDescription.scss";

export default function SectionDescription({ section, courseId, sectionId }) {
  const { t } = useTranslate();
  const practiceAssignments = remainingItems(section, "practice", "assignments");
  const isMobile = useIsMobile()

  const firstIncompleteLesson = section.lessons?.find((lesson) => !lesson.completed) ?? [];
  const lessonRoute =
    section && firstIncompleteLesson.length > 0
      ? COURSE_LESSON_ROUTE.replace(":courseId", courseId)
          .replace(":sectionId", sectionId)
          .replace(":lessonId", firstIncompleteLesson.id)
      : COURSE_LESSON_ROUTE.replace(":courseId", courseId)
          .replace(":sectionId", sectionId)
          .replace(":lessonId", section.lessons[0].id);

  const indicators = [
    {
      id: "1",
      name: "lms-video",
      text: t("LMS.SECTION.VIDEOS_LEFT"),
      number: `${remainingItems(section, "video", "time")} ${
        remainingItems(section, "video", "time") == 1 ? t("LMS.SECTION.MIN") : t("LMS.SECTION.MINS")
      }`,
    },
    {
      id: "2",
      name: "lms-reading",
      text: t("LMS.SECTION.READINGS_LEFT"),
      number: `${remainingItems(section, "reading", "time")} ${
        remainingItems(section, "video", "time") == 1 ? t("LMS.SECTION.MIN") : t("LMS.SECTION.MINS")
      }`,
    },
  ];

  if (practiceAssignments > 0) {
    indicators.push({
      id: "3",
      name: "lms-assignment",
      text: t("LMS.SECTION.PRACTICE_LEFT"),
      number: practiceAssignments,
    });
  }

  return (
    <div className="section__group">
      <div>
        <h2 className="section__title">{section.title}</h2>
        {!isMobile && <List
          className={"section__indicator"}
          items={indicators}
          renderItem={(indicator) => (
            <li key={indicator.id} className={"section__indicator-item"}>
              <SectionTimeIndicator indicator={indicator} />
            </li>
          )}
        />}
        <div className="section__description">
          <ReactMarkdown remarkPlugins={[remarkGfm, remarkEmoji]}>{section.description}</ReactMarkdown>
        </div>
      </div>
      {!isMobile && section.lessons?.length > 0 && (
        <ButtonLink  text={t("LMS.BUTTON.GET_STARTED")} link={lessonRoute} className="section__button button-primary" />
      )}
    </div>
  );
}

import {useTranslate} from "@helpers/hooks";
import {Image} from "@components/common";
import "./CatalogError.scss";

export default function CatalogError({message}) {
    const {t} = useTranslate();
    return (
        <div className={"catalog-error"}>
            <Image className={"catalog-error__img"} name={"catalog-error.png"}/>
            <div className={"catalog-error__content"}>
                <h3 className={"catalog-error__title"}>
                    {t("ERROR.DATA")}
                </h3>
                <p className={"catalog-error__desc"}>
                    {message}
                </p>
            </div>
        </div>
    );
};
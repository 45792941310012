import {useMemo} from "react";
import {useSelector} from "react-redux";
import {useTranslate} from "@helpers/hooks";
import {List} from "@components/common";
import "./DashboardList.scss";

export default function DashboardList({successRate}) {
    const {t} = useTranslate(),
        {results: recommendationPrograms, program} = useSelector(state => state.program),
        recommendations = useMemo(() => [
            {
                id: 1,
                name: t("DASHBOARD.LIST.TEXT_3"),
                text: `${successRate.total}%`,
                isShow: successRate.total
            },
            {
                id: 2,
                name: t("DASHBOARD.LIST.TEXT_4"),
                items: recommendationPrograms?.filter((recommendationProgram) => recommendationProgram.id !== program.id),
                isShow: recommendationPrograms?.length > 0 && program?.subject?.length > 0
            }
        ], [successRate, recommendationPrograms, program, t]);
    return (
        <List
            className={"dashboard-list"}
            items={recommendations}
            renderItem={(recommendation) => (
                recommendation.isShow && <li
                    key={recommendation.id}
                    className={"dashboard-list__item"}
                >
                    <p className={"dashboard-list__name"}>{recommendation.name}</p>
                    {
                        recommendation.items
                            ? <ul className={"dashboard-list__recommendation"}>
                                {
                                    recommendation.items.map((item) => (
                                        <li
                                            key={item.id}
                                            className={"recommendation__item"}
                                        >
                                            <p className={"recommendation__name"}>{item.name}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            : <p className={"dashboard-list__text"}>{recommendation.text}</p>
                    }
                </li>
            )}
        />
    );
};
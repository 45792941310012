import {useTranslate} from "@helpers/hooks";
import {Icon} from "@components/common";
import { logout } from "@utils/functions";
import classNames from "classnames";
import "./Logout.scss";

export default function Logout({className}) {
    const {t} = useTranslate();

    return (
        <div className={classNames("logout", className)} onClick={logout}>
            <Icon name={"logout"}/>
            <p className={"logout__text"}>
                {t("HEADER.PROFILE.LOGOUT_FULL")}
            </p>
        </div>
    );
};
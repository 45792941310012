import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@helpers/hooks';
import { UniversityDetailsCheck } from '@utils/functions';
import './EntryRequirements.scss'

const EntryRequirements = () => {
    const { t } = useTranslate(),
    { university, isUniversityLoading } = useSelector(state => state.university),
    entryRequirements = useMemo(() => [
        {id: 1, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.HEADER"), 
            params: [
            {id: 1, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_1"), value: university.more_info?.high_school_GPA},
            {id: 2, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_2"), value: university.more_info?.high_School_Rank},
            {id: 3, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_3"), value: university.more_info?.high_School_Transcript},
            {id: 4, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_4"), value: university.more_info?.college_Prep_Courses},
            {id: 5, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_5"), value: university.more_info?.recommendations},
            {id: 6, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_6"), value: university.more_info?.personal_statement},
            {id: 7, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_7"), value: university.more_info?.SAT_ACT},
            {id: 8, title: t("RECOMMENDATIONS.ENTRY_REQUIREMENTS.LABEL_8"), 
            value: UniversityDetailsCheck("ielts_toefl", 
            {ielts: university.more_info?.ielts, toefl: university.more_info?.toefl}, t)},
        ]},
        // {id: 2, title: "For Master Degree", params: [
        //     {id: 1, title: "Bachelor Diploma", value: "Required"},
        //     {id: 2, title: "Bachelor Transcript", value: "Required"},
        //     {id: 3, title: "Recommendations", value: "Required"},
        //     {id: 4, title: "Personal Statement", value: "Required"},
        //     {id: 5, title: "GMAT", value: "Required"},
        //     {id: 6, title: "English Proficiency", value: "Required"},
        // ]},
    ], [university, t]);
    
    return (
        <div className='entry'>
            <h1 className='entry__title'>{t("RECOMMENDATIONS.DETAILS_TABS.LABEL_5")}</h1>
            <div className='entry__content'>
                {entryRequirements.map(item => (
                    <div key={item.id} className='item'>
                        <h3 className='item__title'>{item.title}</h3>
                        <div className='item__params'>
                            {item.params.map(param => (
                                <div key={param.id} className='item__param'>
                                    <h4 className='item__param__name'>{param.title}</h4>
                                    <p className='item__param__value'>{param.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <p className='entry__footer'>{university.general.about_university}</p>
            
        </div>
    );
}

export default EntryRequirements;

import {useState} from "react";
import {DataContext} from "@helpers/context";

export default function DataProvider({children, initialData}) {
    const [data, setData] = useState(initialData);

    const setValues = (values) => {
        setData(prevData => ({
            ...prevData,
            ...values
        }));
    };

    return (
        <DataContext.Provider value={{data, setValues}}>
            {children}
        </DataContext.Provider>
    );
};
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction, useTranslate } from '@helpers/hooks';
import { BtnLoader, List, NewModalLayout } from '@components/common';
import { EssayModal, EssaysGeneralListItem, EssaysHeader } from '@components/feature';
import './GeneralEssays.scss';

const GeneralEssays = ({onSelectEssay, onEditEssay, isEditOpenInEssays, setIsEditOpenInEssays, essayId}) => {
    const { t } = useTranslate(),
    [newEssayModalOpen, setNewEssayModalOpen] = useState(false),
    [foundEssay, setFoundEssay] = useState(null),
    { fetchGeneralEssays } = useAction(),
    { generalEssays, isLoading, isUpdating } = useSelector(state => state.essays);
    
    useEffect(() => {
        fetchGeneralEssays();
    }, [isUpdating])

    useEffect(() => {
        if (generalEssays) {
            const essay = generalEssays?.results?.find(essay => essay.id === essayId);
            setFoundEssay(essay);
        }
    }, [generalEssays, essayId])

    const handleAddNewEssay = () => {
        setNewEssayModalOpen(true);
    }

    return (
        <div className='general-essays'>
            <EssaysHeader headerTitle={t("ESSAY.LABELS.LABEL_1")} btnOnClick={handleAddNewEssay} />
            {
                !isUpdating && !isLoading && generalEssays.results ?
                <List 
                    className={"essays-list"}
                    items={generalEssays.results}
                    renderItem={(item) => (
                        <EssaysGeneralListItem
                            key={item.id}
                            item={item}
                            onSelectEssay={onSelectEssay}
                            onEditEssay={onEditEssay}
                        />
                    )}
                />
                : 
                <div className="parent-loader">
                    <BtnLoader className={"btn-loader--blue-large"} />
                </div>
            }
            {
                newEssayModalOpen && 
                <NewModalLayout>
                    <EssayModal
                        title={t("ESSAY.LABELS.LABEL_9")}
                        isModalOpen={newEssayModalOpen}
                        setIsModalOpen={setNewEssayModalOpen}
                        isEdit={false}
                    />
                </NewModalLayout>
            }
            {
                isEditOpenInEssays && foundEssay &&
                <NewModalLayout>
                    <EssayModal
                        title={t("ESSAY.LABELS.LABEL_10")}
                        setIsModalOpen={setIsEditOpenInEssays}
                        isModalOpen={isEditOpenInEssays}
                        essay={foundEssay}
                        isEdit={true}
                    />
                </NewModalLayout>
            }
        </div>
    );
}

export default GeneralEssays;

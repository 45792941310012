import React from "react";
import "./DashboardMockSecton.scss";
import { useAction, useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Button, Image, List } from "@components/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MOCK_EXAMS } from "@utils/consts";

export default function DashboardMockSecton() {
  const { fetchMockExams } = useAction();
  const { mocks } = useSelector((state) => state.mocks);

  const history = useHistory();

  useEffect(() => {
    fetchMockExams();
  }, []);

  const handleRedirect = () => {
    history.push(MOCK_EXAMS);
  };

  const hasCompletedMocks = mocks?.some(
    (mock) =>
      (mock.nuet_mock_exam && mock.nuet_mock_exam.completed) ||
      (mock.sat_mock_exam && mock.sat_mock_exam.completed) ||
      (mock.ielts_mock_exam && mock.ielts_mock_exam.completed)
  );

  const uniqueTypeMocks = [
    mocks?.find((mock) => mock.nuet_mock_exam),
    mocks?.find((mock) => mock.sat_mock_exam),
    mocks?.find((mock) => mock.ielts_mock_exam),
  ].filter(Boolean);

  const { t } = useTranslate();

  const getDisplayType = (mock) => {
    return mock.type ? mock.type.replace("_mock", "") : "";
  };

  return (
    <div className="dashboard__mock">
      <div className="dashboard__mock-title">
        <h2 className="dashboard__subheading">{t("MOCK.TITLE")}</h2>

        <div className="dashboard__mock-placeholder-container">
          <p className="dashboard__mock-placeholder-message">
            {!hasCompletedMocks
              ? t("DASHBOARD.MOCK.MESSAGE_ONE")
              : t("DASHBOARD.MOCK.MESSAGE_TWO")}
          </p>
        </div>

        <List
          className="dashboard__mock-items"
          items={uniqueTypeMocks}
          renderItem={(item) => {
            const displayType = getDisplayType(item);
            return (
              <div
                key={item.id}
                className="dashboard__mock-item"
                onClick={() => handleRedirect()}
              >
                <>
                  <p className="dashboard__mock-item-type">{displayType}</p>
                  <h5 className="dashboard__mock-item-title">{item.title}</h5>
                  <Button
                    className="dashboard__mock-item-button"
                    text={t("DASHBOARD.MOCK.START")}
                  ></Button>
                </>
              </div>
            );
          }}
        />

        <Image
          name="exams_placeholder.png"
          className="dashboard__mock-placeholder"
        />
      </div>
    </div>
  );
}

import classNames from "classnames";
import { CustomLink, Icon } from "@components/common";
import "./MobileNavItem.scss";

const MobileNavItem = (props) => {
  const { id, route, isExternal, handleClick, icon, title, description, target } = props;

  const isLogout = icon == "logout";

  return (
    <li key={id} className={classNames("mobile-nav__item")}>
      <CustomLink
        to={route}
        target={target}
        isExternal={isExternal}
        className={"mobile-nav__link"}
        onClick={handleClick}
      >
        <Icon className={isLogout ? "icon--mobile-red" : "icon--nav"} name={icon} />
        <div className="mobile-nav__item">
          <h5 className={isLogout && "mobile-nav__item-red"}>{title}</h5>
          {description && <p>{description}</p>}
        </div>
        {!isLogout && <Icon className={"icon--mobile"} name="pagination-arrow-right" />}
      </CustomLink>
    </li>
  );
};

export default MobileNavItem;

const MAJOR_STUDY_LEVEL = (t) => [
    {id: 1, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_1"), value: "Bachelor"},
    {id: 2, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_2"), value: "Master"},
    {id: 3, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_3"), value: "MBA"},
    {id: 4, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_4"), value: "PhD"},
    {id: 5, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_5"), value: "College/Foundation"},
    {id: 6, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_6"), value: "Diploma"},
    {id: 7, name: t("common:RECOMMENDATIONS.MAJOR.MAJOR_STUDY_LEVEL.LABEL_7"), value: "Short-term"},
]

export {MAJOR_STUDY_LEVEL};
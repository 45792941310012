import { useState } from "react";
import { useAction, useTranslate, useOutsideClick } from "@helpers/hooks";
import { APPLICATION_STATUS, STATUSID_TO_STATUSNAME } from "@utils/consts";
import { List, Icon } from "@components/common";
import "./StatusSelect.scss";

export default function StatusSelect({ item }) {
  let circleClass, dropdownClass, arrowClass, statusName;
  const { changeApplicationStatus } = useAction(),
    { t } = useTranslate();

  const [selectedStatus, setSelectedStatus] = useState(STATUSID_TO_STATUSNAME(t)[item.status]),
    [isTooltipOpen, setIsTooltipOpen] = useState(false),
    ref = useOutsideClick(() => setIsTooltipOpen(false));

  const onToggleDropdown = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleChange = (status, item) => {
    changeApplicationStatus({ id: item.id, status: { status: status.id.toString() } });
    setSelectedStatus(status.name);
    setIsTooltipOpen(false);
  };

  switch (selectedStatus) {
    case t("common:APPLICATION.STATUS.APPLYING"):
      circleClass = "status__circle-blue";
      dropdownClass = "status__button-blue";
      arrowClass = "status__button-icon-blue";
      break;

    case t("common:APPLICATION.STATUS.APPLICATION_SENT"):
      circleClass = "status__circle-green";
      dropdownClass = "status__button-green";
      arrowClass = "status__button-icon-green";
      break;

    case t("common:APPLICATION.STATUS.ACCEPTED"):
      circleClass = "status__circle-purple";
      dropdownClass = "status__button-purple";
      arrowClass = "status__button-icon-purple";
      break;

    case t("common:APPLICATION.STATUS.UNSUCCESSFUL"):
      circleClass = "status__circle-red";
      dropdownClass = "status__button-red";
      arrowClass = "status__button-icon-red";
      break;

    default:
      circleClass = "";
      dropdownClass = "";
      arrowClass = "";
      statusName = "";
  }

  return (
    <div className="status" ref={ref}>
      <div className={`status__button ${dropdownClass}`} onClick={onToggleDropdown}>
        <div className="status__button-container">
          <div className={`status__circle ${circleClass}`}></div>
          {selectedStatus}
        </div>
        <Icon name={setIsTooltipOpen ? "arrow-down" : "arrow-up"} className={`status__button-icon ${arrowClass}`} />
      </div>
      {isTooltipOpen && (
        <List
          className={"status__dropdown"}
          items={APPLICATION_STATUS(t)}
          renderItem={(status) => (
            <li key={status.id} onClick={() => handleChange(status, item)} className={"action-menu__dropdown-item"}>
              <p>{status.name}</p>
            </li>
          )}
        />
      )}
    </div>
  );
}

import { toast } from 'react-toastify';
import { quotes } from "@utils/consts";

export const showQuote = (chance = 3) => {
  const quoteChance = Math.floor(Math.random() * chance) + 1;
  if (quoteChance === 1) {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    toast.success(
      <div>
        <p className="quote-text">"{randomQuote.text}"</p>
        {randomQuote.author && <p className="quote-author">- {randomQuote.author}</p>}
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: false,
        style: {
          background: '#233059',
          color: '#ffffff',
          borderRadius: '15px',
          lineHeight: '1.3',
        },
        progressStyle: {
          background: '#2196f3',
        },
      }
    );
  }
};
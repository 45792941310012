import { createSlice } from "@reduxjs/toolkit";
import {
  fetchChallenge,
  fetchChallenges,
  getChallengeProgress,
  getChallengeRating,
  submitChallengeAnswers,
} from "@store/actions/challengeAction";

const initialState = {
  challenges: [],
  challenge: null,
  progress: null,
  resources: [],
  resource: null,
  isLoading: false,
  answers: null,
  rating: null,
};

export const challengeSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    clearChallenge: (state) => {
      state.challenge = null;
    },
  },
  extraReducers: {
    [fetchChallenge.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchChallenge.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.challenge = action.payload;
    },
    [fetchChallenge.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchChallenges.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchChallenges.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.challenges = action.payload;
    },
    [fetchChallenges.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getChallengeProgress.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getChallengeProgress.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.progress = action.payload;
    },
    [getChallengeProgress.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [submitChallengeAnswers.pending.type]: (state) => {
      state.isLoading = true;
    },
    [submitChallengeAnswers.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.answers = action.payload;
    },
    [submitChallengeAnswers.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getChallengeRating.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getChallengeRating.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.rating = action.payload;
    },
    [getChallengeRating.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const { clearChallenge } = challengeSlice.actions;

export default challengeSlice.reducer;

import { CourseSidebarAccordionNavLink, CourseSidebarAccordionList } from "@components/common";
import { COURSE_ROUTE, COURSE_ONBOARDING_ROUTE } from "@utils/consts";
import "./CourseSidebarAccordion.scss"
export default function CourseSidebarAccordion({
  route,
  icon,
  name,
  courseId,
  mockId,
  isPathnameAccordion,
  isPathnameWorkshop,
  isPathnameMock,
  isAccordionOpen,
  setIsAccordionOpen,
  course,
  pathname,
  value,
  mockExams
}) {
  const handleAccordionClick = (e) => {
    setIsAccordionOpen((prevState) => !prevState);
    if (isPathnameAccordion) {
      e.preventDefault();
    }
  };

  const getListItems = (value) => {
    if (!course || !course.sections || !mockExams) return [];
    switch (value) {
      case "course-material":
        return course.sections ?? [];
      case "workshop":
        return course.workshops ?? [];
        case "mock":
          return mockExams ?? [];
      default:
        return [];
    }
  };

  const isCurrentPath = (value, id) => {
    switch (value) {
      case "course-material":
        return `${COURSE_ROUTE.replace(":courseId", courseId)}/sections/${id}`;
      case "workshop":
        return `${COURSE_ROUTE.replace(":courseId", courseId)}/workshops/${id}`;
      default:
        return "";
    }
  };

  const completedIds = course?.sections.filter((section) => section.completed).map((section) => section.id);
  const uncompletedSection = course?.sections?.find((section) => !section.completed);
  const unblockedId = uncompletedSection?.id;
  if (unblockedId) {
    completedIds.push(unblockedId);
  }
  
  return (
    <>
      {course && course.enrolled &&
        <CourseSidebarAccordionNavLink 
          handleAccordionClick={handleAccordionClick}
          route={route}
          courseId={courseId}
          isPathnameAccordion={isPathnameAccordion}
          icon={icon}
          name={name}
          isAccordionOpen={isAccordionOpen}
      />}
      {course && course.enrolled && isAccordionOpen && course!==null && (
        <CourseSidebarAccordionList
          getListItems={getListItems}
          value={value}
          courseId={courseId}
          isPathnameWorkshop={isPathnameWorkshop}
          isPathnameMock={isPathnameMock}
          isCurrentPath={isCurrentPath}
          course={course}
          pathname={pathname}
          completedIds={completedIds}
        />
      )}
    </>
  );
}

import { axiosRequest } from "@api/xhr";

export default class NotificationService {
  static async getMyNotifications() {
    return await axiosRequest.get(`auth/my-notifications/`);
  }

  static async markNotifications() {
    return await axiosRequest.post(`auth/my-notifications/read/`);
  }
}

import { Button, Icon } from '@components/common';
import { useTranslate } from '@helpers/hooks';
import './EssaysHeader.scss';

const EssaysHeader = (props) => {

    const {t} = useTranslate()
    const { headerTitle, btnOnClick } = props;
    return (
        <div className='essays-header'>
            <h1 className="essays-header__title">{headerTitle}</h1>
            <Button 
                text={t("ESSAY.ADD")}
                className="btn--secondary btn--pill" 
                onClick={btnOnClick}
                iconLeft={<Icon name="add-activity"/>}
            />
        </div>
    );
}

export default EssaysHeader;

import { ChallengeService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchChallenge = createAsyncThunk(
  "challenge/fetchOne",
  async (id, thunkAPI) => {
    try {
      const res = await ChallengeService.fetchChallenge(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const fetchChallenges = createAsyncThunk(
  "challenge/fetchAll",
  async (id, thunkAPI) => {
    try {
      const res = await ChallengeService.fetchChallenges();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getChallengeProgress = createAsyncThunk(
  "challenge/getProgress",
  async (id, thunkAPI) => {
    try {
      const res = await ChallengeService.getChallengeProgress(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const submitChallengeAnswers = createAsyncThunk(
  "challenge/submitAnswers",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await ChallengeService.submitChallengeAnswers(id, data);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const getChallengeRating = createAsyncThunk(
  "challenge/getRating",
  async (id, thunkAPI) => {
    try {
      const res = await ChallengeService.getChallengeRating(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export {
  fetchChallenge,
  fetchChallenges,
  getChallengeProgress,
  submitChallengeAnswers,
  getChallengeRating,
};

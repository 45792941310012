import {Fragment, useEffect} from "react";
import {useSelector} from "react-redux";
import {useAction} from "@helpers/hooks";
import {parseAllObjToStr} from "@utils/functions";
import {List, Pagination} from "@components/common";
import {CatalogError, CatalogEmpty, UniversitySkeleton, UniversityCard} from "@components/feature";
import "./UniversityList.scss";

export default function UniversityList(props) {
    const {
        debouncedValue, selectedFilters, order, additionalParams,
        universityId, setUniversityId, setUniversityOpen,
    } = props;

    const {results: universities, isLoading, totalCount, pageSize, currentPage, offset, error} = useSelector(state => state.university),
        {fetchUniversities, fetchUniversity, setUniversitiesPageChange, setUniversitiesPageSizeChange} = useAction();



    const onSelectUniversity = (id) => {
        setUniversityId(id);
        setUniversityOpen(true);
    };

    useEffect(() => {
        const parseObj = parseAllObjToStr(selectedFilters);
        const params = {
            limit: pageSize,
            offset: offset,
            search: debouncedValue,
            ordering: order,
            region: parseObj.region,
            country: parseObj.country,
            city: parseObj.city,
            subject: parseObj.subject,
            degree: parseObj.degree,
            tuition_fee: parseObj.tuition_fee,
            scholarships: parseObj.scholarships,
            rating__QS: parseObj.rating__QS,
            study_level: parseObj.primary_study_level,
            ...additionalParams?.universities
        };
        fetchUniversities(params);
        // eslint-disable-next-line
    }, [currentPage, pageSize, debouncedValue, order]);

   

    useEffect(() => {
        if (universityId) {
            fetchUniversity(universityId);
        }
        // eslint-disable-next-line
    }, [universityId]);

    if (universities?.length === 0 && !isLoading) {
        return (
            <CatalogEmpty/>
        );
    } else if (error) {
        return (
            <CatalogError message={error}/>
        );
    }
    return (
        <Fragment>
            {
                universities && !isLoading
                ? <List
                    className={"university-list"}
                    items={universities}
                    renderItem={(university, universityId) =>  (
                        <UniversityCard
                            key={universityId}
                            university={university}
                            selectedFilters={selectedFilters}
                            onSelectUniversity={() => onSelectUniversity(university.id)}
                            isSearch={true}
                        />
                    )}
                />
                :
                <div className={"university-list__skeleton"}>
                    {
                        Array(10).fill(Infinity).map((item, itemId) => itemId).map((university, universityId) => (
                            <UniversitySkeleton key={universityId}/>
                        ))
                    }
                </div>
            }
            <Pagination
                className={"university-list__pagination"}
                totalCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                siblingCount={0}
                onPageChange={(page) => setUniversitiesPageChange(page)}
                onPageSizeChange={(pageSize) => setUniversitiesPageSizeChange(pageSize)}
            />
        </Fragment>
    );
}
import { List } from "@components/common";
import { CourseCard } from "@components/feature/Courses";
import { useAction, useTranslate } from "@helpers/hooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardCourseCard } from "../DashboardCourseCard";
import "./DashboardCourses.scss";

const DashboardCourses = () => {
  const { fetchMyCourses, fetchCourses } = useAction();
  const { courses } = useSelector((state) => state.courses);
  const { t } = useTranslate();
  const [myCoursesFetched, setMyCoursesFetched] = useState(false);

  useEffect(() => {
    const fetchCoursesData = async () => {
      await fetchMyCourses();
      setMyCoursesFetched(true);
    };

    fetchCoursesData();
  }, []);

  useEffect(() => {
    if (myCoursesFetched && courses && courses.length === 0) {
      fetchCourses();
    }
  }, [myCoursesFetched, courses]);

  if (!courses) {
    return null;
  }

  return (
    <div className="dashboard__courses">
      <h2 className="dashboard__subheading">
        {courses.length === 0 ? t("DASHBOARD.POPULAR_COURSES") : t("DASHBOARD.MY_COURSES")}
      </h2>
      <List
        className={"dashboard__courses-list"}
        items={courses}
        renderItem={(item) => <DashboardCourseCard key={item.id} course={item} />}
      />
    </div>
  );
};

export default DashboardCourses;

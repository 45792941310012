import { useTranslate, useIsMobile } from "@helpers/hooks";
import { FormItem, List, AutoComplete } from "@components/common";
import "./Section.scss";

export default function Section({
  index,
  total,
  preferenceSection,
  handleChange,
  isClear,
  setIsClear,
  handleClear,
  selectedFilters,
  setSelectedFilters,
}) {
  const isMobile = useIsMobile()
  const { t } = useTranslate();

  const isLastItem = index === total - 1;
  const listClassName = preferenceSection.isAutocomplete
    ? "section__body-autocomplete"
    : isLastItem
    ? "section__body-last"
    : "section__body";

  return (
    <li key={preferenceSection.id} className={isMobile ? "section-mobile" : ""}>
      {<h3 className={isMobile ? "section__header-mobile" : "section__header"}>{t(preferenceSection.header)}</h3>}

      <List
        className={listClassName}
        items={preferenceSection.items}
        renderItem={(preference) => {
          return preferenceSection.isAutocomplete ? (
            <AutoComplete
              key={preference.id}
              label={preference.label}
              name={preference.name}
              placeholder={t("FIELD.NOT_CHOSEN")}
              disabled={false}
              isClear={isClear}
              setIsClear={setIsClear}
              suggestions={preference.options}
              method={preference.method}
              handleChange={handleChange}
              handleClear={handleClear}
              selectedSuggestion={selectedFilters.subjects || []}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
            />
          ) : (
            <FormItem key={preference.id} item={preference} handleChange={handleChange} />
          );
        }}
      />
    </li>
  );
}

import React, { useState, useMemo } from 'react';
import { Icon } from '@components/common';
import { formatData } from '@utils/functions';
import './CourseGradesMockItem.scss';

const CourseGradesMockItem = ({grade}) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false),
    quizSectionsGrade = useMemo(() => [
        {
            id: 1,
            title: 'Listening',
            grade: grade.listening_score
        },
        {
            id: 2,
            title: 'Reading',
            grade: grade.reading_score
        },
        {
            id: 3,
            title: 'Writing',
            grade: grade.writing_score
        },
        {
            id: 4,
            title: 'Speaking',
            grade: grade.speaking_score
        }
    ], [grade]);

    const handleOpenAccordion = () => {
        setIsAccordionOpen((prevState) => !prevState);
    }

    return (
        <React.Fragment key={grade.id}>
            <div key={grade.id} className="grade__table-record">
                <div className="grade__acc-item" onClick={handleOpenAccordion}>
                    <Icon name={isAccordionOpen ? "chevron-up" : "chevron-down"} />
                    <h5 className="quiz-title">{grade.title}</h5>
                </div>
                <div className="record-p">{formatData(grade.datetime, 1)}</div>
                <div className="record-p">{`${grade.total_score}`}</div>
            </div>
            {isAccordionOpen &&
                quizSectionsGrade.map((section) => (
                    <div className="grade__sub-item" key={section.id}>
                        <p className="record-p">{section.title}</p>
                        <p className="record-p">{section.grade}</p>
                    </div>
                ))
            }
        </React.Fragment>
    );
}

export default CourseGradesMockItem;

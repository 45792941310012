import {WithRemoveScroll} from "@helpers/hoc";
import "./MobileCourseFilter.scss";
import { useMemo } from "react";
import { useTranslate, useWindowSize } from "@helpers/hooks";
import { COURSE_STATUS_FILTER } from "@utils/consts";
import { List, FormItem, Search, Divider } from "@components/common";
import classNames from "classnames";
import { Icon, Button } from "@components/common";
import { trackAmplitude } from "@utils/functions";
export default function MobileCourseFilter(props) {
    const {t} = useTranslate(),
        {
            courses, 
            selectedAuthor,
            setSelectedAuthor,
            selectedCategory,
            setSelectedCategory,
            filterShow, 
            setFilterShow
        } = props;

    const statusList = useMemo(() => {
        if (!courses) return [];
        const uniqueNames = [...new Set(courses.map((course) => course?.author?.name))];
        const categories = uniqueNames.map((name, index) => ({ id: index, value: name, name }));
        return [{ id: -1, value: "All", name: t("APPLICATION.ALL") }, ...categories];
        }, [courses, t]);
        
    const courseInfo = useMemo(() => {
        return {
            header: [
            {
                id: 1,
                label: t("LMS.COURSE.STATUS.TITLE"),
                field: "dropdown",
                name: "status",
                defaultValue: {
                name: selectedCategory ? `APPLICATION.${selectedCategory.toUpperCase()}` : "???",
                value: selectedCategory
                },
                options: COURSE_STATUS_FILTER(t),
            },
            {
                id: 2,
                label: t("LMS.COURSE.AUTHOR"),
                field: "dropdown",
                name: "author",
                defaultValue: {
                name: selectedAuthor === "All" ? `APPLICATION.${selectedAuthor.toUpperCase()}` : selectedAuthor,
                value: selectedAuthor,
                },
                options: statusList,
            },
            ],
        };
        }, [statusList, selectedAuthor, selectedCategory, t]);

    const handleChange = async (e, field, name) => {
        if (name === "status") {
            await setSelectedCategory(e.value);
            trackAmplitude("learning_platfrom_status_select", {description: 'Пользователь успешно выбрал статус курса', status: e.value})
        }
        if (name === "author") {
            await setSelectedAuthor(e.value);
            trackAmplitude("learning_platfrom_author_select", {description: 'Пользователь успешно выбрал автор курса', author: e.value})
        }
    };

    return (
        <WithRemoveScroll flag={filterShow}>
            <div className={classNames("mobile-course-filter", {active: filterShow})}>
                <div className={"mobile-course-filter__header"}>
                    <p className={"mobile-course-filter__title"}>
                        {t("BUTTON.FILTERS")}
                    </p>
                    <Icon name={"burger-close"} handleClick={() => setFilterShow(false)}/>
                </div>
                <div className={"mobile-course-filter__filters"}>
                    <List
                        className={"courses_mobile__header_filter"}
                        items={courseInfo.header}
                        renderItem={(item) => <FormItem key={item.id} item={item} handleChange={handleChange} />}
                    />
                    
                </div>
                <div className={"mobile-course-filter__footer"}>
                    <Button
                        className={"btn--primary btn--pill"}
                        text={t("BUTTON.APPLY_FILTERS")}
                        height={46}
                        onClick={() => setFilterShow(false)}
                    />
                </div>
            </div>
        </WithRemoveScroll>
    );
};
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";

export default function useBanner() {
  const { fetchAnnounces } = useAction();
  const { announces } = useSelector((state) => state.directory);
  const [isBannerActive, setBannerActive] = useState(false);

  useEffect(() => {
    fetchAnnounces();
  }, []);

  useEffect(() => {
    if (announces.length > 0) {
      const currentAnnouncement = announces[0];
      const closedBanners =
        JSON.parse(localStorage.getItem("closedBanners")) || [];

      if (!closedBanners.includes(currentAnnouncement.id.toString())) {
        setBannerActive(true);
      }
    }
  }, [announces]);

  const handleCloseBanner = (bannerId) => {
    const closedBanners =
      JSON.parse(localStorage.getItem("closedBanners")) || [];
    if (!closedBanners.includes(bannerId.toString())) {
      closedBanners.push(bannerId.toString());
      localStorage.setItem("closedBanners", JSON.stringify(closedBanners));
    }
    setBannerActive(false);
  };

  return {
    isBannerActive,
    handleCloseBanner,
    currentAnnouncement: announces[0],
  };
}

import {Activities, ActivitiesTable, ActivityForm} from './Activities';
import {Education} from './Education';
import {Exams} from './Exams';
import {ExamsBlock} from './ExamsBlock';
import {Preferences} from './Preferences';
import {Profile} from "./Profile";
import {ProfileEdit} from "./ProfileEdit";
import {ProfileSummary} from './ProfileSummary';
import {TabsLayout} from './TabsLayout';
import {UploadProfilePhoto} from './UploadProfilePhoto';


export {
  Activities,
  ActivitiesTable,
  ActivityForm,
  Education,
  Exams,
  ExamsBlock,
  Preferences,
  Profile,
  ProfileEdit,
  ProfileSummary,
  TabsLayout,
  UploadProfilePhoto,
};
import { useTranslate } from "@helpers/hooks";
import { Image } from "@components/common";
import "./CatalogEmpty.scss";

export default function CatalogEmpty({ className, isTextEmpty, imageClassName }) {
  const { t } = useTranslate();
  return (
    <div className={`${className || 'catalog-empty '}`}>
      <Image className={`${imageClassName || 'catalog-empty__img '}`} name={"catalog-not-found.png"} />
      {!isTextEmpty && (
        <div className={"catalog-empty__content"}>
          <h3 className={"catalog-empty__title"}>{t("EMPTY.DATA")}</h3>
          <p className={"catalog-empty__desc"}>{t("EMPTY.DESCRIPTION")}</p>
        </div>
      )}
    </div>
  );
}

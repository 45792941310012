// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf__window {
  overflow-y: scroll;
  width: 100%;
}

canvas.react-pdf__Page__canvas {
  margin-bottom: 10px;
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Document {
  padding: 0 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/PdfWindow/PdfWindow.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;AAAJ;;AAIA;EACE,mBAAA;EACE,sBAAA;EACA,uBAAA;AADJ;;AAIA;EACE,0BAAA;AADF","sourcesContent":[".pdf {\n  &__window {\n    overflow-y: scroll;\n    width: 100%;\n  }\n}\n\ncanvas.react-pdf__Page__canvas {\n  margin-bottom: 10px;\n    width: 100% !important;\n    height: auto !important;\n}\n\n.react-pdf__Document {\n  padding: 0 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

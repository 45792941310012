import { Icon, Input, Tag } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import classNames from "classnames";
import { useEffect } from "react";
import "./AutoCompleteField.scss";

export default function AutoCompleteField(props) {
  const {
    suggestionsActive,
    selectedSuggestion,
    deleteSuggestionHandler,
    value,
    handleClick,
    showCompleteHandler,
    setValue,
    isClear,
    setIsClear,
    isOne,
  } = props;
  const { t } = useTranslate();

  const placeholder =
    selectedSuggestion.length > 0
      ? t("FIELD.PLACEHOLDERS.ADD_MORE")
      : t("FIELD.PLACEHOLDERS.TYPE_TO_SEARCH");

  useEffect(() => {
    if (!isClear) return;
    setValue("");
    setIsClear(false);
  }, [isClear]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className={classNames("complete", { active: suggestionsActive && !isOne, "complete__dashboard": isOne })}>
      <div className={classNames("complete__input")}>
        {isOne && selectedSuggestion.length > 0 ? (
          <Tag
            name={selectedSuggestion[0].name}
            handleDelete={() => deleteSuggestionHandler(selectedSuggestion[0])}
            isOne={isOne}
          />
        ) : selectedSuggestion.length > 0 && !isOne ? (
          <>
            <Tag
              className={"complete__tag"}
              name={selectedSuggestion[0]?.name}
              handleDelete={() => deleteSuggestionHandler(selectedSuggestion[0])}
            />

            <Input
              className={"input--reset"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onClick={handleClick}
              placeholder={placeholder}
            />
          </>
        ) : (
          <Input
            className={"input--reset"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onClick={handleClick}
            placeholder={placeholder}
          />
        )}
      </div>
      {!isOne && (
        <div className={"complete__icon"}>
          {selectedSuggestion.length > 1 && (
            <p className={"complete__more"}>+{selectedSuggestion.length - 1}</p>
          )}
          <Icon
            className={"icon--pointer"}
            name={suggestionsActive ? "arrow-up" : "arrow-down"}
            handleClick={showCompleteHandler}
          />
        </div>
      )}
    </div>
  );
}

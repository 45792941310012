import React from "react";
import { CustomLink, List } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { filterItemsWithValues } from "@utils/functions";
import './OverviewCosts.scss'

export default function OverviewCosts({ priceInfo }) {
  const { t } = useTranslate();
  const filteredItems = filterItemsWithValues(priceInfo);

  return (
    <List
      className="overview__cost"
      items={filteredItems}
      renderItem={(item) => {
        return (
          item.value && (
            <div key={item.id} className="overview__cost-item">
              <p className="overview__cost-item-rank">{item.value}</p>
              <p className="overview__cost-item-title">{t(item.title)}</p>
              {item.link && (
                <CustomLink isExternal={true} to={item.link}>
                  <p className="overview__cost-item-link">{t(item.linkLabel)}</p>{" "}
                </CustomLink>
              )}
            </div>
          )
        );
      }}
    />
  );
}

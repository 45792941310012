import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslate, useWindowSize} from "@helpers/hooks";
import {DASHBOARD_ROUTE} from "@utils/consts";
import {Select} from "@components/common";
import classNames from "classnames";
import "./DashboardProgram.scss";

export default function DashboardProgram({selectedProgram, setSelectedProgram}) {
    const {t} = useTranslate(),
        {program, selectFavorites} = useSelector(state => state.program),
        {replace} = useHistory(),
        windowSize = useWindowSize();
    
    return (
        (Object.keys(program).length > 0 || selectedProgram) && <div className={"dashboard-program"}>
            <p className={"dashboard-program__text"}>
                {t("DASHBOARD.SHORTLIST_PROGRAM")}
            </p>
            <Select
                className={classNames("dashboard-program__select select--border", {"select--small": windowSize.width < 524})}
                placeholder={t("FIELD.CHOOSE_PROGRAM")}
                options={selectFavorites}
                defaultValue={selectedProgram ?? {id: program.id, name: program.name, value: program.id}}
                handleChange={(e) => {
                    setSelectedProgram(e);
                    replace(DASHBOARD_ROUTE)
                }}
            />
        </div>
    );
};
import { useMemo, useEffect, useState } from "react";
import { useTranslate } from "@helpers/hooks";
import { List, FormItem } from "@components/common";
import "./DashboardFilters.scss";

const DashboardFilters = ({
  favorites,
  setProgramId,
  selectedProgram,
  setSelectedProgram,
  selectedUniversity,
  setSelectedUniversity,
  data,
}) => {


  const { t } = useTranslate();


  const
    universityList = useMemo(() => {
      if (!favorites) return [];
      const uniqueUniNames = [...new Set(favorites.map((favorite) => favorite?.university))];
      const universitites = uniqueUniNames.map((name, index) => ({ id: index, value: name, name }));
      return [...universitites];
    }, [favorites, t]),
    programList = useMemo(() => {
      if (!favorites) return [];
      let uniquePrograms;
      if (!selectedUniversity) {
        uniquePrograms = [...new Set(favorites.map((favorite) => favorite?.name))];
      } else {
        uniquePrograms = [
          ...new Set(
            favorites
              .filter((favorite) => favorite?.university == selectedUniversity)
              .map((favorite) => favorite?.name)
          ),
        ];
      }
      const programs = uniquePrograms.map((name, index) => ({ id: index, value: name, name }));
      return [...programs];
    }, [favorites, selectedUniversity, t]);

  useEffect(() => {
    if (!favorites) return [];
    if (selectedProgram !== null) {
      let findProgramId = favorites.find((favorite) => favorite?.name === selectedProgram);
      setProgramId(findProgramId?.id);
    }
  }, [selectedProgram, selectedUniversity, favorites]);

  useEffect(() => {
    if (!programList.map((programList) => programList.name).includes(selectedProgram)) {
      setSelectedProgram(programList[0]?.name);
    }
  }, [selectedUniversity]);

  useEffect(() => {
    if (!selectedUniversity && universityList.length > 0) {
      setSelectedUniversity(universityList[0]?.name);
    }
  }, [universityList, selectedUniversity]);

  useEffect(() => {
    if (!selectedProgram && programList.length > 0) {
      setSelectedProgram(programList[0]?.name);
    }
  }, [programList, selectedProgram]);

  const favoritesInfo = useMemo(() => {
    return {
      header: [
        {
          id: 1,
          label: t("DASHBOARD.CHANCES.TEXT_1"),
          field: "dropdown",
          name: "university",
          isChance: true,
          options: universityList,
          defaultValue: {
            name: selectedUniversity ?? "",
            value: selectedUniversity ?? "",
          },
        },
        {
          id: 2,
          label: t("DASHBOARD.CHANCES.TEXT_2"),
          field: "dropdown",
          name: "program",
          isChance: true,
          options: programList,
          defaultValue: {
            name: selectedProgram ?? "",
            value: selectedProgram ?? "",
          },
        },
      ],
    };
  }, [universityList, programList, selectedProgram, selectedUniversity, t]);

  const handleChange = async (e, field, name) => {
    if (name === "university") {
      await setSelectedUniversity(e.value);
    }
    if (name === "program") {
      await setSelectedProgram(e.value);
    }
  };

  return (
    <div className="dashboard__group">
      <List
        className={"dashboard__header"}
        items={favoritesInfo.header}
        renderItem={(item) => <FormItem key={item.id} item={item} handleChange={handleChange} />}
      />

      <p className="dashboard__percent">{t("DASHBOARD.CHANCES.TEXT_3", { data })}</p>


      
    </div>
    
  );
};

export default DashboardFilters;

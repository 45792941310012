import { ButtonLink } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";
import { getNextLessonLink } from "@utils/functions";
import { getPrevLessonLink } from "@utils/functions";
import {useIsMobile} from "@helpers/hooks";
import { useEffect } from "react";
import "./LessonNavigation.scss";

export default function LessonNavigation({ lesson, courseId, sectionId, lessonId }) {

  const isMobile = useIsMobile()

  const { t } = useTranslate(),
    { course } = useSelector((state) => state.courses),
    { fetchCourse } = useAction(),
    prevLessonLink = getPrevLessonLink(courseId, sectionId, lessonId, course),
    nextLessonLink = getNextLessonLink(courseId, sectionId, lessonId, course);

  useEffect(() => {
    fetchCourse(courseId);
  }, []);

  return (
    <div className={isMobile ? `lesson__button-navigation-mobile` : `lesson__button-navigation`}>
      {prevLessonLink && (
        <ButtonLink
          className={`${isMobile && `lesson-nav-mobile`}  button--transparent lesson-nav`}
          iconLeft="arrow-left-nav"
          text={t("LMS.BUTTON.PREVIOUS")}
          link={getPrevLessonLink(courseId, sectionId, lessonId, course)}
        />
      )}
      {nextLessonLink && (
        <ButtonLink
        className={`${isMobile && `lesson-nav-mobile`}  button--transparent lesson-nav`}
          iconRight="arrow-right-nav"
          text={t("LMS.BUTTON.NEXT")}
          link={getNextLessonLink(courseId, sectionId, lessonId, course)}
        />
      )}
    </div>
  );
}

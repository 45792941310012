import React from "react";
import { useTranslate } from "@helpers/hooks";
import { parseStrToArr } from "@utils/functions";
import "./ScholarshipsEligibility.scss";
import { List } from "@components/common";

export default function ScholarshipsEligibility({ item }) {
  const { t } = useTranslate();

  return (
    <div className="scholarships__eligibility">
      <h4>{t("SCHOLARSHIPS.CRITERIA")}</h4>
      <List items={parseStrToArr(item?.eligibility)} renderItem={(item) => <li key={item}>{item}</li>} />
    </div>
  );
}

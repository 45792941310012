// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preferences {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}
.preferences__list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.preferences button {
  align-self: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 40px;
}
.preferences .add-country-button {
  align-self: flex-start;
  display: flex;
  row-gap: 16px;
}
.preferences .delete-country-button {
  align-self: center;
  display: flex;
  row-gap: 16px;
  margin-left: 28px;
  cursor: pointer;
}
.preferences .preferences__dropdown {
  width: 100vw;
}
.preferences .dropdown-country {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: revert;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Profile/Preferences/Preferences.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AACR;AAEI;EACI,oBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AAAR;AAGI;EACI,sBAAA;EACA,aAAA;EACA,aAAA;AADR;AAII;EACI,kBAAA;EACA,aAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;AAFR;AAII;EACI,YAAA;AAFR;AAII;EACI,WAAA;EACA,aAAA;EACA,2BAAA;EAEA,sBAAA;AAHR","sourcesContent":[".preferences {\n    display: flex;\n    flex-direction: column;\n    row-gap: 28px;\n\n    &__list {\n        display: flex;\n        flex-direction: column;\n        row-gap: 15px;\n    }\n\n    button {\n        align-self: flex-end;\n        width: fit-content;\n        margin-right: 40px;\n    }\n\n    .add-country-button {\n        align-self: flex-start;\n        display: flex;\n        row-gap: 16px;\n      }\n\n    .delete-country-button {\n        align-self: center;\n        display: flex;\n        row-gap: 16px;\n        margin-left: 28px;\n        cursor: pointer;\n    }\n    .preferences__dropdown{\n        width:100vw;\n    }\n    .dropdown-country{\n        width: 100%;\n        display: flex;\n        justify-content: flex-start;\n        // justify-content: space-between;\n        flex-direction: revert;\n    }\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

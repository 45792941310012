import { useSelector } from 'react-redux';
import { List } from '@components/common';
import { CourseGradesMockItem } from '@components/feature';
import './CourseGradesMockExams.scss';

const CourseGradesMockExams = ({sortDescending}) => {
    const { mockGrades, isMockGradesLoading } = useSelector((state) => state.courses);

    const getListItems = (grades) => {
        const sortedGrades = [...grades].sort((a, b) => {
            if (sortDescending) {
                return new Date(a.datetime) - new Date(b.datetime);
            } else {
                return new Date(b.datetime) - new Date(a.datetime);
            }
        });
        return sortedGrades;
    }

    return (
        <>
            {
                mockGrades && !isMockGradesLoading &&
                    <List
                        className="grade__table-records"
                        items={getListItems(mockGrades)}
                        renderItem={(grade) => (
                            <CourseGradesMockItem grade={grade} key={grade.id}/>
                        )}
                    />
            }
        </>
    );
}

export default CourseGradesMockExams;

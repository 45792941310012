import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./DashboardBar.scss";

export default function DashboardBar({ exam, data }) {
  const [loaded, setLoaded] = useState(false);

  const { real, desired, scoreReal, scoreDesired } = data;

  useEffect(() => {
    data && setLoaded(true)
  }, [data]);


  const shouldRenderRealBar = () => {
    return !(
      scoreReal === null ||
      scoreReal === undefined ||
      scoreReal === 0 ||
      Number.isNaN(scoreReal)
    );
  };

  const isSuccess = real >= desired;

  const realStyle = {
    width: loaded ? `${real}%` : "0%",
  };

  const desiredStyle = {
    width: loaded ? `${desired}%` : "0%",
  };

  return (
    shouldRenderRealBar() && (
      <div className="exam__container">
        <p className="bar__exam">{exam}</p>
        <div className="bar__container">
          <div className="bar__desired" style={desiredStyle}>
            <p className="bar__desired-text">{scoreDesired}</p>
          </div>

          <div className={isSuccess ? "bar__success" : "bar__real"} style={realStyle}>
            <p className="bar__real-text">{scoreReal}</p>
          </div>
        </div>
      </div>
    )
  );
}

import React from "react";
import "./ModalSheet.scss";
import { useWindowSize } from "@helpers/hooks";
import { useEffect } from "react";

export default function ModalSheet({ children, className }) {
  const windowSize = useWindowSize();

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      body.style.overflow = "auto";
    };
  }, [windowSize]);

  return (
    <div className="sheet">
      <div className={`sheet__content ${className}`}>{children}</div>
    </div>
  );
}

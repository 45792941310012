import {useEffect, useState} from "react";
import {useAction} from "@helpers/hooks";
import {useSelector} from "react-redux";
import {Icon, List} from "@components/common";
import classNames from "classnames";
import "./UniversitySubjects.scss";

export default function UniversitySubjects({setOpen, selectedProgram, setSelectedProgram, setSelectedTab, tabs, setSearch, setIsResetFilter, setOrder}) {
    const {university, universitySubjects} = useSelector(state => state.university);
    const {fetchUniversitySubjects, clearUniversitySubjects} = useAction();
    const [studyLevels, setStudyLevels] = useState(university?.study_level ?? []);
    const [selectedSubject, setSelectedSubject] = useState(null);

    const onSelectedSubject = (universitySubject) => {
        setSelectedSubject(universitySubject);
        setSelectedProgram(null);
    };

    const onSelectProgram = (program) => {
        setSelectedProgram(program);
        setOpen(false);
        setSelectedTab(tabs.programs)
        setIsResetFilter(true);
        setSearch("");
        setOrder(null);
    };

    const getUniversitySubjects = async (studyLevel, studyLevelId) => {
        setSelectedSubject(null);
        clearUniversitySubjects();
        setStudyLevels(prev => prev.map((p) => {
            if (p.id === studyLevelId) {
                return {
                    ...p,
                    isShow: !p.isShow
                }
            } else {
                return  {
                    ...p,
                    isShow: false
                }
            }
        }));
        await fetchUniversitySubjects({
            university: university.id,
            study_level: studyLevel
        });
    };

    useEffect(() => {
        return () => {
            setStudyLevels([]);
            setSelectedSubject(null);
            clearUniversitySubjects();
        };
    }, []);
    return (
        studyLevels.length > 0
            ? <div className={"universitySubjects"}>
                <h5 className={"universitySubjects__title"}>
                    Programs
                </h5>
                <List
                    className={"universitySubjects__list"}
                    items={studyLevels}
                    renderItem={(studyLevel) => (
                        <li key={studyLevel.id} className={"universitySubjects__item"}>
                            <div className={"universitySubjects__level"}>
                                <p className={classNames("universitySubjects__name", {active: studyLevel.isShow})}>{studyLevel.name}</p>
                                <div className={"universitySubjects__more"} onClick={() => getUniversitySubjects(studyLevel.value, studyLevel.id)}>
                                    <p>{studyLevel.isShow ? "Hide" : "More"}</p>
                                    <Icon name={studyLevel.isShow ? "arrow-up" : "arrow-down"}/>
                                </div>
                            </div>
                            {(studyLevel.isShow) && <List
                                className={"universitySubjects__subjects"}
                                items={universitySubjects}
                                renderItem={(universitySubject) => (
                                    universitySubject.programs.length > 0 && <li key={universitySubject.id} onClick={() => onSelectedSubject(universitySubject)}>
                                        <p className={classNames("universitySubjects__subject", {active: selectedSubject?.id === universitySubject.id})}>
                                            {universitySubject.name}
                                        </p>
                                    </li>
                                )}
                            />}
                            {(studyLevel.isShow && selectedSubject) && <List
                                className={"universitySubjects__programs"}
                                items={selectedSubject.programs}
                                renderItem={(program) => (
                                    <li key={program.id} onClick={() => onSelectProgram(program)}>
                                        <p className={classNames("universitySubjects__program", {active: selectedProgram?.id === program.id})}>
                                            {program.name}
                                        </p>
                                    </li>
                                )}
                            />}
                        </li>
                    )}
                />
            </div>
            : null
    );
};
import { Button, Divider, Icon } from "@components/common";
import { LessonNavigation, LessonMarkdown, LessonCompletion, LessonContent } from "@components/feature";
import { useTranslate } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import "./LessonReading.scss";

export default function LessonReading({ lesson, courseId, sectionId, lessonId }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile()

  return (
    <div className={isMobile ? "lesson-mobile" : "lesson"}>
      {!isMobile && <LessonNavigation courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />}
      <LessonContent lesson={lesson}/>
      <LessonCompletion courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />
      <Divider className={"section__divider"} />
      {isMobile && <LessonNavigation courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />}
      {/* <Button
        className="btn--transaprrent"
        iconLeft={<Icon name={"lesson-review"} />}
        text={t("LMS.BUTTON.LEAVE_REVIEW")}
      /> */}
    </div>
  );
}

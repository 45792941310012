import {useTranslate} from "@helpers/hooks";
import {AutoCompleteTag, Checkbox, List} from "@components/common";
import classNames from "classnames";
import "../AdditionalSelect.scss";

export default function AdditionalSelectList(props) {
    const {
        showOptions, options, selectedOptions,
        selectOptionHandler, removeTags, deleteSuggestionHandler
    } = props;
    const {t} = useTranslate();
    return (
        showOptions && <ul className={classNames("additional-select__list", {show: options.length > 0})}>
            {
                selectedOptions.length > 1 && <div key={"tags"} className={"additional-select__tags"}>
                    <AutoCompleteTag
                        selectedSuggestion={selectedOptions}
                        deleteSuggestionHandler={deleteSuggestionHandler}
                        removeTags={removeTags}
                    />
                </div>
            }
            {
                options.length > 0
                    ? options.map((option) => (
                        <li key={option.id} className={"additional-select__item"}>
                            <p className={"additional-select__name"}>{t(option.name)}</p>
                            <List
                                className={"additional-select__fields"}
                                items={option.fields}
                                renderItem={(field) => (
                                    <li key={field.id} className={"additional-select__field"}>
                                        <Checkbox
                                            label={t(field.name)}
                                            name={option.field}
                                            disabled={option.disabled}
                                            onChange={() => selectOptionHandler(field, option.field)}
                                            checked={selectedOptions.map((selectedOption) => selectedOption.name).includes(field.name)}
                                        />
                                    </li>
                                )}
                            />
                        </li>
                    ))
                    : <li className={"additional-select__empty"}>
                        <p className={"additional-select__text"}>
                            {t("FIELD.NO_DATA")}
                        </p>
                    </li>
            }
        </ul>
    );
};
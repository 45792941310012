import { useMemo, useState } from "react";
import { ProgramCard, StatusSelect } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import "./ApplicationCard.scss";
import { fetchAllApplication } from "@store/actions/programAction";

const ApplicationCard = ({ item }) => {
  const { t } = useTranslate(),
    { deleteApplication, changeApplicationStatus } = useAction(),
    info_items = useMemo(
      () => [
        {
          id: 1,
          name: t("RECOMMENDATIONS.MAJOR.LABEL_3"),
          value: [item.logo, item.name],
        },
        {
          id: 2,
          name: t("RECOMMENDATIONS.MAJOR.LABEL_8"),
          value: item.university,
        },
        {
          id: 3,
          name: t("RECOMMENDATIONS.MAJOR.LABEL_9"),
          value: [item.city, item.country],
        },
        {
          id: 5,
          name: t("RECOMMENDATIONS.MAJOR.LABEL_11"),
          value: item.tuition_fee,
        },
        {
          id: 6,
          name: t("RECOMMENDATIONS.MAJOR.LABEL_6"),
          value: item.duration_field_test,
        },
        { id: 7, name: t("APPLICATION.CHANCE"), value: item.graduation_rate },
        { id: 8, name: t("APPLICATION.METHOD"), value: "Common app" },
        { id: 9, name: t("APPLICATION.STATUS.TITLE"), value: "Applying" },
      ],
      [item, t]
    );

  const handleDeleteApplication = () => {
    deleteApplication(item.program_id);
  };

  return (
    <ProgramCard
      info_items={info_items}
      handleClick={handleDeleteApplication}
      buttonText={t("APPLICATION.REMOVE_APPLICATION")}
      status={<StatusSelect item={item} />}
    />
  );
};

export default ApplicationCard;

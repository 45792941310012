import React, { useMemo, memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@helpers/hooks";
import { Button, CustomLink, Icon, Image, List } from "@components/common";
import {
  Feedback,
  HeaderCoins,
  HeaderLanguageToggle,
  HeaderProfile,
  LmsBadgeHeader,
  TourGuide,
} from "@components/feature";
import "./HeaderMenu.scss";
import { HeaderNotifications } from "../HeaderNotifications";

export default memo(function HeaderMenu() {
  const [isGuideActive, setIsGuideActive] = useState(false);
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);
  const { t } = useTranslate();
  const { pathname } = useLocation();

  const navs = useMemo(() => {
    const items = [
      {
        id: 8,
        component: (
          <Image
            className="lamp_feedback"
            name={"lamp_feedback.png"}
            onClick={() => setIsFeedbackActive(true)}
          />
        ),
        condition: true,
      },

      {
        id: 2,
        component: (
          <Icon name="info_line" handleClick={() => setIsGuideActive(true)} />
        ),
        condition: !pathname.includes("section"),
      },
      { id: 7, component: <HeaderNotifications />, condition: true },
      { id: 6, component: <HeaderCoins />, condition: true },
      { id: 3, component: <HeaderLanguageToggle />, condition: true },
      {
        id: 4,
        component: <LmsBadgeHeader />,
        condition: pathname.includes("courses"),
      },
      { id: 5, component: <HeaderProfile />, condition: true },
    ];

    return items.filter((item) => item.condition);
  }, [pathname, t]);

  return (
    <>
      {isGuideActive && <TourGuide />}
      {isFeedbackActive && (
        <Feedback handleClose={() => setIsFeedbackActive(false)} />
      )}
      <List
        className={"header__menu"}
        items={navs}
        renderItem={(nav) => (
          <li key={nav.id} className={"menu__item"}>
            {nav.component}
          </li>
        )}
      />
    </>
  );
});

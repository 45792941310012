export default function currentWorkshop(workshops) {
    const negativeTimeDifference = [];
    for (const workshopArray of Object.values(workshops)) {
      const targetDatetime = new Date(workshopArray.datetime);
      const now = new Date();
      const timeDifference = now - targetDatetime + (now.getTimezoneOffset() - targetDatetime.getTimezoneOffset()) * 60 * 1000;

      if (timeDifference < 0) {
        negativeTimeDifference.push({
          id: workshopArray?.id,
          timeDifference: timeDifference,
          ...workshopArray,
        });
      }
    }
  return negativeTimeDifference;
}
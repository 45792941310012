// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-stats {
  color: black;
  width: -moz-fit-content;
  width: fit-content;
  word-wrap: break-word;
  overflow: hidden;
}
.dashboard-stats--box {
  display: flex;
  border: 1px solid #000000;
  border-radius: 40px;
  width: -moz-fit-content;
  width: fit-content;
}
.dashboard-stats--title {
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
}
.dashboard-stats--title > p {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Dashboard/DashboardStats/DashboardStats.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,uBAAA;EAAA,kBAAA;EACA,qBAAA;EACA,gBAAA;AADJ;AAGI;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AADR;AAII;EACI,eAAA;EACA,gBAAA;EACA,aAAA;AAFR;AAIQ;EACI,YAAA;AAFZ","sourcesContent":["@import \"src/styles/variables\";\n\n.dashboard-stats{\n    color: black;\n    width: fit-content;\n    word-wrap: break-word;\n    overflow: hidden;\n    \n    &--box{\n        display: flex;\n        border: 1px solid $black;\n        border-radius: 40px;\n        width: fit-content;\n    }\n\n    &--title{\n        font-size: 16px;\n        font-weight: 400;\n        padding: 15px;\n\n        &> p{\n            color: black;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

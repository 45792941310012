// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.mobile-header h4 {
  font-size: 15px;
}
.mobile-header__arrow_back {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Mobile/MobileHeader/MobileHeader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,eAAA;AAAR","sourcesContent":[".mobile-header {\n    display: flex;\n    align-items: center;\n    column-gap: 30px;\n    margin-left: 20px;\n    margin-bottom: 20px;\n\n    h4 {\n        font-size: 15px;\n    }\n\n    &__arrow_back {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

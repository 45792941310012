import {useRef, useState} from "react";
import classNames from "classnames";
import "./Tooltip.scss";

export default function Tooltip({children, text, position, className}) {
    const [isHovered, setIsHovered] = useState(false),
        [isFocused, setIsFocused] = useState(false),
        targetRef = useRef(null);

    const getPosition = (position) => {
        switch (position) {
            case "bottom":
                return {
                    bottom: "unset",
                    top: "calc(100% + 10px)"
                }
            case "left":
                return  {
                    marginLeft: "0",
                    width: "100%",
                    left: "unset",
                    top: "50%",
                    right: "calc(100% + 10px)",
                }
            case "right":
                return  {
                    marginLeft: "0",
                    width: "100%",
                    top: "50%",
                    left: "calc(100% + 10px)",
                }
            default:
                return  {
                    bottom: "calc(100% + 10px)"
                }
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (targetRef.current) {
            targetRef.current.blur();
        }
    };
    return (
        <div className={classNames("tooltip", className)}>
            <div
                className={"tooltip__target"}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onClick={handleClick}
                ref={targetRef}
            >
                {
                    children
                }
            </div>
            {
                (isHovered || isFocused) && <div
                    className={"tooltip__content"}
                    style={getPosition(position)}
                >
                    <div className={"tooltip__box"}>
                        {text}
                    </div>
                </div>
            }
        </div>
    );
};
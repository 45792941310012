import {axiosRequest} from "@api/xhr";

export default class EssaysService {
    static async fetchGeneralEssays(params) {
        return await axiosRequest.get("auth/general_essay/", {params});
    };

    static async fetchAvailableTypes(params) {
        return await axiosRequest.get("auth/general_essay/available_types/", {params});
    };

    static async fetchGeneralEssay(id) {
        return await axiosRequest.get(`auth/general_essay/${id}/`);
    };

    static async createGeneralEssay(data) {
        return await axiosRequest.post("auth/general_essay/", data);
    };

    static async updateGeneralEssay(id, data) {
        return await axiosRequest.put(`auth/general_essay/${id}/`, data);
    };

    static async deleteGeneralEssay(id) {
        return await axiosRequest.delete(`auth/general_essay/${id}/`);
    };

    static async fetchUniversitySpecificEssays(params) {
        return await axiosRequest.get("auth/university_specific_essay/", {params});
    };

    static async fetchUniversitySpecificEssay(id) {
        return await axiosRequest.get(`auth/university_specific_essay/${id}/`);
    };

    static async createUniversitySpecificEssay(data) {
        return await axiosRequest.post("auth/university_specific_essay/", data);
    };

    static async updateUniversitySpecificEssay(id, data) {
        return await axiosRequest.put(`auth/university_specific_essay/${id}/`, data);
    };

    static async deleteUniversitySpecificEssay(id) {
        return await axiosRequest.delete(`auth/university_specific_essay/${id}/`);
    };
};
import { useMemo, useState, useEffect } from "react";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { debounce, numberFormat, trackAmplitude } from "@utils/functions";
import { Button, Icon, List, Section } from "@components/common";
import { useSelector } from "react-redux";
import {
  AMOUNT_OF_STUDENTS,
  COLLEGE_SETTING,
  EARNINGS_AFTER_GRADUATION,
  GRADUATION_RATE,
  ON_CAMPUS_HOUSING,
  PERCENTAGE_OF_INTERNATIONAL_STUDENTS,
  RESEARCH_OUTPUT,
  SINGLE_SELECT_CHIP_FIELDS,
  STUDENT_FACULTY_RATIO,
  STUDY_YEAR_COST,
  UNIVERSITY_RANKINGS,
} from "@utils/consts";
import "./MyPreference.scss";

export default function MyPreference({
  myPrefer,
  values,
  setValues,
  handleFilter,
  isMobileFilterOpen,
}) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const { updatePrefer, updateUniversitiesRecommend, fetchSubjects } = useAction();
  const { subjects } = useSelector((state) => state.directory);
  const [isClear, setIsClear] = useState(false);

  const PREFERENCES = useMemo(
    () => [
      {
        id: 1,
        isAutocomplete: true,
        items: [
          {
            id: 2,
            field: "auto-complete",
            label: t("CATALOG.SUBJECT"),
            name: "subjects",
            method: fetchSubjects,
            options: subjects,
            selectedSuggestion: values.subject,
          },
        ],
      },
      {
        id: 2,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_2",
        items: [
          {
            id: 1,
            label: "MY_PREFERENCES.PREFERENCES.LABEL_2",
            field: "chips",
            name: "study_year_cost",
            value: values.study_year_cost || [],
            options: STUDY_YEAR_COST,
          },
          {
            id: 2,
            label: "MY_PREFERENCES.PREFERENCES.LABEL_3",
            field: "input",
            type: "number",
            name: "live_year_cost",
            value: values.live_year_cost,
            min: 0,
            max: 1000000,
            onKeyPress: numberFormat,
          },
          {
            id: 3,
            label: "MY_PREFERENCES.PREFERENCES.LABEL_4",
            field: "input",
            type: "number",
            name: "own_scholarship_cost",
            value: values.own_scholarship_cost,
            min: 0,
            max: 1000000,
            onKeyPress: numberFormat,
          },
        ],
      },
      {
        id: 3,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_3",
        items: [
          {
            id: 1,
            label: "MY_PREFERENCES.PLACEHOLDERS.LABEL_3",
            field: "chips",
            name: "ranking",
            value: values.ranking || [],
            options: UNIVERSITY_RANKINGS,
          },
        ],
      },
      {
        id: 4,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_4",
        items: [
          {
            id: 1,
            label: "MY_PREFERENCES.PLACEHOLDERS.LABEL_3",
            field: "chips",
            name: "research_output",
            value: values.research_output || [],
            options: RESEARCH_OUTPUT,
          },
        ],
      },
      {
        id: 5,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_5",
        items: [
          {
            id: 1,
            label: "MY_PREFERENCES.PLACEHOLDERS.LABEL_3",
            field: "chips",
            name: "full_time_enrollment",
            value: values.full_time_enrollment || [],
            options: AMOUNT_OF_STUDENTS,
          },
        ],
      },
      {
        id: 6,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_6",
        items: [
          {
            id: 1,
            field: "chips",
            name: "student_faculty_ratio",
            value: values.student_faculty_ratio || [],
            options: STUDENT_FACULTY_RATIO,
          },
        ],
      },
      {
        id: 7,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_7",
        items: [
          {
            id: 1,
            label: "MY_PREFERENCES.PLACEHOLDERS.LABEL_3",
            field: "chips",
            name: "percentage_of_international_students",
            value: values.percentage_of_international_students || [],
            options: PERCENTAGE_OF_INTERNATIONAL_STUDENTS,
          },
        ],
      },
      {
        id: 8,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_8",
        items: [
          {
            id: 1,
            field: "dropdown",
            name: "on_campus_housing",
            value: values.on_campus_housing || [],
            defaultValue: {
              name: `MY_PREFERENCES.DROPDOWN_FIELDS.${String(
                values.on_campus_housing
              ).toUpperCase()}`,
              value: values.on_campus_housing,
            },
            options: ON_CAMPUS_HOUSING,
          },
        ],
      },
      {
        id: 9,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_9",
        items: [
          {
            id: 1,
            field: "dropdown",
            name: "graduation_rate",
            value: values.graduation_rate || [],
            defaultValue: {
              name: values.graduation_rate,
              value: values.graduation_rate,
            },
            options: GRADUATION_RATE,
          },
        ],
      },
      {
        id: 10,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_10",
        items: [
          {
            id: 1,
            label: "MY_PREFERENCES.PLACEHOLDERS.LABEL_3",
            field: "chips",
            name: "college_setting",
            value: values.college_setting || [],
            options: COLLEGE_SETTING,
          },
        ],
      },
      {
        id: 11,
        header: "MY_PREFERENCES.PREFERENCES.SECTION_11",
        items: [
          {
            id: 1,
            field: "dropdown",
            name: "earnings_after_graduation",
            value: values.earnings_after_graduation || [],
            defaultValue: {
              name: values.earnings_after_graduation,
              value: values.earnings_after_graduation,
            },
            options: EARNINGS_AFTER_GRADUATION,
          },
        ],
      },
    ],
    [values, t, subjects]
  );


  const handleFindRecommends = () => {
    updateUniversitiesRecommend();
    trackAmplitude("recommendation_find_click", {
      description: 'Пользователь успешно сохранил свои предпочтения для поиска университетов',
      subject: values.subjects,
      study_finance: values.study_year_cost,
      living_finance: values.live_year_cost,
      scholarship_required: values.own_scholarship_cost,
      university_rank: values.ranking,
      research_output: values.research_output,
      students_amounts: values.full_time_enrollment,
      student_faculty_ratio: values.student_faculty_ratio,
      international_students: values.percentage_of_international_students,
      campus: values.on_campus_housing,
      graduation_rate: values.graduation_rate,
      college_setting: values.college_setting,
      earnings: values.earnings_after_graduation
    });
    isMobile && handleFilter();
  };

  const handleClear = (name) => {
    setValues((prev) => ({ ...prev, [name]: [] }));
  };

  const handleDebounce = useMemo(
    () =>
      debounce(async (data) => {
        await updatePrefer({ id: myPrefer.id, data });
      }),
    []
  );

  const handleChange = async (e, field, name, option) => {
    if (field === "input") {
      setValues((prev) => ({ ...prev, [name]: e.target.value }));
      await handleDebounce({ [name]: e.target.value });
      return;
    }

    if (field === "chips") {
      if (SINGLE_SELECT_CHIP_FIELDS.includes(name)) {
        setValues((prev) => ({ ...prev, [name]: option }));
        await handleDebounce({ [name]: option });
        return;
      }

      let prevOptions = values[name] ? [...values[name]] : [];
      if (prevOptions.includes(option)) {
        prevOptions = prevOptions.filter((prevOption) => prevOption !== option);
      } else {
        prevOptions.push(option);
      }
      setValues((prev) => ({ ...prev, [name]: prevOptions }));
      await handleDebounce({ [name]: prevOptions });
      return;
    }

    if (field === "radio") {
      setValues((prev) => ({ ...prev, [name]: +e.target.value }));
      await handleDebounce({ [name]: e.target.value });
      return;
    }

    if (field === "dropdown") {
      setValues((prev) => ({ ...prev, [name]: e.value }));
      await handleDebounce({ [name]: e.value });
      return;
    }

    if (field === "auto-complete") {
      return;
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  //handle sending request of auto-complete
  useEffect(() => {
    const valuesArray = values?.subjects?.map((item) => (item?.id ? item.id : item.value)) || [];
    valuesArray.length > 0 && handleDebounce({ subjects: valuesArray });
  }, [values.subjects]);

  useEffect(() => {}, [values.subjects, handleDebounce]);

  const containerClassname = !isMobile
    ? "preference__container-parent"
    : isMobileFilterOpen
    ? "preference__container-parent-mobile filter__open"
    : "preference__container-parent-mobile filter__closed";

  return (
    <div className={containerClassname}>
      <div className={isMobile ? "preference__container-mobile" : "preference__container"}>
        <div className={isMobile ? "preference__header-parent" : ""}>
          <h1
            className={
              isMobile && isMobileFilterOpen ? "preference__header-filter" : "preference__header"
            }
          >
            {isMobileFilterOpen ? t("BUTTON.FILTER") : t("MY_PREFERENCES.TITLE")}
          </h1>
          {isMobile && <Icon name={"filter_close"} handleClick={handleFilter} />}
        </div>
        <div className={isMobile ? "preference-filters" : ""}>
          <div className={isMobile ? "preference-reminder-mobile" : "preference-reminder"}>
            <p>{t("RECOMMENDATIONS.REMINDER_TO_FILL")}</p>
          </div>
          <List
            className={isMobile ? "preference__list-mobile" : "preference__list"}
            items={PREFERENCES}
            renderItem={(preferenceSection, index) => (
              <Section
                index={index}
                total={PREFERENCES.length}
                selectedFilters={values}
                setSelectedFilters={setValues}
                handleClear={handleClear}
                isClear={isClear}
                setIsClear={setIsClear}
                key={preferenceSection.id}
                preferenceSection={preferenceSection}
                handleChange={handleChange}
              />
            )}
          />
        </div>
      </div>
      <div className={isMobile ? "btn-find-section-mobile" : "btn-find-section"}>
        <Button
          className={`btn--secondary btn--pill`}
          disabled={!values.subjects || values.subjects.length === 0}
          text={t("BUTTON.FIND_REC")}
          onClick={handleFindRecommends}
        />
      </div>
    </div>
  );
}

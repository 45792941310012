import { useState } from "react";
import {Icon, Divider } from "@components/common";
import { VideoNotesItem } from "@components/feature";
import { useSelector } from "react-redux";
import { useTranslate } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import "./LessonVideoNotes.scss";

export default function LessonVideoNotes({
  setIsNotesOpen,
  goToSecond,
  timestamp,
  addingNote,
  setAddingNote,
  setTime,
  notesRef,
}) {
  const [editingNoteId, setEditingNoteId] = useState(null);
  const { notes } = useSelector((state) => state.courses);
  const {t} = useTranslate()
  const isMobile = useIsMobile()

  return (
    <div className={isMobile ? "notes-mobile":"notes"}>
      <div className="notes__divider">
        <div className="notes__header">
          <h3 className="notes__headline">{t("LMS.VIDEO.NOTES")} ({notes.length})</h3>
          <Icon name={"notes-close"} handleClick={() => setIsNotesOpen(false)} />
        </div>
        <Divider className={"note__divider"} />
      </div>

      <div className="notes__group" ref={notesRef}>
        {addingNote && (
          <VideoNotesItem
            setTime={setTime}
            setAddingNote={setAddingNote}
            addingNote={addingNote}
            goToSecond={goToSecond}
            timestamp={timestamp}
          />
        )}

        <div className="notes__map">
          {notes &&
            notes.map((note, index) => {
              return (
                <VideoNotesItem
                  editingNote={editingNoteId === note.id}
                  setEditingNote={setEditingNoteId}
                  setAddingNote={setAddingNote}
                  note={note}
                  key={note.id}
                  goToSecond={goToSecond}
                  setTime={setTime}
                  isLast={index === notes.length - 1}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

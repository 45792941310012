import { useMemo, useState } from 'react';
import { Button, Icon } from '@components/common';
import {useAction, useTranslate} from "@helpers/hooks";
import './MajorProgramCard.scss';

const MajorProgramCard = ({item}) => {
    const { t } = useTranslate(); 
    const  {addFavorite, deleteFavorite} = useAction(),
    info_items = useMemo(() => [
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_3"), value: item.name },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_1"), value: item.study_level },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_4"), value: item.university?.language },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_13"), value: item?.subject?.map(el => el.name).join(', ') },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_5"), value: item?.study_mode },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_6"), value: item?.duration_field_test },
    ], [item, t])

    const onAddProgramToFavorite = () => addFavorite(item.id);
    const onDeleteProgramFromFavorite = () => deleteFavorite(item.id);

    return (
        <div className='major-program'>
            <div className='major-program__info'>
                {info_items?.map((item) => (
                    <div className="major-program__info-item" key={item.name}>
                        <h3>{item.name}</h3>
                        <p>{item.value}</p>
                    </div>
                ))}
              <div className='major-program__buttons'>
                <Button text={t("RECOMMENDATIONS.MAJOR.LABEL_7")} className="btn--rounded btn--white"/>
                <Icon
                  className={`icon--pointer ${item.is_shortlisted ? `icon--blue-active`: `icon--blue-inactive`}`} 
                  name="saved-fill"
                  handleClick={item.is_shortlisted ? onDeleteProgramFromFavorite : onAddProgramToFavorite}
                />
                </div>
            </div>
        </div>
    );
}

export default MajorProgramCard;

import React from "react";
import { FormItem } from "@components/common";
import { getQuestionInfo } from "@utils/functions";

export default function MultipleChoiceQuestion({
  disabled,
  question,
  selectedAnswers,
  onAnswerChange,
}) {
  let questionData = getQuestionInfo(question);

  return (
    <FormItem
      key={question.id}
      item={{
        id: question.id,
        placeholder: "",
        field: "checkbox-quiz",
        value: selectedAnswers || [],
        options: questionData?.answer_options,
        correctAnswer: questionData?.correct_answer,
      }}
      handleChange={(option) => {
        onAnswerChange(option);
      }}
      disabled={disabled}
    />
  );
}

function generateYearsArray() {
    const currentYear = new Date().getFullYear();
    const years = [];
  
    for (let i = 0; i <= 10; i++) {
      const year = currentYear + i;
      years.push({
        id: i + 1,
        name: year,
        value: year,
      });
    }
  
    return years;
}
  
export default generateYearsArray;
  
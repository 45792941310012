import {useEffect} from "react";
import {useStep, useTranslate} from "@helpers/hooks";
import {Modal} from "@components/common";
import {PasswordChange, PasswordSuccess} from "@components/feature";
import "./PasswordModal.scss";

export default function PasswordModal({open, setOpen}) {
    const {t} = useTranslate(),
        {step, setStep, next} = useStep();

    const switchStep = (step) => {
        switch (step) {
            case 1:
                return <PasswordChange setOpen={setOpen} next={next}/>;
            case 2:
                return <PasswordSuccess/>;
            default:
                return <PasswordChange setOpen={setOpen} next={next}/>;
        }
    }

    useEffect(() => {
        return () => {
            if (!open) {
                setStep(1);
            }
        }
    }, [setStep, open]);
    return (
        <Modal
            className={"password-modal"}
            open={open}
            setOpen={setOpen}
            width={362}
            title={step === 1 ? t("SETTINGS.CHANGING_PASSWORD") : null}
        >
            {switchStep(step)}
        </Modal>
    );
};
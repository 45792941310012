import { FormItem, Image } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import "./LongTextQuestion.scss";

export default function LongTextQuestion({ question, userAnswer, onAnswerChange, isQuizInProgress, disabled }) {
  const { t } = useTranslate();
  const questionImage = question?.question_practice?.question_image;
  const minWord = question?.question_practice?.min_char;
  const correctAnswer = question?.question_practice?.correct_answer;

  return (
    <div className="long__group">
      <div className="long__text">
        {questionImage && <Image src={questionImage} className={"long__image"} />}
        {!isQuizInProgress && <p className="long__title">{t("LMS.QUIZ.YOUR_ANSWER")}</p>}
        <FormItem
          key={question.id}
          item={{
            id: question.id,
            placeholder: "LMS.QUIZ.ENTER_ANSWER",
            field: "long-text-quiz",
            value: userAnswer || "",
            options: [],
          }}
          disabled = {disabled}
          handleChange={(e) => onAnswerChange(e.target.value)}
        />
        {isQuizInProgress && minWord && (
          <p className="long__words">
            {t("LMS.QUIZ.WORD_COUNT")}: {minWord}
          </p>
        )}
      </div>
      {correctAnswer && (
        <div className="long__text">
          {!isQuizInProgress && <p className="long__title">{t("LMS.QUIZ.CORRECT_ANSWER_EXAMPLE")}</p>}
          {!isQuizInProgress && correctAnswer && <p className="long__words">{correctAnswer}</p>}
        </div>
      )}
    </div>
  );
}

import React, { useRef, useState } from 'react';
import { Icon, List } from '@components/common';
import { useOutsideClick } from "@helpers/hooks";
import "./ActionsMenu.scss";

export default function ActionsMenu ({ actions }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const dropdownRef = useRef(null);
  const ref = useOutsideClick(() => setIsTooltipOpen(false))


  const onToggleDropdown = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <div className='action-menu__tooltip' ref={ref}>
      <div ref={dropdownRef} className='action-menu__button'>
        <Icon
          name='more'
          className='icon--pointer btn--more action-menu__icon'
          handleClick={onToggleDropdown}
        />
      </div>
      {isTooltipOpen && (
        <List
          className={'action-menu__dropdown'}
          items={actions}
          renderItem={(action) => (
            <li
              key={action.id}
              onClick={() => {
                action.click();
                onToggleDropdown();
              }}
              className={'action-menu__dropdown-item'}
            >
              <Icon name={action.icon} className='icon--saved-dropdown' />
              <p>{action.name}</p>
            </li>
          )}
        />
      )}
    </div>
  );
};



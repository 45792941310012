import { NavLink } from "react-router-dom";
import { Icon } from "@components/common";
import classNames from "classnames";

export default function CourseSidebarAccordionNavLink({ 
  handleAccordionClick, 
  route, 
  courseId, 
  isPathnameAccordion, 
  icon, 
  name, 
  isAccordionOpen 
}) {
  return (
    <NavLink
      onClick={handleAccordionClick}
      to={`${route.replace(":courseId", courseId)}`}
      className={classNames("sidebar__link-arrow", {
        "sidebar__link--selected": isPathnameAccordion,
      })}
    >
      <div className="sidebar__link">
        <Icon className={classNames({ "icon--white": isPathnameAccordion })} name={icon} />
        <p
          className={classNames("sidebar__text", {
            "sidebar__text--selected": isPathnameAccordion,
          })}
        >
          {name}
        </p>
      </div>
      <Icon
        className={isPathnameAccordion ? "icon--white" : "icon--dark"}
        name={isAccordionOpen ? "menu-arrow-up" : "menu-arrow-down"}
      />
    </NavLink>
  );
}

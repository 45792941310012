import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import "./ButtonLink.scss";
import { BtnLoader, Icon } from "@components/common";

function ButtonLink({ link, text, className, iconLeft, iconRight, onClick }) {
  return (
    <>
      {link ? (
        <NavLink className={classNames("button", className)} to={link} onClick={onClick}>
          {iconLeft && (
            <div className={"button__icon--left"}>
              <Icon className={className.includes("button--transparent") ? "icon--blue" : "icon--white"} name={iconLeft} />
            </div>
          )}
          {text}
          {iconRight && (
            <div className={"button__icon--right"}>
              <Icon className={className.includes("button--transparent") ? "icon--blue" : "icon--white"} name={iconRight} />
            </div>
          )}
        </NavLink>
      ) : (
        <BtnLoader />
      )}
    </>
  );
}

export default ButtonLink;

import {useEffect, useState} from "react";
import {useTranslate} from "@helpers/hooks";
import {universityOrders, programOrders} from "@utils/consts";
import {WithRemoveScroll} from "@helpers/hoc";
import {Icon, List} from "@components/common";
import classNames from "classnames";
import "./MobileOrder.scss";

export default function MobileOrder({tabs, selectedTab, sortShow, setSortShow, setOrder, selectedOrder, setSelectedOrder}) {
    const {t} = useTranslate();

    const handleOrder = (order) => {
        setSelectedOrder(order);
        setOrder(order.value);
        setSortShow(false);
    };
    return (
        <WithRemoveScroll flag={sortShow}>
            <div className={classNames("mobile-order", {active: sortShow})} onClick={() => setSortShow(false)}>
                <div className={classNames("mobile-order__content", {active: sortShow})}>
                    <div className={"mobile-order__header"}>
                        <p className={"mobile-order__title"}>
                            {
                                selectedOrder.name ? t(selectedOrder.name) : t("BUTTON.SORT_BY")
                            }
                        </p>
                        <Icon name={"burger-close"} handleClick={() => setSortShow(false)}/>
                    </div>
                    <List
                        className={"mobile-order__list"}
                        items={selectedTab === tabs.universities ? universityOrders : programOrders}
                        renderItem={(order) => (
                            <li key={order.id} className={"mobile-order__item"} onClick={() => handleOrder(order)}>
                                {t(order.name)}
                            </li>
                        )}
                    />
                </div>
            </div>
        </WithRemoveScroll>
    );
};
function convertChallengeQuestions(inputArray) {
  return inputArray?.map((item, index) => {
    let questionData = null;
    let questionKey = "";
    let questionType = "";
    

    questionType = item.type;
    questionData = item[item.type];

    if (questionType === "challenge_insert_text") {
      return {
        id: item.id,
        text: item.question_text,
        type: "insert_text",
        question_true_false: null,
        question_multiple_choice: null,
        question_one_choice: null,
        question_image: item.question_image,
        question_insert_text: questionData
          ? {
              id: questionData.id,
              question_image: questionData?.question_image,
              question_id: questionData.id,
              correct_answer: item?.correct_answer,
              is_correct: item?.is_correct,
              explanation: item?.explanation,
              explanation_image: item?.explanation_image,
              response: item?.response,
              topic: item?.question_topic,
            }
          : null,
        position: index + 1,
      };
    } else if (questionType === "challenge_one_choice") {
      return {
        id: item.id,
        text: item.question_text,
        type: "one_choice",
        question_true_false: null,
        question_multiple_choice: null,
        question_image: item.question_image,
        question_one_choice: questionData
          ? {
              id: questionData.id,
              answer_options: questionData.answer_options,
              question_id: questionData.id,
              correct_answer: item?.correct_answer,
              is_correct: item?.is_correct,
              explanation: item?.explanation,
              explanation_image: item?.explanation_image,
              response: item?.response,
              topic: item?.question_topic,
            }
          : null,
        question_insert_text: null,
        position: index + 1,
      };
    } else if (questionType === "challenge_true_false") {
      return {
        id: item.id,
        text: item.question_text,
        type: "true_false",
        question_one_choice: null,
        question_multiple_choice: null,
        question_image: item.question_image,
        question_true_false: questionData
          ? {
              id: questionData.id,
              question_id: questionData.id,
              correct_answer: item?.correct_answer,
              is_correct: item?.is_correct,
              explanation: item?.explanation,
              explanation_image: item?.explanation_image,
              response: item?.response,
              topic: item?.question_topic,
            }
          : null,
        question_insert_text: null,
        position: index + 1,
      };
    } else if (questionType === "challenge_multiple_choice") {
      return {
        id: item.id,
        text: item.question_text,
        type: "multiple_choice",
        question_one_choice: null,
        question_true_false: null,
        question_image: item.question_image,
        question_multiple_choice: questionData
          ? {
              id: questionData.id,
              question_id: questionData.id,
              answer_options: questionData.answer_options,
              correct_answer: item?.correct_answer,
              is_correct: item?.is_correct,
              explanation: item?.explanation,
              explanation_image: item?.explanation_image,
              response: item?.response,
              topic: item?.question_topic,
            }
          : null,
        question_insert_text: null,
        position: index + 1,
      };
    }
  });
}

export default convertChallengeQuestions;

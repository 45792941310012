import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAction, useTranslate } from "@helpers/hooks";
import { List, CourseButton, CourseSidebarItem, CourseSidebarAccordion } from "@components/common";
import { currentWorkshop, isNewFeature } from "@utils/functions";
import "./CourseSidebar.scss";

import {
  COURSE_ROUTE,
  COURSE_GRADES_ROUTE,
  COURSE_NOTES_ROUTE,
  COURSE_INFO_ROUTE,
  LEARNING_PLATFORM_ROUTE,
  WORKSHOP_ROUTE,
  COURSE_RANKING_ROUTE,
} from "@utils/consts";
import { Banner } from "@components/feature";


export default function CourseSidebar() {
  const { courseId, mockId } = useParams();
  const { t } = useTranslate(),
    { course, workshops, mockExams, notifier } = useSelector((state) => state.courses),
    { fetchCourse, fetchWorkshops, fetchMockExams } = useAction(),
    { pathname } = useLocation(),
    [isCourseMaterialOpen, setIsCourseMaterialOpen] = useState(pathname.includes("sections")),
    [isWorkshopOpen, setIsWorkshopOpen] = useState(pathname.includes("workshop")),
    [isMockOpen, setIsMockOpen] = useState(pathname.includes("mock")),
    isPathnameCourseMaterial = pathname.includes("sections"),
    isPathnameWorkshop = pathname.includes("workshops"),
    isPathnameMock = pathname.includes("mock"),
    [currentWorkshopId, setCurrentWorkshopId] = useState(null),
    enrollment_check = course?.enrollment_type === "Gold" || course?.enrollment_type === "Platinum", // check if not silver
    [isBannerOpen, setIsBannerOpen] = useState(notifier),
    links = useMemo(
      () => [
        {
          id: 0,
          route: LEARNING_PLATFORM_ROUTE,
          icon: "pagination-arrow-left",
          name: t("LMS.BACK_TO_COURSES"),
          isDivider: true,
        },
        {
          id: 1,
          route: `${course && `${COURSE_ROUTE.replace(":courseId", course.id)}/sections/0/onboarding`}`,
          // route: `${COURSE_ROUTE}/sections/${course && course.sections && course.sections[0].id}`,
          // Enable going to next section when last lesson completed task
          // route: `${COURSE_ROUTE}/sections/${course && course.sections && (course.sections[0].id ?? "")}`,

          icon: "course-material",
          name: t("LMS.COURSE_MATERIAL"),
          isPathnameAccordion: isPathnameCourseMaterial,
          isAccordionOpen: isCourseMaterialOpen,
          setIsAccordionOpen: setIsCourseMaterialOpen,
          content: course,
          value: "course-material",
        },
        {
          id: 2,
          route: WORKSHOP_ROUTE.replace(":workshopId", course && (course?.workshops ?? "") && (currentWorkshopId ?? course.workshops[0]?.id ?? "")),
          icon: "workshop",
          name: t("LMS.WORKSHOP"),
          isPathnameAccordion: isPathnameWorkshop,
          isAccordionOpen: isWorkshopOpen,
          setIsAccordionOpen: setIsWorkshopOpen,
          content: course,
          value: "workshop",
        },
        {
          id: 4,
          route: COURSE_GRADES_ROUTE,
          icon: "grades",
          name: t("LMS.GRADES.TITLE"),
        },

        {
          id: 5,
          route: COURSE_NOTES_ROUTE,
          icon: "notes",
          name: t("LMS.NOTES.TITLE"),
        },
        {
          id: 6,
          route: COURSE_RANKING_ROUTE,
          icon: "leaderboard",
          name: t("LMS.RANKING.TITLE"),
          isNew: isNewFeature("2023-12-15"),
        },
        {
          id: 7,
          route: COURSE_INFO_ROUTE,
          icon: "info",
          name: t("LMS.INFO"),
          isDivider: true,
        },
      ],
      [t, course, isPathnameCourseMaterial, isCourseMaterialOpen, setIsCourseMaterialOpen, 
      isPathnameWorkshop, isWorkshopOpen, setIsWorkshopOpen, isMockOpen, setIsMockOpen, currentWorkshopId]
    );


  useEffect(() => {
    fetchWorkshops(courseId);
  }, [])
  
  useEffect(() => {
    if (workshops) {
      const dates = workshops.map((workshop) => {
        return {
          id: workshop?.id,
          datetime: workshop?.datetime
        };
      });
      const negativeTimeDifferences = currentWorkshop(dates); 
      if (negativeTimeDifferences.length > 0) {
        setCurrentWorkshopId(negativeTimeDifferences[0].id); 
      }
    }
  }, [workshops]);
  
  useEffect(() => {
    fetchCourse(courseId);
  }, [courseId]);

  const colors = {
    "Silver": "#C0C0C0",
    "Gold": "#FFD700",
    "Platinum": "#E5E4E2",
  }

  return (
    <aside className={"sidebar"}>
      <nav className={"sidebar__nav"}>
        <div className="sidebar__title-container">
          <h3 className="sidebar__title">{course && course.title}</h3>
          {course?.enrollment_type && 
            <div style={{backgroundColor: colors[course?.enrollment_type]}} className={"sidebar__title-label"}>
              {course?.enrollment_type}
            </div>
          }
        </div>
        <List
          className={"sidebar__list"}
          items={links}
          renderItem={({ id, route, icon, name, value, content, isDivider, isPathnameAccordion, isAccordionOpen, setIsAccordionOpen, isNew}) => (
            <li key={id} className={"sidebar__item"}>
              {route.includes("grades") || route.includes("info") || route.includes("notes") || route.includes("ranking") ? (
                <CourseSidebarItem
                  route={route}
                  icon={icon}
                  name={name}
                  courseId={courseId}
                  isDivider={isDivider}
                  pathname={pathname}
                  isNew={isNew}
                />
              ) : route === "/courses" ? (
                <CourseButton route={route} icon={icon} name={name} isDivider={isDivider} />
              ) : (value === "workshop" && !enrollment_check) ?
                null // Render nothing if it's workshop and the user is silver
              : (
                <CourseSidebarAccordion
                  route={route}
                  icon={icon}
                  name={name}
                  value={value}
                  courseId={courseId}
                  mockId={mockId}
                  isPathnameWorkshop={isPathnameWorkshop}
                  isPathnameMock={isPathnameMock}
                  isPathnameAccordion={isPathnameAccordion}
                  isAccordionOpen={isAccordionOpen}
                  setIsAccordionOpen={setIsAccordionOpen}
                  course={content}
                  mockExams={mockExams}
                  pathname={pathname}
                />
              )}
            </li>
          )}
        />
      </nav>
      {
        notifier && (
          <Banner isBannerOpen={isBannerOpen} setIsBannerOpen={setIsBannerOpen} type={"course"}/>
        )
      }
    </aside>
  );
}

import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useAction } from "@helpers/hooks";
import { Icon, List } from "@components/common";
import { getBreadcrumbItems } from "@utils/functions"
import "./LmsBreadcrumb.scss";


export default function LmsBreadcrumb() {
    const params = useParams();
    const { course } = useSelector((state) => state.courses);
    const { fetchCourse } = useAction();
    const location = useLocation();

    useEffect(() => {
        fetchCourse(params.courseId);
    }, [params.courseId]);

    const items = useMemo(() => getBreadcrumbItems(course, location, params), [course, location, params])

    return (
        <div className="lms-breadcrumb">
            {course &&
                <List
                    items={items}
                    renderItem={(item, index) => (
                        item.isDivider ?
                        <Icon key={index} name="breadcrumb-arrow" /> :
                        <NavLink
                            className={`lms-breadcrumb-item`}
                            activeClassName={`${item.isActive ? "lms-breadcrumb-item__active" : ""}`}
                            to={item.link}
                            key={index}
                        >
                            {item.text}
                        </NavLink>
                    )}
                />
            }
        </div>
    )
}
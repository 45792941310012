// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.essay-details {
  display: flex;
  flex-direction: column;
}
.essay-details__textarea {
  margin-top: 40px;
}
.essay-details__textarea .input__field {
  resize: none;
  height: 30vh;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Essays/EssayDetails/EssayDetails.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;AAGI;EACI,gBAAA;AADR;AAEQ;EACI,YAAA;EACA,YAAA;EACA,WAAA;AAAZ","sourcesContent":["@import \"@styles/variables\";\n\n.essay-details {\n    display: flex;\n    flex-direction: column;\n\n    &__textarea {\n        margin-top: 40px;\n        .input__field {\n            resize: none;\n            height: 30vh;\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

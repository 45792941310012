import { useState } from "react";
import { Document, Page } from "react-pdf";
import "./PdfWindow.scss";

export default function PdfWindow({ link }) {
  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (link) {
    return (
      <div className="pdf__window">
        <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from({ length: numPages }, (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </div>
    );
  }
}

import { CoinService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";


const fetchCoins = createAsyncThunk("coin/fetchAll", async (thunkAPI) => {
    try {
      const res = await CoinService.fetchCoins();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });

const fetchCoinsHistory = createAsyncThunk("coin/fetchHistory", async (thunkAPI) => {
    try {
      const res = await CoinService.fetchCoinsHistory();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });
  


  export {
    fetchCoins,
    fetchCoinsHistory
  }
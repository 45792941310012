import React, {useState} from 'react'
import { useTranslate, useIsMobile } from '@helpers/hooks'
import { NewModalLayout, Icon } from '@components/common'
import { UniversityDetailsTabs, ScholarshipsSaved, ScholarshipsSearch, ScholarshipsOverview, ScholarshipsEligibility, ScholarshipsPrograms   } from "@components/feature";
import './ScholarshipsPopup.scss'


export default function ScholarshipsPopup({handleClose, item}) {

    const isMobile = useIsMobile()


    const TABS = {
      OVERVIEW: "SCHOLARSHIPS.OVERVIEW",
      ELIGIBILITY: "SCHOLARSHIPS.ELIGIBILITY",
      PROGRAM: "SCHOLARSHIPS.PROGRAM",
    };

    const filteredTabs = Object.entries(TABS).reduce((acc, [key, value]) => {
      if (key === "OVERVIEW" || shouldRenderTab(value, item[key.toLowerCase()])) {
        acc[key] = value;
      }
      return acc;
    }, {});
  
    const [selectedTab, setSelectedTab] = useState(TABS.OVERVIEW);

    const getContent = (tab) => {
      switch (tab) {
        case TABS.OVERVIEW:
          return <ScholarshipsOverview item={item} />;
        case TABS.ELIGIBILITY:
          return shouldRenderTab(tab, item.eligibility) ? (
            <ScholarshipsEligibility item={item} />
          ) : null;
        case TABS.PROGRAM:
          return shouldRenderTab(tab, item.program) ? (
            <ScholarshipsPrograms item={item} />
          ) : null;
        default:
          return null;
      }
    };


    function shouldRenderTab (tab, tabItem) {
      return tabItem !== "NaN";
    };


  return (
    <NewModalLayout className={`scholarships__modal pop-up ${isMobile ? `tariff__pop-up-mobile` : `tariff__pop-up`}`}>
      <div className="tariff__title-button">
        <h3 className="">{item.university}</h3>
        <Icon name="close-rounded" className="lms-badge-profile__close" handleClick={handleClose} />
      </div>

      <UniversityDetailsTabs
        classNameTabs={"university-details__tabs"}
        classNameTab={"university-details__tab"}
        tabs={Object.values(filteredTabs)}
        currentTab={selectedTab}
        setCurrentTab={setSelectedTab}
      />

      {getContent(selectedTab)}

   
    </NewModalLayout>
  )
}

import "./EssayTabs.scss";

const EssayTabs = ({currentEssayTab, setCurrentEssayTab, TABS}) => {
    return (
        <div className="essays__tabs">
            {Object.values(TABS).map(tab => (
                <div
                  className={`essays__tab ${currentEssayTab.value === tab.value ? "essays__tab--active" : ""}`}
                  onClick={() => setCurrentEssayTab(tab)}
                  key={tab.id}
                >
                  {tab.name}
                </div>
            ))}
          </div>
    );
}

export default EssayTabs;

import {axiosRequest} from "@api/xhr";

export default class DirectoryService {
    static async fetchRegions(params) {
        return await axiosRequest.get("directory/regions/", {params});
    }

    static async fetchCountries(params) {
        return await axiosRequest.get("directory/countries/", {params});
    }

    static async fetchCountry(id) {
        return await axiosRequest.get(`directory/countries/${id}/`);
    }

    static async fetchCities(params) {
        return await axiosRequest.get("directory/cities/", {params});
    }

    static async fetchCity(id) {
        return await axiosRequest.get(`directory/cities/${id}/`);
    }

    static async fetchDegrees(params) {
        return await axiosRequest.get("directory/degree/", {params});
    }

    static async fetchSubjects(params) {
        return await axiosRequest.get("directory/subjects/", {params});
    }

    static async fetchMajors(params) {
        return await axiosRequest.get("directory/majors/", {params});
    }

    static async fetchMoreInfos(params) {
        return await axiosRequest.get("directory/more_info/", {params});
    }

    static async fetchLanguages() {
        return await axiosRequest.get("auth/user_language/");
    };

    static async fetchExamTypes(params) {
        return await axiosRequest.get("auth/exam_type/", {params});
    };

    static async fetchScholarship(id) {
        return await axiosRequest.get(`directory/scholarships/${id}/`);
    };

    static async fetchCourseBannerButtons() {
        return await axiosRequest.get("directory/course_banner_buttons/");
    }

    static async fetchMockBannerButtons() {
        return await axiosRequest.get("directory/mock_banner_buttons/");
    }

    static async fetchAnnounces() {
        return await axiosRequest.get("directory/announces/");
    }
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.private-layout__article > section {
  animation: fadeIn 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.banner__img {
  border-radius: 14px;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/mobileLayout/MobileLayout.scss"],"names":[],"mappings":"AAEM;EACE,uBAAA;AADR;;AAME;EACE;IACE,UAAA;EAHJ;EAKE;IACE,UAAA;EAHJ;AACF;AAME;EACE,mBAAA;EACA,kBAAA;AAJJ","sourcesContent":[".private-layout {\n    &__article {\n      & > section {\n        animation: fadeIn 300ms;\n      }\n    }\n  }\n  \n  @keyframes fadeIn {\n    0% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n\n  .banner__img {\n    border-radius: 14px;\n    margin-bottom: 0px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {AcademicResults} from "./AcademicResults";
import {ActivitiesAndAwards} from "./ActivitiesAndAwards";
import {PreferencesShort} from "./PreferencesShort";
import {ProfileShort} from "./ProfileShort"
import "./ProfileSummary.scss";


export default function ProfileSummary({editTab, tabs}) {
    return (
        <div className="profile_complete_page_column">
          <div className="profile_complete_page_row">
            <ProfileShort handleChangeTab={editTab(tabs.profile)} />
            <div className="profile_complete_page_column">
              <AcademicResults handleChangeTab={editTab(tabs.exams)} />
              <PreferencesShort handleChangeTab={editTab(tabs.preferences)} />
            </div>
          </div>
          <ActivitiesAndAwards handleChangeTab={editTab(tabs.activites)} />
        </div>
    );
};
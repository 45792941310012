// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input__border {
  background: #ffffff !important;
  border: 1px solid #8392a3 !important;
}

.btn--kaspizhuma {
  background-color: #d02d3b;
  margin-top: 10px;
  height: 50px;
}

.form_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.form_success__title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}
.form_success__text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/AmoForm/AmoForm.scss"],"names":[],"mappings":"AAEA;EACE,8BAAA;EACA,oCAAA;AADF;;AAIA;EACE,yBAAA;EACA,gBAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AADF;AAKE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import \"@styles/variables\";\n\n.input__border {\n  background: $white !important;\n  border: 1px solid $gray-info !important;\n}\n\n.btn--kaspizhuma {\n  background-color: #d02d3b;\n  margin-top: 10px;\n  height: 50px;\n}\n\n.form_success {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n\n\n\n  &__title {\n    font-size: 22px;\n    font-weight: 700;\n    text-align: center;\n  }\n\n  &__text {\n    font-size: 16px;\n    font-weight: 400;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

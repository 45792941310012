function trimNewline(str) {
    if (str === null || str === undefined) {
      return '-';
    }
  
    const newlineIndex = str.indexOf('\n');
    if (newlineIndex !== -1) {
      return str.substring(0, newlineIndex);
    }
    return str;
  }

  export default trimNewline;
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIntro } from "@utils/functions";
import { useAction } from "@helpers/hooks";
import { List } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { HeroClassCard, HeroClassCardSelected } from "@components/feature";
import "./HeroClass.scss";

const HeroClass = () => {
  const { courseId } = useParams(),
    isMobile = useIsMobile(),
    { fetchBadgeCategories, fetchBadge } = useAction(),
    { badgeCategories, badge, isBadgeCategoriesLoading } = useSelector((state) => state.courses),
    [isHeroSelected, setIsHeroSelected] = useState(!!badge?.user_id),
    { t } = useTranslate();

  const intro = getIntro(courseId);

  useEffect(() => {
    fetchBadge();
  }, []);

  useEffect(() => {
    if (!badge?.user_id) {
      fetchBadgeCategories();
    }
  }, []);

  return (
    <div className="step">
      <React.Fragment>
        <h4 className={isMobile ? "step__title" : "step__title-mobile"}>{t(intro.classes?.title) ?? ""}</h4>
        <p className="step__description">{t(intro.classes?.description) ?? ""}</p>
      </React.Fragment>

      {!isHeroSelected
        ? badgeCategories && (
            <List
              className={isMobile ? "hero-class__cards-mobile" : "hero-class__cards"}
              items={badgeCategories}
              renderItem={(item) => <HeroClassCard key={item.id} item={item} setIsHeroSelected={setIsHeroSelected} />}
            />
          )
        : !isBadgeCategoriesLoading && <HeroClassCardSelected />}
    </div>
  );
};

export default HeroClass;

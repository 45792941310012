import React, {useEffect, useMemo, useState} from 'react'
import { CustomRadar,  } from '@components/common';
import './DashboardGraphContent.scss';
import {useAction, useTranslate} from '@helpers/hooks';
import {useSelector} from "react-redux";

const LANGUAGE_EXAM_TYPE = {
  'ielts_score': 1,
  'act_score': 5, 
  'toefl_score': 2,
  'sat_score': 4,
}

const DashboardGraphContent = ({uniData}) => {
  const {myEducation} = useSelector(state => state.questionnaire),
  {fetchMyEducation} = useAction();

  useEffect(() => {
    fetchMyEducation()
  }, []);

  const maxValues = useMemo(() => [{
    gpa_percent: 4,
    ib_score: 45,
    has_cover_letter: 1,
    has_recommendation: 1,
    recommendation_letter_percent: 100,
    projects_percent: 100,
    motivational_letter_percent: 100,
    olympiads_percent: 100,
    duolingo_score: 160,
    sat_percent: 1600,
    ielts_percent: 9,
    toefl_score: 120,
    act_score: 36,
  }], []);

  const values = useMemo(() => {
    if(myEducation != null) {
      return {
        gpa_score: myEducation?.gpa_score,
        ib_score: myEducation?.ib_score,
        has_cover_letter: myEducation?.has_cover_letter,
        has_recommendation: myEducation?.has_recommendation,
        recommendation_letter_score:  myEducation?.recommendation_letter_score,
        projects_score: myEducation?.projects_score,
        motivational_letter_score: myEducation?.motivational_letter_score,
        olympiads_score: myEducation?.olympiads_score,
        duolingo_score: myEducation?.duolingo_score,
        sat_score: myEducation?.lang_tests.find(test => test.exam_type === LANGUAGE_EXAM_TYPE['sat_score'])?.points,
        ielts_score: myEducation?.lang_tests.find(test => test.exam_type === LANGUAGE_EXAM_TYPE['ielts_score'])?.points,
        toefl_score: myEducation?.lang_tests.find(test => test.exam_type === LANGUAGE_EXAM_TYPE['toefl_score'])?.points,
        act_score: myEducation?.lang_tests.find(test => test.exam_type === LANGUAGE_EXAM_TYPE['act_score'])?.points,
      };
    } 
  }, [myEducation]);

  return (
    <>
      <div className="dahsboard-graph">
        <div className="dahsboard-graph-legend">
          {values && <CustomRadar data={values} maxData={maxValues} uniData={uniData}/>}
        </div>
      </div>
    </>
  )
}

export default DashboardGraphContent
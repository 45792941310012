import React, { useState } from "react";

import "./RatingQuestion.scss";
import { Icon } from "@components/common";

const RatingQuestion = ({ question, userAnswer, onAnswerChange }) => {
  const [hover, setHover] = useState(null);

  return (
    <div className="rating-question">
      <div className="star-rating">
        {[...Array(5)].map((_, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => onAnswerChange(ratingValue)}
              />
              <Icon
                name="rating_star"
                className={`star ${ratingValue <= (hover || userAnswer) ? 'filled' : ''}`}
                handleClick={() => onAnswerChange(ratingValue)}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default RatingQuestion;

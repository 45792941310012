import {memo} from "react";
import {useHistory} from "react-router-dom";
import {DASHBOARD_ROUTE} from "@utils/consts";
import {Icon} from "@components/common";
import "./HeaderLogo.scss";

export default memo(function HeaderLogo({setIsMenuOpen}) {
    const router = useHistory();

    const routeToDashboard = () => {
        setIsMenuOpen(false);
        router.push(DASHBOARD_ROUTE);
    };
    return (
        <Icon
            className={"header__logo"}
            name={"logo"}
            handleClick={routeToDashboard}
        />
    );
});
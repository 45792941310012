import {useMemo} from "react";
import {useAction, useTranslate} from "@helpers/hooks";
import {Button, Icon, Image, List} from "@components/common";
import "./ProgramItem.scss";

export default function ProgramItem({program, onSelectProgram, onSelectUniversity}) {
    const {t} = useTranslate(),
        {addFavorite, deleteFavorite} = useAction(),
        PROGRAM_CHARACTERS = useMemo(() => [
            {id: 1, name: "CATALOG.PROGRAMS.DEGREE", value: program?.study_level},
            {id: 2, name: "CATALOG.PROGRAMS.LEARN_FORMAT", value: program?.study_mode},
            {id: 3, name: "CATALOG.PROGRAMS.SPECIALITY", value: program && Array.isArray(program.subject) && program.subject.length > 0 ? program.subject[0].name : undefined,},
            {id: 4, name: "CATALOG.PROGRAMS.DURATION", value: program?.duration_field_test?.substring(1, program.duration_field_test?.length - 1)},
            {id: 5, name: "CATALOG.PROGRAMS.SCHOLARSHIP", value: program?.scholarships},
            {id: 6, name: "CATALOG.PROGRAMS.START", value: program?.start_date}
        ], [program]);

    const onAddProgramToFavorite = () => addFavorite(program.id);
    const onDeleteProgramFromFavorite = () => deleteFavorite(program.id);
    return (
        <li className={"program-item"}>
            <Image
                className={"program-item__picture"}
                src={program.university?.logo}
                alt={"program"}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/img/program.png";
                }}
            />
            <div className={"program-item__info"}>
                <h6 className={"program-item__name"}>
                    {
                        program.name
                    }
                </h6>
                <div className={"program-item__university"} onClick={onSelectUniversity}>
                    <Icon name={"university-list"}/>
                    <p>
                        {program.university?.title}
                    </p>
                </div>
                <div className={"program-item__location"}>
                    <Icon name={"location"}/>
                    {
                        program.university?.city?.length > 0 &&
                        program.university?.city?.map(({id, name}, universityId) =>
                            <p key={id}>
                                {name}{universityId !== program.university.city.length - 1 && ','}
                            </p>
                        )
                    }
                </div>
                {PROGRAM_CHARACTERS && <List
                    className={"program-item__characters"}
                    items={PROGRAM_CHARACTERS}
                    renderItem={(character) => (
                        <li
                            key={character.id}
                            className={"program-item__character"}
                        >
                            <p className={"character__name"}>
                                {t(character.name)}:
                            </p>
                            <h6 className={"character__value"}>
                                {character.value}
                            </h6>
                        </li>
                    )}
                />}
                <div className={"program-item__actions"}>
                    <Button
                        className={"btn--primary btn--pill"}
                        text={t("BUTTON.MORE")}
                        onClick={() => onSelectProgram(program)}
                    />
                    {
                        program.is_shortlisted
                            ? <Button
                                className={"program-item__added btn--text"}
                                loaderClassName={"btn-loader--success"}
                                iconLeft={<Icon name={"correct"}/>}
                                text={t("BUTTON.IN_WISHLIST")}
                                loading={program.isLoading}
                                onClick={onDeleteProgramFromFavorite}
                            />
                            : <Button
                                className={"underline program-item__btn btn--pill btn--text btn--link"}
                                loaderClassName={"btn-loader--primary"}
                                text={t("BUTTON.WISHLIST")}
                                loading={program.isLoading}
                                onClick={onAddProgramToFavorite}
                            />
                    }
                </div>
            </div>
        </li>
    );
};
import classNames from 'classnames';
import { Button, Icon, Image } from '@components/common';
import { useTranslate } from '@helpers/hooks';
import './WelcomeQuestionnaireStart.scss';

const WelcomeQuestionnaireStart = ({next, setIsQuestionnaireOpen}) => {
    const {t} = useTranslate();

    const handleStart = () => {
        next();
    }

    const handleClose = () => {
        setIsQuestionnaireOpen(false);
    }

    return (
        <div className="welcome-q-start">
            <Icon name="close-rounded" className="welcome-q-start__close" handleClick={handleClose}/>
            <h1 className="welcome-q-start__title">{t("WELCOME_QUESTIONNAIRE.TITLE")}</h1>
            <p className="welcome-q-start__subtitle">{t("WELCOME_QUESTIONNAIRE.SUBTITLE")}</p>
            <Image className={"welcome-q-start__img"} name={"welcome-questionnaire.png"}/>
            <Button 
                className={classNames("btn--primary", "welcome-q-start__btn")} 
                onClick={handleStart} 
                text={t("WELCOME_QUESTIONNAIRE.START")}
            />
        </div>
    );
}

export default WelcomeQuestionnaireStart;

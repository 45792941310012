// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 320px 1fr 1fr 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas: "header header header header" "sidebar article article article";
}
.app-practice {
  grid-template-areas: "article article article article article";
}
.app-mock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app.banner-active {
  grid-template-rows: 110px 1fr;
}

@media screen and (max-width: 1023px) {
  .app {
    grid-template-areas: "header header header header header" "article article article article article";
  }
}
@media screen and (max-width: 480px) {
  .app {
    grid-template-areas: "article article article article article" "footer footer footer footer footer";
  }
}
.app.banner-active {
  grid-template-rows: 110px 1fr;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/App/App.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,aAAA;EACA,wCAAA;EACA,4BAAA;EACA,oFACE;AAFJ;AAKE;EACE,8DAAA;AAHJ;AAME;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAJJ;;AAQA;EACE,6BAAA;AALF;;AAQA;EACE;IACE,mGACE;EANJ;AACF;AAYA;EACE;IACE,mGACE;EAXJ;AACF;AAeA;EACE,6BAAA;AAbF","sourcesContent":["@import \"@styles/variables\";\n\n.app {\n  min-height: 100vh;\n  display: grid;\n  grid-template-columns: 320px 1fr 1fr 1fr;\n  grid-template-rows: 70px 1fr;\n  grid-template-areas:\n    \"header header header header\"\n    \"sidebar article article article\";\n\n  &-practice {\n    grid-template-areas: \"article article article article article\";\n  }\n\n  &-mock {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n}\n\n.app.banner-active {\n  grid-template-rows: 110px 1fr;\n}\n\n@media screen and (max-width: 1023px) {\n  .app {\n    grid-template-areas:\n      \"header header header header header\"\n      \"article article article article article\";\n  }\n}\n\n\n\n@media screen and (max-width: 480px) {\n  .app {\n    grid-template-areas:\n      \"article article article article article\"\n      \"footer footer footer footer footer\";\n  }\n}\n\n.app.banner-active {\n  grid-template-rows: 110px 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

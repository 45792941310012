import { toast } from "react-toastify";
import './toastStyles.scss';

const DEFAULT_OPTIONS = {
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
};

export const notify = (message, type) => {
  const customClass = message == "Вы заработали 10 STP Coin!" ? 'Toastify__toast--custom' : '';

  let displayMessage;

  if (typeof message === "object") {
    if (Array.isArray(message)) {
      displayMessage = message.map((item, id) => <p key={id}>{item}</p>);
    } else {
      displayMessage = <p>{message.message || JSON.stringify(message)}</p>;
    }
  } else {
    displayMessage = <p>{message}</p>;
  }

  toast(
    <div>{displayMessage}</div>,
    {
      ...DEFAULT_OPTIONS,
      type: type,
      className: customClass
    }
  );
};

export default notify;
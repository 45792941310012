import {axiosRequest} from "@api/xhr";

export default class ProgramService {
    static async fetchPrograms(params) {
        return await axiosRequest.get("directory/programs/", {params});
    }

    static async fetchSimplePrograms(params) {
        return await axiosRequest.get("directory/simple-program/", {params});
    }

    static async fetchProgram(id) {
        return await axiosRequest.get(`directory/programs/${id}/`);
    }

    static async addProgramToShortlist(id) {
        return await axiosRequest.post(`directory/programs/${id}/toggle-shortlist/`);
    }

    static async addProgramToAplication(id) {
        return await axiosRequest.post(`directory/programs/${id}/toggle-application/`);
    }

    static async fetchProgramSuccessRate(id) {
        return await axiosRequest.get(`directory/programs/${id}/success-rate/`);
    };

    static async changeApplicationStatus({id, status}) {
        return await axiosRequest.post(`directory/applications/${id}/change_status/`, status);
    };

    static async fetchAllApplications(params) {
        return await axiosRequest.get(`directory/applications/`, {params});
    }
};

import React, { useState, useEffect } from "react";
import "./CountdownTimer.scss";
import { getDayWord } from "@utils/functions";

const CountdownTimer = ({ endDate, className }) => {
  const [currentTime, setCurrentTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    const now = new Date();
    setCurrentTime(now);
    setEndTime(calculateEndTime(endDate));

    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  function calculateEndTime(givenEndDate) {
    if (givenEndDate) {
      return new Date(givenEndDate);
    } else {
      const end = new Date();
      end.setDate(end.getDate() + ((7 - end.getDay()) % 7));
      end.setHours(0, 0, 0, 0);
      return end;
    }
  }

  if (!currentTime || !endTime) {
    return <p>Loading...</p>;
  }

  const timeLeft = endTime - currentTime;
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, "0");

  return (
    <>
      {currentTime - endTime < 0 && (
        <p className={className ? className : "component.slide__timer"}>
          {endDate ? "" : "Time left: "}
          {`${days} ${getDayWord(days)} ${hours}:${minutes}:${seconds}`}
        </p>
      )}
    </>
  );
};

export default CountdownTimer;

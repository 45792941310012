import InfiniteScroll from "react-infinite-scroller";
import { useTranslate } from "@helpers/hooks";
import { BtnLoader, Option, Search } from "@components/common";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";
import { Tooltip as ReactTooltip } from "react-tooltip";
import classNames from "classnames";
import "./DropdownList.scss";

export default function DropdownList(props) {
  const {
    showOptions,
    infinity,
    name,
    hasMore,
    onScrollChange,
    isSearch,
    search,
    options,
    selectedOptions,
    setSearch,
    isSearching,
    selectedOption,
    isUnDisabled,
    isTooltip,
  } = props;
  const { t } = useTranslate();
  return (
    showOptions && (
      <ul className={classNames("dropdown-list", { scroll: options.length > 5 })}>
        {isSearch && (
          <Search
            className={"search--select"}
            placeholder={t("FIELD.START_SEARCH")}
            autoComplete={"off"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        {infinity ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={(e) => onScrollChange(e, name)}
            hasMore={hasMore}
            useWindow={false}
            loader={
              <div className={"dropdown-list__loader"} key={0}>
                <BtnLoader className={"btn-loader--blue"} />
              </div>
            }
          >
            {selectedOptions.length > 0
              ? selectedOptions.map((option) => (
                  <Option
                    key={option.id}
                    name={t(option.name)}
                    value={option.value ?? option.id}
                    disabled={!isUnDisabled && selectedOption && option.value === selectedOption.value}
                  />
                ))
              : options.map((option) => (
                  <Option
                    key={option.id}
                    id={option.id}
                    name={option.text}
                    value={option.value ?? option.id}
                    disabled={!isUnDisabled && selectedOption && option.value === selectedOption.value}
                  />
                ))}
          </InfiniteScroll>
        ) : selectedOptions.length > 0 ? (
          selectedOptions.map((option) => (
            <Option
              key={option.id}
              name={t(option.name)}
              value={option.value ?? option.id}
              disabled={!isUnDisabled && selectedOption && option.value === selectedOption.value}
            />
          ))
        ) : !isSearching ? (
          options.length > 0 ? (
            options.map((option) => (
              <div key={option.id}>
                <Option
                  id={option.id}
                  name={t(option.name)}
                  icon={option.icon}
                  value={option.value ?? option.id}
                  disabled={!isUnDisabled && selectedOption && option.value === selectedOption.value}
                />
                {isTooltip && (
                  <ReactTooltip
                    anchorId={option.id}
                    place="left"
                    content={
                      <div className="dropdown-list__tooltip_md">
                        <h4 className="dropdown-list__tooltip_title">{option.title}</h4>{" "}
                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkEmoji]}>{option?.tooltip}</ReactMarkdown>
                      </div>
                    }
                    className="dropdown-list__tooltip"
                  />
                )}
              </div>
            ))
          ) : (
            <Option name={t("FIELD.NO_DATA")} disabled={true} />
          )
        ) : (
          <div className={"dropdown-list__loader"}>
            <BtnLoader className={"btn-loader--blue"} />
          </div>
        )}
      </ul>
    )
  );
}

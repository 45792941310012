import {memo} from "react";
import {List, PaginationArrowNext, PaginationArrowPrevious} from "@components/common";
import classNames from "classnames";
import "../Pagination.scss";

export default memo(function PaginationList(props) {
    const {
        paginationRange, currentPage, lastPage,
        onPrevious, onNext, onDots, onPageChange,
        DOTS
    } = props;
    return (
        <List
            className={"pagination__list"}
            items={paginationRange}
            firstItem={<PaginationArrowPrevious currentPage={currentPage} onPrevious={onPrevious}/>}
            lastItem={<PaginationArrowNext currentPage={currentPage} lastPage={lastPage} onNext={onNext}/>}
            renderItem={(pageNumber, pageNumberId) => (
                pageNumber === DOTS
                    ? <li
                        key={pageNumberId}
                        className={"pagination__item dots"}
                        onClick={() => onDots(pageNumberId)}
                    >
                        &#8230;
                    </li>
                    : <li
                        key={pageNumberId}
                        className={classNames("pagination__item", {selected: pageNumber === currentPage})}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
            )}
        />
    );
});
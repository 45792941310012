import {useFormik} from "formik";
import {useData, useTranslate} from "@helpers/hooks";
import {emailSchema} from "@utils/schemas";
import {Button, Form, Input, ValidationError} from "@components/common";
import "../EmailModal.scss";

export default function EmailChange({setOpen, next}) {
    const {t} = useTranslate(),
        {setValues} = useData(),
        buttons = [
            {id: 1, text: t("BUTTON.CANCEL"), className: "btn--link", type: "button", handleClick: () => setOpen(false)},
            {id: 2, text: t("BUTTON.SAVE"), className: "btn--primary btn--pill", type: "submit"}
        ];

    const onSubmit = async (values) => {
        setValues(values);
        next();
    };

    const {values, errors, touched, handleSubmit, handleChange, handleBlur} = useFormik({
        initialValues: {
            email: ""
        },
        validateOnBlur: true,
        validationSchema: emailSchema,
        onSubmit
    });
    return <Form onSubmit={handleSubmit}>
        <Input
            className={"input--ghost email-modal__input"}
            labelClassName={"label--ghost"}
            name={"email"}
            label={t("SETTINGS.NEW_EMAIL")}
            placeholder={t("FIELD.ENTER_EMAIL")}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <ValidationError
            errors={errors}
            touched={touched}
            name={"email"}
        />
        <div className={"email-modal__btn"}>
            {
                buttons.map((button) => (
                    <Button
                        key={button.id}
                        width={143}
                        height={46}
                        type={button.type}
                        className={button.className}
                        text={button.text}
                        onClick={button.handleClick}
                    />
                ))
            }
        </div>
    </Form>;
};
import React from "react";
import { getIntro } from "@utils/functions";
import { Button, ButtonV2, CustomLink, List, Icon } from "@components/common";
import { useParams, useHistory } from "react-router-dom";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import "./PracticeExam.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";

export default function PracticeExam() {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { courseId } = useParams();
  const intro = getIntro(courseId);

  const { t } = useTranslate();

   return (
    <>
      {intro.practice &&
      <div className="step">
        <div className="step__group">
          <div className="step__subtitle-group">
            <h4 className={`${isMobile && "step__title-mobile"} step__title`}>{t(intro.practice?.title)}</h4>
            <p className="step__description">{t(intro.practice?.description)}</p>
          </div>
        </div>
        {intro?.practice?.subtitle && intro?.practice?.icons && (
          <>
            {intro?.practice.text && <div className="step__subtitle-group">
              <ReactMarkdown className="step__md" remarkPlugins={[remarkGfm, remarkEmoji]}>
                {t(intro.practice?.text)}
              </ReactMarkdown>
              <p className="step__address">{t(intro.practice?.address)}</p>
            </div>}
            <List
              className="step__icons"
              items={intro.practice?.icons}
              renderItem={(icon, index) => (
                <li key={index} className="step__icon">
                  <Icon name={icon.icon} />
                  <div className="step__icon-group">
                    <p className="step__icon-title">{t(icon.title)}</p>
                    <ReactMarkdown className="step__icon-description" remarkPlugins={[remarkGfm, remarkEmoji]}>{t(icon.description)}</ReactMarkdown>
                  </div>
                </li>
              )}
            />
          </>
        )}
      </div>}
    </>
  );
}

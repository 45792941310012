import {useCallback, useMemo, useState} from "react";
import {useAction, useTranslate} from "@helpers/hooks";
import {ACTIVITES_TYPES, TIERS_EXTRACURRICULARS, TIERS_HONORS} from "@utils/consts";
import {ButtonV2, FormItem, List} from "@components/common";
import "./ActivityForm.scss";


export default function ActivityForm({profile, activityItem, handleOnClick, isModal, setShowModal, isEdit}) {
  const {t} = useTranslate(),
    {createAdditionalInfo, updateAdditionalInfo} = useAction(),
    [activityType, setActivityType] = useState(activityItem?.activity_type || 'Extracurriculars'),
    [activityName, setActivityName] = useState(activityItem?.activity_name || ""),
    [activityTier, setActivityTier] = useState(activityItem?.tier || ""),
    [activityDescription, setActivityDescription] = useState(activityItem?.description || ""),
    activities_options = useCallback(() => {
      if (activityType === 'Honors') return TIERS_HONORS;
      return TIERS_EXTRACURRICULARS;
    }, [activityType]),
    activities_name = useCallback(() => {
        if (!activityTier) return null;
        if (activityType === 'Honors') return t(`PROFILE.HONORS_FIELDS.${activityTier}`);
        return t(`PROFILE.EXTRACURRICULARS_FIELDS.${activityTier}`);
    }, [activityTier, activityType, t]),
    ACTIVITY_TYPE = useMemo(() => [
      {
        id: 1,
        label: "PROFILE.ACTIVITY_ITEMS.LABEL_1",
        placeholder: "FIELD.NOT_SELECTED",
        field: "dropdown",
        name: "type",
        defaultValue: {
            name: t(`PROFILE.ACTIVITY_TYPES.${activityType.toUpperCase()}`),
            value: activityType
        },
        value: activityType,
        options: ACTIVITES_TYPES,
      },
    ], [activityType, t]),
    ACTIVITY_INFO = useMemo(() => [
      {
        id: 1,
        label: "PROFILE.ACTIVITY_ITEMS.LABEL_3",
        placeholder: "Enter a name of activity",
        field: "input",
        name: "activity_name",
        type: "text",
        value: activityName,
      },
      {
        id: 2,
        label: "PROFILE.ACTIVITY_ITEMS.LABEL_2",
        placeholder: "FIELD.NOT_SELECTED",
        field: "dropdown",
        name: "tier",
        defaultValue: {
            name: activities_name(),
            value: activityTier
        },
        value: activityTier,
        options: activities_options(),
      },
    ], [activityName, activityTier, activities_name, activities_options]),
    ACTIVITY_DESCRIPTION = useMemo(() => [
      {
        id: 1,
        label: "PROFILE.ACTIVITY_ITEMS.LABEL_4",
        placeholder: "Enter a description of activity",
        field: "input",
        name: "description",
        type: "text",
        value: activityDescription,
        isTextArea: true
      },
    ], [activityDescription]);

    const handleChange = async (e, field, name) => {
      if (field === "dropdown") {
        if (name === "type") setActivityType(e.value);
        if (name === "tier") setActivityTier(e.value);
        return;
      }
      if (field === "input") {
        if (name === "activity_name") setActivityName(e.target.value);
        if (name === "description") setActivityDescription(e.target.value);
        return;
      }
    }

    const handleSubmit = useCallback(() => {
      if (!activityType || !activityTier || !activityName || !activityDescription) {
        alert("Please fill all fields");
        return;
      }
      if (isEdit) {
        updateAdditionalInfo({
          id: activityItem.id,
          data: {
              "activity_name": activityName,
              "tier": activityTier,
              "description": activityDescription,
              "activity_type": activityType,
              "category": 1,
              "user": profile.id
          }
        });
        if (isModal) setShowModal(false);
        return;
      }
      createAdditionalInfo({
        "activity_name": activityName,
        "tier": activityTier,
        "description": activityDescription,
        "activity_type": activityType,
        "category": 1,
        "user": profile.id
      });
      if (isModal) setShowModal(false);
    }, [activityTier, activityType, activityName, activityDescription, profile.id, createAdditionalInfo, isModal, setShowModal])

    return (
        <div className={"activities"}>
          <p className="profile__title">
            {isEdit ? t("PROFILE.EDIT_ACTIVITY") : t('PROFILE.ACTIVITIES')}
          </p>
          <List
            className="activities__letters"
            items={ACTIVITY_TYPE}
            renderItem={(item) => (
              <FormItem
                key={item.id}
                item={item}
                handleChange={handleChange} 
                
              />
            )}
          />
          <List
            className="activities__row"
            items={ACTIVITY_INFO}
            renderItem={(item) => (
              <FormItem
              isTooltip={true}
                key={item.id}
                item={item}
                handleChange={handleChange}
              />
            )}
          />
          <List
            className="activities__letters"
            items={ACTIVITY_DESCRIPTION}
            renderItem={(item) => (
              <FormItem
                key={item.id}
                item={item}
                handleChange={handleChange}
              />
            )}
          />
          {isModal ?
            <div className="add-activity-modal-buttons">
              <ButtonV2
                className="button--primary"
                text={t("BUTTON.CANCEL")}
                onClick={() => {setShowModal(false)}}
              />
              <ButtonV2
                className="button--primary"
                text={isEdit ? t("BUTTON.SAVE") 
                : t("BUTTON.ADD_ACTIVITY")}
                onClick={handleSubmit}
                iconLeft="add-activity"
              />
            </div> :
              <>
                <div className="add-activity-button">
                  <ButtonV2
                    className="button--primary"
                    text={t("BUTTON.ADD_ACTIVITY")}
                    onClick={handleSubmit}
                    iconLeft="add-activity"
                  />
                </div>
                  <div className="move-right">
                    <ButtonV2
                      className="button--primary"
                      text="Next"
                      onClick={handleOnClick}
                      iconRight="arrow-right"
                    />
                  </div>
              </>
          }
        </div>
    );
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dahsboard-graph {
  display: flex;
  flex-direction: column;
  border: 2px solid #5ab2f9;
  padding-bottom: 24px;
  border-radius: 20px;
  width: 100%;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Dashboard/DashboardGraphContent/DashboardGraphContent.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,oBAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;AADJ","sourcesContent":["@import \"src/styles/variables\";\n\n.dahsboard-graph{\n    display: flex;\n    flex-direction: column;\n    border: 2px solid $blue_secondary;\n    padding-bottom: 24px;\n    border-radius: 20px;\n    width: 100%;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

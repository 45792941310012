import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UniversityDetailsCheck, trimNewline } from "@utils/functions";
import { useTranslate, useIsMobile } from "@helpers/hooks";
import { BtnLoader } from "@components/common";
import { LayoutContainer } from "@components/common";
import { UniversityDetailsTabs } from "../UniversityDetailsTabs";
import { OverviewCosts, OverviewIntro, OverviewRanking } from "@components/feature";
import "./Overview.scss";

const Overview = () => {
  const TABS = {
    INTRO: "RECOMMENDATIONS.ADMISSION_TABS.LABEL_1",
    RANKING: "RECOMMENDATIONS.ADMISSION_TABS.LABEL_2",
    COSTS: "RECOMMENDATIONS.ADMISSION_TABS.LABEL_3",
  };



  const isMobile = useIsMobile();
  const [currentTab, setCurrentTab] = useState(TABS.INTRO);
  const { t } = useTranslate();
  const { university, isUniversityLoading } = useSelector((state) => state.university);

  const universityInfo = useMemo(() => {
    return {
      header: [
        {
          id: 1,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_1"),
          value: UniversityDetailsCheck("scholarships", university?.scholarships, t),
        },
        {
          id: 2,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_10"),
          value: UniversityDetailsCheck("graduation_rate", university.general?.graduation_rate, t),
        },
        {
          id: 3,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_3"),
          value: trimNewline(university.degree?.application_deadline),
        },
        {
          id: 4,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_7"),
          value: university.research_output,
          t,
        },
      ],
      about: [
        {
          id: 1,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_5"),
          value: university.general?.about_university,
        },
      ],
      info: [
        {
          id: 1,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_11"),
          value: university?.language,
        },
        {
          id: 2,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_12"),
          value: university?.campus_life?.campus_setting,
        },

        {
          id: 3,
          title: t("RECOMMENDATIONS.OVERVIEW.LABEL_13"),
          value: university?.student?.student_faculty_ratio,
        },
        {
          id: 4,
          title: t("MY_PREFERENCES.PREFERENCES.SECTION_4"),
          value: university?.campus_life?.size,
        },
      ],
      studentsInfo: {
        total: university?.student?.full_time_enrollment,
        inter: university?.international_students,
        interPercent: Math.round(
          (university?.international_students / university?.student?.full_time_enrollment) * 100
        ),
      },
      ratingInfo: [
        {
          id: 1,
          title: "QS",
          value: university?.rating?.QS,
        },
        {
          id: 2,
          title: "Shanghai",
          value: university?.rating?.shanghai,
        },
        {
          id: 3,
          title: "US News",
          value: university?.rating?.us_news,
        },
        {
          id: 4,
          title: "Times Higher Edication",
          value: university?.rating?.the,
        },
      ],
      priceInfo: [
        {
          id: 1,
          title: "RECOMMENDATIONS.COSTS.LABEL_1",
          value: UniversityDetailsCheck("net_price", university?.cost?.net_price, t),
          link: university?.cost?.net_price_calculator_url,
          linkLabel: "RECOMMENDATIONS.COSTS.LABEL_3",
        },
        {
          id: 2,
          title: "RECOMMENDATIONS.COSTS.LABEL_9",
          value: UniversityDetailsCheck("net_price", university?.cost?.in_state_tuition, t),
        },
        {
          id: 3,
          title: "RECOMMENDATIONS.COSTS.LABEL_10",
          value: UniversityDetailsCheck("net_price", university?.cost?.out_of_state_tuition, t),
        },
      ],
    };
  }, [university, t]);

  const getContent = (tab) => {
    switch (tab) {
      case TABS.INTRO:
        return (
          <OverviewIntro
            universityInfo={universityInfo}
            studentsInfo={universityInfo.studentsInfo}
          />
        );
      case TABS.RANKING:
        return <OverviewRanking ratingInfo={universityInfo.ratingInfo} />;
      case TABS.COSTS:
        return <OverviewCosts priceInfo={universityInfo.priceInfo} />;
      default:
        return <LayoutContainer></LayoutContainer>;
    }
  };

  const hasDefinedValuesInArray = (array, property) => {
    return array.some((item) => item[property] !== undefined && item[property] !== null);
  };
  const renderTabs = () => {
    return Object.entries(TABS)
      .filter(([key, label]) => {
        switch (key) {
          case "INTRO":
            return (
              hasDefinedValuesInArray(universityInfo.header, "value") ||
              hasDefinedValuesInArray(universityInfo.about, "value") ||
              hasDefinedValuesInArray(universityInfo.info, "value") ||
              (universityInfo.studentsInfo.total !== undefined &&
                universityInfo.studentsInfo.total !== null)
            );
          case "RANKING":
            return hasDefinedValuesInArray(universityInfo.ratingInfo, "value");
          case "COSTS":
            return hasDefinedValuesInArray(universityInfo.priceInfo, "value");
          default:
            return true;
        }
      })
      .map(([key, label]) => label);
  };

  return (
    <>
      {!isUniversityLoading ? (
        <>
          {!isMobile && <h1 className="overview__title">{t("CATALOG.OVERVIEW")}</h1>}
          <UniversityDetailsTabs
            classNameTabs={"university-details__tabs"}
            classNameTab={"university-details__tab"}
            tabs={renderTabs()}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {getContent(currentTab)}
        </>
      ) : (
        <BtnLoader className={"btn-loader--blue-large"} />
      )}
    </>
  );
};

export default Overview;

import { ButtonV2, Divider } from '@components/common'
import React from 'react'
import ProgressBar from '../ProgressBar/ProgressBar'
import './DashboardExamCard.scss'

const DashboardExamCard = ({examTitle }) => {
  return (
    <div>
        <h3 className="dashboard--subtitle--heading">{examTitle}</h3>
        <div className="progress--bars"> 
            <ProgressBar btnIncluded={true} completed={1490} total={1570} examTitle={examTitle} iconText={"value"}/>
            <ProgressBar completed={1530} total={1570} title={"Students from all backgrounds"}/>
            <Divider
                className={"program-about-skeleton__divider"}
                height={1}
                color={"#D5E4F7"}/>
            <span>Save for the {examTitle} with us and save 30% of your time</span>
            <ButtonV2
                className="button--primary"
                text={`Start ${examTitle} preperation`}/>
        </div>
    </div>
  )
}

export default DashboardExamCard
import ProgressBar from "../ProgressBar"
import './DashboardAssessmentContent.scss'
import { ButtonV2 } from '@components/common';
import { useTranslate } from '@helpers/hooks';

const DashboardAssessmentContent = () => {
  const {t} = useTranslate();

  return (
    <>
    <h1 className="dashboard-heading">{t("DASHBOARD.ASSESSMENT.TITLE")}</h1>
    <div className="dashboard--subtitle">
      <h3 className="dashboard--subtitle--heading">{t("DASHBOARD.ASSESSMENT.COLLEGE_ESSAY")}</h3>
      <ButtonV2
          className="button--transparent btn-text"
          text={t("DASHBOARD.ASSESSMENT.FEEDBACK")}/>
    </div>
    
    <div className="progress--bars">
      <ProgressBar btnIncluded={true} completed={87} examTitle={t("DASHBOARD.PROGRESS_BAR.MOTIVATIONAL_ESSAY")} iconText={t("DASHBOARD.PROGRESS_BAR.ESSAY")}/>
      <ProgressBar completed={80} total={100} title={t("DASHBOARD.PROGRESS_BAR.LABEL_1")}/>
      <ProgressBar completed={80} total={100} title={t("DASHBOARD.PROGRESS_BAR.LABEL_2")}/>
      <ProgressBar completed={80} total={100} title={t("DASHBOARD.PROGRESS_BAR.LABEL_3")}/>
      <ProgressBar completed={80} total={100} title={t("DASHBOARD.PROGRESS_BAR.LABEL_4")}/>
      <ProgressBar completed={80} total={100} title={t("DASHBOARD.PROGRESS_BAR.LABEL_5")}/>
    </div>
    </>
  )
}

export default DashboardAssessmentContent
import {useSelector} from "react-redux";
import {useAction, useTranslate} from "@helpers/hooks";
import { Icon } from "@components/common";
import "./ActivitiesTable.scss";


export default function ActivitiesTable({setActivityItem, setEditModal}) {
  const {t} = useTranslate(),
    {myAdditionalInfo} = useSelector(state => state.questionnaire),
    {deleteAdditionalInfo} = useAction();

  const handleEditActivity = (id) => {
    setActivityItem(myAdditionalInfo.results.find(activity => activity.id === id));
    setEditModal(true);
  }

  const handleDeleteActivity = async (id) => {
    await deleteAdditionalInfo(id);
  }

  return (
    <div className="activities_table">
      <table>
        <thead>
        <tr>
          <th>{t('PROFILE.ACTIVITY_ITEMS.LABEL_1')}</th>
          <th>{t('PROFILE.ACTIVITY_ITEMS.LABEL_2')}</th>
          <th>{t('PROFILE.ACTIVITY_ITEMS.LABEL_3')}</th>
          <th>{t('PROFILE.ACTIVITY_ITEMS.LABEL_4')}</th>
          <th></th>
        </tr>
        </thead>
        {myAdditionalInfo &&
          <tbody>
            {myAdditionalInfo.results.map(activity => (
              <tr key={activity.id}>
                <td>{activity.activity_type}</td>
                <td>{activity.tier}</td>
                <td>{activity.activity_name}</td>
                <td>{activity.description}</td>
                <td className="activities_table__actions">
                  <Icon name="edit-filled" className="activities_table__edit" handleClick={() => handleEditActivity(activity.id)} />
                  <Icon name="delete-trash-filled" className="activities_table__delete" handleClick={() => handleDeleteActivity(activity.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </table>
    </div>
  )
}
export default function findError(error) {
    const tree = Object.values(error);
    const arr = [];
    tree.forEach((element) => {
        if (Array.isArray(element)) {
            arr.push(element);
        } else if (typeof element === 'object' && element !== null) {
            arr.push(findError(element));
        } else {
            arr.push(element);
        }
    });
    return arr.flat(Infinity).join(' ');
}
import {BtnLoader, Option} from "@components/common";
import {useTranslate} from "@helpers/hooks";

export default function AutoCompleteOption({suggestions, filteredSuggestions, selectedSuggestion, isSearching}) {
    const {t} = useTranslate();
    return (
        filteredSuggestions.length > 0
            ? filteredSuggestions.map(({id, name, value}) => (
                <Option
                    key={id}
                    name={name}
                    value={value}
                    disabled={selectedSuggestion.map((suggestion) => suggestion?.name).includes(name)}
                    isMultiSelect
                />
            ))
            : !isSearching
            ? suggestions.length > 0
            ? suggestions.map(({id, name, value}) => (
                <Option
                    key={id}
                    name={name}
                    value={value}
                    disabled={selectedSuggestion.map((suggestion) => suggestion?.name).includes(name)}
                    isMultiSelect
                />
            ))
            : <Option
                name={t("FIELD.NO_DATA")}
                disabled={true}
            />
            : <div className={"auto-complete-option"}>
                <BtnLoader className={"btn-loader--blue"}/>
            </div>
    );
};
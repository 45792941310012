import { useTranslate } from "@helpers/hooks";
import "./DashboardChanceContent.scss";
import {
  DashboardBarsContent,
  DashboardAutocomplete,
} from "@components/feature";
import {useIsMobile} from "@helpers/hooks";
import { BtnLoader } from "@components/common";

export default function DashboardChanceContent({
  data,
  myEducation,
  selectedFilters,
  setSelectedFilters,
}) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();

  return (
    <div className={"dashboard__chances"}>
      {myEducation ? (
        <>
          <h2 className="dashboard__subheading">{t("DASHBOARD.CHANCES.TITLE")} <span>{data?.total}%</span></h2>
          <div className={"dashboard-box"}>
            <div className={"dashboard-chance"}>
              <DashboardBarsContent myEducation={myEducation} uniData={data} />
            </div>
            <DashboardAutocomplete
              data={data?.total}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </div>
        </>
      ) : (
        <BtnLoader className="btn-loader--blue-large dashboard__loader" />
      )}
    </div>
  );
}

import { List } from "@components/common";
import { SidebarListItem } from '@components/common';
import { useTranslate } from "@helpers/hooks";
import { COURSE_ONBOARDING_ROUTE } from "@utils/consts";

const CourseSidebarAccordionList = ({ getListItems, value, courseId, isPathnameWorkshop, isPathnameMock, isCurrentPath, course, pathname, completedIds, isPathnameAccordion }) => {
  const  { t } = useTranslate(),
        courseRoute = `${COURSE_ONBOARDING_ROUTE.replace(":courseId", courseId)}`;
  return (
    <div className={"sidebar__accordion"}>
          <ul className="sidebar__dropdown-list">
            {course && (
              <List
                className={"sidebar__list"}
                firstItem={
                  !isPathnameWorkshop && !isPathnameMock && (
                    <SidebarListItem
                      title={t("LMS.ACCORDION.TITLE")}
                      course={course}
                      value={value}
                      courseRoute={courseRoute}
                      pathname={pathname}
                      isFirstItem={true}
                      isPathnameWorkshop={isPathnameWorkshop}
                      isPathnameMock={isPathnameMock}
                    />
                  )
                }
                items={getListItems(value)}
                renderItem={({ id, title, completed, attendance }) => {
                return (
                  <SidebarListItem
                    key={id}
                    id={id}
                    title={title}
                    course={course}
                    value={value}
                    courseRoute={courseRoute}
                    attendance={attendance} 
                    pathname={pathname}
                    completed={completed}
                    completedIds={completedIds}
                    isFirstItem={false}
                    isCurrentPath={isCurrentPath}
                    isPathnameWorkshop={isPathnameWorkshop}
                    isPathnameMock={isPathnameMock}
                    />
                  )
                }}
              />
            )}
          </ul>
        </div>
  );
};
export default CourseSidebarAccordionList;
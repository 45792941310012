
import { useMemo, useState } from "react";
import { Icon, List } from "@components/common";
import { parseSectionCard } from "@utils/functions";
import "./SectionCard.scss";

export default function SectionCard({ section }) {
  const [expandSection, setExpandSection] = useState(true);
  const sectionObject = useMemo(() => parseSectionCard(section), [section]);

  return (
    <div
      className="section-card"
      onClick={() => {
        setExpandSection(!expandSection);
      }}
    >
      <div className="section-card-header">
        <div className="section-card-title-box">
          <p className="section-card-title">{sectionObject.title}</p>
          <List
            className="section-item-list"
            items={sectionObject.content}
            renderItem={(material) => (
              <li key={material.content} className="section-item-list-card">
                {material.count} {material.content}
              </li>
            )}
          />
        </div>
        <Icon name={expandSection ? "border_arrow_up" : "border_arrow_down"} />
      </div>
      {expandSection && (
        <List
          className="section-card-item-list"
          items={section.lessons}
          renderItem={(lesson) => (
            <div key={lesson.id} className="section-card-lesson">
              <Icon name={lesson.type} />
              <div className="section-card-info">
                <span>{lesson.title}</span>
                <span className="section-card-duration">
                  {lesson.duration ? `${lesson.duration} minutes` : ""}
                </span>
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
}

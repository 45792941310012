import "./DashboardStats.scss"

const DashboardStats = ({text}) => {
  return (
    <div className='dashboard-stats'>
      <div className="dashboard-stats--box">
        <div className="dashboard-stats--title">
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
}

export default DashboardStats
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  border-radius: 4px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1000;
}

.quote-notification.visible {
  opacity: 1;
  transform: translateY(0);
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/QuoteNotification/QuoteNotification.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,wCAAA;EACA,UAAA;EACA,4BAAA;EACA,wCAAA;EACA,aAAA;AACJ;;AAEE;EACE,UAAA;EACA,wBAAA;AACJ","sourcesContent":[".quote-notification {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    background-color: #4CAF50;\n    color: white;\n    padding: 16px;\n    border-radius: 4px;\n    max-width: 300px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    opacity: 0;\n    transform: translateY(-20px);\n    transition: opacity 0.3s, transform 0.3s;\n    z-index: 1000;\n  }\n  \n  .quote-notification.visible {\n    opacity: 1;\n    transform: translateY(0);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { FormItem } from "@components/common";
import React from "react";

export default function TrueFalseQuestion({ question, userAnswer, disabled, onAnswerChange }) {

const correctAnswer = question.question_true_false?.correct_answer

  return (
    <FormItem
      key={question.id}
      item={{
        id: question.id,
        placeholder: "",
        field: "truefalse-quiz",
        value: userAnswer === null ? "" : userAnswer,
        options: [true, false],
        correctAnswer: correctAnswer
      }}
      disabled = {disabled}
      handleChange={(e) => onAnswerChange(e.target.value === 'true')}
    />
  );
}
import { Icon } from "@components/common";
import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { capitalizeFirstLetter } from "@utils/functions";
import { COURSE_LESSON_ROUTE } from "@utils/consts";
import classNames from "classnames";
import "./SectionSidebarItem.scss";

export default function SectionSidebarItem({ lesson, courseId, sectionId, lessonId, pathname }) {
  const lessonRoute = COURSE_LESSON_ROUTE.replace(":courseId", courseId)
    .replace(":sectionId", sectionId)
    .replace(":lessonId", lesson.id);
  const isActiveLesson = `${lesson.id}` === lessonId;
  let icon;
  switch (lesson.type) {
    case "video":
      icon = "lms-video";
      break;
    case "reading":
      icon = "lms-reading";
      break;
    case "practice":
      icon = "lms-practice";
      break;
    default:
      icon = "unknown";
  }

  return (
    <NavLink
      to={lessonRoute}
      className="item"
      activeClassName={classNames({
        "item__active": isActiveLesson,
      })}
    >
      <div className={classNames(`item__icon-circle`, { "item__icon-circle-active": isActiveLesson })}> 
        <Icon name={lesson.completed ? "completed-lesson" : icon} className={lesson.completed ? "icon--completed" : "icon--lms-dark"} />
      </div>
      <div className="item__text">
        <p className="item__title">
          <span className="item__title-type">{capitalizeFirstLetter(lesson.type)}</span>: {lesson.title}
        </p>
        <p className="item__subtitle">{lesson.duration} min</p>
      </div>
    </NavLink>
  );
}

import {useTranslate} from "../../../../helpers/hooks";
import classNames from "classnames";
import "./ValidationError.scss";

export default function ValidationError({className, errors, touched, name}) {
    const {t} = useTranslate();
    return (
        (errors && touched) &&
        <div className={classNames("validation-error", className)}>
            <p className={"validation-error__text"}>
                {touched[name] && t(errors[name])}
            </p>
        </div>
    );
};
const getDuration = (duration) => {
    if (duration) {
        const convertDuration = Number(duration);
        if (!Number.isNaN(convertDuration)) {
            return `${duration} months`;
        }

        return duration;
    } else {
        return "";
    }
};

export {getDuration};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-up {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.pop-up__title {
  font-size: 20px;
  margin-bottom: 20px;
}
.pop-up__text {
  font-size: 18px;
  width: 523px;
}
.pop-up__buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  align-self: flex-end;
  margin-top: 30px;
}
.pop-up__btn {
  height: 32px;
}

@media only screen and (max-width: 481px) {
  .pop-up {
    width: 90%;
  }
  .pop-up__text {
    width: 100%;
  }
  .pop-up__buttons {
    align-self: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/PopupWindow/PopupWindow.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;EACA,mBAAA;AACR;AAEI;EACI,eAAA;EACA,YAAA;AAAR;AAGI;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;AADR;AAII;EACI,YAAA;AAFR;;AAMA;EACI;IACI,UAAA;EAHN;EAIM;IACI,WAAA;EAFV;EAIM;IACI,kBAAA;EAFV;AACF","sourcesContent":[".pop-up {\n    display: flex;\n    flex-direction: column;\n    border-radius: 20px;\n\n    &__title {\n        font-size: 20px;\n        margin-bottom: 20px;\n    }\n\n    &__text {\n        font-size: 18px;\n        width: 523px;\n    }\n\n    &__buttons {\n        display: flex;\n        justify-content: flex-end;\n        column-gap: 20px;\n        align-self: flex-end;\n        margin-top: 30px;\n    }\n\n    &__btn {\n        height: 32px;\n    }\n}\n\n@media only screen and (max-width: 481px) {\n    .pop-up {\n        width: 90%;\n        &__text {\n            width: 100%;\n        }\n        &__buttons {\n            align-self: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

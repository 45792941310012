import classnames from "classnames";
import { useLocation } from "react-router-dom";
import "./Article.scss";

export default function Article({children, className}) {
  const { pathname } = useLocation();
  const condition = (pathname.includes("practice") ? "article__lms" :
  pathname.includes("ongoing") ? "article__mock" :
  pathname.includes("courses/") ? "article__courses" : 
  pathname.includes("courses") ? "article__course" : "article")
    return (
        <article className={classnames(condition, className)}>
            {
                children
            }
        </article>
    );
};
import React, { useEffect } from "react";
import { YMInitializer } from "react-yandex-metrika";
import ym from "react-yandex-metrika";
import { useHistory } from "react-router-dom";
import { env } from "../../../configs";

const YandexMetrikaContainer = () => {
  const history = useHistory();
  const YM_COUNTER_ID = env === "production" ? 97886547 : 97836876;

  useEffect(() => {
    const hit = (url) => {
      ym("hit", url);
    };

    const unlisten = history.listen((location) => {
      hit(location.pathname + location.search);
    });

    hit(window.location.pathname + window.location.search);

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <YMInitializer
      accounts={[YM_COUNTER_ID]}
      options={{
        defer: true,
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      }}
      version="2"
    />
  );
};

export default YandexMetrikaContainer;

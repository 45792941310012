import { useState, useEffect } from "react";
import { ListeningQuestion, QuizQuestionItem, ImageQuestion } from "@components/feature";
import React from "react";
import "./MockQuestions.scss";

export default function MockQuestions({ userAnswers, handleAnswerChange, questionsData, activeContent }) {

  let questionIndex = 0;
  const [renderedQuestions, setRenderedQuestions] = useState([]);

  useEffect(() => {
    const renderQuestions = (questionsData) => {
      return questionsData?.map((question) => {
        let questionKey = question.id;
        if (question.type !== "image_question" && question.type !== "listening_question") {
          questionIndex++;
        }

        switch (question.type) {
          case "image_question":
            return (
              <div key={questionKey} className="question__card-image">
                <ImageQuestion question={question} />
              </div>
            );
          case "listening_question":
            return (
              <div key={questionKey} className="question__card">
                <ListeningQuestion question={question} />
              </div>
            );
          default:
            return (
              <div key={questionKey} className="question__card">
                <QuizQuestionItem
                  question={question}
                  index={questionIndex}
                  userAnswers={userAnswers}
                  handleAnswerChange={handleAnswerChange}
                />
              </div>
            );
        }
      });
    };

    const renderAllQuestions = (questionsData) => {};

    if (activeContent) {
      setRenderedQuestions(renderQuestions(questionsData));
    }
  }, [activeContent, questionsData, userAnswers, handleAnswerChange]);

  return (
    <div className="mock__questions">
      <div className="mock__questions-container">{renderedQuestions}</div>
    </div>
  );
}

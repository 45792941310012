const ADDITIONAL_SELECTS = (selectedTab, tabs) => [
    {
        id: 1, name: "CATALOG.UNIVERSITIES.RATING", from: "universities", field: "rating__QS", fields: [
            {id: "a1", name: "01 - 100", value: "1-100"},
            {id: "a2", name: "101 - 300", value: "101-300"},
            {id: "a3", name: "301 - 500", value: "301-500"},
            {id: "a4", name: "501 - 1 000", value: "501-1000"}
        ]
    },
    {
        id: 2, name: "CATALOG.DURATION", from: "programs", field: "duration_field_test", disabled: selectedTab === tabs.universities, fields: [
            {id: "b1", name: "CHECKBOX.DURATION.SIX", value: "6"},
            {id: "b2", name: "CHECKBOX.DURATION.TWELVE", value: "12"},
            {id: "b3", name: "CHECKBOX.DURATION.TWENTY_FOUR", value: "24"},
            {id: "b4", name: "CHECKBOX.DURATION.THIRTY_SIX", value: "36"}
        ]
    },
    {
        id: 3, name: "CHECKBOX.TUITION_FEE", from: "programs", field: "tuition_fee", fields: [
            {id: "c1", name: "500-1000", value: "500-1000"},
            {id: "c2", name: "1001-5000", value: "1001-5000"},
            {id: "c3", name: "5001-10000", value: "5001-10000"},
            {id: "c4", name: "20000-10000000", value: "20000-10000000"}
        ]
    },
    {
        id: 4, name: "CATALOG.PROGRAMS.LEARN_FORMAT", from: "universities", field: "study_mode", disabled: selectedTab === tabs.universities, fields: [
            {id: "d1", name: "Online", value: "Online"},
            {id: "d2", name: "Executive MBA", value: "Executive MBA"},
            {id: "d3", name: "Full Time", value: "Full Time"},
            {id: "d4", name: "Part Time", value: "Part Time"}
        ]
    },
    {
        id: 5, name: "CATALOG.PROGRAMS.SCHOLARSHIP", from: "universities", field: "scholarships", fields: [
            {id: "e1", name: "BUTTON.YES", value: "Yes"},
            {id: "e2", name: "BUTTON.NO", value: "No"}
        ]
    },
];

export {ADDITIONAL_SELECTS};
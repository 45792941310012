import { BlogService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";


const fetchBlogArticles = createAsyncThunk("blog/fetchAll", async (thunkAPI) => {
    try {
      const res = await BlogService.fetchBlogArticles();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });
  
  const fetchBlogArticle = createAsyncThunk("blog/fetchOne", async (id, thunkAPI) => {
    try {
      const res = await BlogService.fetchBlogArticle(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });

  const fetchResources = createAsyncThunk("blog/fetchResources", async (thunkAPI) => {
    try {
      const res = await BlogService.fetchResources();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });

  const fetchResource = createAsyncThunk("blog/fetchResource", async (id, thunkAPI) => {
    try {
      const res = await BlogService.fetchResource(id);
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });

  export {
    fetchBlogArticles,
    fetchBlogArticle, 
    fetchResources, 
    fetchResource
  }
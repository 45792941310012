import React from "react";
import ReactMarkdown from "react-markdown";

export default function LessonMarkdown({ lesson }) {
  return (
    <div className="lesson__markdown">
      {lesson?.content &&
        lesson.content.map((text, index) => {
          return <ReactMarkdown key={index}>{text?.content_text?.text}</ReactMarkdown>;
        })}
    </div>
  );
}

import { useLocation } from "react-router-dom";
import { WithAuth } from "@helpers/hoc";
import { FallbackError, YandexMetrikaContainer } from "@components/common";
import { AppRouter, SentryFeedback } from "@components/feature";
import { useVisibilityTracking, useBanner } from "@helpers/hooks";
import "./App.scss";

export default function App() {
  const { pathname } = useLocation();
  useVisibilityTracking();
  const { isBannerActive, handleCloseBanner, currentAnnouncement } =
    useBanner();

  return (
    <WithAuth>
      <YandexMetrikaContainer />
      <main
        className={`app ${
          pathname.includes("practice") || pathname.includes("ongoing")
            ? "app-practice"
            : ""
        } ${isBannerActive ? "banner-active" : ""}`}
      >
        <AppRouter
          isBannerActive={isBannerActive}
          onCloseBanner={() => handleCloseBanner(currentAnnouncement?.id)}
        />
      </main>
      <SentryFeedback />
    </WithAuth>
  );
}

import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Suspense} from "react";
import {Provider} from "react-redux";
import {store} from "@store";
import {WithTranslations} from "@helpers/hoc";
import {App} from "@components/feature";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "@styles/index.scss";

import * as Sentry from "@sentry/react";
import { BASE_URL } from "../../configs";
import { FallbackError } from "@components/common";

Sentry.init({
    dsn: "https://0a65746891febc66b232ba1e0bd7a0a1@o4508244514832384.ingest.us.sentry.io/4508244519157760",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        messagePlaceholder: "Пожалуйста, опишите проблему, c которой вы столкнулись",
        messageLabel: "Описание проблемы",
        nameLabel: "Имя",
        emailLabel: "Почта",
        submitButtonLabel: "Отправить отзыв",
        formTitle: "Отправка отзыва",
        namePlaceholder: "Ваше имя",
        emailPlaceholder: "Ваша почта",
        closeButtonLabel: "Закрыть",

        autoInject: false,
      }),

      
    ],

    
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1.0,
    replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
    beforeSend(event) {
      if (event.request) {
        delete event.request.headers;
      }
      return event;
    },
    sendDefaultPii: true,
    replaysOptions: {
      maskAllText: false,
      blockAllMedia: false,
    },
    tracePropagationTargets: [
      "localhost",
      new RegExp(`^${BASE_URL.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")}\\/api`),
    ],
  });

export default function AppContainer() {
    return (
        <Sentry.ErrorBoundary
          fallback={<FallbackError />}
          showDialog={true}
          // onError={() => {
          //   window.location.href = "/cours";
          // }}
        >
          <Provider store={store}>
            <BrowserRouter>
              <ToastContainer />
              <WithTranslations>
                <Suspense fallback={"loading..."}>
                  <App />
                </Suspense>
              </WithTranslations>
            </BrowserRouter>
          </Provider>
        </Sentry.ErrorBoundary>
      );
};
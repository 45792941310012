import { useHistory } from 'react-router-dom';
import { convertSecondsToMinutesAndSeconds } from '@utils/functions';
import { useAction, useTranslate } from '@helpers/hooks';
import { Button, Icon, ShowMoreToggle } from '@components/common';
import './CourseNotesItem.scss';

const CourseNotesItem = ({note}) => {
    const { t } = useTranslate(),
    history = useHistory(),
    { deleteNote } = useAction();
    
    const handleNavigateToVideo = () => {
        history.push(`sections/${note.section_id}/lessons/${note.lesson_id.id}`);
    }

    const onDeleteClick = () => {
        deleteNote(note.id);
    }


    return (
        <div className="course_notes__item">
            <div className="course_notes__timestamp">
                <Button
                    className="btn--transaprrent"
                    iconLeft={<Icon name={"notes-play"} />}
                    text={
                        <div className="link_timecode">
                            <p className="play-video-link">{`${t("LMS.NOTES.PLAY_VIDEO")}`}</p>
                        </div>
                    }
                    onClick={handleNavigateToVideo}
                />
                <p className="course_notes__timecode">{convertSecondsToMinutesAndSeconds(note.timestamp)}</p>
            </div>
            <div className="course_notes__description">
                <ShowMoreToggle text={note.text} />
                <div className="note__buttons">
                    <Button
                        iconLeft={<Icon className="note--red-icon" name="notes-delete" />}
                        className={"btn--transaprrent btn--pill note__button-delete"}
                        text={t("LMS.BUTTON.DELETE")}
                        onClick={onDeleteClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default CourseNotesItem;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__burger {
  display: none;
}

@media screen and (max-width: 1023px) {
  .header__burger {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Header/HeaderBurger/HeaderBurger.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;;AAIA;EACE;IACE,cAAA;EADF;AACF","sourcesContent":["@import \"src/styles/variables\";\n\n.header__burger {\n  display: none;\n}\n\n@media screen and (max-width: 1023px){\n  .header__burger {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

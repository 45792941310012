import classNames from "classnames";
import { useIsMobile } from "@helpers/hooks";
import "./Burger.scss";

export default function Burger({animate, handleClick}) {
    const isMobile = useIsMobile();
    return (
        <div className={classNames("burger", {animate})} onClick={handleClick}>
            <span className={"burger__line"}/>
            <span className={"burger__line"}/>
            <span className={"burger__line"}/>
            {!isMobile && <span className={"burger__line"}/>}
        </div>
    );
};
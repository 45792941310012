import { useState } from 'react';
import { Button, Image, PopupWindow } from '@components/common';
import { useAction, useTranslate } from '@helpers/hooks';
import './HeroClassCard.scss';

const HeroClassCard = ({setIsHeroSelected, item}) => {
    const { t } = useTranslate(),
    {createBadgeCategory} = useAction(),
    [isPopupOpen, setIsPopupOpen] = useState(false);
    
    const handleChooseHero = () => {
        setIsPopupOpen(true);
    }

    const handleCancel = () => {
        setIsPopupOpen(false);
    }

    const handleConfirm = async () => {
        await createBadgeCategory({"badge_category": item.id});
        await setIsPopupOpen(false);
        await setIsHeroSelected(true);
    }

    const getHeroClass = (t, name, gender) => {
        const GENDER = gender.toUpperCase();
        const NAME = name.toUpperCase();
        return t(`LMS.BADGES.HEROES.${GENDER}.${NAME}`)
    }

    const getHeroDescription = (t, name) => {
        const NAME = name.toUpperCase();
        return t(`LMS.BADGES.HEROES.DESCRIPTION.${NAME}`)
    }

    return (
        <div className="hero-card">
            <h4 className="hero-card__title">{getHeroClass(t, item.name, item.gender)}</h4>
            <Image className="hero-card__image" src={item.image} />
            <p className="hero-card__description">{getHeroDescription(t, item.name)}</p>
            <Button
                text={t("LMS.BUTTON.CHOOSE")}
                className="btn--secondary btn--pill hero-card__btn"
                onClick={handleChooseHero}
            />
            {isPopupOpen &&
                <PopupWindow
                    confirmationTitle="LMS.BADGES.LABEL_3"
                    confirmationText="LMS.BADGES.LABEL_4"
                    handleCancel={handleCancel}
                    handleConfirm={handleConfirm}
                />
            }
        </div>
    );
}

export default HeroClassCard;

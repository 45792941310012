export const ESSAY_TYPES = (t) => [
    {
        id: 1,
        name: t("ESSAY.LABELS.US"),
        value: "us",
        icon: "usa"
    },
    {
        id: 2,
        name: t("ESSAY.LABELS.UK"),
        value: "uk",
        icon: "gb"
    },
];

import { useTranslate } from '@helpers/hooks';
import { useState } from 'react';
import './ShowMoreToggle.scss';

const ShowMoreToggle = ({ text, className }) => {
  const [isShown, setIsShown] = useState(false);
  const toggleText = () => setIsShown(!isShown);
  const showToggle = text.length > 100;
  const { t } = useTranslate();

  return (
    <div className="toggle">
      <p className={className}>
        {isShown || !showToggle ? text : `${text.substring(0, 100)}...`}
      </p>
      {showToggle && (
        <span 
          onClick={toggleText}
          className='toggle__text'
        >
          {isShown ? `[${t("BUTTON.VIEW_MORE")}]` : `[${t("BUTTON.VIEW_LESS")}]`}
        </span>
      )}
    </div>
  );
};

export default ShowMoreToggle;

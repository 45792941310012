const findStudyLevel = (id) => {
    if (id) {
        let studyLevelName = "";
        switch (id) {
            case 2:
                studyLevelName = "Bachelors";
                break;
            case 3:
                studyLevelName = "Masters";
                break;
            case 4:
                studyLevelName = "MBA";
                break;
            case 5:
                studyLevelName = "PHD";
                break;
            case 6:
                studyLevelName = "Research";
                break;
            case 7:
                studyLevelName = "Certification";
                break;
            default:
                studyLevelName = "";

        }
        return {
            id,
            name: studyLevelName,
            value: studyLevelName.toLowerCase()
        }
    } else {
        return null
    }
};

export default findStudyLevel;

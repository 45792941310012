// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.university__list-mobile {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Recommendations/UniversityListRecommended/UniversityListRecommended.scss"],"names":[],"mappings":"AAGQ;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAFZ","sourcesContent":["\n.university {\n    &__list {\n        &-mobile {\n            display: flex;\n            flex-direction: column;\n            gap: 16px;\n            margin-bottom: 80px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

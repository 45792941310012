import React from 'react';
import './DashboardChancesStats.scss';
import { DashboardStats } from '@components/feature';
import { ButtonV2 } from '@components/common';
import { useTranslate } from '@helpers/hooks';
import { useAction } from '@helpers/hooks';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import "./DashboardChancesStats.scss";

const DashboardChancesStats = ({ program, university, data, programId }) => {
  const { t } = useTranslate();
  const { addApplication } = useAction();
  const { favorites } = useSelector((state) => state.program);

  const isApplying = favorites?.find(favorite => favorite.id === programId && favorite.is_applying);
  const history = useHistory();
  const handleApplyClick = () => {
    addApplication(programId);
    history.push('/application')
    
  };

  const handleStatusClick = () => {
    history.push('/application')
  };
return (
    <div className="dashboard-chancesStats">
      <div className="dashboard-chancesStats--box">
        <div className="dashboard-chancesStats--text">
          <p>{t('DASHBOARD.CHANCES.TEXT_1')}</p>
          <DashboardStats text={university} />
          <p>{t('DASHBOARD.CHANCES.TEXT_2')}</p>
          <DashboardStats text={program} />
          <p>{t('DASHBOARD.CHANCES.TEXT_3', { data })}</p>
        </div>
        {isApplying ? (
          <ButtonV2
            text={t('DASHBOARD.CHANCES.STATUS')}
            className="button--primary dashboard__status"
            onClick={handleStatusClick}
          />
        ) : (
          <ButtonV2
            text={t('DASHBOARD.CHANCES.BUTTON_TEXT')}
            className="button--primary"
            onClick={handleApplyClick}
            iconRight="arrow-right"
          />
        )}
      </div>
    </div>
  );
};

export default DashboardChancesStats
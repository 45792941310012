import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { capitalizeFirstLetter } from "@utils/functions";

const SidebarListItemNavLink = ({ route, title, isActive }) => {
  return (
    <NavLink
      to={route}
      className="sidebar__dropdown-link"
      activeClassName={classNames({
        "sidebar__dropdown-link-active": isActive
      })}
    >
      {capitalizeFirstLetter(title)}
    </NavLink>
  );
};

export default SidebarListItemNavLink;

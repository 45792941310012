import Skeleton from "react-loading-skeleton";
import {List} from "@components/common";
import "./SettingsSkeleton.scss";

export default function SettingsSkeleton() {
    return (
        <div className={"settings-skeleton"}>
            <Skeleton className={"settings-skeleton__title"} width={187} height={22}/>
            <List
                className={"settings-skeleton__list"}
                items={Array(7).fill(Infinity).map((item, itemId) => itemId)}
                renderItem={(info) => (
                    <li key={info} className={"settings-skeleton__item"}>
                        <div className={"settings-skeleton__field"}>
                            <Skeleton/>
                        </div>
                        <div className={"settings-skeleton__value"}>
                            <Skeleton/>
                        </div>
                    </li>
                )}
            />
            <Skeleton width={187} height={22} style={{marginTop: 60}}/>
            <List
                className={"settings-skeleton__list"}
                items={Array(2).fill(Infinity).map((item, itemId) => itemId)}
                renderItem={(info) => (
                    <li key={info} className={"settings-skeleton__item"}>
                        <div className={"settings-skeleton__field"}>
                            <Skeleton/>
                        </div>
                        <div className={"settings-skeleton__value"}>
                            <Skeleton/>
                        </div>
                    </li>
                )}
            />
        </div>
    );
};
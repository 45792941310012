import { Fragment, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useDebounce, useOutsideClick, useTranslate, useAction } from "@helpers/hooks";
import { SelectProvider } from "@helpers/providers";
import { parseArrToStr } from "@utils/functions";
import {
  AutoCompleteEmpty,
  AutoCompleteField,
  AutoCompleteOption,
  AutoCompleteTag,
  isOne,
  Label,
} from "@components/common";
import "./AutoComplete.scss";

export default function AutoComplete(props) {
  const { t } = useTranslate(),
    {
      className,
      label,
      setSelectedFilters,
      selectedFilters,
      selectedSuggestion,
      placeholder = t("FIELD.CHOOSE"),
      suggestions,
      handleChange,
      defaultValue = "",
      name = "auto-complete",
      isClear,
      handleClear,
      disabled,
      method,
      setIsClear,
      isOne,
    } = props,
    [filteredSuggestions, setFilteredSuggestions] = useState([]),
    [suggestionsActive, setSuggestionsActive] = useState(false),
    [value, setValue] = useState(defaultValue ?? ""),
    [isSearching, setIsSearching] = useState(false),
    [isUpdate, setIsUpdate] = useState(false),
    debounceSearch = useDebounce(value, 700),
    { fetchCountries, fetchCities } = useAction();


    


  const showCompleteHandler = () => {
    handleChange(selectedSuggestion);
    setSuggestionsActive((prev) => !prev);
  };

  const ref = useOutsideClick(() => {
    if (suggestionsActive && selectedFilters.length > 0) {
      setSuggestionsActive(false);
      handleChange(selectedFilters);
    } else {
      setSuggestionsActive(false);
    }
  });

  const handleClick = (e) => {
    if (e.target.innerText.length === 0) setValue("");
    setFilteredSuggestions([]);
    setValue(e.target.innerText);
    setSuggestionsActive(true);
  };

  const handleScroll = (e) => {
    const bottomTolerance = 10;
    const isNearBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + bottomTolerance;
    if (isNearBottom) {
      setIsUpdate(true);
    }
  };
  const selectSuggestionHandler = useCallback(
    (suggestion) => {
      if (
        !selectedFilters ||
        typeof selectedFilters !== "object"
        // || !selectedFilters.hasOwnProperty(name) //commented to test the scholarships bug with autocomplete after clear all 
      ) {
        return;
      }
  
      const copySelectedFilters = { ...selectedFilters };
  
      if (isOne) { 
        // If isOne prop is true, set the array to contain only the current suggestion
        copySelectedFilters[name] = [suggestion];
      } else {
        // Otherwise, add the new suggestion to the existing list
        if (!Array.isArray(copySelectedFilters[name])) {
          copySelectedFilters[name] = [];
        }
        copySelectedFilters[name] = [...copySelectedFilters[name], suggestion];
      }
      setSelectedFilters(copySelectedFilters);
      setValue("");
    },
    [selectedFilters, isOne]
  );

  

  const deleteSuggestionHandler = useCallback(
    (currentSuggestion) => {
      if (!selectedFilters || typeof selectedFilters !== "object") {
        return;
      }

      const updateSelectedFilters = { ...selectedFilters };

      if (!Array.isArray(updateSelectedFilters[name])) {
        updateSelectedFilters[name] = [];
      }

      updateSelectedFilters[name] = updateSelectedFilters[name].filter(
        (item) => item.value != currentSuggestion.value
      );

      if (
        name === "region" &&
        (!updateSelectedFilters["region"] || updateSelectedFilters["region"].length === 0)
      ) {
        updateSelectedFilters["country"] = [];
        updateSelectedFilters["city"] = [];
      }

      if (
        name === "country" &&
        (!updateSelectedFilters["country"] || updateSelectedFilters["country"].length === 0)
      ) {
        updateSelectedFilters["city"] = [];
      }

      setSelectedFilters(updateSelectedFilters);
    },
    [selectedFilters]
  );

  const removeTags = useCallback(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      name: [],
    }));
    handleClear(name);
    handleChange([]);
    setSuggestionsActive(false);
  }, [name]);


  useEffect(() => {
    if (!isClear) return;
    setValue("");
  }, [isClear]);

  useEffect(() => {
    if (
      !selectedFilters ||
      typeof selectedFilters !== "object" ||
      !selectedFilters.hasOwnProperty(name)
    ) {
      return;
    }
    const copySelectedFilters = { ...selectedFilters };
    if (!Array.isArray(copySelectedFilters[name])) {
      copySelectedFilters[name] = [];
    }
    setSelectedFilters(copySelectedFilters);
  }, [isClear]);

  useEffect(() => {
    let isMounted = true;

    if (value && value.length === 0 && method) {
      setIsSearching(true);
      method({ search: null })
        .then(() => {
          if (isMounted) {
            setIsSearching(false);
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsSearching(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [value]);

  useEffect(() => {
    let isMounted = true;

    if (debounceSearch && method) {
      setIsSearching(true);
      method({ search: debounceSearch })
        .then(() => {
          if (isMounted) {
            setIsSearching(false);
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsSearching(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [debounceSearch]);

  useEffect(() => {
    let isMounted = true;

    const asyncOperation = async () => {
      if (
        name !== "country" ||
        suggestions === undefined ||
        selectedFilters["country"] === undefined
      ) {
        return;
      }

      const selectedFiltersCountryIds = parseArrToStr(selectedFilters["country"]).split(",");
      const countrySuggestions = parseArrToStr(suggestions).split(",");

      if (countrySuggestions.length === 0 || selectedFiltersCountryIds.length == 0) {
        return;
      }

      const updateSelectedFilters = { ...selectedFilters };
      selectedFiltersCountryIds.forEach((id) => {
        if (!countrySuggestions.includes(id)) {
          updateSelectedFilters["country"] = updateSelectedFilters["country"].filter(
            (country) => country.value != id
          );
        }
      });

      if (isMounted) {
        setSelectedFilters(updateSelectedFilters);
      }
    };

    asyncOperation();

    return () => {
      isMounted = false;
    };
  }, [suggestions]);

  useEffect(() => {
    let isMounted = true;

    const asyncOperation = async () => {
      if (name !== "city" || suggestions === undefined || selectedFilters["city"] === undefined)
        return;

      const selectedFiltersCityIds = parseArrToStr(selectedFilters["city"]).split(",");
      const citySuggestions = parseArrToStr(suggestions).split(",");

      if (citySuggestions.length === 0 || selectedFiltersCityIds.length == 0) return;

      const updateSelectedFilters = { ...selectedFilters };
      selectedFiltersCityIds.forEach((id) => {
        if (!citySuggestions.includes(id)) {
          updateSelectedFilters["city"] = updateSelectedFilters["city"].filter(
            (city) => city.value != id
          );
        }
      });

      if (isMounted) {
        setSelectedFilters(updateSelectedFilters);
      }
    };

    asyncOperation();

    return () => {
      isMounted = false;
    };
  }, [suggestions]);
  

  useEffect(() => {
    let isMounted = true;
    const loadMoreData = async () => {
      if (!isUpdate) return;

      if (name !== "country" || suggestions === undefined) return;
      const offsetFrom = suggestions.length * 2;

      if (name === "country") {
        await fetchCountries({ limit: offsetFrom, region: parseArrToStr(selectedFilters.region) });
      }

      if (isMounted) {
        setIsUpdate(false);
      }
    };

    loadMoreData();

    return () => {
      isMounted = false;
    };
  }, [isUpdate]);

  useEffect(() => {
    let isMounted = true;

    const loadMoreData = async () => {
      if (!isUpdate) return;

      if (name !== "city" || suggestions === undefined) return;
      const offsetFrom = suggestions.length * 2;

      if (name === "city") {
        await fetchCities({ limit: offsetFrom });
      }

      if (isMounted) {
        setIsUpdate(false);
      }
    };

    loadMoreData();

    return () => {
      isMounted = false;
    };
  }, [isUpdate]);

  return (
    <SelectProvider selectedOption={selectedFilters} changeSelectedOption={selectSuggestionHandler}>
      <Label htmlFor={name} label={label} />
      <div className={classNames(`${isOne ? 'auto-complete-dashboard' : 'auto-complete'}`, className, { disabled })} id={name} ref={ref}>
        <AutoCompleteField
          suggestionsActive={suggestionsActive}
          selectedSuggestion={selectedSuggestion}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          deleteSuggestionHandler={deleteSuggestionHandler}
          handleClick={handleClick}
          showCompleteHandler={showCompleteHandler}
          isClear={isClear}
          setIsClear={setIsClear}
          isOne={isOne}
        />
        { suggestionsActive && (
          <ul
            className={classNames("auto-complete__list", {
              scroll:
                filteredSuggestions.length > 0
                  ? filteredSuggestions.length > 5
                  : suggestions.length > 5,
            })}
            onScroll={handleScroll}
          >
            { suggestions.length > 0 ? (
              <Fragment>
                <AutoCompleteTag
                  selectedSuggestion={selectedSuggestion}
                  deleteSuggestionHandler={deleteSuggestionHandler}
                  removeTags={removeTags}
                />
                <AutoCompleteOption
                  suggestions={suggestions}
                  filteredSuggestions={filteredSuggestions}
                  selectedSuggestion={selectedSuggestion}
                  isSearching={isSearching}
                />
              </Fragment>
            ) : (
              <AutoCompleteEmpty />
            )}
          </ul>
        )}
      </div>
    </SelectProvider>
  );
}

import {useCallback, useEffect, useState} from "react";
import {useOutsideClick, useWindowSize} from "@helpers/hooks";
import {AdditionalSelectList, AdditionalSelectOption, Label} from "@components/common";
import classNames from "classnames";
import "./AdditionalSelect.scss";

export default function AdditionalSelect(props) {
    const {
        className, label, placeholder, options,
        defaultOption, onClick, isClear, setIsClear,
        setSelectedFilters
    } = props;

    const [showOptions, setShowOptions] = useState(false),
        [selectedOptions, setSelectedOptions] = useState(defaultOption || []),
        [selectedField, setSelectedField] = useState({}),
        ref = useOutsideClick(() => setShowOptions(false)),
        windowSize = useWindowSize();

    const showOptionsHandler = () => setShowOptions(showOptions => !showOptions);

    const selectOptionHandler = useCallback((option, field) => {
        if (selectedOptions.map((selectedOption) => selectedOption.id).includes(option.id)) {
            setSelectedOptions(prev => prev.filter((prevOption) => prevOption.id !== option.id));
            setSelectedField(prev => ({...prev, [field]: prev[field].filter((prevOption) => prevOption.id !== option.id)}));
            onClick({...selectedField, [field]: selectedField[field].filter((prevOption) => prevOption.id !== option.id)});
        } else {
            setSelectedOptions(prev => [...prev, {...option, field}]);
            setSelectedField(prev => ({...prev, [field]: prev[field] ? [...prev[field], option] : [option]}));
            onClick({...selectedField, [field]: selectedField[field] ? [...selectedField[field], option] : [option]});
        }
    }, [selectedOptions, onClick]);

    const deleteSuggestionHandler = (currentSuggestion) => {
        setSelectedOptions(prev => prev.filter((suggestion) => suggestion.value !== currentSuggestion.value));
        setSelectedField(prev => ({...prev, [currentSuggestion.field]: prev[currentSuggestion.field].filter((suggestion) => suggestion.value !== currentSuggestion.value)}));
        onClick({...selectedField, [currentSuggestion.field]: selectedField[currentSuggestion.field].filter((suggestion) => suggestion.value !== currentSuggestion.value)});
    };

    const removeTags = () => {
        setSelectedOptions([]);
        onClick({});
        setSelectedFilters(prev => ({
            ...prev,
            rating__QS: [],
            duration_field_test: [],
            tuition_fee: [],
            study_mode: [],
            scholarships: []
        }));
        setSelectedField({});
    };

    useEffect(() => {
        if (windowSize.width < 574) {
            setShowOptions(false);
        }
    }, [windowSize]);

    useEffect(() => {
        if (isClear) {
            setSelectedOptions([]);
            setIsClear(false);
        }
        return () => {
            setIsClear(false);
        }
    }, [isClear])
    return (
        <div className={classNames("additional-select", className)}>
            <Label
                htmlFor={"additional-select"}
                label={label}
            />
            <div
                className={"additional-select__select"}
                id={"additional-select"}
                ref={ref}
            >
                <AdditionalSelectOption
                    showOptions={showOptions}
                    selectedOptions={selectedOptions}
                    placeholder={placeholder}
                    showOptionsHandler={showOptionsHandler}
                    deleteSuggestionHandler={deleteSuggestionHandler}
                />
                <AdditionalSelectList
                    showOptions={showOptions}
                    options={options}
                    selectedOptions={selectedOptions}
                    selectOptionHandler={selectOptionHandler}
                    removeTags={removeTags}
                    deleteSuggestionHandler={deleteSuggestionHandler}
                />
            </div>
        </div>
    );
};
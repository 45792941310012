import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useAction } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { Image, LayoutContainer, Icon, List, CustomLink, BtnLoader, Divider, DividerVertical } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import { formatData } from "@utils/functions";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useIsMobile } from "@helpers/hooks";

import "./BlogArticlePage.scss";
import { BlogArticlesList } from "../BlogArticlesList";

export default function BlogArticlePage() {
  const { blogId } = useParams();
  const { article, isArticleLoading } = useSelector((state) => state.blog);
  const { fetchBlogArticle } = useAction();
  const { t } = useTranslate();
  const isMobile = useIsMobile();

  const SOCIALS = [
    {
      id: 1,
      icon: "insta_blog",
      link: "https://www.instagram.com/smartestprep/",
    },
    {
      id: 2,
      icon: "tiktok_blog",
      link: "https://www.tiktok.com/@smartestprep.int",
    },
    {
      id: 3,
      icon: "linkedin_blog",
      link: "https://www.linkedin.com/company/smartestprep/",
    },
  ];

  let articleDate = formatData(article?.created_at, 2);

  useEffect(() => {
    fetchBlogArticle(blogId);
  }, [blogId]);

  return (
    <LayoutContainer className="blog__page-container">
      {article && (
        <div className="blog__page">
          <div className="blog__page-group">
            <div className={"blog__page-group-title"}>
              <h2 className={isMobile ? "blog__page-title-mobile" : "blog__page-title" }>{article?.title}</h2>
            </div>
            <div className="blog__page-divider" />
            <div className={isMobile ? 'blog__page-group-content-mobile' : 'blog__page-group-content'}>
              <div className={isMobile ? 'blog__page-group-article-mobile' : 'blog__page-group-article'}> 
                {isArticleLoading ? <BtnLoader className={"btn-loader--primary"} /> : <Image src={article?.image} className="blog__page-image" />}
                <ReactMarkdown className="blog__page-text">{article?.text}</ReactMarkdown>
              </div>

              {!isMobile && (
                <div className="blog__page-group-info">
                  <div className="blog__page-group-info-up">
                    <div className="blog__page-group-author">
                      <Image src={article?.user_image} className="blog__page-avatar" />
                      <p className="blog__page-author">{article.author}</p>
                    </div>
                    <div className="blog__page-topic">{article.category.name}</div>
                    <div className="blog__page-group-vertical">
                      <div className="blog__page-group-horizontal">
                        <Icon name="views" className="blog__page-views-icon" />
                        <p className="blog__page-views">
                          {article.views_count} {t("BLOG.VIEWS")}
                        </p>
                      </div>

                      <div className="blog__page-group-horizontal">
                        <Icon name="blog_date" className="blog__page-views-icon" />
                        <p className="blog__page-views">{articleDate}</p>
                      </div>
                    </div>
                  </div>

                  <List
                    className="blog__page-group-socials"
                    items={SOCIALS}
                    renderItem={(item) => (
                      <li key={item.id} className="">
                        <CustomLink isExternal={true} to={item.link}>
                          <Icon name={item.icon} className="blog__page-group-socials-icon" />
                        </CustomLink>
                      </li>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="blog__page-divider" />
          <div className="blog__page-group-also">
            <h4 className="blog__page-group-also-title">{t("BLOG.ALSO")}</h4>
            <BlogArticlesList blogId={blogId} isAdditional={true} />
          </div>
        </div>
      )}
    </LayoutContainer>
  );
}

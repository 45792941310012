import { createSlice } from "@reduxjs/toolkit";
import {
  getMyNotifications,
  markMyNotifications,
} from "@store/actions/notificationAction";

const initialState = {
  notifications: [],
  isLoading: false,
  error: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: {
    [getMyNotifications.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getMyNotifications.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.notifications = action.payload;
    },
    [getMyNotifications.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [markMyNotifications.pending.type]: (state) => {
      state.isLoading = true;
    },
    [markMyNotifications.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [markMyNotifications.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default notificationSlice.reducer;

import { useMemo } from "react";
import {useHistory} from "react-router-dom";
import { ProgramCard, ActionsMenu } from "@components/common";
import { useAction, useTranslate } from "@helpers/hooks";
import {DASHBOARD_ROUTE} from "@utils/consts";
import "./FavoritesCard.scss";

const FavoritesCard = ({ item }) => {
  const { t } = useTranslate(),
    {push} = useHistory(),
    { deleteFavorite, addApplication } = useAction(),
    info_items = useMemo(
      () => [
        { id: 1, name: t("RECOMMENDATIONS.MAJOR.LABEL_3"), value: [item.logo, item.name] },
        { id: 2, name: t("RECOMMENDATIONS.MAJOR.LABEL_8"), value: item.university },
        { id: 3, name: t("RECOMMENDATIONS.MAJOR.LABEL_9"), value: [item.city, item.country] },
        { id: 4, name: t("RECOMMENDATIONS.MAJOR.LABEL_10"), value: item.ielts },
        { id: 5, name: t("RECOMMENDATIONS.MAJOR.LABEL_11"), value: item.tuition_fee },
        { id: 6, name: t("RECOMMENDATIONS.MAJOR.LABEL_6"), value: item.duration_field_test },
        { id: 7, name: t("RECOMMENDATIONS.MAJOR.LABEL_12"), value: item.application_deadline },
        { id: 8, name: t("FAVORITE.STATUS.TITLE"), value: item.is_applying },
      ],
      [item, t]
    );

  const handleSave = () => {
    deleteFavorite(item.id);
  };

  const handleAdd = () => {
    addApplication(item.id);
  };

  const handleCalculate = (e) => {
    push({
      pathname: DASHBOARD_ROUTE,
      search: `?programId=${item.id}`
    });
  };

  const actions = useMemo(() => {
    const items = [{ id: 2, name: t("FAVORITE.ACTION.REMOVE_SAVED"), click: handleSave, icon: "save-program" }];
    if (!info_items[7].value) {
      items.unshift({ id: 1, name: t("FAVORITE.ACTION.ADD_APPLICATION"), click: handleAdd, icon: "add-program" });
    }
    return items;
  }, [t, info_items]);

  return (
    <ProgramCard
      info_items={info_items}
      actions={actions}
      handleClick={(e) => handleCalculate(e)}
      buttonText={t("TOOLTIP.CALCULATE_CHANCE")}
      action_menu={<ActionsMenu actions={actions} />}
    />
  );
};

export default FavoritesCard;

import {useTranslate} from "@helpers/hooks";
import "./AutoCompleteEmpty.scss";

export default function AutoCompleteEmpty() {
    const {t} = useTranslate();
    return (
        <li className={"auto-complete-empty"}>
            <p className={"auto-complete-empty__text"}>
                {t("FIELD.NO_DATA")}
            </p>
        </li>
    );
};
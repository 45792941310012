// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-essays .icon--saved-dropdown > svg > path {
  fill: none;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Essays/GeneralEssays/GeneralEssays.scss"],"names":[],"mappings":"AAIQ;EACI,UAAA;AAHZ","sourcesContent":["@import \"@styles/variables\";\n\n.general-essays {\n    .icon--saved-dropdown {\n        & > svg > path {\n            fill: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export default function parseDirectories(arr) {
    if (arr.length > 0) {
        return arr.map((directory) => {
            return {
                id: directory.id,
                name: directory.name ?? directory.title,
                value: directory.id
            }
        });
    } else {
        return [];
    }
};
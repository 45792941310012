import {Icon} from "@components/common";
import classNames from "classnames";
import "./Tag.scss";

export default function Tag({className, name, handleDelete, isDelete=true, isOne}) {
    return (
        <div
            className={classNames(`${isOne ? 'tag__chance' : 'tag'}`, className)}
        >
            <p className={isOne ? "tag__chance-text" : "tag__text"}>
                {
                    name
                }
            </p>
            {
                isDelete && <Icon
                    className={"tag__icon icons--pointer"}
                    name={"close"}
                    handleClick={handleDelete}
                />
            }
        </div>
    );
};
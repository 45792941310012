import { Icon } from '@components/common';
import './MobileHeader.scss';

const MobileHeader = ({title, onClickToGoBack}) => {
    return (
        <div className="mobile-header">
            <Icon className="mobile-header__arrow-back" name="arrow-back" handleClick={onClickToGoBack}/>
            <h4>{title}</h4>
        </div>
    );
}

export default MobileHeader;

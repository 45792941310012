import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@helpers/hooks';
import { BtnLoader, FormItem, LayoutContainer, NewModalLayout } from '@components/common';
import { EssayDetailsHeader, EssayFeedback, EssayModal } from '@components/feature';
import './EssayDetails.scss';

const EssayDetails = ({setIsEssayOpen, currentEssayTab}) => {
    const { t } = useTranslate(),
    [isEditOpen, setIsEditOpen] = useState(false),
    { generalEssay, universitySpecificEssay, isEssayLoading, isUpdating } = useSelector(state => state.essays),
    [currentItem, setCurrentItem] = useState(null),
    item = {
        id: 1,
        label: t("ESSAY.LABELS.LABEL_8", {word_limit: currentItem?.word_limit}),
        field: "input",
        name: "essay",
        type: "text",
        value: currentItem?.text,
        isTextArea: true,
        isDisabled: true,
    }

    useEffect(() => {
        if (currentEssayTab.value === "GENERAL_ESSAYS") {
            setCurrentItem(generalEssay);
        }
        if (currentEssayTab.value === "UNIVERSITY_SPECIFIC_ESSAYS") {
            setCurrentItem(universitySpecificEssay)
        }
    }, [generalEssay, universitySpecificEssay]);

    const handleGoBack = () => {
        setIsEssayOpen(false);
    }

    const handleOpenEdit = () => {
        setIsEditOpen(true);
    }

    return (
        <LayoutContainer>
            {
                ( !isEssayLoading && !isUpdating && generalEssay ) ?
                <div className='essay-details'>
                    <EssayDetailsHeader
                        currentItem={currentItem}
                        handleGoBack={handleGoBack}
                        currentEssayTab={currentEssayTab}
                        handleOpenEdit={handleOpenEdit}
                    />
                    <div className='essay-details__textarea'>
                        <FormItem key={item.id} item={item} />
                    </div>
                    <EssayFeedback currentItem={currentItem}/>
                </div>
                : 
                <div className="parent-loader">
                    <BtnLoader className={"btn-loader--blue-large"} />
                </div>
            }
            {
                isEditOpen && 
                <NewModalLayout>
                    <EssayModal
                        title={t("ESSAY.LABELS.LABEL_10")}
                        setIsModalOpen={setIsEditOpen}
                        isModalOpen={isEditOpen}
                        essay={currentItem}
                        isEdit={true}
                        isUniversitySpecific={currentEssayTab.value === "UNIVERSITY_SPECIFIC_ESSAYS" ? true : false}
                    />
                </NewModalLayout>
            }
        </LayoutContainer>
    );
}

export default EssayDetails;

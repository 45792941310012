import { useEffect, useState } from "react";
import { useOutsideClick, useTranslate } from "@helpers/hooks";
import { locales } from "@utils/consts";
import { Icon, List } from "@components/common";
import classNames from "classnames";
import "./HeaderLanguage.scss";

export default function HeaderLanguage() {
  const { i18n } = useTranslate(),
    [isLanguageOpen, setIsLanguageOpen] = useState(false),
    ref = useOutsideClick(() => setIsLanguageOpen(false));

  const saveLang = async (lang) => {
    localStorage.setItem("lang", lang);
    await i18n.changeLanguage(lang);
    setIsLanguageOpen(false);
  };

  useEffect(() => {
    if (JSON.parse(JSON.stringify(localStorage.getItem("lang"))) === "ru") {
      localStorage.setItem("lang", i18n.language);
      return new Promise(() => i18n.changeLanguage(i18n.language));
    } else {
      localStorage.setItem("lang", "en");
      return new Promise(() => i18n.changeLanguage("en"));
    }
  }, [i18n]);
  return (
    <>
      <div
        className={"header__language"}
        onClick={() => setIsLanguageOpen((isLanguageOpen) => !isLanguageOpen)}
      >
        <Icon name={"languagePrev"} />
        <div className={"language__title"} ref={ref}>
          <p className={"language__name"}>
            {i18n.language === "en" ? "ENG" : "РУС"}
          </p>
          <Icon name={isLanguageOpen ? "arrow-up" : "arrow-down"} />
          {isLanguageOpen && (
            <List
              className={"language__dropdown"}
              items={locales}
              renderItem={(locale) => (
                <li
                  key={locale.id}
                  className={classNames("language__item", {
                    "language__item--selected": i18n.language === locale.name,
                  })}
                  onClick={() =>
                    i18n.language !== locale.name && saveLang(locale.name)
                  }
                >
                  <p>{locale.text}</p>
                </li>
              )}
            />
          )}
        </div>
      </div>
    </>
  );
}

function getQuestionInfo(question) {
  let questionData;
  const questionType = question?.type;

  if (questionType?.includes("mock")) {
    questionData = question[question[question.type]];
  } else if (questionType?.includes("feedback")) {
    questionData = {
      text: question.description,
      answer_options: question.answer_options,
    };
  } else {
    questionData = question["question_" + questionType];
  }

  return questionData;
}

export default getQuestionInfo;

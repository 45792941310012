import {axiosRequest} from "@api/xhr";

export default class UniversityService {
    static async fetchUniversities(params) {
        return await axiosRequest.get("directory/universities/", {params});
    };

    static async fetchUniversity(id) {
        return await axiosRequest.get(`directory/universities/${id}/`);
    };

    static async fetchUniversitySubjects(params) {
        return await axiosRequest.get("directory/subjects/", {params});
    };
};
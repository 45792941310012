import React, { useEffect, useState, useRef } from "react";
import { formatTime } from "@utils/functions";
import "./MockTimer.scss";
import { Button } from "@components/common";

export default function MockTimer({
  endTime,
  handleSubmit,
  stage,
  isTimeUpBannerOpen,
  setIsTimeUpBannerOpen,
}) {
  const [timeLeft, setTimeLeft] = useState(null);
  const hasSubmittedRef = useRef(false);
  const warningTime = 60000;

  const handleTimeUpBanner = () => {
    setIsTimeUpBannerOpen(true);
  };


  useEffect(() => {
    if (endTime && stage !== "finished") {
      const end = new Date(endTime).getTime();
      let timer;

      const updateRemainingTime = () => {
        const now = new Date().getTime();
        const distance = end - now;
        if (distance <= 0 && !hasSubmittedRef.current) {
          clearInterval(timer);
          {
            stage === "crit" ? handleTimeUpBanner() : handleSubmit();
          }
          hasSubmittedRef.current = true;
        } else {
          setTimeLeft(Math.max(distance, 0));
        }
      };

      updateRemainingTime();
      timer = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timer);
    }
  }, [endTime, handleSubmit, stage]);

  return (
    <div>
      <p
        className={`timer__numbers ${
          stage === "finished"
            ? "timer__blue"
            : timeLeft <= warningTime
            ? "timer__red"
            : "timer__green"
        }`}
      >
        {stage === "finished" ? "Review" : formatTime(timeLeft)}
      </p>
    </div>
  );
}

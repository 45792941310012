// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide__header {
  margin-bottom: 16px;
}

.react-joyride__spotlight {
  border-radius: 18px !important;
}

.react-joyride__tooltip {
  border-radius: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/TourGuide/TourGuide.scss"],"names":[],"mappings":"AAEI;EACI,mBAAA;AADR;;AAKA;EACI,8BAAA;AAFJ;;AAKA;EACI,8BAAA;AAFJ","sourcesContent":["\n.guide {\n    &__header {\n        margin-bottom: 16px;\n    }\n}\n\n.react-joyride__spotlight {\n    border-radius: 18px !important;\n}\n\n.react-joyride__tooltip {\n    border-radius: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { NotificationService } from "@api/services/NotificationService";
import { createAsyncThunk } from "@reduxjs/toolkit";

const getMyNotifications = createAsyncThunk(
  "notifications/fetchAll",
  async (id, thunkAPI) => {
    try {
      const res = await NotificationService.getMyNotifications();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const markMyNotifications = createAsyncThunk(
  "notifications/markAll",
  async (id, thunkAPI) => {
    try {
      const res = await NotificationService.markNotifications();
      return res;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export { getMyNotifications, markMyNotifications };

import { Fragment, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { useAction } from "@helpers/hooks";
import {
  Article,
  Header,
  LmsBreadcrumb,
  CourseSidebar,
  LayoutContainer,
  SectionSidebar,
  Image,
} from "@components/common";
import "./LmsLayout.scss";
import { checkIfMarathon } from "@utils/functions";
import classNames from "classnames";

export default function LmsLayout({ children }) {
  const history = useHistory();
  const { courseId } = useParams();
  const { pathname } = useLocation();
  const { fetchBadge } = useAction();
  const { course, badge } = useSelector((state) => state.courses);
  const showBadgeCondition =
    pathname.includes(`courses/${course?.id}`) &&
    !pathname.includes("practice") &&
    badge?.user_id;
  const handleSquidBoard = () => {
    history.push("/courses/13/ranking");
  };

  useEffect(() => {
    fetchBadge();
  }, []);

  return (
    <Fragment>
      {!pathname.includes("practice") && !pathname.includes("ongoing") && (
        <Header />
      )}
      <div className={classNames("lms__content")}>
        {pathname.includes("lessons") &&
          !pathname.includes("practice") &&
          !pathname.includes("ongoing") && <SectionSidebar />}
        {!pathname.includes("lessons") &&
          !pathname.includes("practice") &&
          !pathname.includes("ongoing") && <CourseSidebar />}

        <Article className={"private-layout__article"}>
          {!pathname.includes("lessons") &&
            !pathname.includes("practice") &&
            !pathname.includes("ongoing") &&
            checkIfMarathon(courseId, 13) && (
              <Image
                handleClick={handleSquidBoard}
                className={"banner__img"}
                name={
                  pathname.includes("ranking")
                    ? "ad-banner-web-s.jpg"
                    : "ad-banner-web.jpg"
                }
              />
            )}
          {pathname.includes("practice") || pathname.includes("ongoing") || pathname.includes("info")  ? (
            <Suspense fallback={<div>Загрузка...</div>}>{children}</Suspense>
          ) : (
            <Suspense fallback={<div>Загрузка...</div>}>
              <LayoutContainer className={"layout__equal-padding"}>
                {course ? (
                  <>
                    <LmsBreadcrumb />
                    {children}
                  </>
                ) : (
                  <div>Загрузка...</div>
                )}
              </LayoutContainer>
            </Suspense>
          )}
        </Article>
      </div>
    </Fragment>
  );
}

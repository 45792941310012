import { useTranslate } from '@helpers/hooks';
import { Icon } from '@components/common';
import './EssayFeedback.scss'

const EssayFeedback = ({currentItem}) => {
    const { t } = useTranslate();
    return (
        <div className='essay-feedback__note'>
            <div className='essay-feedback__note-danger'>
                <Icon name='danger' />
                <p>{t("ESSAY.LABELS.LABEL_12")}</p>
            </div>
            <h1 className='essay-feedback__note-title'>{t("ESSAY.LABELS.LABEL_13", {score: currentItem?.score})}</h1>
            <ul className='essay-feedback__note-content'>
                <li>
                    {currentItem?.feedback}
                </li>
            </ul>
        </div>
    );
}

export default EssayFeedback;

function convertIeltsQuestions(inputArray) {
  const processedQuestions = new Set();



  return inputArray?.map((item, index) => {
    if (!item) {
      return;
    }

    if (processedQuestions.has(item.id)) {
      return null;
    }
    processedQuestions.add(item.id);

    let questionData = null;
    let questionType = "";

    // Determine the specific question data based on the type and set the simplified type
    if (item?.writing_text) {
      questionType = "practice";
      questionData = item.ielts_mock_writing_images;
    } else if (item?.speaking_text) {
      questionType = "speaking_question";
      questionData = item.ielts_mock_speaking_images;
    } else {
      switch (item.type) {
        case "ielts_mock_reading_one_choice":
        case "ielts_mock_listening_one_choice":
          questionData =
            item.ielts_mock_reading_one_choice ||
            item.ielts_mock_listening_one_choice;
          questionType = "one_choice";
          break;
        case "ielts_mock_reading_true_false":
        case "ielts_mock_listening_true_false":
          questionData =
            item.ielts_mock_reading_true_false ||
            item.ielts_mock_listening_true_false;
          questionType = "true_false";
          break;
        case "ielts_mock_reading_insert_text":
        case "ielts_mock_listening_insert_text":
          questionData =
            item.ielts_mock_reading_insert_text ||
            item.ielts_mock_listening_insert_text;
          questionType = "insert_text";
          break;
        case "ielts_mock_reading_question_images":
        case "ielts_mock_listening_question_images":
          questionData =
            item.ielts_mock_reading_question_images ||
            item.ielts_mock_listening_question_images;
          questionType = "question_images";
          break;
        case "ielts_mock_reading_multiple_choice":
        case "ielts_mock_listening_multiple_choice":
          questionData =
            item.ielts_mock_reading_multiple_choice ||
            item.ielts_mock_listening_multiple_choice;
          questionType = "multiple_choice";
          break;
        case "ielts_mock_reading_dnd":
        case "ielts_mock_listening_dnd":
          questionData =
            item.ielts_mock_reading_dnd ||
            item.ielts_mock_listening_dnd;
          questionType = "dnd";
          break;
        case "ielts_mock_listening_table":
        case "ielts_mock_reading_table":
          questionData =
            item.ielts_mock_listening_table ||
            item.ielts_mock_reading_table;
          questionType = "table";
          break;
        default:
          questionData = null;
          questionType = "unknown";
      }
    }

    // Common structure for the converted question
    const baseQuestion = {
      id: item.id,
      question_text: item.writing_text || "",
      text: item.question_text || item.speaking_text || "",
      type: questionType,
      question_true_false: null,
      question_multiple_choice: null,
      question_one_choice: null,
      question_image: null,
      question_insert_text: null,
      question_practice: null,
      question_images: null,
      question_speaking: null,
      question_listening: null,
      question_dnd: [],
      question_table: [],
      position: index + 1,
    };

    // Populate the question fields based on the type
    if (questionType === "insert_text") {
      baseQuestion.question_insert_text = questionData
        ? {
            id: questionData.id,
            question_image: questionData?.question_image,
            question_id: questionData.id,
            correct_answer: item?.correct_answer,
            is_correct: item?.is_correct,
            explanation: questionData?.explanation,
            explanation_image: questionData?.explanation_image,
            response: item?.response,
          }
        : null;
    } else if (questionType === "one_choice") {
      baseQuestion.question_one_choice = questionData
        ? {
            id: questionData.id,
            answer_options: questionData.answer_options,
            question_id: questionData.id,
            correct_answer: item?.correct_answer,
            is_correct: item?.is_correct,
            explanation: questionData?.explanation,
            explanation_image: questionData?.explanation_image,
            response: item?.response,
          }
        : null;
    } else if (questionType === "multiple_choice") {
      baseQuestion.question_multiple_choice = questionData
        ? {
            id: questionData.id,
            answer_options: questionData.answer_options,
            question_id: questionData.id,
            correct_answer: item?.correct_answer,
            is_correct: item?.is_correct,
            explanation: questionData?.explanation,
            explanation_image: questionData?.explanation_image,
            response: item?.response,
          }
        : null;
    } else if (questionType === "true_false") {
      baseQuestion.question_true_false = questionData
        ? {
            id: questionData.id,
            question_id: questionData.id,
            correct_answer: item?.correct_answer,
            is_correct: item?.is_correct,
            explanation: questionData?.explanation,
            explanation_image: questionData?.explanation_image,
            response: item?.response,
          }
        : null;
    } else if (questionType === "question_images") {
      baseQuestion.question_images = questionData
        ? {
            id: questionData.id,
            question_id: questionData.id,
            images: questionData?.ielts_mock_listening_images_store[0]?.image,
            correct_answer: item?.correct_answer,
            is_correct: item?.is_correct,
            explanation: questionData?.explanation,
            explanation_image: questionData?.explanation_image,
            response: item?.response,
          }
        : null;
    } else if (questionType === "practice") {
      baseQuestion.question_practice = {
        id: item.id,
        text: item.writing_text || item.speaking_text,
        images_store: questionData
          ? questionData.map((img) => ({
              id: img.id,
              image: img.image,
            }))
          : [],
        response: item?.response,
      };
    } else if (questionType === "speaking_question") {
      baseQuestion.question_speaking = {
        id: item.id,
        text: item.writing_text || item.speaking_text,
        images_store: questionData
          ? questionData.map((img) => ({
              id: img.id,
              image: img.image,
            }))
          : [],
        response: item?.response,
      };
    } else if (questionType === "dnd") {
      baseQuestion.question_dnd.push({
        id: item.id,
        answer_options: questionData.answer_options,
        question_id: questionData.id,
        correct_answer: item.correct_answer,
        is_correct: item.is_correct,
        response: item.response,
        start: questionData.start,
        end: questionData.end,
      });

      // Handle additional DnD questions from the array
      inputArray.forEach((dndItem) => {
        if (
          (dndItem.type === "ielts_mock_reading_dnd" || dndItem.type === "ielts_mock_listening_dnd") &&
          dndItem.id !== item.id &&
          !processedQuestions.has(dndItem.id)
        ) {
          const dndData = dndItem.ielts_mock_reading_dnd || dndItem.ielts_mock_listening_dnd;
          baseQuestion.question_dnd.push({
            id: dndItem.id,
            answer_options: dndData.answer_options,
            question_id: dndData.id,
            start: dndData.start,
            end: dndData.end,
            correct_answer: dndItem?.correct_answer,
            response: dndItem?.response,
            is_correct: dndItem?.is_correct,
          });
          processedQuestions.add(dndItem.id);
        }
      });
    } else if (questionType === "table") {
      baseQuestion.question_table.push({
        id: item.id,
        answer_options: questionData.answer_options,
        question_id: questionData.id,
        correct_answer: item.correct_answer,
        is_correct: item.is_correct,
        response: item.response,
        text: item.question_text
      });

      // Handle additional table questions from the array
      inputArray.forEach((tableItem) => {
        if (
          (tableItem.type === "ielts_mock_reading_table" || tableItem.type === "ielts_mock_listening_table") &&
          tableItem.id !== item.id &&
          !processedQuestions.has(tableItem.id)
        ) {
          const tableData = tableItem.ielts_mock_reading_table || tableItem.ielts_mock_listening_table;
          
          baseQuestion.question_table.push({
            id: tableItem.id,
            answer_options: tableData.answer_options,
            question_id: tableData.id,
            text: tableItem.question_text, 
            correct_answer: tableItem?.correct_answer,
            response: tableItem?.response,
            is_correct: tableItem?.is_correct,
          });
          processedQuestions.add(tableItem.id);
        }
      });
    }

    return baseQuestion;
  }).filter(item => item !== null);
}

export default convertIeltsQuestions;

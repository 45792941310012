// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scholarships__list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 20px;
}
.scholarships__count {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Scholarships/ScholarshipsList/ScholarshipsList.scss"],"names":[],"mappings":"AAGI;EACI,aAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;AAFR;AAKI;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;AAHR","sourcesContent":["@import '@styles/variables';\n\n.scholarships {\n    &__list {\n        display: flex;\n        flex-wrap: wrap;\n        gap: 32px;\n        margin-top: 20px;\n    }\n\n    &__count {\n        margin-top: 20px;\n        font-size: 18px;\n        font-weight: 500;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

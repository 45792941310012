import classNames from "classnames";
import { Icon } from "@components/common";
import "./Radio.scss";

export default function Radio({className, label, labelClassName, isCorrect, ...otherProps}) {
    return (
        <div className={classNames("radio", className)}>
            <label
                className={"radio__label"}
                htmlFor={otherProps.id}
            >
                <input
                    type={"radio"}
                    className={"radio__input"}
                    {...otherProps}
                />
                <span className={"radio__box"}/>
                <p className={`radio__text ${labelClassName} ${labelClassName && "radio__text-quiz"}`}>{label}</p>
                {isCorrect && <Icon name='correct_circle' className='correct-answer'/>}
            </label>
        </div>
    );
};
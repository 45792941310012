import { useEffect } from "react";
import { trackAmplitude } from "@utils/functions";

const useVisibilityTracking = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        trackAmplitude("session_end", {});
      } else if (document.visibilityState === "visible") {
        trackAmplitude("session_start", {});
      }
    };

    trackAmplitude("session_start", {});

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      trackAmplitude("session_end", {});
    };
  }, []);
};

export default useVisibilityTracking;

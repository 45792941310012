// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-list {
  display: flex;
  flex-direction: column;
}
.program-list > li {
  margin-top: 16px;
}
.program-list > li:first-child {
  margin-top: 0;
}
.program-list__skeleton {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.program-list__pagination {
  margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Catalog/ProgramsList/ProgramsList.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,aAAA;AADN;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ;AAME;EACE,gBAAA;AAJJ","sourcesContent":["@import \"src/styles/variables\";\n\n.program-list {\n  display: flex;\n  flex-direction: column;\n\n  & > li {\n    margin-top: 16px;\n\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n\n  &__skeleton {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n\n  &__pagination {\n    margin-top: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


import { LessonNavigation, LessonQuizContent } from "@components/feature";
import { Button, Icon } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import {useIsMobile} from "@helpers/hooks";
import "./LessonQuiz.scss";


export default function LessonQuiz({ lesson, courseId, sectionId, lessonId }) {
  const { t } = useTranslate();
  const isMobile = useIsMobile()

  return (
    <div className="lesson">
      {!isMobile && <LessonNavigation courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />}
      <LessonQuizContent courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />
      {isMobile && <LessonNavigation courseId={courseId} sectionId={sectionId} lessonId={lessonId} lesson={lesson} />}
    </div>
  );
}

import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { debounce, numberFormat, numberFormatWithDash } from "@utils/functions";
import { useAction, useTranslate } from "@helpers/hooks";
import { Button, ButtonV2, FormItem, Icon, List } from "@components/common";
import { useIsMobile } from "@helpers/hooks";
import { trackAmplitude } from "@utils/functions";
import "./AcademicResults.scss";

const LANGUAGE_EXAMS = ["ielts_score", "act_score", "toefl_score", "sat_score"];
const LANGUAGE_EXAM_TYPE = {
  ielts_score: 1,
  act_score: 5,
  toefl_score: 2,
  sat_score: 4,
};
const getLanguageTestScore = (lang_tests, type) => {
  return lang_tests.find((test) => test.exam_type === LANGUAGE_EXAM_TYPE[type])
    ?.points;
};
const updateLangTest = (lang_tests, type, points) => {
  const exam_type = LANGUAGE_EXAM_TYPE[type];
  const test = {
    ...lang_tests.find((test) => test.exam_type === exam_type),
    exam_type,
    points,
  };
  return [...lang_tests.filter((test) => test.exam_type !== exam_type), test];
};

export default function AcademicResults({ handleChangeTab, data }) {
  const isMobile = useIsMobile();
  const [isEdit, setIsEdit] = useState(false);

  const { t } = useTranslate(),
    { myEducation } = useSelector((state) => state.questionnaire),
    { updateEducation, fetchProgramSuccessRate } = useAction(),
    initialValues = useMemo(
      () => ({
        gpa_score: myEducation.gpa_score,
        ib_score: myEducation.ib_score,
        has_cover_letter: myEducation.has_cover_letter,
        has_recommendation: myEducation.has_recommendation,
        lang_tests: myEducation.lang_tests,
        duolingo_score: myEducation.duolingo_score,
      }),
      [myEducation]
    ),
    [values, setValues] = useState(initialValues),
    ACADEMIC_RESULTS = useMemo(
      () => [
        {
          id: 1,
          label: "PROFILE.EDUCATION_ITEMS.LABEL_5",
          placeholder: "4.0",
          field: "input",
          name: "gpa_score",
          type: "number",
          value: values.gpa_score,
          step: "0.01",
          min: 1,
          maxLength: 4,
          max: 4,
          boxWidth: true,
          onKeyPress: numberFormatWithDash,
          validateRange: true,
          className: "input-horizontal",
          inputClassName: "input-score",
        },
        {
          id: 2,
          label: "PROFILE.EXAM_ITEMS.LABEL_1",
          placeholder: "1600",
          field: "input",
          name: "sat_score",
          type: "number",
          min: 400,
          max: 1600,
          maxLength: 4,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "sat_score"),
          onKeyPress: numberFormat,
          validateRange: true,
          className: "input-horizontal",
          inputClassName: "input-score",
        },
        {
          id: 3,
          label: "PROFILE.EXAM_ITEMS.LABEL_2",
          placeholder: "5",
          field: "input",
          name: "act_score",
          type: "number",
          min: 1,
          max: 36,
          maxLength: 2,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "act_score"),
          validateRange: true,
          className: "input-horizontal",
          inputClassName: "input-score",
        },
        {
          id: 5,
          label: "PROFILE.EXAM_ITEMS.LABEL_4",
          placeholder: "9.0",
          field: "input",
          name: "ielts_score",
          type: "number",
          min: 1,
          max: 9,
          step: "0.5",
          maxLength: 3,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "ielts_score"),
          onKeyPress: numberFormatWithDash,
          validateRange: true,
          className: "input-horizontal",
          inputClassName: "input-score",
        },
        {
          id: 6,
          label: "PROFILE.EXAM_ITEMS.LABEL_5",
          placeholder: "120",
          field: "input",
          name: "toefl_score",
          type: "number",
          min: 0,
          max: 120,
          maxLength: 3,
          boxWidth: true,
          value: getLanguageTestScore(values.lang_tests, "toefl_score"),
          onKeyPress: numberFormatWithDash,
          validateRange: true,
          className: "input-horizontal",
          inputClassName: "input-score",
        },
      ],
      [values]
    );

  const handleEditScore = async () => {
    setIsEdit((prevIsEdit) => !prevIsEdit);

    await updateEducation({ id: myEducation.id, data });

    if (isEdit) {
      trackAmplitude("profile_save_click", {
        description:
          "Пользователь успешно сохранил свои академические результаты в данных профиля. ",
        GPA: values.gpa_score,
        IELTS: getLanguageTestScore(values.lang_tests, "ielts_score"),
        TOEFL: getLanguageTestScore(values.lang_tests, "toefl_score"),
        SAT: getLanguageTestScore(values.lang_tests, "sat_score"),
        ACT: getLanguageTestScore(values.lang_tests, "act_score"),
      });
    } else {
      trackAmplitude("profile_edit_click", {
        description:
          "Пользователь вошел в режим редактирования академических результатов",
      });
    }

    fetchProgramSuccessRate(myEducation.program);
  };

  const handleDebounce = useMemo(
    () =>
      debounce(async (data) => {
        await updateEducation({ id: myEducation.id, data });
      }),
    []
  );

  const handleChange = async (e, field, name) => {
    if (field === "select") {
      setValues((prev) => ({ ...prev, [name]: e.value }));
      await handleDebounce({ [name]: e.value });
    } else if (field === "dropdown") {
      setValues((prev) => ({ ...prev, [name]: e.value }));
      await handleDebounce({ [name]: e.value });
    } else {
      if (name === "has_cover_letter" || name === "has_recommendation") {
        const value = e.target.value === "true";
        setValues((prev) => ({ ...prev, [name]: value }));
        await handleDebounce({ [name]: value });
        return;
      }
      let { value, min, max, maxLength } = e.target;
      if (value.length >= 2 && value[0] === "0" && value[1] === "0") {
        value = value.substring(1);
      }

      if (name === "ielts_score") {
        const splitValue = value.split(".");
        if (splitValue.length === 2) {
          if (+splitValue[1] >= 5) {
            value = `${splitValue[0]}.5`;
          } else {
            value = `${splitValue[0]}.0`;
          }
        }
      }

      if (maxLength !== -1) {
        value = value.slice(0, maxLength);
      }

      if (value.length === 0) {
        value = null;
      }

      if (LANGUAGE_EXAMS.includes(name)) {
        // update lang_tests array
        setValues((prev) => ({
          ...prev,
          lang_tests: updateLangTest(prev.lang_tests, name, value),
        }));
        await handleDebounce({
          lang_tests: updateLangTest(values.lang_tests, name, value),
        });
      } else {
        // update individual field
        setValues((prev) => ({ ...prev, [name]: value }));
        await handleDebounce({ [name]: value });
      }
    }
  };

  const disabledCondition = () => {
    // Validate GPA
    const isGpaInvalid =
      values.gpa_score === null ||
      values.gpa_score === "" ||
      values.gpa_score < 1 ||
      values.gpa_score > 4;

    // Validate language exam scores
    const isLangTestInvalid = values.lang_tests.some((test) => {
      switch (test.exam_type) {
        case LANGUAGE_EXAM_TYPE.ielts_score: // IELTS
          return (
            test.points === null ||
            test.points === "" ||
            test.points < 1 ||
            test.points > 9
          );
        case LANGUAGE_EXAM_TYPE.toefl_score: // TOEFL
          return (
            test.points === null ||
            test.points === "" ||
            test.points < 0 ||
            test.points > 120
          );
        case LANGUAGE_EXAM_TYPE.sat_score: // SAT
          return (
            test.points === null ||
            test.points === "" ||
            test.points < 400 ||
            test.points > 1600
          );
        case LANGUAGE_EXAM_TYPE.act_score: // ACT
          return (
            test.points === null ||
            test.points === "" ||
            test.points < 1 ||
            test.points > 36
          );
        default:
          return false;
      }
    });
    return isGpaInvalid || isLangTestInvalid;
  };

  return (
    <div className={isMobile ? "academic_results-mobile" : "academic_results"}>
      <div className="academic_results_header">
        <h2 className="dashboard__subheading">{t("PROFILE.ACADEMIC_RESULTS")}</h2>
        <div className="academic_results-edit" onClick={handleEditScore}>
          <Icon name="edit-filled" />
        </div>
      </div>
      <List
        className="academic_results_list"
        items={ACADEMIC_RESULTS.filter((item) => item.value !== undefined)}
        renderItem={(item) =>
          !isEdit ? (
            <div key={item.id} className="academic_results-item">
              <h4>{t(item.label)}</h4>
              <p>
                {item.value}/{item.max}
              </p>
            </div>
          ) : (
            <FormItem
              key={item.id}
              item={item}
              isDisabled={!isEdit}
              handleChange={handleChange}
              disabled={!isEdit}
            />
          )
        }
      />

      {isEdit && <Button
        className={"btn--primary btn--pill"}
        text={t("BUTTON.SAVE")}
        onClick={handleEditScore}
        iconLeft={!isEdit && <Icon name={"pen_border"} />}
        disabled={disabledCondition()}
      />}
    </div>
  );
}

import {useMemo} from "react";
import {useTranslate} from "@helpers/hooks";
import {Button, CustomLink, Icon, Image, List} from "@components/common";
import "./UniversityItem.scss";

export default function UniversityItem({onSelectUniversity, university}) {
    const {t} = useTranslate(),
        UNIVERSITY_CHARACTERS = useMemo(() => [
            {id: 1, name: "CATALOG.UNIVERSITIES.TYPE", value: university.status},
            {id: 2, name: "CATALOG.UNIVERSITIES.SEARCH_FREQUENCY", value: university.research_output},
            {id: 3, name: "CATALOG.UNIVERSITIES.SCHOLARSHIP", value: university.scholarships ? t("BUTTON.YES") : t("BUTTON.NO")},
            {id: 4, name: "CATALOG.UNIVERSITIES.INTERNATIONAL_STUDENTS", value: university.international_students}
        ], [university, t]);
    return (
        <li className={"university-item"}>
            <Image
                className={"university-item__picture"}
                src={university.logo}
                alt={"university"}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/img/university.jpg";
                }}
            />
            <div className={"university-item__info"}>
                <h6 className={"university-item__name"}>
                    {
                        university.title
                    }
                </h6>
                <div className={"university-item__location"}>
                    <Icon name={"location"}/>
                    <ul className={"location__list"}>
                        <li
                            className={"location__item"}
                        >
                            <p>{university.city.map((city) => city.name).join(", ")}</p>
                        </li>
                    </ul>
                </div>
                <List
                    className={"university-item__characters"}
                    items={UNIVERSITY_CHARACTERS}
                    renderItem={(character) => (
                        <li
                            key={character.id}
                            className={"university-item__character"}
                        >
                            <p className={"character__name"}>
                                {t(character.name)}:
                            </p>
                            <h6 className={"character__value"}>
                                {character.value}
                            </h6>
                        </li>
                    )}
                />
                <div className={"university-item__actions"}>
                    <Button
                        className={"btn--primary btn--pill"}
                        text={t("BUTTON.MORE")}
                        onClick={() => onSelectUniversity(university)}
                    />
                    {
                        university?.uni_website?.length > 0 && <CustomLink
                            className={"underline university-item__link"}
                            isExternal={true}
                            to={university.uni_website}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                        >
                            {t("LINK.GO_WEBSITE")}
                        </CustomLink>
                    }
                </div>
            </div>
        </li>
    );
};
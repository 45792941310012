import { useMemo } from "react";
import { useTranslate, useIsMobile } from "@helpers/hooks";
import { Button, CustomLink, Icon, Image, List } from "@components/common";
import { trackAmplitude } from "@utils/functions";
import "./UniversityCard.scss";

export default function UniversityCard({ onSelectUniversity, university, matchingPrograms, isSearch }) {
  const isMobile = useIsMobile()
  const { t } = useTranslate();

  const UNIVERSITY_CHARACTERS = useMemo(
      () => [{ id: 1, name: "CATALOG.UNIVERSITIES.MATCHING", value: matchingPrograms }],
      [university, t]
    );

    const  UNIVERSITY_CHARACTERS_SEARCH = useMemo(() => [
      {id: 1, name: "CATALOG.UNIVERSITIES.TYPE", value: university.status},
      {id: 3, name: "CATALOG.UNIVERSITIES.SCHOLARSHIP", value: university.scholarships ? t("BUTTON.YES") : t("BUTTON.NO")},
      {id: 4, name: "CATALOG.UNIVERSITIES.INTERNATIONAL_STUDENTS", value: university.international_students}
  ], [university, t]);

  const handleLearn = () => {
    trackAmplitude("recommendation_learn_click", {
      description: 'Пользователь успешно открыл карточку университета',
      university_name: university.title,
      location: university.city[0]?.name,
      matching_programs: matchingPrograms
    })
    onSelectUniversity(university.id)
  }

  return (
    <li className={isMobile ? "university-card-item-mobile" : "university-card-item"}>
      <div className={isMobile ? "university-card-item__first-mobile" : "university-card-item__first"}>
        <Image
          className={"university-card-item__picture"}
          src={university.logo}
          alt={"university"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/img/university.jpg";
          }}
        />
        <h6 className={"university-card-item__name"}>{university.title}</h6>
      </div>

      <div className={isMobile ? "university-card-item__info-mobile" : "university-card-item__info"}>
        <div className={"university-card-item__location"}>
          <Icon name={"location_bold"} className="location__icon" />
          <ul className={"location__list"}>
            <li className={"location__item"}>
              <p>{university.city[0]?.name}</p>
            </li>
          </ul>
        </div>

        <List
          className={"university-card-item__characters"}
          items={isSearch ? UNIVERSITY_CHARACTERS_SEARCH : UNIVERSITY_CHARACTERS}
          renderItem={(character) => (
            <li key={character.id} className={"university-card-item__character"}>
              <p className={isSearch ? "character__name-search" : "character__name"}>{t(character.name)}:</p>
              <h6 className={"character__value"}>{character.value}</h6>
            </li>
          )}
        />

        <div className={"university-card-item__actions"}>
          <Button
            className={"btn--secondary btn--pill"}
            text={t("BUTTON.LEARN_MORE")}
            onClick={()=>handleLearn()}
          />
          {university?.uni_website?.length > 0 && (
            <CustomLink
              className={"university-card-item__link"}
              isExternal={true}
              to={university.uni_website}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              {t("LINK.WEBSITE")}
            </CustomLink>
          )}
        </div>
      </div>
    </li>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-q-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 40px;
}
.welcome-q-start__close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.welcome-q-start__title {
  font-size: 36px;
  text-align: center;
}
.welcome-q-start__subtitle {
  text-align: center;
  margin-top: 20px;
}
.welcome-q-start__img {
  width: 312px;
  height: 312px;
}
.welcome-q-start__btn {
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  font-weight: 400;
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .welcome-q-start__title {
    font-size: 20px;
  }
  .welcome-q-start__subtitle {
    font-size: 14px;
  }
  .welcome-q-start__img {
    width: 160px;
    height: 160px;
  }
}
@media only screen and (max-width: 481px) {
  .welcome-q-start__title {
    font-size: 18px;
  }
  .welcome-q-start__subtitle {
    font-size: 14px;
    padding: 0 10px;
  }
  .welcome-q-start__btn {
    width: 90%;
  }
  .welcome-q-start__img {
    width: 280px;
    height: 280px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/WelcomeQuestionnaire/WelcomeQuestionnaireStart/WelcomeQuestionnaireStart.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AACR;AAEI;EACI,eAAA;EACA,kBAAA;AAAR;AAGI;EACI,kBAAA;EACA,gBAAA;AADR;AAII;EACI,YAAA;EACA,aAAA;AAFR;AAKI;EACI,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;AAHR;AAMI;EACI;IACI,eAAA;EAJV;EAOM;IACI,eAAA;EALV;EAQM;IACI,YAAA;IACA,aAAA;EANV;AACF;AASI;EACI;IACI,eAAA;EAPV;EAUM;IACI,eAAA;IACA,eAAA;EARV;EAWM;IACI,UAAA;EATV;EAYM;IACI,YAAA;IACA,aAAA;EAVV;AACF","sourcesContent":[".welcome-q-start {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    padding-top: 40px;\n\n    &__close {\n        position: absolute;\n        top: 0px;\n        right: 0px;\n    }\n\n    &__title {\n        font-size: 36px;\n        text-align: center;\n    }\n\n    &__subtitle {\n        text-align: center;\n        margin-top: 20px;\n    }\n\n    &__img {\n        width: 312px;\n        height: 312px;\n    }\n\n    &__btn {\n        margin-top: 20px;\n        width: 100%;\n        border-radius: 10px;\n        font-weight: 400;\n    }\n\n    @media only screen and (min-width: 481px) and (max-width: 900px) {\n        &__title {\n            font-size: 20px;\n        }\n\n        &__subtitle {\n            font-size: 14px;\n        }\n\n        &__img {\n            width: 160px;\n            height: 160px;\n        }\n    }\n\n    @media only screen and (max-width: 481px) {\n        &__title {\n            font-size: 18px;\n        }\n        \n        &__subtitle {\n            font-size: 14px;\n            padding: 0 10px;\n        }\n\n        &__btn {\n            width: 90%;\n        }\n\n        &__img {\n            width: 280px;\n            height: 280px;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {Fragment} from "react";
import Skeleton from "react-loading-skeleton";
import {Divider, List} from "@components/common";
import "./UniversityAboutSkeleton.scss";

export default function UniversityAboutSkeleton() {
    const items = [4, 4, 3, 2, 2, 4, 2];
    return (
        <div className={"university-about-skeleton"}>
            <div className={"university-about-skeleton__about"}>
                <div className={"university-about-skeleton__img"}>
                    <Skeleton/>
                </div>
                <div className={"university-about-skeleton__info"}>
                    <div className={"university-about-skeleton__desc"}>
                        <Skeleton count={10}/>
                    </div>
                    <div className={"university-about-skeleton__link"}>
                        <Skeleton/>
                    </div>
                </div>
            </div>
            <Divider
                className={"university-about-skeleton__divider"}
                height={1}
                color={"#F3F2F2"}
            />
            <List
                className={"university-about-skeleton__characters"}
                items={Array(4).fill(Infinity).map((item, itemId) => itemId)}
                renderItem={(character, characterId) => (
                    <li key={characterId} className={"university-about-skeleton__character"}>
                        <Skeleton width={100} height={30}/>
                        <Skeleton width={150} height={34}/>
                    </li>
                )}
            />
            {
                items.map((item, itemId) => (
                    <Fragment key={itemId}>
                        <Divider
                            className={"university-about-skeleton__divider"}
                            height={1}
                            color={"#F3F2F2"}
                        />
                        <div className={"university-about-skeleton__items"}>
                            <div className={"university-about-skeleton__label"}>
                                <Skeleton/>
                            </div>
                            <List
                                className={"university-about-skeleton__options"}
                                items={Array(item).fill(Infinity).map((item, itemId) => itemId)}
                                renderItem={(character, characterId) => (
                                    <li key={characterId} className={"university-about-skeleton__option"}>
                                        <Skeleton height={25}/>
                                        <Skeleton height={25}/>
                                    </li>
                                )}
                            />
                        </div>
                    </Fragment>
                ))
            }
        </div>
    );
};